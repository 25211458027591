import {getDateDDMMYYYY, getHourInFormat12HRS, getLinkImage} from 'src/app/helpers/converters.helper';
import {EVENT_TYPE, EVENT_TYPE_ROUTES} from 'src/app/types/constants/event-type.enum';
import Event from '../events/interfaces/event.interface';
import Evento from 'src/app/interfaces/evento.interface';
import {TYPE_PERMISSIONS} from 'src/app/types/constants/permission.enum';
import * as moment from 'moment';
import {EventDetail, Video, Image, Presentation} from 'src/app/pages/events/interfaces/event-detail.interface';

export const TitleEventPage = (event: EVENT_TYPE_ROUTES) => {
  switch (event) {
    case EVENT_TYPE_ROUTES.ANIVERSARIO:
      return {title: 'Aniversario'};
    case EVENT_TYPE_ROUTES.ASEI_BUSINESS_TOUR:
      return {title: 'Tour Ejecutivo'};
    case EVENT_TYPE_ROUTES.ASEI_MEETING:
      return {title: 'ASEI Meetings'};
    case EVENT_TYPE_ROUTES.ENASEI:
      return {title: 'ENASEI'};
    case EVENT_TYPE_ROUTES.FERIA_NEXO:
      return {title: 'Ferias Nexo Inmobiliario'};
    case EVENT_TYPE_ROUTES.FERIA_LIMA_HOME:
      return {title: 'Ferias Lima Home'};
    case EVENT_TYPE_ROUTES.REUNION_SOCIO:
      return {title: 'Reunión de Asociados'};
    case EVENT_TYPE_ROUTES.COPA_ASEI:
      return {title: 'Copa ASEI'};
    case EVENT_TYPE_ROUTES.ASEI_PIURA:
      return {title: 'ASEI Piura'};
    default:
      return {title: 'Eventos'};
  }
};

export const permissionsFormRequired: TYPE_PERMISSIONS =
  TYPE_PERMISSIONS.CREATE_EVENTS_ASEI_MEETING ||
  TYPE_PERMISSIONS.CREATE_EVENTS_ANNIVERSARY ||
  TYPE_PERMISSIONS.CREATE_EVENTS_ASEI_CUP ||
  TYPE_PERMISSIONS.CREATE_EVENTS_BUSINESS_TOUR ||
  TYPE_PERMISSIONS.CREATE_EVENTS_ENASEI ||
  TYPE_PERMISSIONS.CREATE_EVENTS_PARTNER_MEETING
;

export const permissionsListRequired: TYPE_PERMISSIONS =
  TYPE_PERMISSIONS.READ_EVENTS_ANNIVERSARY ||
  TYPE_PERMISSIONS.READ_EVENTS_ENASEI ||
  TYPE_PERMISSIONS.READ_EVENTS_ASEI_MEETING ||
  TYPE_PERMISSIONS.READ_EVENTS_PARTNER_MEETING ||
  TYPE_PERMISSIONS.READ_EVENTS_BUSINESS_TOUR ||
  TYPE_PERMISSIONS.READ_EVENTS_ASEI_CUP
;

export const parseEvents = (data: any): Event => {
  return {
    id: data.id || 0,
    title: data.title,
    description: data.description ?? "",
    content: data.content ?? "",
    showButtons: data.showButtons ?? false,
    linkImage: getLinkImage(data.linkImage),
    dateInit: getDateDDMMYYYY(data.dateInit),
    dateEnd: getDateDDMMYYYY(data.dateEnd),
    timeInit: getHourInFormat12HRS(data.timeInit),
    timeEnd: getHourInFormat12HRS(data.timeEnd),
    eventType: data.eventType,
    textButtonRegister: data.textButtonRegister ?? '',
    linkRegister: data.linkRegister ?? '',
    phone: data.phone ?? '',
  }
}

export const parseEventsList = (dataList: Evento[]): Event[] => {
  return dataList.map(data => {
    return parseEvents(data);
  });
}

export const getYearOfEvent = (date: string) => {
  return moment(date).format('DD/MM/YYYY').split('/')[2];
};
export const getMonthByDate = (date: string) => {
  return moment(date).format('MM');
};
export const getMonthNameByDate = (date: string) => {
  moment.locale('es'); // Configura moment para usar el locale en español
  const monthName = moment(date).format('MMMM');
  return monthName.toUpperCase();
};

export const getMonthObjectOfEvent = (date: string) => {
  return {
    month: getMonthByDate(date),
    monthName: getMonthNameByDate(date)
  };
};

export const listYearEventContenData = (dataList: Event[] | EventDetail[]): { value: string, text: string }[] => {
  const listYears = dataList.map(data => getYearOfEvent(data.dateInit));
  const listYearsSet = new Set(listYears);
  const listObjetYear: { value: string, text: string }[] = [];
  listYearsSet.forEach(year => listObjetYear.push({value: year, text: year}))
  return listObjetYear;
}
export const listMonthEventContentData = (dataList: Event[] | EventDetail[]): { value: string, text: string }[] => {
  const listMonths = dataList.map(data => getMonthObjectOfEvent(data.dateInit));
  const listMonthsSet = new Set(listMonths);
  const listObjetMonth: { value: string, text: string }[] = [];
  listMonthsSet.forEach(monthObject => listObjetMonth.push({value: monthObject.month, text: monthObject.monthName}))
  return listObjetMonth;
}

const isValidContent = (content: string) => {
  return content &&
    content !== '<p></p>'
}
export const containEventFirsList = (data: EventDetail) => {
  return [
    {
      title: 'Contenido',
      data: isValidContent(data.content) ? [data.content] : null,
    },
    {
      title: 'Fotos',
      data: data.images,
    },
    {
      title: 'Videos',
      data: data.videos,
    },
    {
      title: 'Presentaciones',
      data: data.presentations,
    },
    {
      title: 'Auspiciadores',
      data: data.sponsors,
    },
  ]
}

export const getTypeEventId = (EventType: EVENT_TYPE_ROUTES) => {
  switch (EventType) {
    case EVENT_TYPE_ROUTES.ANIVERSARIO:
      return EVENT_TYPE.ANIVERSARIO;
    case EVENT_TYPE_ROUTES.ASEI_BUSINESS_TOUR:
      return EVENT_TYPE.ASEI_BUSINESS_TOUR;
    case EVENT_TYPE_ROUTES.ASEI_MEETING:
      return EVENT_TYPE.ASEI_MEETING;
    case EVENT_TYPE_ROUTES.COPA_ASEI:
      return EVENT_TYPE.COPA_ASEI;
    case EVENT_TYPE_ROUTES.ENASEI:
      return EVENT_TYPE.ENASEI;
    case EVENT_TYPE_ROUTES.FERIA_NEXO:
      return EVENT_TYPE.FERIA_NEXO;
    case EVENT_TYPE_ROUTES.FERIA_LIMA_HOME:
      return EVENT_TYPE.FERIA_LIMA_HOME;
    case EVENT_TYPE_ROUTES.REUNION_SOCIO:
      return EVENT_TYPE.REUNIÓN_SOCIO;
    case EVENT_TYPE_ROUTES.ASEI_PIURA:
      return EVENT_TYPE.ASEI_PIURA;
    default:
      return 0;
  }
}

type EventDetailNew = Omit<EventDetail, 'id'>

export const getDefaulData = (): EventDetail => {
  return {
    id: 0,
    title: '',
    content: '',
    showButtons: false,
    description: '',
    dateInit: '',
    dateEnd: '',
    timeInit: '',
    timeEnd: '',
    linkImage: '',
    videos: [],
    images: [],
    sponsors: [],
    presentations: [],
    eventType: {
      name: '',
      id: 0
    },
    textButtonRegister: '',
    linkRegister: '',
    phone: '',
    subpage: '',
  }
}

export const parseDatagroupEventDetail = (data: EventDetail[]): EventByGroupYear[] => {
  let dataFormat: EventByGroupYear[] = [];
  listYearEventContenData(data).forEach((year) => {
    const events = data.filter((event) => getYearOfEvent(event.dateInit) === year.value);
    if (events.length > 0) {
      dataFormat.push({title: `${year.text}`, events: getEventGroupFormat(events)});
    }
  })
  return dataFormat;
}
export const parseDatagroupEventDetailMonth = (data: EventDetail[]): EventByGroupYear[] => {
  let dataFormat: EventByGroupYear[] = [];
  listMonthEventContentData(data).forEach((monthObject) => {
    const events = data.filter((event) => getMonthObjectOfEvent(event.dateInit).month === monthObject.value);
    if (events.length > 0) {
      dataFormat.push({title: `${monthObject.value}`, events: getEventGroupFormat(events)});
    }
  })
  return dataFormat;
}

const getEventGroupFormat = (events: EventDetail[]): EventGroupFormat[] => {
  return events.map(event => {
    return parseEventGroupFormat(event)
  })
}
export const parseEventGroupFormat = (event: EventDetail) => {
  return {
    id: event.id || 0,
    title: event.title,
    description: event.description ?? "",
    content: event.content ?? "",
    showButtons: event.showButtons ?? false,
    linkImage: getLinkImage(event.linkImage),
    dateInit: getDateDDMMYYYY(event.dateInit),
    dateEnd: getDateDDMMYYYY(event.dateEnd),
    timeInit: getHourInFormat12HRS(event.timeInit),
    timeEnd: getHourInFormat12HRS(event.timeEnd),
    eventType: event.eventType,
    textButtonRegister: event.textButtonRegister ?? '',
    linkRegister: event.linkRegister ?? '',
    phone: event.phone ?? '',
    detail: getDetailEventByGroup(event),
  }
}

const getDetailEventByGroup = (group: EventDetail): DetailEventByGroup[] => {
  return containEventFirsList(group);
}
export const isFairByEventType = (eventType: EVENT_TYPE_ROUTES): boolean => {
  return (
    eventType === EVENT_TYPE_ROUTES.FERIA_NEXO ||
    eventType === EVENT_TYPE_ROUTES.FERIA_LIMA_HOME
  );
}

export interface EventByGroupYear {
  title: string,
  events: EventGroupFormat[],
}

export interface EventGroupFormat extends Event {
  detail: DetailEventByGroup[]
}

interface DetailEventByGroup {
  title: string,
  data: any[] | null | undefined,
}
