import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {NgForm} from '@angular/forms';
import {AuthService} from "../services/auth.service";
import {firstValueFrom} from "rxjs";

@Component({
  selector: 'app-reset',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  showPassword = false;
  passwordToggleIcon = 'visibility_off';
  email = '';
  password = '';
  token = '';
  showNotification = false;
  isShowLoaderButton = false;
  notifications: Map<string, string> = new Map<string, string>();

  constructor(private route: ActivatedRoute, private router: Router, private authService: AuthService) {
    this.email = this.route.snapshot.queryParams['email'];
    this.token = this.route.snapshot.queryParams['token'];
  }

  async ngOnInit() {
    await this.checkTokenExpiration();
  }

  async checkTokenExpiration() {
    try {
      if (!this.email || !this.token) {
        await this.router.navigate(['/login']);
      }
      let response = await firstValueFrom(this.authService.checkTokenExpirationRequestResetPassword(
        {
          email: this.email,
          token: this.token
        }
      ))
      if (response.body.expired) {
        await this.router.navigate(['/login']);
      }
    } catch (error) {
      await this.router.navigate(['/login']);
    }
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
    this.passwordToggleIcon = this.showPassword ? 'visibility_off' : 'visibility';
  }

  async changePassword() {
    try {
      this.isShowLoaderButton = true;
      if (this.validateNewPassword()) {
        this.showNotification = true;
        await firstValueFrom(this.authService.resetPassword({
          email: this.email,
          token: this.token,
          password: this.password,
          password_confirmation: this.password
        }));
        this.isShowLoaderButton = false;
      } else {
        this.notifications.set('error-reset-validation', 'La contraseña no válida, verfica las instrucciones');
      }
      this.isShowLoaderButton = false;
    } catch (error) {
      this.notifications.set('error-reset', 'hubo un error, intenta nuevamente mas tarde');
      this.isShowLoaderButton = false;
    }
  }

  validateNewPassword() {
    return !!this.password && this.password.length >= 6;
  }
}
