import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'asei-title-section',
  templateUrl: './asei-title-section.component.html',
  styleUrls: ['./asei-title-section.component.scss']
})
export class ASEITitleSectionComponent {
  @Input() title = '';
  @Input() description = '';
  @Input() link = '';
  @Input() hasPermission: boolean = false;
  @Input() version: string = "one";
  @Input() redirectTo: string = '';
  @Input() actionText: string = '';
  @Input() actionExportText: string = '';
  @Input() editLink: string = '';
  @Input() editActionText: string = 'Editar';
  @Input() pClassList: string = '';

  constructor(
    private router: Router,
    private readonly userService: UserService
  ) { }

  redirect(path: string) {
    this.router.navigate([path]);
  }

  onExportExcel() {
    this.userService
      .getUserLoginExcel()
      .subscribe((data) => {
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `users-login.xlsx`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      });
  }
}
