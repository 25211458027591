import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {Observable, Subject} from "rxjs";
import {map} from 'rxjs/operators';
import UserData from "../interfaces/user-data.interface";

const URL = environment.url + '/api/v1';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  private nameUserSubject = new Subject<string>();

  private httpOptions = {};

  constructor(private http: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({
        "Accept": "application/json",
        "Content-Type": "application/json"
      }),
      observe: 'response' as const // Asegura que el tipo de 'observe' sea correcto
    }
  }
  getDirectoryAsei() {
    const url = `${URL}/configuration/directory-asei`;
    return this.http.get<any>(url).pipe(
      map(response => response)
    );
  }
  updateDirectoryAsei(data: any) {
    const url = `${URL}/configuration/directory-asei`;
    return this.http.put<any>(url, JSON.stringify(data), this.httpOptions).pipe(
      map(response => response)
    );
  }
}
