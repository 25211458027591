import {Component, EventEmitter, Output} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss']
})
export class DeleteModalComponent {
  @Output() deleteConfirmed: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(public dialogRef: MatDialogRef<DeleteModalComponent>) {}
  onConfirmDeletion(confirm: boolean): void {
    this.deleteConfirmed.emit(confirm);
    this.dialogRef.close();
  }
}
