<!-- FERIAS -->
@if (showNotifications) {
  <div class="notifications">
    <asei-notifications [notifications]="notifications"></asei-notifications>
  </div>
}
@if (pageInfo) {
  <div class="container_section_large page_with_filter">
    <div class="page filter">
      <asei-title-section
        [link]="pageInfo.slug + '/nuevo'"
        [title]="pageInfo.name"
        [description]="pageInfo.description"
        [hasPermission]="pageInfo.hasPermission.create"
        actionText="Nuevo"
        version="two"
      ></asei-title-section>
      <app-section-filter
        [searchAll]="filterSearch"
        [dateStartFilter]="filterDateStartFormat ?? ''"
        [dateEndFilter]="filterDateEndFormat ?? ''"
        (filterEvent)="onFilter($event)"
      ></app-section-filter>
    </div>
    <div class="page">
      <div class="card-list">
        @for (post of events; track post.id) {
          <asei-card-info-master
            [id]="post.id"
            [title]="post.title"
            [dateInit]="getDateDDMMYYYY(post.dateInit)"
            [dateEnd]="getDateDDMMYYYY(post.dateEnd)"
            [dateTimeInit]="getHourInFormat12HRS(post.timeInit)"
            [dateTimeEnd]="getHourInFormat12HRS(post.timeEnd)"
            [linkImage]="post.linkImage"
            [canEdit]="pageInfo.hasPermission.update"
            [canDelete]="pageInfo.hasPermission.delete"
            ($deleteEventId)="onRemovePost($event)"
            [environment]="pageInfo.slug"
            design="default"
          ></asei-card-info-master>
        }
      </div>
      @if (paginator) {
        <app-paginator [paginatorData]="paginator"
                       (pageSelected)="onPageSelected($event)"
                       design="simple"
        ></app-paginator>
      }
    </div>
  </div>
}
