import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
})
export class SwitchComponent implements OnInit {

  @Input() title: string = '';
  @Input() checked: boolean = false;
  @Input() disabled: boolean = false;
  constructor() { }

  ngOnInit() {}

}
