<asei-home
  [dataCards]="dataCards"
></asei-home>

@if (isShowBanner && currentPopups) {
  <p-galleria
    [value]="currentPopups!"
    [(visible)]="displayBasic"
    [responsiveOptions]="responsiveOptions"
    [containerStyle]="{ 'max-width': '850px' }"
    [numVisible]="5"
    [showItemNavigators]="true"
    [fullScreen]="true"
    [showThumbnails]="true"
    [circular]="true"
    [autoPlay]="true"
    (onHide)="onCloseModal()"
  >
    <ng-template pTemplate="item" let-item>
      <a *ngIf="item.url; else noLink" [href]="item.url" target="_blank">
        <img [src]="getLinkImage(item.image)" class="image-item" />
      </a>
      <ng-template #noLink>
        <img [src]="getLinkImage(item.image)" class="image-item" />
      </ng-template>
    </ng-template>  
      <ng-template pTemplate="thumbnail" let-item>
        <div class="grid grid-nogutter justify-content-center">
            <img [src]="getLinkImage(item.image)"  class="image-thumbnail" />
        </div>
      </ng-template>
  </p-galleria>
}
