import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UserService} from "../../services/user.service";
import {firstValueFrom} from "rxjs";

@Component({
  selector: 'app-user-status-modal',
  templateUrl: './asei-users-status-modal.component.html',
  styleUrls: ['./asei-users-status-modal.component.scss']
})
export class AseiUsersStatusModalComponent implements OnInit{
  title!:string;
  type: string;
  emails: string[];
  constructor(
    public dialogRef: MatDialogRef<AseiUsersStatusModalComponent>,
    private readonly userService:UserService,
    @Inject(MAT_DIALOG_DATA) public data: {
      type:string
      emails: string[]
    }) {
    this.type = data.type;
    this.emails = data.emails;
  }
  ngOnInit(): void {
    this.title = `Usuarios ${this.type === 'active' ? 'Activos' : 'Inactivos'}`
  }
  onExportExcel(){
    const type = this.type;
    this.userService.
      getEmailsUserStatusExcel(type)
      .subscribe((data) => {
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${this.title}.xlsx`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      });
  }
}
