import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class PermissionsInterceptor implements HttpInterceptor {

  constructor(private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentUrl: string = this.router.routerState.snapshot.url;
    const moduleSlug = this.getSlug(currentUrl);
    const action = this.determineAction(request.method, currentUrl);
    let permission = moduleSlug ? `${action} ${moduleSlug}` : '';

    if (permission) {
      request = request.clone({
        headers: request.headers.set('X-Permission', permission)
      });
    }

    return next.handle(request);
  }

  private getSlug(currentRoute: string): string | null {
    if (currentRoute !== '') {
      const cleanedRoute = currentRoute.replace(/^\//, '');
      const parts = cleanedRoute.split('/');
      const filteredParts = parts.filter(part => isNaN(Number(part)) && part !== 'editar' && part !== 'nuevo');
      const result = filteredParts.join('.'); // Unir todas las partes filtradas con un punto

      return result;
    } else {
      return null;
    }
  }

  private determineAction(method: string, currentUrl: string): string {
    switch (method) {
      case 'GET':
        const isReadOne = this.isReadOneRequest(currentUrl);
        return isReadOne ? 'read one' : 'read';
      case 'POST':
        return 'create';
      case 'PUT':
      case 'PATCH':
        return 'update';
      case 'DELETE':
        return 'delete';
      default:
        return '';
    }
  }

  private isReadOneRequest(currentUrl: string): boolean {
    const segments = currentUrl.split('/');
    const isReadOne = segments.length > 1 && !isNaN(+segments[segments.length - 1]);
    return isReadOne;
  }
}
