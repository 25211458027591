import {Component, Input, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {
  getPathByEventType,
  getHourInFormat12HRS,
  getDateDDMMYYYY
} from "../../helpers/converters.helper";
import Event from "../../pages/events/interfaces/event.interface";

@Component({
  selector: 'app-card-aniversario-old',
  templateUrl: './card-asei-aniversario-old.component.html',
  styleUrls: ['./card-asei-aniversario-old.component.scss'],
})
export class CardAseiAniversarioOldComponent implements OnInit {

  @Input() event!: Event;
  @Input() environment = ''

  constructor(private router: Router) {
  }

  ngOnInit() {
  }

  getDay(dateMeet: string): string {
    return dateMeet.slice(-2);
  }

  getMonth(dateMeet: string): string {

    let monthString = [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre'
    ]

    if (dateMeet == '') return '-'
    const [year, month, day] = dateMeet.split('-');
    const date = new Date(Number(year), Number(month) - 1, Number(day));
    const monthIndex = date.getMonth();

    return monthString[monthIndex];
  }

  preview(meet: Event) {
    const env = meet.eventType.name.replace(/\./g, "/")
    const url = `${env}/${meet.id}`; // Ruta dinámica de tu aplicación
    this.router.navigate([url]);
  }

  protected readonly getHourInFormat12HRS = getHourInFormat12HRS;
  protected readonly getDateDDMMYYYY = getDateDDMMYYYY;
}
