<div class="asei-card-info-master_main">
  @if (design == 'default') {
    <div [class]="background">
      <div class="asei-card-info-master_content {{buttonActions&&'asei-card-info-master_content-extends'}}">
        <div class="asei-card-info-master_content-image" (click)="navigateTo(environment)">
          <img [src]="getLinkImagePreview()" alt=""/>
          @if (flagText) {
            <span
              class="asei-card-info-master_content-image--flag"
            >{{ flagText }}</span
            >
          }
        </div>
        <div class="dateTime_container" (click)="navigateTo(environment)">
          <!-- aplica dates-small, solo a los cards del modulo de "asei noticias"-->
          <div class="dates"
               [ngClass]="{'dates-small': environment === 'asei-noticias'}"
          >
            <div class="date">
              <h1 class="day">{{ day }}</h1>
              <p class="month">{{ month }}</p>
            </div>
            @if (dayEnd && monthEnd && (dateInit != dateEnd)) {
              <div class="date">
                <h1 class="day">{{ dayEnd }}</h1>
                <p class="month">{{ monthEnd }}</p>
              </div>
            }
            @if (dateTimeInit) {
              <div class="asei-card-info-master_content-info--time">
                <mat-icon>access_time</mat-icon>
                <span>{{ dateTimeInit }}  {{ dateTimeEnd ? ' - ' + dateTimeEnd : '' }}</span>
              </div>
            }
          </div>
        </div>
        <div class="asei-card-info-master_content-info" (click)="navigateTo(environment)">
          <h2 class="asei-card-info-master_content-info--subtitle">
            {{ title }}
          </h2>
        </div>
        @if (buttonActions) {
          <div class="buttons_actions">
            @if (registrationLink) {
              <button (click)="onRegister()" class="btn primary">
                {{ textButtonRegister || "Registrate" }}
              </button>
            }
            @if (phone) {
              <button (click)="onConfirmAssistance()" class="btn primary">
                Confirmar Asistencia
              </button>
            }
            <button (click)="onAddCalendar()" class="btn secondary">
              Agregar al calendario
            </button>
          </div>
        }
      </div>
    </div>
  }
  @if (design == 'compact') {
    <mat-card [class]="backgroundCompact" (click)="navigateTo(environment)">
      <mat-card-header>
        <div class="asei-card-info-master_content-info--date_compact">
          <mat-icon>calendar_month</mat-icon>
          <span>{{ dateInit }}  {{ dateEnd ? '-' + dateEnd : '' }}</span>
        </div>
        <div class="asei-card-info-master_content-info--time_compact">
          <mat-icon>access_time</mat-icon>
          <span>{{ dateTimeInit }}  {{ dateTimeEnd ? '-' + dateTimeEnd : '' }}</span>
        </div>
      </mat-card-header>
      <mat-card-content>
        <h2 class="asei-card-info-master_content-info--subtitle">
          {{ title }}
        </h2>
      </mat-card-content>
    </mat-card>
  }
  @if (design == 'default' && (canEdit || canDelete)) {
    <div [ngClass]="{'activeActions': isTouchDevice}" class="actions">
      <mat-icon (click)="edit(environment)">edit</mat-icon>
      <mat-icon (click)="openDialogDelete('250ms', '250ms')">delete</mat-icon>
    </div>
  }
</div>
