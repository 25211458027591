import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from "../services/auth.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(private authService: AuthService, private router: Router) {
  }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const isLoggedIn = await this.authService.isLoggedIn();
    const isForgotPasswordRoute =
      state.url.includes('/solicitud-restablecer-contrasena') ||
      state.url.includes('/restablecer-contrasena') ||
      state.url.includes('/login');

      if (state.url.includes('/solicitud-restablecer-contrasena') || state.url.includes('/restablecer-contrasena')) {
        localStorage.removeItem('UserData');
        localStorage.removeItem('currentUrl');
        return true;
      }


     // Si el usuario está autenticado y la ruta es relacionada con olvido de contraseña o inicio de sesión
    if (isLoggedIn && isForgotPasswordRoute) {
      this.router.navigate(['/inicio']);
      return false;
    }

    // Si el usuario no está autenticado y la ruta no es relacionada con olvido de contraseña o inicio de sesión
    if (!isLoggedIn && !isForgotPasswordRoute) {
      localStorage.setItem('currentUrl', state.url);
      this.router.navigate(['/login']);
      return false;
    }

    return true; // Permitir el acceso a la ruta
  }
}
