export enum TYPE_PERMISSIONS{
    'READ_HOME' = 'read home',
    'READ_CALENDARIO' = 'read calendario',
    'READ_NOTILEX' = 'read notilex',
    'READ_EVENTS_ASEI_MEETING' = 'read events.asei-meeting',
    'READ_ONE_EVENTS_ASEI_MEETING' = 'read one events.asei-meeting',
    'CREATE_EVENTS_ASEI_MEETING' = 'create events.asei-meeting',
    'UPDATE_EVENTS_ASEI_MEETING' = 'update events.asei-meeting',
    'DELETE_EVENTS_ASEI_MEETING' = 'delete events.asei-meeting',
    'READ_EVENTS_PARTNER_MEETING' = 'read events.partner-meeting',
    'READ_ONE_EVENTS_PARTNER_MEETING' = 'read one events.partner-meeting',
    'CREATE_EVENTS_PARTNER_MEETING' = 'create events.partner-meeting',
    'UPDATE_EVENTS_PARTNER_MEETING' = 'update events.partner-meeting',
    'DELETE_EVENTS_PARTNER_MEETING' = 'delete events.partner-meeting',
    'READ_EVENTS_ANNIVERSARY' = 'read events.anniversary',
    'READ_ONE_EVENTS_ANNIVERSARY' = 'read one events.anniversary',
    'CREATE_EVENTS_ANNIVERSARY' = 'create events.anniversary',
    'UPDATE_EVENTS_ANNIVERSARY' = 'update events.anniversary',
    'DELETE_EVENTS_ANNIVERSARY' = 'delete events.anniversary',
    'READ_EVENTS_BUSINESS_TOUR' = 'read events.business-tour',
    'READ_ONE_EVENTS_BUSINESS_TOUR' = 'read one events.business-tour',
    'CREATE_EVENTS_BUSINESS_TOUR' = 'create events.business-tour',
    'UPDATE_EVENTS_BUSINESS_TOUR' = 'update events.business-tour',
    'DELETE_EVENTS_BUSINESS_TOUR' = 'delete events.business-tour',
    'READ_EVENTS_ENASEI' = 'read events.enasei',
    'READ_ONE_EVENTS_ENASEI' = 'read one events.enasei',
    'CREATE_EVENTS_ENASEI' = 'create events.enasei',
    'UPDATE_EVENTS_ENASEI' = 'update events.enasei',
    'DELETE_EVENTS_ENASEI' = 'delete events.enasei',

    'READ_EVENTS_ASEI_CUP' = 'read events.asei-cup',
    'READ_ONE_ASEI_CUP' = 'read one events.asei-cup',
    'CREATE_EVENTS_ASEI_CUP' = 'create events.asei-cup',
    'UPDATE_EVENTS_ASEI_CUP' = 'update events.asei-cup',
    'DELETE_EVENTS_ASEI_CUP' = 'delete events.asei-cup',


    'READ_EVENTS_ASEI_PIURA' = 'read events.asei-piura',
    'READ_ONE_ASEI_PIURA' = 'read one events.asei-piura',
    'CREATE_EVENTS_ASEI_PIURA' = 'create events.asei-piura',
    'UPDATE_EVENTS_ASEI_PIURA' = 'update events.asei-piura',
    'DELETE_EVENTS_ASEI_PIURA' = 'delete events.asei-piura',

    'READ_EVENTS_LIMA_HOME_FAIR' = 'read events.lima-home-fair',
    'READ_ONE_EVENTS_LIMA_HOME_FAIR' = 'read one lima-home-fair',
    'CREATE_EVENTS_LIMA_HOME_FAIR' = 'create events.lima-home-fair',
    'UPDATE_EVENTS_LIMA_HOME_FAIR' = 'update events.lima-home-fair',
    'DELETE_EVENTS_LIMA_HOME_FAIR' = 'delete events.lima-home-fair',

    'READ_EVENTS_NEXO_FAIR' = 'read events.nexo-fair',
    'READ_ONE_EVENTS_NEXO_FAIR' = 'read one events.nexo-fair',
    'CREATE_EVENTS_NEXO_FAIR' = 'create events.nexo-fair',
    'UPDATE_EVENTS_NEXO_FAIR' = 'update events.nexo-fair',
    'DELETE_EVENTS_NEXO_FAIR' = 'delete events.nexo-fair',
    'READ_REPORTS_LEGAL' = 'read reports.legal',
    'READ_ONE_REPORTS_LEGAL' = 'read one reports.legal',
    'CREATE_REPORTS_LEGAL' = 'create reports.legal',
    'UPDATE_REPORTS_LEGAL' = 'update reports.legal',
    'DELETE_REPORTS_LEGAL' = 'delete reports.legal',
    'DOWNLOAD_REPORTS_LEGAL' = 'download reports.legal',
    'READ_REPORTS_MARKET' = 'read reports.market',
    'READ_ONE_REPORTS_MARKET' = 'read one reports.market',
    'CREATE_REPORTS_MARKET' = 'create reports.market',
    'UPDATE_REPORTS_MARKET' = 'update reports.market',
    'DELETE_REPORTS_MARKET' = 'delete reports.market',
    'DOWNLOAD_REPORTS_MARKET' = 'download reports.market',

    'READ_REPORTS_EXECUTIVE' = 'read reports.executive',
    'READ_ONE_REPORTS_EXECUTIVE' = 'read one reports.executive',
    'CREATE_REPORTS_EXECUTIVE' = 'create reports.executive',
    'UPDATE_REPORTS_EXECUTIVE' = 'update reports.executive',
    'DELETE_REPORTS_EXECUTIVE' = 'delete reports.executive',
    'DOWNLOAD_REPORTS_EXECUTIVE' = 'download reports.executive',

    'READ_REPORTS_INSTITUTIONALS' = 'read reports.institutionals',
    'READ_ONE_REPORTS_INSTITUtIONALS' = 'read one reports.institutionals',
    'CREATE_REPORTS_INSTITUTIONALS' = 'create reports.institutionals',
    'UPDATE_REPORTS_INSTITUTIONALS' = 'update reports.institutionals',
    'DELETE_REPORTS_INSTITUTIONALS' = 'delete reports.institutionals',
    'DOWNLOAD_REPORTS_INSTITUTIONALS' = 'download reports.institutionals',

    'READ_REPORTS_DEMAND' = 'read reports.demand',
    'READ_ONE_REPORTS_DEMAND' = 'read one reports.demand',
    'CREATE_REPORTS_DEMAND' = 'create reports.demand',
    'UPDATE_REPORTS_DEMAND' = 'update reports.demand',
    'DELETE_REPORTS_DEMAND' = 'delete reports.demand',
    'DOWNLOAD_REPORTS_DEMAND' = 'download reports.demand',


    'READ_POSTS_ASEI_NEWS' = 'read posts.asei-news',
    'READ_ONE_POSTS_ASEI_NEWS' = 'read one posts.asei-news',
    'CREATE_POSTS_ASEI_NEWS' = 'create posts.asei-news',
    'UPDATE_POSTS_ASEI_NEWS' = 'update posts.asei-news',
    'DELETE_POSTS_ASEI_NEWS' = 'delete posts.asei-news',
    'READ_EMPLOYESS_DIRECTORY_AFFILIATES' = 'read employees.directory-affiliates',
    'READ_ONE_EMPLOYESS_DIRECTORY_AFFILIATES' = 'read one employees.directory-affiliates',
    'CREATE_EMPLOYESS_DIRECTORY_AFFILIATES' = 'create employees.directory-affiliates',
    'UPDATE_EMPLOYESS_DIRECTORY_AFFILIATES' = 'update employees.directory-affiliates',
    'DELETE_EMPLOYESS_DIRECTORY_AFFILIATES' = 'delete employees.directory-affiliates',
    'READ_POPUPS' = 'read popups',
    'READ_OEN_POPUP' = 'read one popup',
    'UPDATE_POPUP' = 'update popup',
    'NOTIFICATION_POPUP' = 'notification popup',
    'READ_USER_STATUS' = 'read user-status',
    'READ_DIRECTORIO_ASEI' = 'read directorio-asei',
    'UPDATE_DIRECTORIO_ASEI' = 'update directorio-asei',



}
