import {Component, OnChanges, Input, SimpleChanges, Output, EventEmitter} from '@angular/core';
import { AseiInfo } from 'src/app/types/asei-info';
import { AseiInfoFairs } from 'src/app/types/asei-info-fairs';
import { Router } from '@angular/router';
import {getDateDDMMYYYY, getHourInFormat12HRS, getLinkImage} from "../../helpers/converters.helper";
import Event from "../../pages/events/interfaces/event.interface";
import {DeleteModalComponent} from "../delete-modal/delete-modal.component";
import {MatDialog} from "@angular/material/dialog";



@Component({
  selector: 'asei-card-info',
  templateUrl: './asei-card-info.component.html',
  styleUrls: ['./asei-card-info.component.scss']
})
export class ASEICardInfoComponent implements OnChanges  {
  @Input() aseiCardInfo!: Event;
  @Input() flagText = '';
  @Input() environmentss = '';
  @Input() isShowTitle=true;
  //option background secondary=white || ''=default
  @Input() background=""
  // permissions
  @Input() canEdit = false;
  @Input() canDelete = false;
  @Output() $deleteEventId: EventEmitter<number> = new EventEmitter<number>();

  constructor(public router: Router,
              public dialog: MatDialog) { }

  ngOnChanges(changes: SimpleChanges) {
    if('aseiCardInfo' in changes){
      this.aseiCardInfo = (changes['aseiCardInfo'].currentValue);
    }
  }
  // edit(id: number | undefined) {
  //   if (id) {
  //     const url = '/aniversarios/' + id + '/editar'; // Ruta dinámica de tu aplicación
  //     this.router.navigateByUrl(url);
  //   }
  // }
  edit(environment: string) {
    const url = `${environment}/${this.aseiCardInfo.id}/editar`;
    this.router.navigateByUrl(url);
  }
  navigateTo(environment: string) {
    const url = `/${environment}/${this.aseiCardInfo.id}`;
    this.router.navigate([url]);
  }
  openDialogDelete(enterAnimationDuration: string, exitAnimationDuration: string): void {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      width: '250px',
      enterAnimationDuration,
      exitAnimationDuration,
    });

    dialogRef.componentInstance.deleteConfirmed.subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.$deleteEventId.emit(this.aseiCardInfo.id);
      }
    });
  }

  protected readonly getDateDDMMYYYY = getDateDDMMYYYY;
  protected readonly getLinkImage = getLinkImage;
  protected readonly getHourInFormat12HRS = getHourInFormat12HRS;
}
