import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {getLinkImage} from "../../helpers/converters.helper";
import {TYPE_PERMISSIONS} from "../../types/constants/permission.enum";
import {PermissionsService} from "../../services/permissions.service";
import DataCardHome from "../../interfaces/data-card-home";

@Component({
  selector: 'asei-home',
  templateUrl: './asei-home.component.html',
  styleUrls: ['./asei-home.component.scss'],
})
export class AseiHomeComponent implements OnInit {
  typePermission: typeof TYPE_PERMISSIONS = TYPE_PERMISSIONS;
  @Input()
  dataCards!: DataCardHome[]

  constructor(
    private router: Router,
    private permissionsService: PermissionsService,
  ) {
  }

  async ngOnInit() {
  }

  redirect(path: string) {
    if (path.includes('http')) {
      window.open(path, '_blank');
    }
    this.router.navigate([path]);
  }

  can(action: TYPE_PERMISSIONS) {
    return this.permissionsService.can(action);
  };

  protected readonly getLinkImage = getLinkImage;
  protected readonly TYPE_PERMISSIONS = TYPE_PERMISSIONS;
}
