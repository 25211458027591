<asei-home
  [dataCards]="dataCards"
></asei-home>

@if (isShowBanner && currentPopups) {
  <p-galleria
    [value]="currentPopups!"
    [(visible)]="displayBasic"
    [responsiveOptions]="responsiveOptions"
    [containerStyle]="{ 'max-width': '850px' }"
    [numVisible]="5"
    [showItemNavigators]="true"
    [fullScreen]="true"
    [showThumbnails]="true"
    [circular]="true"
    [autoPlay]="true"
    (onHide)="onCloseModal()"
  >
    <ng-template pTemplate="item" let-item>
        <img [src]="getLinkImage(item.image)" class="image-item" />
    </ng-template>
    <ng-template pTemplate="thumbnail" let-item>
      <div class="grid grid-nogutter justify-content-center">
          <img [src]="getLinkImage(item.image)"  class="image-thumbnail" />
      </div>
  </ng-template>
  </p-galleria>
}
