import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { of, switchMap, firstValueFrom, throwError, catchError } from 'rxjs';
import {EVENT_TYPE, EVENT_TYPE_ROUTES} from 'src/app/types/constants/event-type.enum';
import {TitleEventPage, getDefaulData, getTypeEventId, isFairByEventType} from '../events.helper';
import { EventService } from 'src/app/pages/events/services/event.service';
import Event from '../../events/interfaces/event.interface';
import { EventDetail } from 'src/app/pages/events/interfaces/event-detail.interface';
import { NgForm } from '@angular/forms';
import { FilesService } from 'src/app/services/files.service';
import { environment } from 'src/environments/environment';
import { getLinkImage } from 'src/app/helpers/converters.helper';
import {getErrorMessage} from "../../../helpers/validators.helper";
import VALIDATORS_INPUT from 'src/app/helpers/validators-input.helper';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-events-form',
  templateUrl: './events-form.component.html',
  styleUrls: ['./events-form.component.scss'],
  providers: [MessageService]
})
export class EventsFormComponent implements OnInit {

  @ViewChild('form', { static: false }) public form!: NgForm;
  @ViewChild('top') topElement!: ElementRef;

  eventType!: EVENT_TYPE_ROUTES;
  event!: EventDetail;
  file?: File | '';
  title = '';
  notifications: Map<string, string> = new Map<string, string>();
  typeFile = '';
  oldUrlFile = '';
  urlBaseFile = environment.url;
  isChangeUrlFile = false;

  protected readonly getLinkImage = getLinkImage;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private readonly eventService: EventService,
    private filesService: FilesService,
    private messageService: MessageService,
  ){}

  ngOnInit(){
    this.route.paramMap
      .pipe(switchMap((params)=> {
        this.eventType = params.get("type") as EVENT_TYPE_ROUTES;
        const eventId = params.get("id");
        this.title = `Nuevo ${TitleEventPage(this.eventType).title}`;
        if(eventId === 'nuevo'){
          return of(getDefaulData());
        }
        else if(Number(eventId)){
          this.title = `Editar ${TitleEventPage(this.eventType).title}`;
          return this.getEvent(String(eventId));
        }
        return throwError(() => new Error('Invalid ID'));
      }))
      .subscribe((res)=> {
        if(res){
          this.event = res;
          this.event.eventType = {
            id: getTypeEventId(this.eventType),
            name:'',
          }
        }
      });
  }

  async getEvent(id: string) {
    try {
      const response = await firstValueFrom(this.eventService.getOne(id))
      if (response.ok) {
        return response.body.data
      }
      return null
    } catch (error) {
      this.notifications.set(
        'getNews',
        'Hubo un error intentelo mas tarde'
      );
    }
  }

  onImageSelected(file: File){
    this.file = file;
  }

  canSave(): boolean {
    return Boolean(this.form && this.form.valid);
  }

  onSave() {
    if (!this.canSave()) {
      this.scrollToTop();
      return this.notifications.set('not-save', 'Hay campos requeridos por llenar verificar!!');
    }
    if (this.file == undefined || this.file == '') {
      return this.create(this.event, true);
    }else{
      if (!this.file) {
        return this.sendData(this.event, false);
      }
      return  this.saveImageAndSendData();
    }
  }

  saveImageAndSendData() {
    if (this.file) {
      this.filesService
        .uploadImage(this.file)
        .pipe(
          catchError((error) => {
            this.scrollToTop();
            this.notifications.set(
              'image',
              'Hubo un error al subir la imagen, intentelo mas tarde'
            );
            return throwError(() => error);
          })
        )
        .subscribe((data) => {
          if (data.status === 200 || data.status === 201) {
            this.sendData(this.parseData(data.body.path));
          }
        });
    }
  }

  sendData(data: EventDetail, isRollBack = true){
    if (data.id) {
      this.update(data, isRollBack);
    } else {
      this.create(data, isRollBack);
    }
  }

  update(data: EventDetail, isRollback: boolean) {
    this.scrollToTop();
    this.eventService
      .update(String(data.id), data)
      .pipe(
        catchError((error) => {
          if (this.isChangeUrlFile && isRollback) {
            this.rollbackImageUpload(data.linkImage);
          }
          this.scrollToTop();
          this.notifications.set(
            'updateEvent',
            'Hubo un error intentelo mas tarde'
          );
          return throwError(() => error);
        })
      )
      .subscribe((res) => {
        if (res.status === 200 || res.status === 201) {
          this.notifications.clear();
          this.isChangeUrlFile && this.rollbackImageUpload(this.oldUrlFile);
          this.showSuccess('Evento actualizado.');
          //this.backPage();
        }
      });
  }

  create(data: EventDetail, isRollback:boolean) {
    this.scrollToTop();
    try {
      this.eventService
        .createEvent(data)
        .pipe(
          catchError((error) => {
            isRollback && this.rollbackImageUpload(data.linkImage);
            this.scrollToTop();
            this.notifications.set(
              'createEvent',
              'Hubo un error intentelo mas tarde'
            );
            return error;
          })
        )
        .subscribe((res) => {
          if (res.status === 200 || res.status === 201) {
            //this
            this.notifications.clear();
            this.showSuccess('Evento creado.');
            //this.backPage();
          }
        });
    } catch (error) {
      console.error(error);
    }
  }

  parseData(urlImage: string) {
    this.validChangeUrlFile(urlImage);
    this.event.linkImage = urlImage;
    return this.event;
  }

  validChangeUrlFile(urlImage: string) {
    if (this.event.id && this.getFileName(this.event.linkImage) !== this.getFileName(urlImage)) {
      this.isChangeUrlFile = true;
      this.oldUrlFile = this.event.linkImage;
    } else {
      this.isChangeUrlFile = false;
    }
  }

  getFileName(fileName: string): string {
    if (this.urlBaseFile === fileName.split('/')[2]) {
      return fileName.split('/')[6];
    }
    if (fileName.split('/')[0] === 'uploads') {
      return fileName.split('/')[2];
    }
    return '';
  }

  rollbackImageUpload(url: string) {
    this.deleteImage(url);
  }

  deleteImage(urlImage: string) {
    try {
      const image = this.getFileName(urlImage);
      this.filesService.DeleteImage(image).subscribe();
    } catch (error) {
      console.error('error log: ', error);
    }
  }
  getPathBack(eventType:EVENT_TYPE_ROUTES):string{
    const type:string = isFairByEventType(eventType) ? 'ferias' : 'eventos';
    const path = `${type}/${eventType}`; // Ruta dinámica de tu aplicación
    return path;
  }
  backPage(): void {
    const path = this.getPathBack(this.eventType)
    this.router.navigateByUrl(path);
  }

  scrollToTop() {
    this.topElement.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  addVideo() {
    if(this.event && this.canSave()){
      this.event.videos.push({ link:'' } );
    }else{
      this.notifications.set('errorFile', 'Hay campos requeridos que falta completar');
      this.scrollToTop();
    }
  }

  removeVideo(indexData: number) {
    this.event.videos = this.event.videos.filter((_, index) => index !== indexData);
  }
  addFoto() {
    if(this.event && this.canSave()){
      this.event.images.push({ link:'' } );
    }else{
      this.notifications.set('errorFile', 'Hay campos requeridos que falta completar');
      this.scrollToTop();
    }
  }

  removeFoto(indexData: number) {
    this.event.images = this.event.images.filter((_, index) => index !== indexData);
  }

  addSponsor(){
    if(this.canSave()){
      this.event.sponsors.push({ name: '', linkCommercial: '', linkImage: ''});
    }else{
      this.notifications.set('errorFile', 'Hay campos requeridos que falta completar');
      this.scrollToTop();
    }
  }

  removeSponsor(indexData: number) {
    this.event.sponsors = this.event.sponsors.filter((_, index) => index !== indexData);
  }

  addPresentation(){
    if(this.canSave()){
      this.event.presentations.push({ name: '', speaker: '', link: ''});
    }else{
      this.notifications.set('errorFile', 'Hay campos requeridos que falta completar');
      this.scrollToTop();
    }
  }

  removePresentation(indexData: number) {
    this.event.presentations = this.event.presentations.filter((_, index) => index !== indexData);
  }

  showSuccess(message: string) {
    this.messageService.add({severity: 'success', summary: 'Operación exitosa', detail: message, life: 3000});
  }

  showError(message: string) {
    this.messageService.add({severity: 'error', summary: 'Error', detail: message, life: 3000});
  }

  protected readonly getErrorMessage = getErrorMessage;
  protected readonly validatorsInput = VALIDATORS_INPUT
}
