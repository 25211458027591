import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import MESSAGE_ERROR from 'src/app/helpers/message-error.helper';
import VALIDATORS_INPUT from 'src/app/helpers/validators-input.helper';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss'],
})
export class UploadFileComponent implements OnInit {
  imageDefault: string | null = null;
  @Input() imagePlaceholder: string | null = null;
  @ViewChild('fileInput') fileInput?: ElementRef;
  fileSelected: any = null
  @Output() fileSelectedEmitter = new EventEmitter<File>();
  @Output() typeSelected = new EventEmitter<string>();
  @Input() invalid: boolean = false;
  @Input() errorMessage: string | null = '';
  @Input() notPdf = false;
  messageToNonPdf: boolean = false;

  @Input() imageDimensionText = '800 x 455';

  @Input() accept: string = ".pdf,.jpg,.jpeg,.png";

  imagenPlaceholderDefaulFileUpload = `https://fakeimg.pl/600x400?text=Sin+Archivo&font=bebas`;

  typeFile?: string;


  constructor() {
  }

  ngOnInit() {
    if (this.imagePlaceholder == "") {
      this.imagePlaceholder = null;
    }
    this.imageDefault = this.imagePlaceholder ??  `https://fakeimg.pl/600x400?text=Sin+Archivo&font=bebas`;
    this.imagePlaceholder = this.imageDefault;
  }

  OpenInputFile() {
    if (this.fileInput) {
      this.fileInput.nativeElement.click();
    }
  }

  handleImageError(event: any): void {
    const imgElement = event.target as HTMLImageElement;
    imgElement.onerror = null;
    imgElement.src = 'https://fakeimg.pl/600x400?text=Sin+Archivo&font=bebas';
  }

  cargarImagen(event: any) {
    if (event.target.files && event.target.files[0]) {
      const isPdf = event.target.files[0].name.toLowerCase().endsWith('.pdf');
      if (!this.isFileValid(event) || (isPdf && this.notPdf)) {
        this.invalid = true
        this.messageToNonPdf = true
        alert('formato invalido de archivo seleccionado')
        return;
      } else {
        this.messageToNonPdf = false
      }
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const file = event.target.files[0];
        if (file && file.type.startsWith('image/')) {
          this.imagePlaceholder = e.target.result;
        }
        else {
          this.imagePlaceholder = "https://fakeimg.pl/600x400/001E4D/ffffff?text=Archivo+Cargado&font=bebas";
        }
      };
      this.fileSelected = event.target.files[0];
      reader.readAsDataURL(event.target.files[0]);
      this.fileSelectedEmitter.emit(this.fileSelected);
      this.typeSelected.emit(this.typeFile)
    } else {
      this.fileSelected = null;
      this.imagePlaceholder = this.imageDefault;
      this.clear();
    }

  }

  isFileValid(event: any) {
    const file = event.target.files[0];
    const allowedExtensions = /(\.pdf|\.jpg|\.jpeg|\.png)$/i;
    const typeFile = file.type;
    const maxSize = typeFile.startsWith('image/') ? VALIDATORS_INPUT.FILE_IMG_SIZE : VALIDATORS_INPUT.FILE_OTHERS_SIZE; // 2MB for images, 5MB for other files

    if (!allowedExtensions.exec(file.name)) {
      this.errorMessage = MESSAGE_ERROR.IMG_EXTENSION(file.name);
      return false;
    }

    if (file.size > maxSize) {
      this.errorMessage = MESSAGE_ERROR.IMG_SIZE(file.name, maxSize);
      return false;
    }

    this.errorMessage = '';
    this.typeFile = typeFile.split('/')[1];
    return true;
  }

  clear(){
    this.fileSelected = '';
    this.typeFile = '';
    this.fileSelectedEmitter.emit(this.fileSelected);
    this.typeSelected.emit(this.typeFile)
    this.imagePlaceholder = 'https://fakeimg.pl/600x400?text=Sin+Archivo&font=bebas';
    this.fileSelected = null;
    this.invalid = false;
    this.errorMessage = null;
  }

}
