import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {PaginatorData} from "../../interfaces/paginator-data.interface";

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent implements OnInit {

  @Input() paginatorData!: PaginatorData;
  // Complete and Simple
  @Input() design = 'complete';
  currentPage = 1;

  totalPages!: number;

  @Output() pageSelected = new EventEmitter<string>();

  constructor() {
  }

  ngOnInit() {
    if (this.paginatorData.meta) {
      this.totalPages = (this.paginatorData.meta.last_page);
      this.currentPage = this.paginatorData.meta.current_page;
    }
  }

  /** Update totalPage number if the collectionSize or pageSize values change */
  ngOnChanges(changes: SimpleChanges) {
    if (this.paginatorData.meta) {
      this.totalPages = (this.paginatorData.meta.last_page);
      this.currentPage = this.paginatorData.meta.current_page;
    }
  }

  /** Set page number */
  selectPageNumber(link: any) {
    if (link.url) {
      this.currentPage = +link.label;
      this.pageSelected.emit(link.url);
    }
  }
}
