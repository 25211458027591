<div class="card-directivo" [ngClass]="{'card-active': isActive}">
  @if (image) {
    <img class="image" [src]="image" alt="">
  }
  @if (!image) {
    <div class="avatar">
      <span class="first-letter">{{this.firstLetterName}}</span>
    </div>
  }
  <div class="info">
    <h2 class="name">{{name}}</h2>
    <p class="job">{{job}}</p>
  </div>
  @if (status) {
    <span [ngClass]="{'badge-active': isActive, 'badge': !isActive}">{{status}}</span>
  }
  @if (inmobiliaria) {
    <span
      [ngClass]="{'badge-active': isActive, 'badge': !isActive}">
      Inmobiliaria
      <br>
        {{inmobiliaria}}
      </span>
    }

    @if (inmobiliaria) {
      <div class="icono-link">
        <div class="circle">
          <mat-icon class="icon">chevron_right</mat-icon>
        </div>
      </div>
    }
  </div>
