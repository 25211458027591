import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SuppliersService } from '../../../services/suppliers.service';
import { SupplierTypesService } from '../../../services/supplier-types.service';
import { Rating, SelectOption, Supplier, SupplierSave, SupplierType } from '../../../interfaces/supplier.interface';
import { defaultSupplier } from 'src/app/helpers/supplier.helper';
import { NgForm } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { RatingComponent } from '../../../components/rating/rating.component';
import UserDataLogin from 'src/app/interfaces/user-data-login.interface';
import { SupplierTypeListComponent } from '../../supplier-types/list/supplier-type.component';

@Component({
  selector: 'app-supplier-form',
  templateUrl: './supplier-form.component.html',
  styleUrls: ['./supplier-form.component.scss'],
  providers: [MessageService, DialogService]
})
export class SupplierFormPageComponent implements OnInit {
  @ViewChild('form', {static: false}) public form!: NgForm;
  notifications: Map<string, string> = new Map<string, string>();
  isEditMode: boolean = false;
  supplierId: number = 0;
  supplier!: Supplier;
  isPopupVisible: boolean = false;
  selectOptionsType: SelectOption[] = [];
  sizeAvatar = 'md'
  isConfirmPopupVisible: boolean = false;
  isRatingPopupVisible: boolean = false;

  ratingPopupTitle: string = '';
  selectedRating: number = 0;
  mockComment: string = '';
  ratings: Rating[] = [];

  editingRating: any = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private readonly suppliersService: SuppliersService,
    private readonly messageService: MessageService,
    private readonly dialogService: DialogService,
  ) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params['id']) {
        this.isEditMode = true;
        this.supplierId = params['id'];
        this.getSupplier(this.supplierId);
      } else {
        this.isEditMode = false;
        this.supplier = defaultSupplier();
      }
    });

    this.getSupplierType();
  }

  getSupplierType() {
    this.suppliersService.getSupplierTypes().subscribe((supplierTypes: SupplierType[]) => {
      this.selectOptionsType = supplierTypes
        .filter((supplierType: SupplierType) => supplierType.isActive)
        .map((supplierType: SupplierType) => ({
          value: supplierType.id,
          label: supplierType.name,
        }));
    });
  }

  getUserData(): UserDataLogin | undefined {
    const jsonData = localStorage.getItem('UserData');
    const userData = jsonData ? JSON.parse(jsonData) : undefined;
    return userData.data;
  }

  getSupplier(supplierId: number) {
    this.suppliersService.getOne(supplierId).subscribe((supplier: Supplier) => {
      const data = this.getUserData();
      supplier?.ratings?.forEach((rating: Rating) => {
        rating.isEdit = data?.id == rating.user.id
      });
      this.supplier = supplier;
    });
  }

  showConfirmPopup(): void {
    this.isConfirmPopupVisible = true;
  }

  hideConfirmPopup(): void {
    this.isConfirmPopupVisible = false;
  }

  updateRating(supplierId: number, rating: Rating): void {
    const refModal = this.dialogService.open(RatingComponent, {
      data: {
        rating,
        supplierId,
        title: 'Editar Calificación'
      },
      header: '',
    });
    refModal.onClose.subscribe({
      next: (value) => {
        if (value && value.success) {
            this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: 'Registrado con exito!',
                life: 3000,
            });
            this.getSupplier(this.supplierId);
        }
      },
    });
  }

  createRating(supplierId: number) {
    const refModal = this.dialogService.open(RatingComponent, {
      data: {
        supplierId,
        title: 'Nueva Calificación'
      },
      header: '',
    });

    refModal.onClose.subscribe({
      next: (value) => {
        if (value && value.success) {
            this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: 'Registrado con exito!',
                life: 3000,
            });
            this.getSupplier(this.supplierId);
        }
      },
    });
  }

  hideRatingPopup(): void {
    this.isRatingPopupVisible = false;
  }

  selectRating(rating: number): void {
    this.selectedRating = rating;
  }

  toBack(): void {
    this.router.navigate(['/guia-proveedores']);
  }

  onSelectedValueChange(newValue: string | number): void {
    this.supplier.supplierTypeId = Number(newValue);
  }

  updateImage(event: string) {
    this.supplier.image = event;
  }

  errorLoadImage(event: string) {
    this.notifications.set('errorLoadImage', event);
  }

  save(supplier: Supplier) {
    this.notifications.clear();
    const supplierCreated = new SupplierSave(supplier);
    if (supplier.id == 0) {
      this.suppliersService.create(supplierCreated).subscribe({
        next: () => {
          this.form.reset();
          this.showSuccess('Proveedor creado.');
        },
        error: () => {
          this.notifications.set('not-save', 'Hubo un error, intenteló nuevamente');
        }
      });
    } else {
      this.suppliersService.update(supplierCreated).subscribe({
        next: () => this.showSuccess('Proveedor creado.'),
        error: () => this.notifications.set('not-save', 'Hubo un error, intenteló nuevamente'),
      });
    }
  }

  newSupplierType() {
    const refModal = this.dialogService.open(SupplierTypeListComponent, {
      data: null,
      header: 'Tipos de Proveedor',
    });

    refModal.onClose.subscribe({
      next: () => {
        this.getSupplierType();
      },
    });

  }

  onChangeSliderState(event: boolean) {
    this.supplier.isActive = event;
  }

  showSuccess(message: string) {
    this.messageService.add({ severity: 'success', summary: 'Operación exitosa', detail: message, life: 3000 });
  }

  showError(message: string) {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: message, life: 3000 });
  }
}
