import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import LinkEvento, {MultimediaEvento} from "../../interfaces/link-evento.interface";
import {getLinkImage, getLinkVideo} from "../../helpers/converters.helper";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-video-modal',
  templateUrl: './multimedia-modal.component.html',
  styleUrls: ['./multimedia-modal.component.scss']
})
export class MultimediaModalComponent {
  multimedia: MultimediaEvento;

  constructor(
    public dialogRef: MatDialogRef<MultimediaModalComponent>,
    private readonly http: HttpClient,
    @Inject(MAT_DIALOG_DATA
    ) public data: {
      multimedia: MultimediaEvento
    }) {
    this.multimedia = data.multimedia; // Obtener la variable de video desde los datos del diálogo
  }

  extractGoogleDriveId(link: string): string | null {
    const regex = /https:\/\/drive\.google\.com\/file\/d\/([a-zA-Z0-9_-]+)\//;
    const match = link.match(regex);
    return match ? match[1] : null;
  }

  getGoogleDriveThumbnail(link: string): string {
    const googleDriveId = this.extractGoogleDriveId(link);
    if (googleDriveId) {
      return `https://drive.google.com/thumbnail?id=${googleDriveId}&sz=w1000`;
    }
    return link;
  }

  closeModal() {
    this.dialogRef.close();
  }
  onRedirectImage(link:string){
    const linkImage = this.getGoogleDriveThumbnail(link);
    window.open(linkImage,'_blank')
  }

  protected readonly getLinkVideo = getLinkVideo;
  protected readonly getLinkImage = getLinkImage;
}
