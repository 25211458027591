import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { firstValueFrom } from "rxjs";
import { Router } from "@angular/router";
import { ConfigurationService } from "../../../../../services/configuration.service";
import { ConfigurationCategoriesService } from "../../../../../services/configuration-categories.service";
import { MessageService, ConfirmationService } from 'primeng/api';

interface ConfigItem {
  id?: number;
  text: string;
  configuration_category_id: number;
  category?: string;
}
interface Category {
  id: number;
  name: string;
  originalName?: string;
}

@Component({
  selector: 'reports-home',
  templateUrl: './asei-directory-form-page.component.html',
  styleUrls: ['./asei-directory-form-page.component.scss'],
  providers: [MessageService, ConfirmationService]
})
export class AseiDirectoryFormPageComponent implements OnInit {
  form!: FormGroup;
  configData: ConfigItem[] = [];
  categories: Category[] = [];
  editCategoryId: number | null = null;
  editableCategoryName = '';

  constructor(
    private configurationService: ConfigurationService,
    private configurationCategoriesService: ConfigurationCategoriesService,
    private router: Router,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {}

  async ngOnInit() {
    try {
      const catResponse = await firstValueFrom(this.configurationCategoriesService.getAllCategories());
      this.categories = catResponse.data;
      const configResponse = await firstValueFrom(this.configurationService.getAllConfigurations());
      this.configData = configResponse.data;
      this.createForm(this.categories, this.configData);
    } catch {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Error al cargar datos',
        life: 3000
      });
    }
  }

  createForm(categories: Category[], configData: ConfigItem[]) {
    const controls: { [key: string]: any } = {};
    for (const cat of categories) {
      const ctrlName = this.getControlName(cat.name);
      const found = configData.find(cfg => cfg.category === cat.name);
      controls[ctrlName] = [found ? found.text : ''];
    }
    this.form = this.formBuilder.group(controls);
  }

  getControlName(categoryName: string) {
    return 'content' + (categoryName?.replace(/[\s.]+/g, '') || '');
  }

  onTextEditorChange(text: string, fieldName: string) {
    this.form.get(fieldName)?.setValue(text);
  }

  onEditCategory(cat: Category) {
    const indexInArray = this.categories.findIndex(c => c.id === cat.id);
    if (indexInArray === -1) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Advertencia',
        detail: 'La categoría ya no existe.',
        life: 3000
      });
      return;
    }

    if (this.editCategoryId === cat.id) {
      const oldName = cat.originalName || cat.name;
      const newName = this.editableCategoryName.trim();

      if (!newName) {
        this.messageService.add({
          severity: 'warn',
          summary: 'Nombre vacío',
          detail: 'No se permiten nombres vacíos. Se mantendrá el nombre anterior.',
          life: 3000
        });
        cat.name = oldName; // Revertimos al nombre anterior
        this.editCategoryId = null;
        this.editableCategoryName = '';
        return;
      }

      if (newName !== oldName) {
        cat.name = newName;
        const oldCtrlName = this.getControlName(oldName);
        const newCtrlName = this.getControlName(newName);
        const oldValue = this.form.get(oldCtrlName)?.value;

        this.form.removeControl(oldCtrlName);
        this.form.addControl(newCtrlName, new FormControl(oldValue));

        this.configData
          .filter(cfg => cfg.category === oldName)
          .forEach(cfg => {
            cfg.category = newName;
          });
      }

      this.editCategoryId = null;
      this.editableCategoryName = '';
    } else {
      cat.originalName = cat.name;
      this.editCategoryId = cat.id;
      this.editableCategoryName = cat.name;
    }
  }

  onCancelEdit(cat: Category) {
    if (cat.originalName) {
      cat.name = cat.originalName;
    }
    this.editCategoryId = null;
    this.editableCategoryName = '';
  }

  onDeleteCategory(cat: Category) {
    this.confirmationService.confirm({
      header: 'Confirmar eliminación',
      message: `¿Deseas eliminar la categoría "${cat.name}"?`,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Eliminar',
      rejectLabel: 'Cancelar',
      accept: async () => {
        try {
          await firstValueFrom(this.configurationCategoriesService.deleteCategory(cat.id));
          this.categories = this.categories.filter(c => c.id !== cat.id);
          this.configData = this.configData.filter(cfg => cfg.category !== cat.name);
          this.rebuildForm();
          this.messageService.add({
            severity: 'success',
            summary: 'Eliminado',
            detail: 'Categoría eliminada correctamente',
            life: 3000
          });
        } catch {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Error al eliminar la categoría',
            life: 3000
          });
        }
      }
    });
  }

  rebuildForm() {
    this.createForm(this.categories, this.configData);
  }

  onSubmit() {
    this.confirmationService.confirm({
      header: 'Confirmar Guardado',
      message: '¿Deseas guardar los cambios?',
      acceptLabel: 'Guardar',
      rejectLabel: 'Cancelar',
      accept: async () => {
        try {
          const categoriesToUpdate = this.categories.map(c => ({
            id: c.id,
            name: c.name
          }));
          await firstValueFrom(this.configurationCategoriesService.updateCategories(categoriesToUpdate));
          const payload = {
            data: this.configData.map(item => ({
              id: item.id!,
              text: this.form.get(this.getControlName(item.category!))?.value || ''
            }))
          };
          await firstValueFrom(this.configurationService.updateAllDirectoryAsei(payload));
          this.messageService.add({
            severity: 'success',
            summary: 'Éxito',
            detail: 'Datos actualizados correctamente',
            life: 3000
          });
          this.router.navigateByUrl('/directorio-asei');
        } catch {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Error al actualizar datos',
            life: 3000
          });
        }
      }
    });
  }

  backPage() {
    this.router.navigateByUrl('/directorio-asei');
  }
}
