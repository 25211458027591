// Get Custom Link for preview, use in cards
import * as moment from "moment";
import {environment} from "../../environments/environment";
import Event from "../pages/events/interfaces/event.interface";
import {AseiInfoEvent} from "../types/asei-info";
import {EVENT_TYPE, EVENT_TYPE_ROUTES} from "../types/constants/event-type.enum";
import {isFairByEventType} from "../pages/events-activities/events.helper";
import {log} from "@angular-devkit/build-angular/src/builders/ssr-dev-server";

interface Paths {
  [key: string]: string;
}

const URL = environment.url;

export const getThumbnailVideo = (link: string): string => {
  if (link.includes('vimeo')) {
    return getThumbnailVimeo(link);
  }
  if (link.includes('youtu')) {
    return getThumbnailYoutube(link);
  }
  if (link.includes('drive')) {
    return getThumbnailVideoDrive(link);
  }
  return ''
}
const getThumbnailYoutube = (link: string): string => {
  const id = getIDYoutube(link);
  return`https://img.youtube.com/vi/${id}/mqdefault.jpg`;
}
const getThumbnailVimeo = (link: string): string => {
  const id = getIdVideoVimeo(link);
  return `https://vumbnail.com/${id}.jpg`
}
const getThumbnailVideoDrive = (link: string): string => {
  const id = getIDVideoDrive(link);
  return `https://drive.google.com/thumbnail?id=${id}`;
}

const getIdVideoVimeo = (url: string) => {
  let regex = /vimeo\.com(?:\/video)?\/(\d+)/;
  let match = url.match(regex);

  if (match) {
    return match[1];
  }

  return '';
}
export const getLinkVideo = (link: string) => {
  if (link.includes('youtu')) {
    const idYoutube = getIDYoutube(link);
    return `https://www.youtube.com/embed/${idYoutube}`
  }
  if (link.includes('drive')) {
    const idVideoDrive = getIDVideoDrive(link);
    if (idVideoDrive) {
      return `https://drive.google.com/file/d/${idVideoDrive}/preview`;
    }
  }
  return link;
}
// Get Link Image
export const getLinkImage = (link: string) => {
  if (!link) {
    return ''; // O toma alguna acción apropiada
  }
  if (link.includes('drive')) return getLinkImageDrive(link);
  if (link.includes('http')) return link;
  return `${URL}/storage/${link}`;
}
const getLinkImageDrive = (link: string) => {

  let url = ''
  const id = getIDGoogleDrive(link);
  if (id) {
    url = `https://lh3.google.com/u/0/d/${id}`;
  }
  return url;
}

function getIDYoutube(url:string) {
  // Verificar si el enlace es válido
  const patron = /^(?:https?:\/\/)?(?:www\.)?youtu\.be\/([a-zA-Z0-9_-]{11})(?:\?.*)?$/;
  const coincidencias = url.match(patron);

  if (coincidencias) {
    // El ID se encuentra en la posición 1 del arreglo de coincidencias
    const id = coincidencias[1];
    return id;
  }
  return '';
}
function getIDVideoDrive(link:string){
  const driveLinkParts = link.match(/\/file\/d\/([^/]+)/);
  return driveLinkParts && driveLinkParts.length === 2 ? driveLinkParts[1] : null;
}

const getIDGoogleDrive = (link: string) => {
  const regex = /\/d\/(.+?)\/view/;
  const match = link.match(regex);

  if (match && match.length > 1) {
    const id = match[1];
    return id;
  }
  return '';
};
// convert and get Date in format yyyy-mm-dd --> dd/mm/yyyyy
export const getDateDDMMYYYY = (date: string | null) => {
  if (date) {
    return moment(date).format('DD/MM/YYYY');
  }
  return '';
}
export const getDateYYYYMMDD = (date: string | null) => {
  if (date) {
    return moment(date).format('YYYY-MM-DD');
  }
  return '';
}
export const getDateDDMMYYYYWithFormat = (date: string | null) => {
  if (date) {
    return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
  }
  return '';
}
export const getDateDDMMYYYYToYMD = (date: string) => {
  return moment(date, "DD/MM/YYYY").format("YYYY-MM-DD")
}
// convert and get Hour in format H:i:s --> hh:mm (am/pm)
export const getHourInFormat12HRS = (hour: string | null | undefined) => {
  if (hour) {
    // SI LA HORA YA ESTÁ FORMATEADA,RETORNARLA
    if (moment(hour, 'hh:mm A', true).isValid()) {
      return hour;
    }
    // FORMATEAR LA HORA A HH::mm:ss
    let hourF = moment(hour, 'HH:mm:ss', true).isValid() ? hour : hour + ':00';
    // RETORNAR LA HORA DE HH:mm:ss a hh:mm A (am/pm)
    return moment(hourF, 'HH:mm:ss', true).format('hh:mm A');
  }
  return '';
}

export const getPathByEventType = (meet: Event | AseiInfoEvent) => {
  const name = meet.eventType.name
  const paths: Paths = {
    'ASEI Meeting': EVENT_TYPE_ROUTES.ASEI_MEETING,
    'Reunion Socio': EVENT_TYPE_ROUTES.REUNION_SOCIO,
    'Aniversario': EVENT_TYPE_ROUTES.ANIVERSARIO,
    'ENASEI': EVENT_TYPE_ROUTES.ENASEI,
    'ASEI business tour': EVENT_TYPE_ROUTES.ASEI_BUSINESS_TOUR,
    'Copa Asei':EVENT_TYPE_ROUTES.COPA_ASEI,
    'ASEI Piura':EVENT_TYPE_ROUTES.ASEI_PIURA,
    'Feria Nexo': EVENT_TYPE_ROUTES.FERIA_NEXO,
    'Feria Lima Home': EVENT_TYPE_ROUTES.FERIA_LIMA_HOME
  }
  const eventType = paths[name] as EVENT_TYPE_ROUTES
  const type:string = isFairByEventType(eventType) ? 'ferias' : 'eventos';
  return `${type}/${eventType}`;
}

export const convertDatetime = (datetimeString:string) => {
  const datetimeMoment = moment(datetimeString, 'YYYY-MM-DD HH:mm:ss');

  const dateFormatted = datetimeMoment.format('YYYY-MM-DD'); // Formato 'Y-m-d'
  const timeFormatted = datetimeMoment.format('HH:mm'); // Formato 'H:i'

  return [dateFormatted, timeFormatted];
}

export const getDatetimeUTC = (date: string, time: string | undefined) => {
  return moment(
    time ? `${time} ${getDateDDMMYYYYWithFormat(date)}`: getDateDDMMYYYYWithFormat(date),
    time ? 'hh:mm A DD/MM/YYYY':'DD/MM/YYYY'
  ).utc().format('YYYYMMDD[T]HHmmss[Z]');
}
export const getDatetimeWithoutFormatUTC = (date: string, time: string | undefined) => {
  return moment(
    time ? `${time} ${date}`: date,
    time ? 'hh:mm A DD/MM/YYYY':'DD/MM/YYYY'
  ).utc().format('YYYYMMDD[T]HHmmss[Z]');
}

export const getDatetime = (date: string, time: string | undefined) => {
  return moment(
    time ? `${time} ${getDateDDMMYYYYWithFormat(date)}`: getDateDDMMYYYYWithFormat(date),
    time ? 'hh:mm A DD/MM/YYYY':'DD/MM/YYYY'
  ).utc().format('YYYY-MM-DD');
}
export const now = () => {
  return moment().format("YYYY-MM-DD")
}
