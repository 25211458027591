import {Component, Input, OnInit} from '@angular/core';
import {Router} from "@angular/router";


@Component({
  selector: 'app-header-page',
  templateUrl: './header-page.component.html',
  styleUrls: ['./header-page.component.scss'],
})
export class HeaderPageComponent implements OnInit {

  @Input() title: string = '';
  @Input() subtitle: string = '';
  @Input() actionText: string = '';
  @Input() redirectTo: string = '';
  @Input() hasPermission: boolean = false;

  constructor(private router: Router) {
  }

  ngOnInit() {
  }

  redirect(path: string) {
    this.router.navigate([path]);
  }

}
