import { FilesService } from 'src/app/services/files.service';
import { Component, DoCheck } from '@angular/core';
import { firstValueFrom } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { getDateDDMMYYYY, getHourInFormat12HRS, getLinkImage, getThumbnailVideo } from "../../../../helpers/converters.helper";
import { Location } from "@angular/common";
import { PostService } from "../../services/post.service";
import { parseAseiInfoNews } from "../../../../mapper/news.mapper";
import { AseiInfoNews } from "../../../../types/asei-info-news";
import { getImageOThumbnail } from "../../../../helpers/news.helper";
import { DomSanitizer } from '@angular/platform-browser';
import { MessageService } from 'primeng/api';


@Component({
  selector: 'app-view-reunion-socios',
  templateUrl: './post-show-page.component.html',
  styleUrls: ['./post-show-page.component.scss'],
  providers: [MessageService]
})
export class PostShowPageComponent implements DoCheck {

  post: AseiInfoNews | null = null;
  path: string = '';
  isFile: boolean = false;
  isNotImg: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private readonly newService: PostService,
    private sanitizer: DomSanitizer,
    private readonly filesService: FilesService,
    private messageService: MessageService,
  ) {
  }

  async ngOnInit() {
    this.path = this.route.snapshot.url[0]?.path ?? '';
    const id: string = this.route.snapshot.paramMap.get('id') ?? '';
    this.post = await this.getPost(id);
    if (!this.post) this.backPage()

    const filename = this.getFileNameFromUrl(getLinkImage(this.post!.linkImage));
    const extension = this.getExtensionFromFilename(filename);
    if (extension == 'pdf') {
      this.isFile = true;
    }
    if (extension == '') {
      this.isNotImg = true;
    }
  }

  ngDoCheck() {
    const content = document.getElementById("content_event");
    if (content) {
      const img = content.querySelector("img");
      img?.setAttribute('style', 'width: 100%');
    }
  }


  async getPost(id: string) {
    try {
      const response = await firstValueFrom(this.newService.getOne(id))
      return parseAseiInfoNews(response.body.data)
    } catch (error) {
      return null;
    }
  }

  getFileNameFromUrl(url: string): string {
    const parts = url.split('/');
    return parts[parts.length - 1];
  }

  getExtensionFromFilename(filename: string): string {
    const parts = filename.split('.');
    return parts.length > 1 ? parts[parts.length - 1] : '';
  }

  onClickDownload(post: AseiInfoNews) {
    const filename = this.getFileNameFromUrl(getLinkImage(post.linkImage));
    const extension = this.getExtensionFromFilename(filename);
    if (extension == 'pdf') {
      this.filesService.downloadFile(filename).subscribe({
        next: (response: Blob) => {
          const url = window.URL.createObjectURL(response);
          const link = document.createElement('a');
          link.href = url;
          link.download = filename;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        },
        error: () => {
          this.showError('Pdf no encontrado.')
        }
      });
    } else {
      this.filesService.downloadImage(filename).subscribe({
        next: (response: Blob) => {
          const url = window.URL.createObjectURL(response);
          const link = document.createElement('a');
          link.href = url;
          link.download = filename;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        },
        error: () => {
          this.showError('Imagen no encontrada.')
        }
      });
    }
  }

  onClickShare() {
    const path = window.location.pathname;
    const fullUrl = window.location.origin + path;

    navigator.clipboard.writeText(fullUrl).then(() => {
      alert('URL copiada al portapapeles');
    });
  }

  backPage() {
    if (window.history.length > 1) {
      this.location.back();
    } else {
      const path = '/' + this.path; // Ruta dinámica de tu aplicación
      this.router.navigateByUrl(path);
    }
  }

  showSuccess(message: string) {
    this.messageService.add({ severity: 'success', summary: 'Operación exitosa', detail: message, life: 3000 });
  }

  showError(message: string) {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: message, life: 3000 });
  }

  protected readonly getLinkImage = getLinkImage;
  protected readonly getDateDDMMYYYY = getDateDDMMYYYY;
  protected readonly getHourInFormat12HRS = getHourInFormat12HRS;
  protected readonly getThumbnailVideo = getThumbnailVideo;
  protected readonly getImageOThumbnail = getImageOThumbnail;
}
