import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-select-group',
  templateUrl: './select-group.component.html',
  styleUrls: ['./select-group.component.scss'],
})
export class SelectGroupComponent implements OnInit {

  @Input() title: string | null | undefined = '';
  @Input() value: string | number | null | undefined = '';
  @Input() disabled: boolean = false;
  @Input() options: any = [];
  @Input() colorLabelInverse: boolean = false;
  @Input() invalid: boolean = false;
  @Input() errorMessage: string | null = '';
  // Nuevo
  @Input() lockSelectedOption: boolean = false;

  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  ngOnInit() {}

  onInputChange(event: any) {
    this.valueChange.emit(event);
  }

  getOptionText(val: any): string {
    if (!this.options || !val) {
      return '';
    }
    for (var i = 0; i < this.options.length; i++) {
      if (this.options[i].value === val) {
        return this.options[i].text;
      }
    }
    return val;
  }
}
