import { Component, ElementRef, ViewChild } from '@angular/core';
import { firstValueFrom } from "rxjs";
import { ActivatedRoute, Router, UrlSegment } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { FilesService } from "../../../../services/files.service";
import { EventDetail } from "../../../events/interfaces/event-detail.interface";
import { getErrorMessage } from "../../../../helpers/validators.helper";
import Report from "../../../../interfaces/report.interface";
import { ReportService } from "../../services/report.service";
import { ReportTypeService } from "../../services/report-type.service";
import { ReportType } from "../../interfaces/report-type.interface";
import { Location } from "@angular/common";
import { getLinkImage } from "../../../../helpers/converters.helper";
import VALIDATORS_INPUT from 'src/app/helpers/validators-input.helper';
import { MessageService } from 'primeng/api';
import { getImageOThumbnail } from 'src/app/helpers/news.helper';

@Component({
  selector: 'app-reunion-socios-edit',
  templateUrl: './report-form-page.component.html',
  styleUrls: ['./report-form-page.component.scss'],
  providers: [MessageService]
})

export class ReportFormPageComponent {
  @ViewChild('top') topElement!: ElementRef;
  event!: EventDetail;
  form!: FormGroup;
  image = ''
  notifications: Map<string, string> = new Map<string, string>();
  fileSelected!: File | "";
  // submit = false;
  titlePage!: string;
  showDataMonthEvent: boolean = false;

  report!: Report;
  reportType!: ReportType;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private filesService: FilesService,
    private formBuilder: FormBuilder,
    private reportService: ReportService,
    private reportTypeService: ReportTypeService,
    private location: Location,
    private messageService: MessageService,
  ) { }

  async ngOnInit() {
    this.prepareDataForPost()
  }

  prepareDataForPost(){
    this.route.url.subscribe(segments => {
      const path = segments.join('/');
      this.showDataMonthEvent = path.includes('informes-del-mercado-inmobiliario/oferta-y-venta') || path.includes('informes-del-mercado-inmobiliario/analisis-sector-vivienda')

      if (path.includes('nuevo')) {
        this.loadDataForPostNew(segments)
      } else {
        this.loadDataForPostEdit()
      }
    });
  }

  loadDataForPostNew(segments: UrlSegment[]) {
    const segmentsExceptLast = segments.slice(0, -1);
    const postTypeName = segmentsExceptLast.join('.');
    this.titlePage = 'Nuevo Informe';
    this.reportTypeService.getOne(postTypeName).subscribe({
      next: response => {
        this.reportType = response.body.data;
        const report: Report = {
          id: 0,
          title: '',
          description: '',
          file: '',
          fileThumbnail: '',
          reportType: this.reportType,
          creationDate: '',
          creationTime: ''
        }
        if (this.showDataMonthEvent) {
          report.month_event = ''
        }
        this.report = report
        this.form = this.createFormWithValidation(report);
      }
    })
  }

  loadDataForPostEdit() {
    this.route.paramMap.subscribe(params => {
      const idValue = params.get('id');
      if (idValue) {
        this.titlePage = 'Editar Publicación';
        // Cargar Data del Post
        this.reportService.getOne(idValue).subscribe({
          next: response => {
            const report: Report = response.body.data;
            this.report = report;
            this.form = this.createFormWithValidation(report);

            if (report.file && report.file.includes('uploads/image')) {
              this.image = getLinkImage(report.file);
            } else if (report.fileThumbnail) {
              this.image = getLinkImage(report.fileThumbnail);
            } else {
              this.image = '';
            }
          }
        });
      }
    });
  }

  scrollToTop() {
    this.topElement.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  async create(data: Report) {
    return await firstValueFrom(this.reportService.create(data))
  }

  async update(id: number, data: EventDetail) {
    try {
      return await firstValueFrom(this.reportService.update(id, data))
    } catch (error) {
      return null;
    }
  }

  async onSubmit() {
    // this.submit = true
    if (this.form.valid) {
      await this.uploadFile()
      if (this.report.id == 0) {
        let payload = {
          ...this.form.value,
          reportType: this.reportType,
        }
        if (this.showDataMonthEvent) {
          payload.month_event = this.form.value.month_event + '-01'
        }
        await this.create(payload).then(() => {
          this.notifications.clear();
          this.prepareDataForPost();
          this.fileSelected = '';
          this.report.file = "https://fakeimg.pl/600x400?text=Sin+Archivo&amp;font=bebas";
          this.showSuccess('Informe Creado.')
        });
      } else {
        let payload = {
          ...this.form.value,
          reportType: this.report.reportType,
        }

        if (this.showDataMonthEvent) {
          payload.month_event = this.form.value.month_event + '-01'
        }

        await this.update(this.report.id, payload).then((response: any) => {
          this.notifications.clear();
          this.showSuccess('Informe Actualizado.')
          const report = response.body.data;
          this.report.fileThumbnail = report.fileThumbnail ?? 'https://fakeimg.pl/600x400?text=Sin+Archivo&font=bebas';
        });
      }
    } else {
      this.scrollToTop();
      this.notifications.set('not-save', 'Hay campos requeridos por llenar verificar!!');
    }
  }

  async uploadFile() {
    let response;
    let value;
    if (this.fileSelected) {
      if (this.fileSelected.type.startsWith('image/')) {
        response = await firstValueFrom(this.filesService.uploadImage(this.fileSelected));
        if (response.ok) {
          value = response.body.path
          this.form.get('file')?.setValue(value);
          this.form.get('fileThumbnail')?.setValue(response.body.thumbnail_path);

        }
      } else {
        response = await firstValueFrom(this.filesService.uploadPDF(this.fileSelected, 'yes'));
        if (response.ok) {
          value = response.body.path
          this.form.get('file')?.setValue(value);
          this.form.get('fileThumbnail')?.setValue(response.body.thumbnail_path);
        }
      }
    }
  }

  createFormWithValidation(report: Report) {

    let verify: any = {
      title: [report.title, [Validators.compose([Validators.required, Validators.maxLength(VALIDATORS_INPUT.MAX_TEXT)])]],
      description: [report.description, [Validators.compose([Validators.maxLength(VALIDATORS_INPUT.MAX_TEXT_DESCRIPTION)])]],
      file: [report.file, []],
      fileThumbnail: [report.fileThumbnail, []],
    }

    if (this.showDataMonthEvent) {
      verify.month_event = [report.month_event?.substring(0, report.month_event?.length - 3), [Validators.required]]
    }

    return this.formBuilder.group(verify);
  }

  onImageSelected(file: File): void {
    this.fileSelected = file
    const value = this.fileSelected ? 'file' : null
    this.form.get('file')?.setValue(value)
  }

  backPage(): void {
    if (window.history.length > 1) {
      this.location.back();
    } else {
      this.router.navigateByUrl('/inicio');
    }
  }

  showSuccess(message: string) {
    this.messageService.add({ severity: 'success', summary: 'Operación exitosa', detail: message, life: 3000 });
  }

  showError(message: string) {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: message, life: 3000 });
  }

  protected readonly getImageOThumbnail = getImageOThumbnail;
  protected readonly getErrorMessage = getErrorMessage;
  protected readonly validatorsInput = VALIDATORS_INPUT
}
