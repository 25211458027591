<h2 mat-dialog-title class="title">Presentaciones</h2>
<button mat-icon-button mat-dialog-close class="close-button" aria-label="Cerrar">
  <mat-icon>close</mat-icon>
</button>
<mat-dialog-content>
  <div class="presentaciones">
    @for (presentacion of presentaciones; track presentacion) {
      <div class="presentacion">
        <p>{{presentacion.nombre ? presentacion.nombre : 'Presentación' + presentacion.orden}}</p>
        <a [href]="presentacion.link" target="_blank">Ver</a>
      </div>
    }
  </div>
</mat-dialog-content>
