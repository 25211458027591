import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Blacklist } from '../../../interfaces/blacklist.interface';
import { map } from "rxjs/operators";

const URL = environment.url + '/api/v1';

@Injectable({
  providedIn: 'root',
})
export class BlacklistService {
  private httpOptions = {
    headers: new HttpHeaders({
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) {}

  findAll(page: number = 1, per_page: number = 10, search: string = '') {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('per_page', per_page.toString());

    if (search) {
      params = params.set('search', search);
    }

    const url = `${URL}/blacklist`;
    return this.http.get<any>(url, { ...this.httpOptions, params }).pipe(
      map(response => {
        return {
          data: response.data || [],
          meta: response.meta || {},
          links: response.links || {}
        };
      })
    );
  }

  create(word: string) {
    const url = `${URL}/blacklist`;
    return this.http.post<Blacklist>(url, { word }, this.httpOptions);
  }

  update(id: number, word: string) {
    const url = `${URL}/blacklist/${id}`;
    return this.http.put<Blacklist>(url, { word }, this.httpOptions);
  }

  updateDeletedAt(id: number, deleted: boolean) {
    const url = `${URL}/blacklist/${id}`;
    return this.http.put<any>(url, { deleted_at: deleted }, this.httpOptions);
  }

  delete(id: number, isDeleted: string) {
    const url = `${URL}/blacklist/${id}/delete/${isDeleted}`;
    return this.http.delete<any>(url, this.httpOptions);
  }
}
