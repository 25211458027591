import {Component, Input, OnInit} from '@angular/core';


@Component({
  selector: 'app-card-directive',
  templateUrl: './card-directive.component.html',
  styleUrls: ['./card-directive.component.scss'],
})
export class CardDirectiveComponent implements OnInit {

  @Input() image: string = '';
  @Input() name: string = '';
  @Input() job: string = '';
  @Input() status: string = '';
  @Input() inmobiliaria: string = '';
  @Input() isActive: boolean = false;
  firstLetterName = '';

  constructor() {
  }

  ngOnInit() {
    this.firstLetterName = this.name.charAt(0).toUpperCase();
  }

}
