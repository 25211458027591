@if (hasPermissionView) {
  @if (!hasExternalLink) {
    <a mat-list-item
       routerLinkActive="list-item-active"
       [routerLink]="path"
       [queryParams]="queryParams"
       (click)="onClick()"
    >
      <div class="content-box">
        @if (icon) {
          <i [class]="icon"></i>
        }
        <span matLine class="title">{{ title }}</span>
      </div>
    </a>
  } @else {
    <a mat-list-item
       routerLinkActive="list-item-active"
       [href]="path"
       target="_blank"
       (click)="onClick()"
    >
      <div class="content-box">
        @if (icon) {
          <i [class]="icon"></i>
        }
        <span matLine class="title">{{ title }}</span>
      </div>
    </a>
  }

}

