import {PostType} from "../types/asei-info-news";
import {getDateDDMMYYYY, getLinkImage, now} from "./converters.helper";

export const _defaultNewsData = (): _NewsData => {
  return {
    title: '',
    date: getDateDDMMYYYY(now()),
    summary: '',
    content: '',
    links: [],
    linkImage: '',
    postType: {
      id: 1,
      name: "asei-noticia.comunidad"
    },
  };
};

export type _NewsData = {
  id?: number
  title: string;
  date: string;
  summary: string;
  content: string;
  links: _Links[];
  linkImage: string;
  file?: File | '';
  postType: PostType
}

export type _Links = {
  value: string;
};

export const getImageOThumbnail = (url: string, getThumbnail: string | null): string => {
  let image = ''
  const allowedExtensions = /(\.pdf)$/i;
  if (allowedExtensions.exec(url)) {
    image = getThumbnail || '';
  }else{
    image = url
  }
  return getLinkImage(image);
}
