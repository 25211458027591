import {Injectable} from '@angular/core';
import {ComponentType} from "../interfaces/component-type.interface";

@Injectable({
  providedIn: 'root'
})
export class ComponentTypesService {

  constructor() {
  }

  getAll(): ComponentType[] {
    return [
      {
        value: 'EventSimple',
        name: 'Evento Simple'
      },
      {
        value: 'EventMonthly',
        name: 'Evento Mensual'
      },
      {
        value: 'EventAnnual',
        name: 'Evento Anual'
      },
      {
        value: 'Post',
        name: 'Noticia'
      },
      {
        value: 'Report',
        name: 'Informe'
      }
    ]
  }
  getAllForModules(): ComponentType[] {
    return [
      {
        value: 'EventSimple',
        name: 'Evento Simple'
      },
      {
        value: 'EventMonthly',
        name: 'Evento Mensual'
      },
      {
        value: 'EventAnnual',
        name: 'Evento Anual'
      },
      {
        value: 'Post',
        name: 'Noticia'
      },
      {
        value: 'Report',
        name: 'Informe'
      },
      {
        value: 'ListShortcutsBigCard',
        name: 'Lista de BigCards'
      },
      // {
      //   value: 'Home',
      //   name: 'Accesos Directos'
      // },
      {
        value: 'Redirect',
        name: 'Redirección'
      },
    ]
  }

  getByType(type: string): ComponentType[] {
    let search:string  = ''
    if (type.includes('Event')) {
      search = 'Event'
    } else {
      search = type
    }
    const all: ComponentType[] = this.getAll();
    return all.filter((item) => item.value.includes(search))
  }
  getByTypeForModules(type: string): ComponentType[] {
    let search:string  = ''
    if (type.includes('Event')) {
      search = 'Event'
    } else {
      search = type
    }
    const all: ComponentType[] = this.getAllForModules();
    return all.filter((item) => item.value.includes(search))
  }
}
