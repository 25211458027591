import {Component} from '@angular/core';
import {PostService} from "../../services/post.service";
import {firstValueFrom} from "rxjs";
import {PaginatorData} from "../../../../interfaces/paginator-data.interface";
import {ActivatedRoute} from "@angular/router";
import {getDateDDMMYYYY, getLinkImage} from "../../../../helpers/converters.helper";
import {ModuleService} from "../../../modules/services/module.service";
import { Page } from '../../../../interfaces/page.interface';
import {Module} from "../../../../interfaces/module.interface";
import {Post} from "../../interfaces/post.interface";
import {getImageOThumbnail} from "../../../../helpers/news.helper";
import {findModuleOrPageBySlug} from "../../../../helpers/module.helper";
import { DateTime } from 'luxon';
import UserDataLogin from 'src/app/interfaces/user-data-login.interface';
import { SubPage } from 'src/app/interfaces/sub-page.interface';

@Component({
  selector: 'app-news-list-page',
  templateUrl: './post-list-page.component.html',
  styleUrls: ['./post-list-page.component.scss']
})
export class PostListPageComponent {
  notifications: Map<string, string> = new Map<string, string>();
  showNotifications: boolean = false;
  pageInfo!: Module | Page | SubPage
  posts!: Post[]
  postType!: string;
  paginator !: PaginatorData;
  filterType: string = 'post';
  slugFilter: string = '';
  filter: any;
  filterDateStartFormat: any;
  filterDateEndFormat: any;
  filterSearch: string = '';
  filterPage: string = '';

  constructor(
    private route: ActivatedRoute,
    private readonly postService: PostService,
    private readonly moduleService: ModuleService,
  ) {
  }

  async ngOnInit() {
    this.route.url.subscribe(segments => {
      const slug = segments.join('/');
      this.slugFilter = slug;
      this.postType = segments.join('.')
      this.getPageInfo(slug)
      this.initializeFilters();
    });
  }

  getUserData(): UserDataLogin | undefined {
    const jsonData = localStorage.getItem('UserData');
    const userData = jsonData ? JSON.parse(jsonData) : undefined;
    return userData.data;
  }

  getPageInfo(slug: string) {
    this.moduleService.getAll().subscribe({
      next:response => {
        const modules: Module[] = response.body.data
        const pageInfo: Module | Page | SubPage | null = findModuleOrPageBySlug(modules, slug)
        const data = this.getUserData();
        const isAdmin = data?.roles.some((rol) => rol.name === 'admin' || rol.name === 'writer');
        if (!isAdmin) {
          modules.forEach(module => {
            module.hasPermission.create = false;
            module.hasPermission.update = false;
            module.hasPermission.delete = false
          });

          pageInfo!.hasPermission.create = false;
          pageInfo!.hasPermission.update = false;
          pageInfo!.hasPermission.delete = false;

        }
        if (pageInfo) {
          this.pageInfo = pageInfo
        }
      }
    });
  }

  getPosts(
    dateStart: string = '',
    dateEnd: string = '',
    search: string = '',
    page: string,
  ) {
    const postType = this.postType
    this.postService.getAll(postType, dateStart, dateEnd, search, page).subscribe({
      next: response => {
        this.posts = response.body.data;
        this.paginator = {links: response.body.links, meta: response.body.meta};
        this.filterPage = this.paginator.meta.current_page.toString();
        this.changeLocalStorage(this.filterPage, this.slugFilter, this.filterType);
      },
      error: () => {
        this.notifications.set('not-save', 'Se han actualizado los permisos, refresca la página con F5!!');
        this.showNotifications = true;
      },
    })
  }

  onFilter(value: any) {
    let {dateStartFilter, dateEndFilter, searchAll} = value

    if (dateStartFilter){
      dateStartFilter = DateTime.fromFormat(
        dateStartFilter,
        'dd/MM/yyyy'
      ).toFormat('yyyy-MM-dd');
    }

    if (dateEndFilter) {
      dateEndFilter = DateTime.fromFormat(
        dateEndFilter,
        'dd/MM/yyyy'
      ).toFormat('yyyy-MM-dd');
    }

    this.getPosts(dateStartFilter, dateEndFilter, searchAll, this.filter.page);

    this.filter = {
      type: 'post',
      slug: this.slugFilter,
      dateStart: dateStartFilter,
      dateEnd: dateEndFilter,
      search: searchAll,
    }
    localStorage.setItem('filters', JSON.stringify(this.filter));
  }

  async onPageSelected(url: string) {
    this.postService.getByUrlPagination(url).subscribe({
      next: response => {
        this.posts = response.body.data;
        this.paginator = {links: response.body.links, meta: response.body.meta};
        this.filterPage = this.paginator.meta.current_page.toString();
        this.changeLocalStorage(this.filterPage, this.slugFilter, this.filterType);
      }
    })
  }

  async onRemovePost(id: number) {
    try {
      const response = await firstValueFrom(
        this.postService.delete(id)
      );
      if (response.ok) {
        await this.ngOnInit();
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  changeLocalStorage(page: string, slug: string, type: string) {
    this.filter = {
      ...this.filter,
      slug: slug,
      page: page,
      type: type,
    }
    localStorage.setItem('filters', JSON.stringify(this.filter));
  }

  private initializeFilters(): void {
    const storedFilters = localStorage.getItem('filters');

    if (!storedFilters) {
      this.getPosts('','','',this.filter?.page ?? '1');
      return;
    }

    this.filter = JSON.parse(storedFilters);
    const isFilterChanged = this.isFilterChanged();

    if (isFilterChanged) {
      this.resetFilters();
      this.getPosts('','','','1');
      this.filter.page = '1';
      localStorage.setItem('filters', JSON.stringify({page: '1'}));
    } else {
      this.applyFilters();
      this.getPosts(this.filter.dateStart, this.filter.dateEnd, this.filter.search, this.filter.page);
    }
  }

  private isFilterChanged(): boolean {
    return this.filterType !== this.filter.type || this.slugFilter !== this.filter.slug;
  }

  private resetFilters(): void {
    localStorage.removeItem('filters');
    this.filterSearch = '';
    this.filterDateStartFormat = '';
    this.filterDateEndFormat = '';
  }

  private applyFilters(): void {
    if (this.filter.dateStart) {
      this.filterDateStartFormat = DateTime.fromFormat(this.filter.dateStart, 'yyyy-MM-dd')
        .toFormat('dd/MM/yyyy');
    }

    if (this.filter.dateEnd) {
      this.filterDateEndFormat = DateTime.fromFormat(this.filter.dateEnd, 'yyyy-MM-dd')
        .toFormat('dd/MM/yyyy');
    }

    this.filterSearch = this.filter.search;
  }

  protected readonly getDateDDMMYYYY = getDateDDMMYYYY;
  protected readonly getLinkImage = getLinkImage;
  protected readonly getImageOThumbnail = getImageOThumbnail;
}
