import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router, UrlSegment} from '@angular/router';
import {of, switchMap, firstValueFrom, throwError, catchError} from 'rxjs';
import {EVENT_TYPE, EVENT_TYPE_ROUTES} from 'src/app/types/constants/event-type.enum';
import {EventService} from 'src/app/pages/events/services/event.service';
import {EventDetail} from 'src/app/pages/events/interfaces/event-detail.interface';
import {NgForm} from '@angular/forms';
import {FilesService} from 'src/app/services/files.service';
import {environment} from 'src/environments/environment';
import {getDateDDMMYYYY, getDateDDMMYYYYToYMD, getDateYYYYMMDD, getLinkImage} from 'src/app/helpers/converters.helper';
import {
  getDefaulData,
  isFairByEventType,
} from "../../../events-activities/events.helper";
import {getErrorMessage} from "../../../../helpers/validators.helper";
import {_defaultNewsData} from "../../../../helpers/news.helper";
import {PostType} from "../../../post/interfaces/post-type.interface";
import {parseAseiInfoNews} from "../../../../mapper/news.mapper";
import {EventTypeService} from "../../services/event-type.service";
import {Location} from "@angular/common";
import {EventType} from "../../interfaces/event-type.interface";
import VALIDATORS_INPUT from 'src/app/helpers/validators-input.helper';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-events-form',
  templateUrl: './events-form-page.component.html',
  styleUrls: ['./events-form-page.component.scss'],
  providers: [MessageService]
})
export class EventsFormPageComponent implements OnInit {

  @ViewChild('form', {static: false}) public form!: NgForm;
  @ViewChild('top') topElement!: ElementRef;

  eventType!: EVENT_TYPE_ROUTES;
  event!: EventDetail;
  fileSeletected?: File | "";
  title = '';
  notifications: Map<string, string> = new Map<string, string>();
  typeFile = '';
  oldUrlFile = '';
  urlBaseFile = environment.url;
  isChangeUrlFile = false;
  titlePage!: string;
  protected readonly getLinkImage = getLinkImage;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private readonly eventService: EventService,
    private readonly eventTypeService: EventTypeService,
    private filesService: FilesService,
    private location: Location,
    private messageService: MessageService,
  ) {
  }

  ngOnInit() {
    this.prepareDataForEvent();
  }

  prepareDataForEvent() {
    this.route.url.subscribe(segments => {
      const path = segments.join('/');
      if (path.includes('nuevo')) {
        this.loadDataForEventNew(segments)
      } else {
        this.loadDataForEventEdit()
      }
    });
  }

  loadDataForEventNew(segments: UrlSegment[]) {
    const segmentsExceptLast = segments.slice(0, -1);
    const postTypeName = segmentsExceptLast.join('.');

    this.titlePage = 'Nuevo Evento';
    this.event = getDefaulData();
    // Cargar el Tipo de Publicación (postType) a Objeto (Post) por default
    this.eventTypeService.getOne(postTypeName).subscribe({
      next: response => {
        const eventType: EventType = response.body.data;
        this.event.eventType = eventType
      }
    })
  }

  loadDataForEventEdit() {
    this.route.paramMap.subscribe(params => {
      const idValue = params.get('id');
      if (idValue) {
        this.titlePage = 'Editar Evento';
        // Cargar Data del Post
        this.eventService.getOne(idValue).subscribe({
          next: (response: any) => {
            const formatEvent = { ...response.body.data }
            formatEvent.dateInit = getDateDDMMYYYY(formatEvent.dateInit);
            formatEvent.dateEnd = getDateDDMMYYYY(formatEvent.dateEnd);
            formatEvent.linkImage =  formatEvent.linkImage ?? 'https://fakeimg.pl/600x400?text=Sin+Archivo&font=bebas'
            this.event = formatEvent
          }
        })
      }
    });
  }

  onImageSelected(file: File) {
    this.fileSeletected = file;
  }

  canSave(): boolean {
    return Boolean(this.form && this.form.valid);
  }

  onSave() {
    if (!this.canSave()) {
      this.scrollToTop();
      return this.notifications.set('not-save', 'Hay campos requeridos por llenar verificar!!');
    }
    if (!this.fileSeletected) {
      return this.sendData(this.event, false);
    }
    return this.saveImageAndSendData();
  }

  saveImageAndSendData() {
    if (this.fileSeletected) {
      this.filesService
        .uploadImage(this.fileSeletected)
        .pipe(
          catchError((error) => {
            this.scrollToTop();
            this.notifications.set(
              'image',
              'Hubo un error al subir la imagen, intentelo mas tarde'
            );
            return throwError(() => error);
          })
        )
        .subscribe((data) => {
          if (data.status === 200 || data.status === 201) {
            this.sendData(this.parseData(data.body.path));
          }
        });
    }
  }

  sendData(data: EventDetail, isRollBack = true) {
    if (data.id) {
      this.update(data, isRollBack);
    } else {
      this.create(data, isRollBack);
    }
  }

  update(data: EventDetail, isRollback: boolean) {
    if (this.fileSeletected === "") {
      data.linkImage = "";
    }
    this.scrollToTop();
    this.eventService
      .update(String(data.id), data)
      .pipe(
        catchError((error) => {
          if (this.isChangeUrlFile && isRollback) {
            this.rollbackImageUpload(data.linkImage);
          }
          this.scrollToTop();
          this.notifications.set(
            'updateEvent',
            'Hubo un error intentelo mas tarde'
          );
          return throwError(() => error);
        })
      )
      .subscribe({
        next: (res: any) => {
          this.notifications.clear();
          this.isChangeUrlFile && this.rollbackImageUpload(this.oldUrlFile);
          this.showSuccess('Evento actualizado.');
        },
        error: () => {
          this.event.linkImage = 'https://fakeimg.pl/600x400?text=Sin+Archivo&font=bebas';
        }
      });
  }

  create(data: EventDetail, isRollback: boolean) {
    this.scrollToTop();
    try {
      this.eventService
        .createEvent(data)
        .pipe(
          catchError((error) => {
            isRollback && this.rollbackImageUpload(data.linkImage);
            this.scrollToTop();
            this.notifications.set(
              'createEvent',
              'Hubo un error intentelo mas tarde'
            );
            return error;
          })
        )
        .subscribe((res) => {
          if (res.status === 200 || res.status === 201) {
            // this.backPage();
            this.notifications.clear();
            this.prepareDataForEvent();
            this.fileSeletected = '';
            this.event.linkImage = "https://fakeimg.pl/600x400?text=Sin+Archivo&font=bebas";
            this.showSuccess('Evento creado.');
          }
        });
    } catch (error) {
      console.error(error);
    }
  }

  parseData(urlImage: string) {
    this.validChangeUrlFile(urlImage);
    this.event.linkImage = urlImage;
    return this.event;
  }

  validChangeUrlFile(urlImage: string) {
    if (this.event && this.event.id && this.event.linkImage != null && this.getFileName(this.event.linkImage) !== this.getFileName(urlImage)) {
      this.isChangeUrlFile = true;
      this.oldUrlFile = this.event.linkImage;
    } else {
      this.isChangeUrlFile = false;
    }
  }

  getFileName(fileName: string): string {
    if (this.urlBaseFile === fileName.split('/')[2]) {
      return fileName.split('/')[6];
    }
    if (fileName.split('/')[0] === 'uploads') {
      return fileName.split('/')[2];
    }
    return '';
  }

  rollbackImageUpload(url: string) {
    this.deleteImage(url);
  }

  deleteImage(urlImage: string) {
    try {
      const image = this.getFileName(urlImage);
      this.filesService.DeleteImage(image).subscribe((data) => {
      });
    } catch (error) {
      console.error('error log: ', error);
    }
  }

  getPathBack(eventType: EVENT_TYPE_ROUTES): string {
    const type: string = isFairByEventType(eventType) ? 'ferias' : 'eventos';
    const path = `${type}/${eventType}`; // Ruta dinámica de tu aplicación
    return path;
  }

  backPage(): void {
    if (window.history.length > 1) {
      this.location.back();
    } else {
      this.router.navigateByUrl('/inicio');
    }
  }

  scrollToTop() {
    this.topElement.nativeElement.scrollIntoView({behavior: 'smooth'});
  }

  addVideo() {
    if (this.event && this.canSave()) {
      this.event.videos.push({link: ''});
    } else {
      this.notifications.set('errorFile', 'Hay campos requeridos que falta completar');
      this.scrollToTop();
    }
  }

  removeVideo(indexData: number) {
    this.event.videos = this.event.videos.filter((_, index) => index !== indexData);
  }

  addFoto() {
    if (this.event && this.canSave()) {
      this.event.images.push({link: ''});
    } else {
      this.notifications.set('errorFile', 'Hay campos requeridos que falta completar');
      this.scrollToTop();
    }
  }

  removeFoto(indexData: number) {
    this.event.images = this.event.images.filter((_, index) => index !== indexData);
  }

  addSponsor() {
    if (this.canSave()) {
      this.event.sponsors.push({name: '', linkCommercial: '', linkImage: ''});
    } else {
      this.notifications.set('errorFile', 'Hay campos requeridos que falta completar');
      this.scrollToTop();
    }
  }

  removeSponsor(indexData: number) {
    this.event.sponsors = this.event.sponsors.filter((_, index) => index !== indexData);
  }

  addPresentation() {
    if (this.canSave()) {
      this.event.presentations.push({name: '', speaker: '', link: ''});
    } else {
      this.notifications.set('errorFile', 'Hay campos requeridos que falta completar');
      this.scrollToTop();
    }
  }

  removePresentation(indexData: number) {
    this.event.presentations = this.event.presentations.filter((_, index) => index !== indexData);
  }

  showSuccess(message: string) {
    this.messageService.add({severity: 'success', summary: 'Operación exitosa', detail: message, life: 3000});
  }

  showError(message: string) {
    this.messageService.add({severity: 'error', summary: 'Error', detail: message, life: 3000});
  }

  protected readonly getErrorMessage = getErrorMessage;
  protected readonly validatorsInput = VALIDATORS_INPUT
}
