<div class="page container_section_large">
  <div class="header">
    <div class="content">
      <h2 class="title">Gestión Popup</h2>
      <p class="subtitle"></p>
    </div>
    @if (hasPermission) {
      <div class="actions">
        <a (click)="addPopup()">
          <span class="badge">Nuevo</span>
        </a>
      </div>
    }
  </div>
  <app-section-filter
    [dateStartFilter]="filterDateStartFormat ?? ''"
    [dateEndFilter]="filterDateEndFormat ?? ''"
    (filterEvent)="onFilter($event)"
    [isPopupFilter]="true"
  ></app-section-filter>


  <!-- [showCurrentPageReport]="true"
  [rowsPerPageOptions]="[4, 15, 30]"
  [paginator]="true"
  currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros" -->

  <div class="card">
    <p-table
      [value]="(popups |async) ?? []"
      [tableStyle]="{ 'min-width': '50rem' }"
      [rows]="4"
      [showCurrentPageReport]="true"
      [rowsPerPageOptions]="[4, 15, 30]"
      [paginator]="true"
      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
    >
      <ng-template pTemplate="header">
          <tr>
              <th>Imagen</th>
              <th>Fecha de Inicio</th>
              <th>Fecha de Fin</th>
              <th>Acciones</th>
          </tr>
      </ng-template>
      <ng-template pTemplate="body" let-popup>
          <tr>
              <td>
                <img
                  [src]="getImage(popup.image)"
                  alt="popup image"
                  width="100"
                  class="shadow-4"
                />
              </td>
              <td>{{ formatDate(popup.datetimeInit) }}</td>
              <td>{{ formatDate(popup.datetimeEnd) }}</td>
              <td>
                <p-button
                  icon="pi pi-pencil"
                  [rounded]="true"
                  [text]="true"
                  [style]="{ 'margin-right': '.5em' }"
                  severity="warning"
                  pTooltip="Editar"
                  [showDelay]="1000"
                  [hideDelay]="300"
                  tooltipPosition="bottom"
                  (click)="editPopup(popup.id)"
                ></p-button>
                <p-button
                  icon="pi pi-trash"
                  [rounded]="true"
                  [text]="true"
                  severity="danger"
                  pTooltip="Eliminar"
                  [showDelay]="1000"
                  [hideDelay]="300"
                  tooltipPosition="bottom"
                  (click)="deletePopup($event, popup.id)"
                ></p-button>
              </td>
          </tr>
      </ng-template>
  </p-table>
  </div>
  <p-toast></p-toast>
  <p-confirmDialog></p-confirmDialog>
</div>
