import { Component } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { AseiCalendarModalComponent } from "../asei-calendar-modal/asei-calendar-modal.component";
import * as moment from "moment";
import { firstValueFrom } from "rxjs";
import { EventService } from "../../pages/events/services/event.service";
import Evento from "../../interfaces/evento.interface";
import Event from "../../pages/events/interfaces/event.interface";
import { now } from "../../helpers/converters.helper";

interface Day {
  value: number;
  class: string;
  fullDate: string;
}

interface EventoGroupedByDate {
  [date: string]: Event[];
}

@Component({
  selector: 'asei-calendar',
  templateUrl: './asei-calendar.component.html',
  styleUrls: ['./asei-calendar.component.scss']
})
export class AseiCalendarComponent {
  currYear: number = new Date().getFullYear();
  currMonth: number = new Date().getMonth();
  showYearPicker = false;
  showMonthPicker = false;
  selectedYear: number | undefined;
  selectedMonth: number | undefined;

  daysName = ['D', 'L', 'M', 'M', 'J', 'V', 'S'];
  months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
  days: Day[] = [];
  currentMonth!: string
  currentYear!: number

  events!: Event[];
  eventoGroupedByDate!: EventoGroupedByDate;


  monthLists = [
    { name: 'Enero', value: 0 },
    { name: 'Febrero', value: 1 },
    { name: 'Marzo', value: 2 },
    { name: 'Abril', value: 3 },
    { name: 'Mayo', value: 4 },
    { name: 'Junio', value: 5 },
    { name: 'Julio', value: 6 },
    { name: 'Agosto', value: 7 },
    { name: 'Septiembre', value: 8 },
    { name: 'Octubre', value: 9 },
    { name: 'Noviembre', value: 10 },
    { name: 'Diciembre', value: 11 }
  ];
  endYear = this.currYear + 20;
  yearLists: { name: string, value: number }[] = [];

  constructor(
    private readonly eventService: EventService,
    private dialog: MatDialog
  ) {
  }

  async ngOnInit() {
    for (let year = 1990; year <= this.endYear; year++) {
      this.yearLists.push({ name: year.toString(), value: year });
    }
    await this.renderCalendar();
  }

  async toggleYearPicker() {
    this.selectedYear = this.currYear;
    this.showYearPicker = !this.showYearPicker;
    if (!this.showYearPicker) {
      await this.renderCalendar();
    }
  }

  async toggleMonthPicker() {
    this.selectedMonth = this.currMonth;
    this.showMonthPicker = !this.showMonthPicker;
    if (!this.showMonthPicker) {
      await this.renderCalendar();
    }
  }

  onYearSelect(event: any) {
    this.currYear = event;
    this.toggleYearPicker();
  }

  onMonthSelect(event: any) {
    this.currMonth = event;
    this.toggleMonthPicker();
  }

  renderCalendar = async () => {
    this.days = []
    this.events = await this.getEvents();
    this.eventoGroupedByDate = this.groupByDate(this.events)
    const date = new Date(this.currYear, this.currMonth, 1);
    let firstDayofMonth = date.getDay();
    let lastDateofMonth = new Date(this.currYear, this.currMonth + 1, 0).getDate();
    let lastDayofMonth = new Date(this.currYear, this.currMonth, lastDateofMonth).getDay();
    let lastDateofLastMonth = new Date(this.currYear, this.currMonth, 0).getDate();


    for (let i = firstDayofMonth; i > 0; i--) {
      const value = lastDateofLastMonth - i + 1;
      const fullDate = this.getFullDate(this.currYear, this.currMonth, value)
      const day: Day = {
        value: value,
        class: 'inactive',
        fullDate: fullDate,
      }
      this.days.push(day)
    }
    //
    for (let i = 1; i <= lastDateofMonth; i++) {
      const value = i;
      const fullDate = this.getFullDate(this.currYear, this.currMonth, value)
      const day: Day = {
        value: value,
        class: this.getIsActiveDay(fullDate),
        fullDate: fullDate
      }
      this.days.push(day)
    }
    //
    for (let i = lastDayofMonth; i < 6; i++) {
      const value = i - lastDayofMonth + 1;
      const fullDate = this.getFullDate(this.currYear, this.currMonth, value)

      const day: Day = {
        value: value,
        class: 'inactive',
        fullDate: fullDate
      }
      this.days.push(day)
    }
    const fullDateToFind = now(); // la fecha que estás buscando
    const foundDay = this.days.find(day => day.fullDate === fullDateToFind);
    if (foundDay) {
      foundDay.class += ' today';
    }
    this.currentMonth = this.months[this.currMonth];
    this.currentYear = this.currYear;
  };

  async changeCalendar(action: string) {
    this.currMonth = action === "prev" ? this.currMonth - 1 : this.currMonth + 1;

    if (this.currMonth < 0 || this.currMonth > 11) {
      this.currYear = action === "prev" ? this.currYear - 1 : this.currYear + 1;
      this.currMonth = this.currMonth < 0 ? 11 : 0;
    }
    this.selectedMonth = this.currMonth;
    this.selectedYear = this.currYear;
    await this.renderCalendar();
  }

  getIsActiveDay(date: string): string {
    const events = this.eventoGroupedByDate;
    return date in events ? 'active' : '';
  }

  openDayModal(day: Day) {
    if (day.class.includes('active')) {
      const eventsByDate = this.eventoGroupedByDate[day.fullDate];
      this.dialog.open(AseiCalendarModalComponent, {
        minWidth: 300, // Clase CSS personalizada
        maxWidth: 600,
        data: {
          date: day.fullDate,
          events: eventsByDate
        }
      });
    }
  }

  getFullDate(year: number, month: number, day: number) {
    const monthStr = (month + 1).toString().padStart(2, '0');
    const dayStr = day.toString().padStart(2, '0');
    return `${year}-${monthStr}-${dayStr}`
  }

  async getEvents() {
    const firstDayOfMonth = moment().year(this.currYear).month(this.currMonth).startOf('month').format('YYYY-MM-DD');
    const lastDayOfMonth = moment().year(this.currYear).month(this.currMonth).endOf('month').format('YYYY-MM-DD');
    const response = await firstValueFrom(this.eventService.getAll('', firstDayOfMonth, lastDayOfMonth, '', '', 400));
    if (response.ok) {
      return response.body.data;
    }
  }

  groupByDate(contenidoArray: Event[]): Record<string, Event[]> {
    return contenidoArray.reduce((groups: Record<string, Event[]>, obj: Event) => {
      const date = obj.dateInit.split(' ')[0];
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(obj);
      return groups;
    }, {});
  }
}
