import {Component} from '@angular/core';
import {ComponentsModule} from "../../../../components/components.module";
import {Post} from "../../interfaces/post.interface";
import {PostService} from "../../services/post.service";
import {firstValueFrom} from "rxjs";
import {getDateDDMMYYYY} from "../../../../helpers/converters.helper";
import {getImageOThumbnail} from "../../../../helpers/news.helper";
import {PostType} from "../../interfaces/post-type.interface";
import { DateTime } from 'luxon';

@Component({
  selector: 'app-last-news',
  standalone: true,
  imports: [
    ComponentsModule
  ],
  templateUrl: './last-news.component.html',
  styleUrl: './last-news.component.scss'
})
export class LastNewsComponent {
  notifications: Map<string, string> = new Map<string, string>();
  showNotifications: boolean = false;
  lastNews: Post[] = [];
  filterType: string = 'last-post';
  slugFilter: string = '';
  filter: any;
  filterDateStartFormat: any;
  filterDateEndFormat: any;
  filterSearch: string = '';

  constructor(
    private readonly postService: PostService,
  ) {
  }

  async ngOnInit() {
    // this.lastNews = await this.getNews();
    this.slugFilter = 'asei-noticias';
    this.initializeFilters();
  }

  async getNews(
    dateStart: string = '',
    dateEnd: string = '',
    search: string = '',
  ) {
    try {
      const response = await firstValueFrom(
        this.postService.getAllLast(dateStart, dateEnd, search)
      );
      return response.body.data;
    } catch (error) {
      this.notifications.set('not-save', 'Se han actualizado los permisos, refresca la página con F5!!');
      this.showNotifications = true;
    }
  }

  getEnvironment(postType: PostType): string {
    return postType.name.replace(/\./g, "/")
  }

  async onFilter(value: any) {
    let {dateStartFilter, dateEndFilter, searchAll} = value

    if (dateStartFilter){
      dateStartFilter = DateTime.fromFormat(
        dateStartFilter,
        'dd/MM/yyyy'
      ).toFormat('yyyy-MM-dd');
    }

    if (dateEndFilter) {
      dateEndFilter = DateTime.fromFormat(
        dateEndFilter,
        'dd/MM/yyyy'
      ).toFormat('yyyy-MM-dd');
    }

    this.lastNews = await this.getNews(dateStartFilter, dateEndFilter, searchAll);

    const filters = {
      type: 'last-post',
      slug: this.slugFilter,
      dateStart: dateStartFilter,
      dateEnd: dateEndFilter,
      search: searchAll,
    }
    localStorage.setItem('filters', JSON.stringify(filters));
  }

  private async initializeFilters(): Promise<any> {
    const storedFilters = localStorage.getItem('filters');

    if (!storedFilters) {
      this.lastNews = await this.getNews();
      return;
    }

    this.filter = JSON.parse(storedFilters);
    const isFilterChanged = this.isFilterChanged();

    if (isFilterChanged) {
      this.resetFilters();
      this.lastNews = await this.getNews();
    } else {
      this.applyFilters();
      this.lastNews = await this.getNews(this.filter.dateStart, this.filter.dateEnd, this.filter.search);
    }
  }

  private isFilterChanged(): boolean {
    return this.filterType !== this.filter.type || this.slugFilter !== this.filter.slug;
  }

  private resetFilters(): void {
    localStorage.removeItem('filters');
    this.filterSearch = '';
    this.filterDateStartFormat = '';
    this.filterDateEndFormat = '';
  }

  private applyFilters(): void {
    if (this.filter.dateStart) {
      this.filterDateStartFormat = DateTime.fromFormat(this.filter.dateStart, 'yyyy-MM-dd')
        .toFormat('dd/MM/yyyy');
    }

    if (this.filter.dateEnd) {
      this.filterDateEndFormat = DateTime.fromFormat(this.filter.dateEnd, 'yyyy-MM-dd')
        .toFormat('dd/MM/yyyy');
    }

    this.filterSearch = this.filter.search;
  }

  // protected readonly getDateDDMMYYYY = getDateDDMMYYYY;
  protected readonly getDateDDMMYYYY = getDateDDMMYYYY;
  protected readonly getImageOThumbnail = getImageOThumbnail;
}
