import {AfterViewChecked, Component, DoCheck, ElementRef, Renderer2} from '@angular/core';
import {firstValueFrom} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {Location} from "@angular/common";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {EventDetail} from "../../interfaces/event-detail.interface";
import {EventGroupFormat, parseEventGroupFormat} from "../../../events-activities/events.helper";
import {EventService} from "../../services/event.service";
import {MultimediaEvento} from "../../../../interfaces/link-evento.interface";
import {MultimediaModalComponent} from "../../../../components/video-modal/multimedia-modal.component";
import {parseAseiInfoEvent} from "../../../../mapper/asei-info.mapper";
import {getHourInFormat12HRS, getLinkImage, getThumbnailVideo} from "../../../../helpers/converters.helper";
import { DateTime } from 'luxon';

@Component({
  selector: 'app-events-view',
  templateUrl: './event-show-page.component.html',
  styleUrls: ['./event-show-page.component.scss']
})
export class EventShowPageComponent implements DoCheck, AfterViewChecked {
  event!: EventDetail;
  eventGroupFormat!: EventGroupFormat
  path: string = '';
  currentDate = DateTime.now().toFormat('dd/MM/yyyy');

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
    private location: Location,
    private sanitizer: DomSanitizer,
    private readonly eventoService: EventService,
    private el: ElementRef,
    private renderer: Renderer2,
  ) {
  }

  ngAfterViewChecked() {
    const contentEventElements = this.el.nativeElement.querySelectorAll('.content-eventGroupFormat');
    contentEventElements.forEach((contentEventElement: any) => {
      const images = contentEventElement.querySelectorAll('img');
      images.forEach((img: any) => {
        this.renderer.setStyle(img, 'width', '100%');
      });
    });
  }

  async ngOnInit() {
    this.path = this.route.snapshot.url[0]?.path ?? '';
    const id: string = this.route.snapshot.paramMap.get('id') ?? '';
    this.event = await this.getEvent(id);
    this.eventGroupFormat = parseEventGroupFormat(this.event)
    if (!this.event) this.backPage()
  }

  ngDoCheck() {
    const content = document.getElementById("content_event");
    if (content) {
      const img = content.querySelector("img");
      img?.setAttribute('style', 'width: 100%');
    }
  }

  renderContent(content: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(content);
  }


  async getEvent(id: string) {
    try {
      const response = await firstValueFrom(this.eventoService.getOne(id))
      if (response.ok) {
        return response.body.data
      }
      return null
    } catch (error) {
      return null;
    }
  }

  redirect(link: string | null) {
    if (link) {
      window.open(link, '_target')
    }
  }

  handleImageError(event: any): void {
    const imgElement = event.target as HTMLImageElement;
    imgElement.onerror = null;
    imgElement.src = '';
    imgElement.alt = 'Multimedia dañada o eliminada, revise el link de la imagen o video';
    const parentElement = imgElement.parentElement;
    if (parentElement) {
      parentElement.classList.add('disabled');
      parentElement.innerHTML = 'Multimedia dañada o eliminada, revise el link de la imagen o video';
    }
  }

  handleClick(event: MouseEvent, multimedia: MultimediaEvento): void {
    const parentElement = (event.currentTarget as HTMLElement);
    if (!parentElement.classList.contains('disabled')) {
      this.openMultimediaModal(multimedia);
    }
  }

  openMultimediaModal(multimedia: MultimediaEvento) {
    this.dialog.open(MultimediaModalComponent, {
      data: {
        multimedia: multimedia
      }
    });
  }

  backPage() {
    if (window.history.length > 1) {
      this.location.back();
    } else {
      const path = '/' + this.path; // Ruta dinámica de tu aplicación
      this.router.navigateByUrl(path);
    }
  }


  truncateText( text: string ) {
    if( text.length < 70 ) return text
    return `${text?.slice(0, 70)}...`
  }

  protected readonly parseAseiInfoEvent = parseAseiInfoEvent;
  protected readonly getThumbnailVideo = getThumbnailVideo;
  protected readonly getLinkImage = getLinkImage;
  protected readonly getHourInFormat12HRS = getHourInFormat12HRS;
}
