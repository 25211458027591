const VALIDATORS_INPUT = {
  MAX_TEXT: 100,
  MAX_DNI: 8,
  MAX_CE: 12,
  MAX_TEXT_DESCRIPTION: 500,
  MAX_PRESENTATION: 40,
  LENGTH_DNI: 8,
  LENGTH_RUC: 11,
  FILE_IMG_SIZE: 2 * 1024 * 1024, // 2MB for images
  FILE_OTHERS_SIZE: 15 * 1024 * 1024 // 15MB for other files
}
export default VALIDATORS_INPUT;
