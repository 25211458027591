<h1 mat-dialog-title>
  <strong>
    {{this.title}}
  </strong>
</h1>
<button mat-icon-button mat-dialog-close class="close-button" aria-label="Cerrar">
  <mat-icon>close</mat-icon>
</button>
<div mat-dialog-content class="content">
  <ul class="list">
    @for (email of data.emails; track email) {
      <li>{{email}}</li>
    }
  </ul>
</div>
<div mat-dialog-actions class="footer">
  <button
    mat-button mat-dialog-close
    cdkFocusInitial
    (click)="onExportExcel()"
    >
    Exportar
  </button>
</div>
