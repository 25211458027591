import { Component, OnInit } from '@angular/core';
import { SupplierType } from "../../../../../interfaces/supplier-type.interface";
import { SupplierTypeService } from "../../../services/supplier-type.service";
import { MessageService } from 'primeng/api';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-supplier-type',
  templateUrl: 'supplier-type.component.html',
  styleUrls: ['supplier-type.component.scss'],
  providers: [MessageService, SupplierTypeService],
})
export class SupplierTypeListComponent implements OnInit {
  supplierTypes: SupplierType[] = [];
  filteredSupplierTypes: SupplierType[] = [];
  paginatedSupplierTypes: SupplierType[] = [];
  filterSearch: string = '';

  paginatorData: any = {
    total: 0,
    perPage: 10,
    currentPage: 1,
    totalPages: 0,
  };

  activeMenu: number | null = null;

  // Variables para los popups y el formulario
  displayListDialog: boolean = true;
  displayFormDialog: boolean = false;
  displayConfirmDialog: boolean = false;
  dialogTitle: string = '';
  form!: FormGroup;
  isEditMode: boolean = false;
  supplierTypeId!: number;
  selectedSupplierId: number | null = null;

  constructor(
    private supplierTypeService: SupplierTypeService,
    private messageService: MessageService,
    private fb: FormBuilder,
    private readonly dynamicDialogRef: DynamicDialogRef,
  ) {}

  ngOnInit(): void {
    this.fetchSupplierTypes();
    this.initializeForm();
  }

  initializeForm(): void {
    this.form = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(255)]],
    });
  }

  fetchSupplierTypes(): void {
    this.supplierTypeService.getAll().subscribe({
      next: (data) => {
        this.supplierTypes = data;
        this.filteredSupplierTypes = data;
        this.updatePaginator();
      },
      error: () => this.showError('No se pudo cargar la lista de tipos de proveedores.'),
    });
  }

  openListDialog(): void {
    this.displayListDialog = true;
  }

  closeListDialog(): void {
    this.dynamicDialogRef.close()
  }

  openFormDialog(supplierType?: SupplierType): void {
    if (supplierType) {
      this.isEditMode = true;
      this.dialogTitle = 'Editar Tipo de Proveedor';
      this.form.patchValue({ name: supplierType.name });
      this.supplierTypeId = supplierType.id;
    } else {
      this.isEditMode = false;
      this.dialogTitle = 'Crear Tipo de Proveedor';
      this.form.reset();
    }
    this.displayFormDialog = true;
  }

  closeFormDialog(): void {
    this.displayFormDialog = false;
  }

  onSubmit(): void {
    if (this.form.valid) {
      const supplierTypeData = this.form.value;

      if (this.isEditMode) {
        this.supplierTypeService.update(this.supplierTypeId, supplierTypeData).subscribe({
          next: () => {
            this.showSuccess('Tipo de proveedor actualizado con éxito.');
            this.closeFormDialog();
            this.fetchSupplierTypes();
          },
          error: () => this.showError('No se pudo actualizar el tipo de proveedor.'),
        });
      } else {
        this.supplierTypeService.create(supplierTypeData).subscribe({
          next: () => {
            this.showSuccess('Tipo de proveedor creado con éxito.');
            this.closeFormDialog();
            this.fetchSupplierTypes();
          },
          error: () => this.showError('No se pudo crear el tipo de proveedor.'),
        });
      }
    } else {
      this.showError('Por favor, complete todos los campos requeridos.');
    }
  }

  confirmDeleteDialog(id: number): void {
    this.selectedSupplierId = id;
    this.displayConfirmDialog = true;
  }

  closeConfirmDialog(): void {
    this.displayConfirmDialog = false;
    this.selectedSupplierId = null;
  }

  confirmDelete(): void {
    if (this.selectedSupplierId !== null) {
      this.supplierTypeService.delete(this.selectedSupplierId).subscribe({
        next: () => {
          this.supplierTypes = this.supplierTypes.filter((type) => type.id !== this.selectedSupplierId);
          this.filteredSupplierTypes = this.filteredSupplierTypes.filter((type) => type.id !== this.selectedSupplierId);
          this.updatePaginator();
          this.fetchSupplierTypes();
          this.showSuccess('Tipo de proveedor eliminado con éxito.');
        },
        error: () => this.showError('No se pudo eliminar el tipo de proveedor.'),
      });
    }
    this.closeConfirmDialog();
  }

  showSuccess(message: string): void {
    this.messageService.add({ severity: 'success', summary: 'Éxito', detail: message });
  }

  showError(message: string): void {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: message });
  }

  updatePaginator(): void {
    const totalItems = this.filteredSupplierTypes.length;
    const itemsPerPage = this.paginatorData.perPage;
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    this.paginatorData.total = totalItems;
    this.paginatorData.totalPages = totalPages;
    this.paginatorData.currentPage = 1;
    this.updatePaginatedItems();
  }

  updatePaginatedItems(): void {
    const currentPage = this.paginatorData.currentPage;
    const itemsPerPage = this.paginatorData.perPage;
    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    this.paginatedSupplierTypes = this.filteredSupplierTypes.slice(start, end);
  }
}
