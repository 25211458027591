@if (showNotifications) {
  <div class="notifications">
    <asei-notifications [notifications]="notifications"></asei-notifications>
  </div>
}
@if (pageInfo) {
  <div class="container_section_large page_with_filter">
    <div class="page filter">
      <asei-title-section
        [link]="pageInfo.slug + '/nuevo'"
        [title]="pageInfo.name"
        [description]="pageInfo.description"
        [hasPermission]="pageInfo.hasPermission.create"
        actionText="Nuevo"
        version="two"
      ></asei-title-section>
      <app-section-filter
        typeFilter = "list"
        titleFilter = 'Mes'
        [List]="monthsObjetList"
        [listValue]="dateFilter"
        (filterList)="onFilter($event)"
      ></app-section-filter>
    </div>
    <div class="page">
      <div class="card-list">
        @for (eventGroup of eventByGroupYear; track eventGroup; let ii = $index) {
          <section>
            @if (eventGroup.events.length > 0) {
              <div class="container_big_card _{{ii}}">
                <h1 id="section_{{ eventGroup.title }}" class="title-general"
                    style="visibility: hidden">{{ eventType | uppercase }} {{ eventGroup.title }}</h1>
                @for (event of eventGroup.events; track event; let es = $index) {
                  <div>
                    <h1 class="title-general">{{ event.title }}</h1>
                    <div class="container_section_asei">
                      <div class="flyer">
                        <img [src]='event.linkImage != "" ? event.linkImage : "https://fakeimg.pl/600x400?text=Archivo&font=bebas"' alt="">
                      </div>
                      <div class="info">
                        <div class="date-time">
                          <p>
                            <mat-icon>calendar_month</mat-icon>
                            <span>
                          {{ event.dateInit }}
                              {{ event.dateEnd && event.dateEnd !== event.dateInit ? ' - ' + event.dateEnd : '' }}
                        </span>
                          </p>
                          @if (event.timeInit) {
                            <p class="time">
                              <mat-icon>access_time</mat-icon>
                              <span>
                            {{ getHourInFormat12HRS(event.timeInit) }}
                                {{ event.timeEnd ? ' - ' + getHourInFormat12HRS(event.timeEnd) : '' }}
                          </span>
                            </p>
                          }
                        </div>
                      </div>
                      @if (pageInfo.hasPermission.update || pageInfo.hasPermission.delete) {
                        <div class="actions">
                          <mat-icon (click)="edit(event.id)">edit</mat-icon>
                          <mat-icon (click)="openDialogDelete('250ms', '250ms', event.id)">delete</mat-icon>
                        </div>
                      }
                    </div>
                    <div class="acordion">
                      @for (item of event.detail; track item; let in = $index) {
                        <mat-accordion class="container_accordion">
                          @if (item.data && item.data.length > 0) {
                            <mat-expansion-panel [expanded]="in === 0">
                              <mat-expansion-panel-header>
                                <mat-panel-title>
                                  {{ item.title }}
                                </mat-panel-title>
                                <mat-panel-description>
                                </mat-panel-description>
                              </mat-expansion-panel-header>
                              @switch (item.title) {
                                @case ('Contenido') {
                                  <div class="section">
                                    <div class="content-event" [innerHTML]="item.data[0]"></div>
                                  </div>
                                }
                                @case ('Videos') {
                                  <div class="section">
                                    <div class="multimedias">
                                      @for (video of item.data; track video) {
                                        <div class="multimedia" (click)="openMultimediaModal(
                                      {
                                      link:video.link,
                                      tipo_link_evento_id:'1'
                                    }
                                    )">
                                          <img [src]="getThumbnailVideo(video.link)" alt="thumbnail video" (error)="handleImageError($event)"/>
                                          <span class="play-icon"><mat-icon>play_arrow</mat-icon></span>
                                        </div>
                                      }
                                    </div>
                                  </div>
                                }
                                @case ('Fotos') {
                                  <div class="section">
                                    <div class="multimedias">
                                      @for (image of item.data; track image) {
                                        <div class="multimedia" (click)="openMultimediaModal(
                                    {
                                    link:image.link,
                                    tipo_link_evento_id:'3'
                                  }
                                  )">
                                          <img [src]="getLinkImage(image.link)" alt="imagen" (error)="handleImageError($event)"/>
                                        </div>
                                      }
                                    </div>
                                  </div>
                                }
                                @case ('Presentaciones') {
                                  <div class="section">
                                    <div class="card-list">
                                      @for (elem of item.data; track elem; let i = $index) {
                                        <div class="card-presentation">
                                          <div class="card-header">
                                            <h2
                                              class="">{{ truncateText(elem.name || ('Presentación ' + (i + 1))) }}</h2>
                                            <p>{{ elem.speaker }}</p>
                                          </div>
                                          <a target="_blank" [href]="elem.link">Descargar</a>
                                        </div>
                                      }
                                    </div>
                                  </div>
                                }
                                @case ('Auspiciadores') {
                                  <div class="section">
                                    <div class="multimedias">
                                      @for (sponsor of item.data; track sponsor) {
                                        <div class="sponsor">
                                          <img [src]="getLinkImage(sponsor.linkImage)" alt="">
                                          @if (sponsor.linkCommercial) {
                                            <div class="link">
                                              <a [href]="sponsor.linkCommercial" target="_blank">Propuesta Comercial</a>
                                            </div>
                                          }
                                        </div>
                                      }
                                    </div>
                                  </div>
                                }
                              }
                            </mat-expansion-panel>
                          }
                        </mat-accordion>
                      }
                    </div>
                  </div>
                }
              </div>
            }
          </section>
        }
      </div>
      @if (paginator) {
        <app-paginator [paginatorData]="paginator"
                       (pageSelected)="onPageSelected($event)"
                       design="simple"
        ></app-paginator>
      }
    </div>
  </div>
}
