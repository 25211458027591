@if (hasPermissionView) {
  <mat-list-item
    [routerLink]="path"
    routerLinkActive="list-item-active"
    (click)="toggleDropdown()"
  >
    <div class="content-box">
      <i [class]="icon"></i>
      <span matLine>{{ title }}</span>
      <mat-icon class="dropdown-icon">{{ dropdown ? 'expand_less' : 'expand_more' }}</mat-icon>
    </div>
  </mat-list-item>
  @if (dropdown) {
    <div class="dropdown-content">
      @for (subPage of items;track subPage.id) {
        <menu-item-link
          [title]="subPage.name"
          [path]="subPage.slug"
          (clicked) = "onClick()"
        />
      }
    </div>
  }
}
