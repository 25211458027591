<div class="container_section_large" #top>
  @if(titlePage){
    <asei-title-section
      link="/directorio-afiliados/new"
      [title]="titlePage"
      version="two"
    ></asei-title-section>
  }
  <p-toast></p-toast>
  <asei-notifications [notifications]="notifications"></asei-notifications>
  @if (company) {
    <div class="affiliate-information">
      <div class="image">
        <ASEI-upload-avatar
        [name]="company.name"
        [avatarUrl]="company.image ?? ''"
        ($change)="updateImage($event)"
        ($error)="errorLoadImage($event)"
        [disabled]="isRepresentative || disabledField"
        [size] = "sizeAvatar"
        >
      </ASEI-upload-avatar>
    </div>
    @if (company) {
      <form #form="ngForm"  class="information">
        <div class="flex">
          <div class="input-box">
            <app-input-group
              name="company.name"
              type="text"
              [required]="true"
              title="Nombre comercial de la empresa"
              [(ngModel)]="company.name"
              [disabled]="isRepresentative || disabledField"
              [maxlength]="validatorsInput.MAX_TEXT"
            ></app-input-group>
            <p class="errorsValidate" *ngIf="nameValidate">
              {{ nameValidate }}
            </p>
          </div>
          <div class="input-box">
            <app-input-group
              name="company.businessName"
              type="text"
              title="Razón social"
              [required]="!isAdmin"
              [(ngModel)]="company.businessName"
              [disabled]="isRepresentative || disabledField"
              [maxlength]="validatorsInput.MAX_TEXT"
            ></app-input-group>
            <p class="errorsValidate" *ngIf="businessNameValidate">
              {{ businessNameValidate }}
            </p>
          </div>
        </div>

        <div class="flex">
          <div class="input-box">
            <app-input-group
              name="company.documentNumber"
              type="ruc"
              title="RUC"
              [required]="!isAdmin"
              [(ngModel)]="company.documentNumber"
              [disabled]="isRepresentative || disabledField"
            ></app-input-group>
            <p class="errorsValidate" *ngIf="documentNumberValidate">
              {{ documentNumberValidate }}
            </p>
          </div>
          <div class="input-box">
            <app-input-group
              name="company.entryDate"
              type="date"
              title="Fecha de ingreso"
              [required]="!isAdmin"
              [(ngModel)]="company.entryDate"
              [disabled]="isRepresentative || disabledField"
            ></app-input-group>
          </div>
        </div>

        <div class="flex">
          <div class="input-box">
            <app-input-group
              name="company.web"
              type="url"
              title="Web"
              [(ngModel)]="company.web"
              [disabled]="isRepresentative || disabledField"
            ></app-input-group>
          </div>
          <div class="input-box">
            <app-input-group
              name="company.address"
              type="text"
              title="Dirección"
              [(ngModel)]="company.address"
              [disabled]="isRepresentative || disabledField"
              [maxlength]="validatorsInput.MAX_TEXT"
            ></app-input-group>
          </div>
        </div>

        <div class="input-box">
          <div class="radio-button_container">
            <p class="labelType">Tipo:</p>
            <!-- <mat-radio-group [(ngModel)]="company.companyType.id" name="company.companyType.id">
            <mat-radio-button [value]="1">Socio</mat-radio-button>
            <mat-radio-button [value]="2">Aliados estratégicos</mat-radio-button>
          </mat-radio-group> -->
          <ASEI-radio-button
          [options]="selectOptionsType"
          [selectedValue]="company.companyType.id"
          ($change)="onSelectedValueChange($event)"
          [disabled]="isRepresentative || disabledField"
          >
        </ASEI-radio-button>
      </div>
      @if (company.id) {
        <div class="container-slider">
          <p class="labelType">Estado:</p>
          <ASEI-input-slider
            [isActive]="!!company.isActive"
            ($change)="onChangeSliderState($event)"
            [disabled]="isRepresentative || disabledField"
          >
          </ASEI-input-slider>
        </div>
      }
  </div>
@if (isAdmin) {
  @if (!legalRepresentativeIsEdit) {
    <div class="perfil-title-center">
      <h1 class="mr-3">{{ employeeTypes[0].name }}</h1>
      <button
        pButton
        pRipple
        type="button"
        class="p-button-rounded ml-1"
        size="small"
        icon="pi pi-pencil"
        pTooltip="Editar"
        tooltipPosition="bottom"
        (click)="legalRepresentativeButton(employeeTypes[0].id)"></button>
    </div>
  } @else {
    <div class="perfil-title-center">
      <app-input-group
        name="employeeTypes_{{ [0] }}"
        type="text"
        class="mr-3"
        [(ngModel)]="employeeTypes[0].name"
        [maxlength]="validatorsInput.MAX_TEXT"
        [isLabel]="false"
      ></app-input-group>
    <button
        pButton
        pRipple
        type="button"
        class="p-button-rounded ml-1 mr-1"
        icon="pi pi-save"
        pTooltip="Guardar"
        tooltipPosition="bottom"
        (click)="legalRepresentativeButton(employeeTypes[0].id, true)"></button>
    <button
        pButton
        pRipple
        type="button"
        class="p-button-rounded ml-1"
        icon="pi pi-times"
        pTooltip="Cancelar"
        tooltipPosition="bottom"
        (click)="legalRepresentativeButton(employeeTypes[0].id, false)"></button>
    </div>
  }
} @else {
  <h1 class="mr-3">{{ employeeTypes[0].name }}</h1>
}
  @for (
    representative of company.employees.legalRepresentatives; track
    representative; let i = $index
  ) {
      <div class="container_data">
        <div class="flex">
          <div class="input-box">
            <app-input-group
              name="representative.name_{{ i }}"
              title="Nombres"
              type="text"
              [required]="!isAdmin"
              [(ngModel)]="representative.name"
              [disabled]="isRepresentative || disabledField"
              [maxlength]="validatorsInput.MAX_TEXT"
            ></app-input-group>
          </div>
          <div class="input-box">
            <app-input-group
              name="representative.fatherName_{{ i }}"
              title="Apellidos"
              type="text"
              [required]="!isAdmin"
              [(ngModel)]="representative.fatherName"
              [disabled]="isRepresentative || disabledField"
              [maxlength]="validatorsInput.MAX_TEXT"
            ></app-input-group>
          </div>
        </div>
        <div class="flex">
          <div class="input-box">
            <app-input-group
              name="representative.dni_{{ i }}"
              title="DNI"
              type="dni"
              [(ngModel)]="representative.documentNumber"
              [disabled]="isRepresentative || disabledField"
              [maxlength]="validatorsInput.MAX_DNI"
            ></app-input-group>
          </div>
          <div class="input-box">
            <app-input-group
              name="representative.birthday_{{ i }}"
              type="date"
              title="Fecha de Nacimiento"
              [(ngModel)]="representative.birthday"
              [disabled]="isRepresentative || disabledField"
            ></app-input-group>
          </div>
        </div>

        <div class="flex">
          @if (!disabledField){
            <div class="input-box">
              <app-input-group
                name="representative.position_{{ i }}"
                title="Cargo"
                type="text"
                [(ngModel)]="representative.position"
                [disabled]="isRepresentative || disabledField"
              ></app-input-group>
            </div>
            <div class="input-box">
              <app-input-group
                name="representative.phone_{{ i }}"
                title="Celular"
                type="phone"
                [(ngModel)]="representative.phone"
                [disabled]="isRepresentative || disabledField"
              ></app-input-group>
            </div>
          }
        </div>
        <div class="flex">
          @if (!disabledField){
            <div class="input-box">
              <div class="sub_group">
                <app-input-group
                  name="representative.email_{{ i }}"
                  title="Correo electrónico"
                  [required]="!isAdmin"
                  type="email"
                  [(ngModel)]="representative.email"
                  [disabled]="isRepresentative || disabledField"
                ></app-input-group>
                <button
                  type="button"
                  *ngIf="isEditMode"
                  (click)="removeRepresentative(i, representative.id)"
                  class="btn remove"
                  >
                  <mat-icon>delete_forever</mat-icon>
                </button>
              </div>
              <p class="errorsValidate">
                {{ getErrorMessages('employees.legalRepresentatives', i) }}
              </p>
            </div>
            }
        </div>
      </div>
  }
  @if (!disabledField && company.employees.legalRepresentatives.length < 1){
    <div class="button-add">
      <button
        type="button"
        (click)="addRepresentative()"
        class="btn btn-primary"
      >
        Agregar<mat-icon>add</mat-icon>
      </button>
    </div>
  }
@if (isAdmin) {
  @if (!assistantIsEdit) {
    <div class="perfil-title-center">
      <h1 class="mr-3">{{ employeeTypes[1].name }}</h1>
      <button
        pButton
        pRipple
        type="button"
        class="p-button-rounded ml-1"
        size="small"
        icon="pi pi-pencil"
        pTooltip="Editar"
        tooltipPosition="bottom"
        (click)="assistantButton(employeeTypes[1].id)"></button>
    </div>
  } @else {
    <div class="perfil-title-center">
      <app-input-group
        name="employeeTypes_{{ [1] }}"
        type="text"
        class="mr-3"
        [(ngModel)]="employeeTypes[1].name"
        [maxlength]="validatorsInput.MAX_TEXT"
        [isLabel]="false"
      ></app-input-group>
    <button
        pButton
        pRipple
        type="button"
        class="p-button-rounded ml-1 mr-1"
        icon="pi pi-save"
        pTooltip="Guardar"
        tooltipPosition="bottom"
        (click)="assistantButton(employeeTypes[1].id, true)"></button>
      <button
        pButton
        pRipple
        type="button"
        class="p-button-rounded ml-1"
        icon="pi pi-times"
        pTooltip="Cancelar"
        tooltipPosition="bottom"
        (click)="assistantButton(employeeTypes[1].id, false)"></button>
    </div>
  }
} @else {
  <h1 class="mr-3">{{ employeeTypes[1].name }}</h1>
}
@for (assistant of company.employees.assistants; track assistant; let i = $index) {
  <div class="container_data">
    <div class="flex">
      <div class="input-box">
        <app-input-group
          name="assistant.name_{{ i }}"
          title="Nombres"
          type="text"
          [required]="!isAdmin"
          [(ngModel)]="assistant.name"
          [disabled]="disabledField"
          [maxlength]="validatorsInput.MAX_TEXT"
        ></app-input-group>
      </div>
      <div class="input-box">
        <app-input-group
          name="assistant.fatherName_{{ i }}"
          title="Apellidos"
          type="text"
          [required]="!isAdmin"
          [(ngModel)]="assistant.fatherName"
          [disabled]="disabledField"
          [maxlength]="validatorsInput.MAX_TEXT"
        ></app-input-group>
      </div>
    </div>

    <div class="flex">
      <div class="input-box">
        <app-input-group
          name="assistant.position_{{ i }}"
          title="Cargo"
          type="text"
          [(ngModel)]="assistant.position"
          [disabled]="disabledField"
        ></app-input-group>
      </div>
      <div class="input-box">
        <app-input-group
          name="assistant.phone_{{ i }}"
          title="Celular"
          type="phone"
          [(ngModel)]="assistant.phone"
          [disabled]="disabledField"
        ></app-input-group>
      </div>
    </div>
    <div class="flex">
      <div class="input-box">
        <div class="sub_group">
          <app-input-group
            name="assistant.email_{{ i }}"
            title="Correo electrónico"
            type="email"
            [(ngModel)]="assistant.email"
            [disabled]="disabledField"
          ></app-input-group>
          @if (!disabledField){
            <button
              type="button"
              (click)="removeAssistant(i, assistant, $event)"
              class="btn remove"
            >
              <mat-icon>delete_forever</mat-icon>
            </button>
          }
        </div>
      </div>
    </div>
</div>
}
@if(!disabledField){
  <div class="button-add">
    <button type="button" (click)="addAssistant()" class="btn btn-primary">
      Agregar<mat-icon>add</mat-icon>
    </button>
  </div>
}
@if (isAdmin) {
  @if (!contactIsEdit) {
    <div class="perfil-title-center">
      <h1 class="mr-3">{{ employeeTypes[2].name }}</h1>
      <button
        pButton
        pRipple
        type="button"
        class="p-button-rounded ml-1"
        size="small"
        icon="pi pi-pencil"
        pTooltip="Editar"
        tooltipPosition="bottom"
        (click)="contactButton(employeeTypes[2].id)"></button>
    </div>
  } @else {
    <div class="perfil-title-center">
      <app-input-group
        name="employeeTypes_{{ [2] }}"
        type="text"
        class="mr-3"
        [(ngModel)]="employeeTypes[2].name"
        [maxlength]="validatorsInput.MAX_TEXT"
        [isLabel]="false"
      ></app-input-group>
    <button
        pButton
        pRipple
        type="button"
        class="p-button-rounded ml-1 mr-1"
        icon="pi pi-save"
        pTooltip="Guardar"
        tooltipPosition="bottom"
        (click)="contactButton(employeeTypes[2].id, true)"></button>
      <button
        pButton
        pRipple
        type="button"
        class="p-button-rounded ml-1"
        icon="pi pi-times"
        pTooltip="Cancelar"
        tooltipPosition="bottom"
        (click)="contactButton(employeeTypes[2].id, false)"></button>
    </div>
  }
} @else {
  <h1 class="mr-3">{{ employeeTypes[2].name }}</h1>
}
@for (contact of company.employees.contacts; track contact; let i = $index) {
  <div class="container_data">
    <div class="flex">
      <div class="input-box">
        <app-input-group
          name="contact.name_{{ i }}"
          title="Nombres"
          type="text"
          [required]="!isAdmin"
          [(ngModel)]="contact.name"
          [disabled]="disabledField"
          [maxlength]="validatorsInput.MAX_TEXT"
        ></app-input-group>
      </div>
      <div class="input-box">
        <app-input-group
          name="contact.fatherName_{{ i }}"
          title="Apellidos"
          type="text"
          [required]="!isAdmin"
          [(ngModel)]="contact.fatherName"
          [disabled]="disabledField"
          [maxlength]="validatorsInput.MAX_TEXT"
        ></app-input-group>
      </div>
    </div>

    <div class="flex">
      <div class="input-box">
        <app-input-group
          name="contact.position_{{ i }}"
          title="Cargo"
          type="text"
          [(ngModel)]="contact.position"
          [disabled]="disabledField"
          [maxlength]="validatorsInput.MAX_TEXT"
        ></app-input-group>
      </div>
      <div class="input-box">
        <app-input-group
          name="contact.phone_{{ i }}"
          title="Celular"
          type="phone"
          [(ngModel)]="contact.phone"
          [disabled]="disabledField"
          [maxlength]="validatorsInput.MAX_TEXT"
        ></app-input-group>
      </div>
    </div>
    <div class="flex">
      <div class="input-box">
        <div class="sub_group">
          <app-input-group
            name="contact.email_{{ i }}"
            type="email"
            title="Correo electrónico"
            [(ngModel)]="contact.email"
            [disabled]="disabledField"
          ></app-input-group>
          @if (!disabledField){
            <button
              type="button"
              (click)="removeContact(i, contact, $event)"
              class="btn remove"
            >
              <mat-icon>delete_forever</mat-icon>
            </button>
          }
        </div>
      </div>
    </div>
  </div>
}
@if(!disabledField){
  <div class="button-add">
    <button type="button" (click)="addContact()" class="btn btn-primary">
      Agregar<mat-icon>add</mat-icon>
    </button>
  </div>
}

@if (isAdmin) {
  @if (!userAdditionalIsEdit) {
    <div class="perfil-title-center">
      <h1 class="mr-3">{{ employeeTypes[3].name }}</h1>
      <button
        pButton
        pRipple
        type="button"
        class="p-button-rounded ml-1"
        size="small"
        icon="pi pi-pencil"
        pTooltip="Editar"
        tooltipPosition="bottom"
        (click)="userAdditionalButton(employeeTypes[3].id)"></button>
    </div>
  } @else {
    <div class="perfil-title-center">
      <app-input-group
        name="employeeTypes_{{ [3] }}"
        type="text"
        class="mr-3"
        [(ngModel)]="employeeTypes[3].name"
        [maxlength]="validatorsInput.MAX_TEXT"
        [isLabel]="false"
      ></app-input-group>
    <button
        pButton
        pRipple
        type="button"
        class="p-button-rounded ml-1 mr-1"
        icon="pi pi-save"
        pTooltip="Guardar"
        tooltipPosition="bottom"
        (click)="userAdditionalButton(employeeTypes[3].id, true)"></button>
      <button
        pButton
        pRipple
        type="button"
        class="p-button-rounded ml-1"
        icon="pi pi-times"
        pTooltip="Cancelar"
        tooltipPosition="bottom"
        (click)="userAdditionalButton(employeeTypes[3].id, false)"></button>
    </div>
  }
} @else {
  <h1 class="mr-3">{{ employeeTypes[3].name }}</h1>
}

@for (other of company.employees.others; track other; let i = $index) {
  <div class="container_data">
    <div class="flex">
      <div class="input-box">
        <app-input-group
          name="other.name_{{ i }}"
          title="Nombres"
          type="text"
          [required]="!isAdmin"
          [(ngModel)]="other.name"
          [disabled]="disabledField"
          [maxlength]="validatorsInput.MAX_TEXT"
        ></app-input-group>
      </div>
      <div class="input-box">
        <app-input-group
          name="other.fatherName_{{ i }}"
          title="Apellidos"
          type="text"
          [required]="!isAdmin"
          [(ngModel)]="other.fatherName"
          [disabled]="disabledField"
          [maxlength]="validatorsInput.MAX_TEXT"
        ></app-input-group>
      </div>
    </div>
    <div class="flex">
      <div class="input-box">
        <app-input-group
          name="other.position_{{ i }}"
          title="Cargo"
          type="text"
          [(ngModel)]="other.position"
          [disabled]="disabledField"
          [maxlength]="validatorsInput.MAX_TEXT"
        ></app-input-group>
      </div>
      <div class="input-box">
        <app-input-group
          name="other.phone_{{ i }}"
          title="Celular"
          type="phone"
          [(ngModel)]="other.phone"
          [disabled]="disabledField"
          [maxlength]="validatorsInput.MAX_TEXT"
        ></app-input-group>
      </div>
    </div>

    <div class="flex">
      <div class="input-box">
        <div class="sub_group">
          <app-input-group
            name="other.email_{{ i }}"
            type="email"
            title="Correo electrónico"
            [(ngModel)]="other.email"
            [disabled]="disabledField"
            [required]="!isAdmin"
          ></app-input-group>
          @if (!disabledField){
            <button
              type="button"
              (click)="removeOther(i, other, $event)"
              class="btn remove"
            >
              <mat-icon>delete_forever</mat-icon>
            </button>
          }
        </div>
        <p class="errorsValidate">
          {{ getErrorMessages('employees.others', i) }}
        </p>
      </div>
    </div>
  </div>
}
<!-- @if(!disabledField && company.employees.others.length < 3){ -->
@if (!disabledField){
  <div class="button-add">
    <button type="button" (click)="addOther()" class="btn btn-primary">
      Agregar<mat-icon>add</mat-icon>
    </button>
  </div>
}
<!-- } -->

</form>
}
<div class="actions">
  <button (click)="onCancel()" class="btn">
    Regresar
    <mat-icon>arrow_back</mat-icon>
  </button>
  @if (!disabledField){
    <button class="btn" (click)="save($event)">
      Guardar<mat-icon>save</mat-icon>
    </button>
  }
</div>
</div>
}
</div>
<p-confirmDialog [closable]="false"></p-confirmDialog>
