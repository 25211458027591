import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router, UrlSegment} from '@angular/router';
import {of, switchMap, firstValueFrom, throwError, catchError} from 'rxjs';
import {EVENT_TYPE, EVENT_TYPE_ROUTES} from 'src/app/types/constants/event-type.enum';
import {EventService} from 'src/app/pages/events/services/event.service';
import {EventDetail} from 'src/app/pages/events/interfaces/event-detail.interface';
import {NgForm} from '@angular/forms';
import {FilesService} from 'src/app/services/files.service';
import {environment} from 'src/environments/environment';
import {getDateDDMMYYYY, getDateDDMMYYYYToYMD, getDateYYYYMMDD, getLinkImage} from 'src/app/helpers/converters.helper';
import {
  getDefaulData,
  isFairByEventType,
} from "../../../events-activities/events.helper";
import {getErrorMessage} from "../../../../helpers/validators.helper";
import {_defaultNewsData} from "../../../../helpers/news.helper";
import {PostType} from "../../../post/interfaces/post-type.interface";
import {parseAseiInfoNews} from "../../../../mapper/news.mapper";
import {EventTypeService} from "../../services/event-type.service";
import {Location} from "@angular/common";
import {EventType} from "../../interfaces/event-type.interface";
import VALIDATORS_INPUT from 'src/app/helpers/validators-input.helper';
import { MessageService } from 'primeng/api';
import {SubPage, SubPageOption} from "../../../../interfaces/sub-page.interface";
import {PageService} from "../../../modules/services/page.service";

@Component({
  selector: 'app-events-form',
  templateUrl: './events-form-page.component.html',
  styleUrls: ['./events-form-page.component.scss'],
  providers: [MessageService]
})
export class EventsFormPageComponent implements OnInit {

  @ViewChild('form', {static: false}) public form!: NgForm;
  @ViewChild('top') topElement!: ElementRef;

  eventType!: EVENT_TYPE_ROUTES;
  event!: EventDetail;
  fileSeletected?: File | "";
  title = '';
  subPages: SubPage[] = [];
  selectedSubPageSlug: string = '';
  subPageOptions: SubPageOption[] = [];
  notifications: Map<string, string> = new Map<string, string>();
  typeFile = '';
  oldUrlFile = '';
  urlBaseFile = environment.url;
  isChangeUrlFile = false;
  isEditMode = false;
  titlePage!: string;
  dbSubPage: string | null = null;
  defaultEventType!: EventType;
  protected readonly getLinkImage = getLinkImage;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private readonly eventService: EventService,
    private pageService: PageService,
    private readonly eventTypeService: EventTypeService,
    private filesService: FilesService,
    private location: Location,
    private messageService: MessageService,
  ) {
  }

  ngOnInit() {
    this.prepareDataForEvent();
    if (this.subPages.length > 0) {
      this.subPageOptions = this.subPages.map(sp => ({ value: sp.slug, text: sp.name }));
    }
  }

  prepareDataForEvent() {
    this.route.url.subscribe((segments: UrlSegment[]) => {
      const fullPath = segments.map(s => s.path).join('/');
      if (fullPath.includes('nuevo')) {
        this.isEditMode = false;
        this.loadDataForNewEvent(segments);
      } else {
        this.isEditMode = true;
        this.loadDataForEditEvent();
      }
    });
  }

  onSelectSubPage() {
    if (this.selectedSubPageSlug === '') {
      this.event.subpage = null;
      if (this.defaultEventType) {
        this.event.eventType = this.defaultEventType;
      }
      return;
    }

    this.event.subpage = this.selectedSubPageSlug;
    const slugWithDots = this.selectedSubPageSlug.replace(/\//g, '.');

    this.eventTypeService.getOne(slugWithDots).subscribe({
      next: (resp) => {
        const eventType = resp.body?.data;
        if (eventType) {
          eventType.name = eventType.name.replace(/\//g, '.');
          this.event.eventType = eventType;

        }
      }
    });
  }

  loadDataForNewEvent(segments: UrlSegment[]) {
    this.titlePage = 'Nuevo Evento';
    this.event = getDefaulData();

    const pageSlug = segments.slice(0, -1).map(s => s.path).join('.');

    this.pageService.getOne(pageSlug).subscribe({
      next: (resPage) => {
        const pageData = resPage.body?.data;

        this.subPageOptions = [{ value: '', text: 'Sin categoría' }];

        if (pageData?.subPages?.length > 0) {
          this.subPages = pageData.subPages;
          this.subPageOptions = [
            { value: '', text: 'Sin categoría' },
            ...pageData.subPages.map((sp: SubPage) => ({
              value: sp.slug,
              text: sp.name
            }))
          ];
        } else {
          this.subPages = [];
        }
      }
    });

    this.eventTypeService.getOne(pageSlug).subscribe({
      next: (resType) => {
        const eventType = resType.body?.data;
        if (eventType) {
          this.event.eventType = eventType;
          this.defaultEventType = eventType;
        }
      }
    });
  }

loadDataForEditEvent() {
  this.route.paramMap.subscribe(params => {
    const idValue = params.get('id');
    if (idValue) {
      this.titlePage = 'Editar Evento';

      this.eventService.getOne(idValue).subscribe({
        next: (res: any) => {
          const data = res.body?.data;
          if (!data) return;

          data.dateInit = getDateDDMMYYYY(data.dateInit);
          data.dateEnd = getDateDDMMYYYY(data.dateEnd);
          data.linkImage = data.linkImage ?? 'https://fakeimg.pl/600x400?text=Sin+Archivo&font=bebas';

          this.event = data;
          this.dbSubPage = data.subPage ?? null;
          this.selectedSubPageSlug = data.subPage ?? '';

          this.subPageOptions = [{ value: '', text: 'Sin categoría' }];

          if (data.subPage) {
            this.subPageOptions.push({ value: data.subPage, text: data.subPage });
          }

          const fullSlug = data.eventType?.name;
          if (fullSlug) {
            this.pageService.getOne(fullSlug).subscribe({
              next: (resPage) => {
                const pageData = resPage.body?.data;
                if (pageData?.subPages?.length > 0) {
                  this.subPages = pageData.subPages;
                  this.subPageOptions = [
                    { value: '', text: 'Sin categoría' },
                    ...pageData.subPages.map((sp: SubPage) => ({
                      value: sp.slug,
                      text: sp.name
                    }))
                  ];
                } else {
                  this.subPages = [];
                }
              }
            });
          }

          if (data.eventType?.name) {
            const fullSlug = data.eventType.name;
            this.eventTypeService.getOne(fullSlug).subscribe({
              next: (resType) => {
                const eventType = resType.body?.data;
                if (eventType) {
                  this.event.eventType = eventType;
                  this.defaultEventType = eventType;
                }
              }
            });
          }
        }
      });
    }
  });
}

  onImageSelected(file: File) {
    this.fileSeletected = file;
  }

  canSave(): boolean {
    return Boolean(this.form && this.form.valid);
  }

  onSave() {
      if (!this.canSave()) {
          this.scrollToTop();
          this.notifications.set('not-save', 'Hay campos requeridos por llenar');
          return;
      }

      if (this.event?.eventType?.name) {
          this.event.eventType.name = this.event.eventType.name.replace(/\//g, '.');
      }

      if (!this.fileSeletected) {
          this.sendData(this.event, false);
      } else {
          this.saveImageAndSendData();
      }
  }

  saveImageAndSendData() {
    if (this.fileSeletected) {
      this.filesService
        .uploadImage(this.fileSeletected)
        .pipe(
          catchError((error) => {
            this.scrollToTop();
            this.notifications.set(
              'image',
              'Hubo un error al subir la imagen, intentelo mas tarde'
            );
            return throwError(() => error);
          })
        )
        .subscribe((data) => {
          if (data.status === 200 || data.status === 201) {
            this.sendData(this.parseData(data.body.path));
          }
        });
    }
  }

  sendData(data: EventDetail, isRollBack = true) {
    if (data.id) {
      this.update(data, isRollBack);
    } else {
      this.create(data, isRollBack);
    }
  }

  update(data: EventDetail, isRollback: boolean) {
    if (this.fileSeletected === "") {
      data.linkImage = "";
    }
    this.scrollToTop();
    this.eventService
        .update(String(data.id), data)
        .pipe(
            catchError((error) => {
                if (this.isChangeUrlFile && isRollback) {
                    this.rollbackImageUpload(data.linkImage);
                }
                this.scrollToTop();
                this.notifications.set('updateEvent', 'Hubo un error intentelo más tarde');
                return throwError(() => error);
            })
        )
        .subscribe({
            next: (res: any) => {
                this.notifications.clear();
                this.isChangeUrlFile && this.rollbackImageUpload(this.oldUrlFile);
                this.showSuccess('Evento actualizado.');

                if (this.event.subpage) {
                    this.dbSubPage = this.event.subpage;
                }
            },
            error: () => {
                this.event.linkImage = 'https://fakeimg.pl/600x400?text=Sin+Archivo&font=bebas';
            }
        });
  }

  create(data: EventDetail, isRollback: boolean) {
    this.scrollToTop();
    try {
      this.eventService
        .createEvent(data)
        .pipe(
          catchError((error) => {
            isRollback && this.rollbackImageUpload(data.linkImage);
            this.scrollToTop();
            this.notifications.set(
              'createEvent',
              'Hubo un error intentelo mas tarde'
            );
            return error;
          })
        )
        .subscribe((res) => {
          if (res.status === 200 || res.status === 201) {
            // this.backPage();
            this.notifications.clear();
            this.prepareDataForEvent();
            this.fileSeletected = '';
            this.event.linkImage = "https://fakeimg.pl/600x400?text=Sin+Archivo&font=bebas";
            this.showSuccess('Evento creado.');
          }
        });
    } catch (error) {
      console.error(error);
    }
  }

  parseData(urlImage: string) {
    this.validChangeUrlFile(urlImage);
    this.event.linkImage = urlImage;
    return this.event;
  }

  validChangeUrlFile(urlImage: string) {
    if (this.event && this.event.id && this.event.linkImage != null && this.getFileName(this.event.linkImage) !== this.getFileName(urlImage)) {
      this.isChangeUrlFile = true;
      this.oldUrlFile = this.event.linkImage;
    } else {
      this.isChangeUrlFile = false;
    }
  }

  getFileName(fileName: string): string {
    if (this.urlBaseFile === fileName.split('/')[2]) {
      return fileName.split('/')[6];
    }
    if (fileName.split('/')[0] === 'uploads') {
      return fileName.split('/')[2];
    }
    return '';
  }

  rollbackImageUpload(url: string) {
    this.deleteImage(url);
  }

  deleteImage(urlImage: string) {
    try {
      const image = this.getFileName(urlImage);
      this.filesService.DeleteImage(image).subscribe((data) => {
      });
    } catch (error) {
      console.error('error log: ', error);
    }
  }

  getPathBack(eventType: EVENT_TYPE_ROUTES): string {
    const type: string = isFairByEventType(eventType) ? 'ferias' : 'eventos';
    const path = `${type}/${eventType}`;
    return path;
  }

  backPage(): void {
    if (window.history.length > 1) {
      this.location.back();
    } else {
      this.router.navigateByUrl('/inicio');
    }
  }

  scrollToTop() {
    this.topElement.nativeElement.scrollIntoView({behavior: 'smooth'});
  }

  addVideo() {
    if (this.event && this.canSave()) {
      this.event.videos.push({link: ''});
    } else {
      this.notifications.set('errorFile', 'Hay campos requeridos que falta completar');
      this.scrollToTop();
    }
  }

  removeVideo(indexData: number) {
    this.event.videos = this.event.videos.filter((_, index) => index !== indexData);
  }

  addFoto() {
    if (this.event && this.canSave()) {
      this.event.images.push({link: ''});
    } else {
      this.notifications.set('errorFile', 'Hay campos requeridos que falta completar');
      this.scrollToTop();
    }
  }

  removeFoto(indexData: number) {
    this.event.images = this.event.images.filter((_, index) => index !== indexData);
  }

  addSponsor() {
    if (this.canSave()) {
      this.event.sponsors.push({name: '', linkCommercial: '', linkImage: ''});
    } else {
      this.notifications.set('errorFile', 'Hay campos requeridos que falta completar');
      this.scrollToTop();
    }
  }

  removeSponsor(indexData: number) {
    this.event.sponsors = this.event.sponsors.filter((_, index) => index !== indexData);
  }

  addPresentation() {
    if (this.canSave()) {
      this.event.presentations.push({name: '', speaker: '', link: ''});
    } else {
      this.notifications.set('errorFile', 'Hay campos requeridos que falta completar');
      this.scrollToTop();
    }
  }

  removePresentation(indexData: number) {
    this.event.presentations = this.event.presentations.filter((_, index) => index !== indexData);
  }

  showSuccess(message: string) {
    this.messageService.add({severity: 'success', summary: 'Operación exitosa', detail: message, life: 3000});
  }

  showError(message: string) {
    this.messageService.add({severity: 'error', summary: 'Error', detail: message, life: 3000});
  }

  protected readonly getErrorMessage = getErrorMessage;
  protected readonly validatorsInput = VALIDATORS_INPUT
}
