import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import Report from 'src/app/interfaces/report.interface';
import { Router } from "@angular/router";
import { getDateDDMMYYYY, getHourInFormat12HRS, getLinkImage, getThumbnailVideo } from "../../helpers/converters.helper"
import { can } from "../../helpers/permissions.helper";
import { DeleteModalComponent } from "../delete-modal/delete-modal.component";
import { MatDialog } from "@angular/material/dialog";
import { DomSanitizer } from '@angular/platform-browser';
import { MessageService } from 'primeng/api';
import { FilesService } from 'src/app/services/files.service';

@Component({
  selector: 'app-card-report',
  templateUrl: './card-report.component.html',
  styleUrls: ['./card-report.component.scss'],
  providers: [MessageService]
})

export class CardReportComponent implements OnInit {

  @Input() report!: Report;
  @Output() onDelete: EventEmitter<void> = new EventEmitter();
  @Input() environment!: string;
  // permissions
  @Input() canDownload = false;
  @Input() canEdit = false;
  @Input() canDelete = false;
  @Input() showThumbnail: boolean = true;
  @Output() $deleteReportId: EventEmitter<number> = new EventEmitter<number>();
  isFile: boolean = false;
  isNotImg: boolean = false;
  thumbnail: string | null = null;


  constructor(private router: Router,
    public dialog: MatDialog,
    private readonly filesService: FilesService,
    private messageService: MessageService,
  ) { }

  ngOnInit() {
    const filename = this.getFileNameFromUrl(getLinkImage(this.report.file));
    const extension = this.getExtensionFromFilename(filename);
    if(extension == 'pdf'){
      this.isFile = true;
      this.thumbnail = this.report.fileThumbnail ? getLinkImage(this.report.fileThumbnail) : null;
    }
    if(extension == ''){
      this.isNotImg = true;
    }
  }

  onButtonClick(event: MouseEvent): void {
    event.stopPropagation();
  }

  getFileNameFromUrl(url: string): string {
    const parts = url.split('/');
    return parts[parts.length - 1];
  }

  getExtensionFromFilename(filename: string): string {
    const parts = filename.split('.');
    return parts.length > 1 ? parts[parts.length - 1] : '';
  }

  onClickDownload(item: Report) {
    const filename = this.getFileNameFromUrl(getLinkImage(item.file));
    const extension = this.getExtensionFromFilename(filename);
    if(extension == 'pdf'){
      this.isFile = true;
      this.filesService.downloadFile(filename).subscribe({
        next: (response: Blob) => {
          const url = window.URL.createObjectURL(response);
          const link = document.createElement('a');
          link.href = url;
          link.download = filename;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        },
        error: () => {
          this.showError('Pdf no encontrado.')
        }
      });
    }else{
      this.filesService.downloadImage(filename).subscribe({
        next: (response: Blob) => {
          const url = window.URL.createObjectURL(response);
          const link = document.createElement('a');
          link.href = url;
          link.download = filename;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        },
        error: () => {
          this.showError('Imagen no encontrada.')
        }
      });
    }
  }

  navigateTo(item: Report) {
    const url = `/${this.environment}/${item.id}`;
    this.router.navigate([url]);
  }

  onClickEdit(item: Report) {
    this.router.navigate([`${this.environment}/${item.id}/editar`]);
  }

  async onClickDelete() {
    this.onDelete.emit();
  }

  preview(archivo: string) {
    window.open(getLinkImage(archivo), '_blank');
  }

  getLinkImagePreview() {
    let image = null;
    if (this.report.file && !this.report.file.includes('pdf')) {
      image = this.report.file;
    }
    if (this.report.fileThumbnail) {
      image = this.report.fileThumbnail;
    }
    return image ? getLinkImage(image) : 'https://fakeimg.pl/302x180?text=Archivo&font=bebas';
  }
  openDialogDelete(enterAnimationDuration: string, exitAnimationDuration: string): void {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      width: '250px',
      enterAnimationDuration,
      exitAnimationDuration,
    });

    dialogRef.componentInstance.deleteConfirmed.subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.$deleteReportId.emit(this.report.id);
      }
    });
  }

  showSuccess(message: string) {
    this.messageService.add({ severity: 'success', summary: 'Operación exitosa', detail: message, life: 3000 });
  }

  showError(message: string) {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: message, life: 3000 });
  }

  protected readonly getLinkImage = getLinkImage;
  protected readonly can = can;
  protected readonly getHourInFormat12HRS = getHourInFormat12HRS;
  protected readonly getDateDDMMYYYY = getDateDDMMYYYY;
}
