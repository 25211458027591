<form (ngSubmit)="changePasswordForm(fChangePassword)" #fChangePassword="ngForm" class="form">
  <div class="input-box">
    <app-input-group title="Contraseña actual" name="current_password" type="currentPassword" [required]="true"
      [(ngModel)]="userForm.currentPassword"></app-input-group>
  </div>

  <div class="input-box">
    <app-input-group title="Nueva contraseña" name="new_password" type="password" [required]="true"
      [(ngModel)]="userForm.newPassword"></app-input-group>
  </div>

  <div class="input-box">
    <app-input-group title="Confirma contraseña" name="new_password_confirmation" type="password" [required]="true"
      [(ngModel)]="userForm.newPasswordConfirmation"></app-input-group>
  </div>

  <div *ngIf="errorMessage" class="error-message mb-2">
    <p class="font-error">{{ errorMessage }}</p>
  </div>

  <div class="actions">
    <button class="btn primary">Guardar</button>
  </div>
</form>
