
@if (showNotifications) {
  <div class="notifications">
    <asei-notifications [notifications]="notifications"></asei-notifications>
  </div>
} @else {
  <asei-home
    [dataCards]="dataCards"
  ></asei-home>
}
