<div class="page container_section_large">
  <app-header-page
    [title]="title"
  ></app-header-page>
  <p-toast></p-toast>
  <div class="main">
    <div class="card personal-information">
      @if (form) {
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
          <div class="information">
            <div class="section">
              <div class="input-box">
                <app-asei-input label="Nombre" type="text" controlName="name"/>
              </div>
              <div class="input-box">
                <app-asei-input label="Descripción" type="text" controlName="description"/>
              </div>
              <p class="title-component">Componente:</p>
              <div class="field-checkbox">
                <div *ngFor="let category of componentList">
                  <p-radioButton [inputId]="category.value" [value]="category.value"
                                 formControlName="component"></p-radioButton>
                  <label style="margin-left: 5px" [for]="category.value" class="ml-2">{{ category.name }}</label>
                </div>
                <div class="flexflex align-items-center mt-2">
                  <p-checkbox
                    formControlName="hasPages"
                    [binary]="true"
                    inputId="hasPages" />
                  <label for="hasPages" style="margin-left: 10px">Páginas</label>
                </div>
              </div>
              <app-asei-upload-file label="Imagen de Fondo del Card" accept="image/*" controlName="imageBackgroundCard" [required]="true"/>
              <app-asei-upload-file label="Logo Primario del Card" accept="image/*" controlName="logoPrimaryCard"/>
              <app-asei-upload-file label="Logo Secundario del Card" accept="image/*" controlName="logoSecondaryCard"/>
            </div>
            <div class="actions">
              <a class="btn secondary" (click)="backPage()">Cancelar</a>
              <button class="btn primary" type="submit">Guardar</button>
            </div>
          </div>
        </form>
      }
    </div>
  </div>
</div>
