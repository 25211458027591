export interface IReview {
  description: string;
  supplierId?: number;
}

export class ReviewCreated {
  description: string;
  supplierId?: number;
  constructor (review: IReview) {
    this.description = review.description;
    this.supplierId = review.supplierId;
  }
}

export class ReviewUpdated {
  description: string;
  constructor (review: IReview) {
    this.description = review.description;
  }
}
