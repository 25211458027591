export const displaySize: {[size:string]: number} = {
  "sm":576,
  "md":768,
  "lg":1080,
  "xl":1256,
  "xxl":1440,
}

export const isShowSidenav: boolean = window.innerWidth > displaySize['lg'];

export const getDevice = () =>{
  const screenWidth = window.innerWidth;
  return{
    mobile : screenWidth < displaySize['sm'],
    tablet: screenWidth > displaySize['sm'] && screenWidth < displaySize['md'],
    tabletLarge: screenWidth > displaySize['md'] && screenWidth < displaySize['lg'],
    desktop: screenWidth > displaySize['lg'] && screenWidth < displaySize['xl'],
    desktopLarge: screenWidth > displaySize['xl'] && screenWidth < displaySize['xxl']
  }
}

export const getNumersCol = (cols: number[]) => {
  const screenWidth = window.innerWidth;
  if (screenWidth > displaySize['lg']) {
    return cols[0];
  } else if (screenWidth > displaySize['md'] && screenWidth < displaySize['lg']) {
    return cols[1];
  } else if (screenWidth > displaySize['sm'] && screenWidth < displaySize['md']) {
    return cols[2];
  } else {
    return cols[3];
  }
};

export const getheightRow = (rowHeight: number[]) => {
  const screenWidth = window.innerWidth;
  if (screenWidth > displaySize['lg']) {
    return rowHeight[0];
  } else if (screenWidth > displaySize['md'] && screenWidth < displaySize['lg']) {
    return rowHeight[1];
  } else if (screenWidth > displaySize['sm'] && screenWidth < displaySize['md']) {
    return rowHeight[2];
  } else {
    return rowHeight[3];
  }
};

export interface Tile {
  cols: number;
  rows: number;
}

export const getGridheightColRow = (heightRowList: number[][], cols:number[][]) => {
  let heightRows: Tile[]=[];
  heightRows = heightRowList.map((heightRow, index) => {
    return { cols: 0, rows: getheightRow(heightRow)};
  });
  cols.forEach((col, index) => {
    heightRows[index].cols = getNumersCol(col);
  });
  return heightRows;
}

