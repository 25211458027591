import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { catchError, throwError } from 'rxjs';
import { TechnicalAssistanceService } from 'src/app/pages/technical-assistance/services/technical-assistance.service';
import { AseiTechnicalAssistance } from 'src/app/types/asei-technical-assistance';
import { FilesService } from '../../../../services/files.service';
import { getLinkImage } from 'src/app/helpers/converters.helper';
import { getErrorMessage } from 'src/app/helpers/validators.helper';
import VALIDATORS_INPUT from 'src/app/helpers/validators-input.helper';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-technical-assistance-page',
  templateUrl: './technical-assistance-page.component.html',
  styleUrls: ['./technical-assistance-page.component.scss'],
  providers: [MessageService]
})
export class TechnicalAssistancePageComponent implements OnInit {
  @ViewChild('form', { static: false }) public form!: NgForm;
  @ViewChild('top') topElement!: ElementRef;

  technicalAssistance!: AseiTechnicalAssistance;
  attachedFile?: File;
  notifications: Map<string, string> = new Map<string, string>();
  typeFile = '';
  sendSuccess = false;

  maxLengthConsultation=1500;

  getLinkImage = getLinkImage;

  constructor(
    private technicalAssistanceService: TechnicalAssistanceService,
    private fileService: FilesService,
    private messageService: MessageService,
  ) {}
  ngOnInit(): void {
    this.technicalAssistance = this.defaultInitTechnicalAssistance();
  }

  onImageSelected(image: File) {
    this.attachedFile = image;
  }
  defaultInitTechnicalAssistance(): AseiTechnicalAssistance{
    const dataUser = JSON.parse(localStorage.getItem('UserData')??'');
    return {
      name: dataUser.data.name,
      lastname: '',
      documentNumber: '',
      job: '',
      businessName: '',
      theme: '',
      consultation: '',
      urlFile: '',
      email: dataUser.data.email,
    }
  }

  onTypeSelected(type: string) {
    this.typeFile = type;
  }

  scrollToTop() {
    this.topElement.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  get isValid(): boolean|null {
    return this.form.valid;
  }

  sendEmail() {
    // console.log('1', this.technicalAssistance);
    // console.log(this.form.value, this.form.valid);
    // if (!this.canSendEmail()) {
    //   console.log('2', this.technicalAssistance);
    //   this.scrollToTop();
    //   return this.notifications.set('not-save', 'Hay campos requeridos por llenar, verificar!!');
    // }
    if(this.attachedFile){
      if(this.typeFile==='pdf'){
        return this.uploadPdf();
      }else{
        return this.uploadImage();
      }
    }

    return this.sendData();
  }

  newConsult(){
    this.sendSuccess = false;
  }

  async sendData() {
    console.log('3', this.technicalAssistance);
    this.scrollToTop();
    this.technicalAssistanceService
      .SendEmail(this.technicalAssistance).subscribe({
        next: (response)=>{
          this.sendSuccess = true;
          this.notifications.clear();
          this.showSuccess('Asistencia Institucional Enviada.')
        },
        error: (error) => {
          this.notifications.set(
            'sendEmailTechnicalAssistance',
            'Hubo un error intentelo mas tarde'
          );
          console.log('4', error);
          return throwError(() => error);
        },
      });
  }



  uploadPdf(){
    this.fileService
        .uploadPDF(this.attachedFile)
        .pipe(
          catchError((error) => {
            this.scrollToTop();
            this.notifications.set(
              'pdf',
              'Hubo un error al subir el PDF, intentelo mas tarde'
            );
            return throwError(() => error);
          })
        )
        .subscribe((data) => {
          if (data.status === 200 || data.status === 201) {
            this.technicalAssistance.urlFile = this.getLinkImage(data.body.path);
            this.sendData();
          }
        });
  }

  uploadImage(){
    if(this.attachedFile){
      this.fileService
        .uploadImage(this.attachedFile)
        .pipe(
          catchError((error) => {
            this.scrollToTop();
            this.notifications.set(
              'imageUpload',
              'Hubo un error al subir la imagen, intentelo mas tarde'
            );
            return throwError(() => error);
          })
        )
        .subscribe((data) => {
          if (data.status === 200 || data.status === 201) {
            this.technicalAssistance.urlFile = this.getLinkImage(data.body.path);
            this.sendData();
          }
        });
    }
  }

  showSuccess(message: string) {
    this.messageService.add({ severity: 'success', summary: 'Operación exitosa', detail: message, life: 3000 });
  }

  showError(message: string) {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: message, life: 3000 });
  }

  protected readonly getErrorMessage = getErrorMessage;
  protected readonly validatorsInput = VALIDATORS_INPUT;
}
