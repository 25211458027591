import { Component, Input  } from '@angular/core';

@Component({
  selector: 'asei-notifications',
  templateUrl: './asei-notifications.component.html',
  styleUrls: ['./asei-notifications.component.scss'],
})
export class AseiNotificationsComponent{
  private notificationsMap: Map<string, string> = new Map();

  @Input() set notifications(messages: Map<string, string>) {
    this.notificationsMap = messages;
  }

  get notifications(): Map<string, string> {
    return this.notificationsMap;
  }

  removeNotification(key: string): void {
    this.notificationsMap.delete(key);
  }
}
