import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SupplierType, SupplierTypeResponse } from '../interfaces/supplier-type.interface';
import { BehaviorSubject, map, Observable } from 'rxjs';
const URL = environment.url + '/api/v1';

@Injectable({
  providedIn: 'root'
})
export class SupplierTypesService {
  private httpOptions = {};

  supplierTypes: SupplierType[] = [];
  supplierTypes$: BehaviorSubject<SupplierType[]> = new BehaviorSubject<SupplierType[]>(
    this.supplierTypes,
  );

  constructor(private http: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({
        "Accept": "application/json",
        "Content-Type": "application/json"
      }),
      observe: 'response' as const
    }
  }

  callGetList(): Observable<void> {
    return this.http.get<SupplierTypeResponse>(`${URL}/supplier-types`, this.httpOptions)
      .pipe(
        map((response: SupplierTypeResponse) => {
          this.updateSupplierTypes(response.body.data);
        }),
      );
  }

  getList(): Observable<SupplierType[]> {
    return this.supplierTypes$.asObservable();
  }

  private updateSupplierTypes(value: SupplierType[]): void {
    this.supplierTypes = value;
    this.supplierTypes$.next(this.supplierTypes);
  }
}
