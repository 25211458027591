<div class="asei_title_section_header_main">
  <div class="asei_title_section_header">
    <div class="asei_title_section_header-title">
      <h1>{{title}}</h1>
      @if (description) {
        <p class="{{pClassList}}">{{description}}</p>
      }
    </div>
    @if (version==='one') {
      <div class="asei_title_section_header-link">
        <a mat-list-item [routerLink]="link" routerLinkActive="list-item-active">
          Ver más
        </a>
      </div>
    }
    @if (hasPermission && version==='two') {
      <div class="actions">
        <a (click)="redirect(link)">
          <span class="badge">{{actionText}}</span>
        </a>
      </div>
    }
    @if (hasPermission && version==='three') {
      <div class="actions">
        <a (click)="onExportExcel()" style="margin-right: 4px;">
          <span class="badge">{{actionExportText}}</span>
        </a>

        <a (click)="redirect(link)">
          <span class="badge">{{actionText}}</span>
        </a>
      </div>
    }
  </div>
</div>
