import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Validators } from 'ngx-editor';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { RatingModule } from 'primeng/rating';
import { Rating } from '../../interfaces/supplier.interface';
import { ReviewCreated, ReviewUpdated } from '../../interfaces/review.interface';
import { ReviewsService } from '../../services/reviews.service';
import { SuppliersService } from '../../services/suppliers.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';

@Component({
  selector: 'app-rating',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, RatingModule, ConfirmDialogModule, ToastModule],
  templateUrl: './rating.component.html',
  styleUrl: './rating.component.scss',
  providers: [MessageService, ConfirmationService]
})
export class RatingComponent implements OnInit {
  title: string | null = null;
  supplierId: number = 0;
  rating!: Rating;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly dialogRef: DynamicDialogRef,
    private readonly dynamicDialogConfig: DynamicDialogConfig,
    private readonly reviewsService: ReviewsService,
    private readonly suppliersService: SuppliersService,
    private readonly messageService: MessageService,
  ) {}

  ngOnInit(): void {
    this.title = this.dynamicDialogConfig.data.title;
    this.supplierId = this.dynamicDialogConfig.data.supplierId;
    if (this.dynamicDialogConfig.data.rating) {
      this.rating = this.dynamicDialogConfig.data.rating;
      this.form.get('rating')?.setValue(this.rating.number);
      this.form.get('review')?.setValue(this.rating.review?.description);
    }
  }

  form: FormGroup = this.formBuilder.group({
    rating: [null, Validators.required],
    review: [null, Validators.required],
  });

  save() {
    if (!this.form.get('rating')?.value || this.form.get('rating')?.value === 0) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Debe seleccionar al menos una estrella antes de guardar la calificación.',
        life: 1500,
      });
      return;
    }

    if (this.form) {
      if (this.rating) {
        this.modifyRating();
        if (this.rating.review) {
          const reviewBody = {
            description: this.form.get('review')?.value,
          }
          const review = new ReviewUpdated(reviewBody);
          this.reviewsService.update(this.rating.review.id, review).subscribe({
            next: () => this.dialogRef.close({ success: true }),
            error: (response: any) => {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: response.error.error,
                life: 1500,
              });
            }
          })
        } else {
          if (this.form.get('review')?.value) {
            const body = {
              description: this.form.get('review')?.value,
              supplierId: this.supplierId,
            }
            const review = new ReviewCreated(body);
            this.reviewsService.create(review).subscribe({
              next: (response: any) => {
                const body = {
                  ratingId: this.form.get('rating')?.value,
                  reviewId: response.reviewId,
                  supplierRatingId: this.rating.supplierRatingId,
                }
                this.suppliersService.addReview(this.supplierId, body).subscribe({
                  next: () => this.dialogRef.close({ success: true }),
                  error: () => {}
                });
              },
              error: (response: any) => {
                this.messageService.add({
                  severity: 'error',
                  summary: 'Error',
                  detail: response.error.error,
                  life: 1500,
                });
              }
            });
          }
        }
      } else {
        if (this.form.get('review')?.value) {
          const body = {
            description: this.form.get('review')?.value,
            supplierId: this.supplierId,
          }
          const review = new ReviewCreated(body);
          this.reviewsService.create(review).subscribe({
            next: (response: any) => {
              const body = {
                ratingId: this.form.get('rating')?.value,
                reviewId: response.reviewId,
              }
              this.addRating(this.supplierId, body);
            },
            error: (response: any) => {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: response.error.error,
                life: 1500,
              });
            }
          });
        } else {
          const body = {
            ratingId: this.form.get('rating')?.value,
          }
          this.addRating(this.supplierId, body);
        }
      }
    }
  }


  addRating(supplierId: number, body: any) {
    this.suppliersService.addRating(supplierId, body).subscribe({
      next: () => {
        this.dialogRef.close({ success: true })},
      error: () => {},
    });
  }

  modifyRating() {
    this.suppliersService.modifyRating(this.supplierId, this.rating.id, this.form.get('rating')?.value).subscribe();
  }
}
