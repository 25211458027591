<mat-card>
    <mat-card-header>
      <div class="card-avatar">
        <span>Pedro Mendoza</span>
      </div>
      <div class="card-time">
        <mat-icon>calendar_month</mat-icon>
        <span>2023/03/21</span>
      </div>
    </mat-card-header>
    <mat-card-content>
      <span class="titulo">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eismod tempor.</span>
    </mat-card-content>
</mat-card>
  