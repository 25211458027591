<div mat-dialog-title class="header">
  <div class="title">
    <mat-icon>calendar_month</mat-icon>
    <span>{{getDateDDMMYYYY(date)}}</span>
  </div>
  <p class="description">Revisa aquí el cronograma de las ultimas actividades</p>
</div>
<button mat-icon-button mat-dialog-close class="close-button" aria-label="Cerrar">
  <mat-icon>close</mat-icon>
</button>
<mat-dialog-content>
  <div class="list-cards" >
    @for (event of events; track event.id) {
      <app-card-aniversario-old
        [event]="event" (click)="closeModal()"
        >
      </app-card-aniversario-old>
    }
  </div>
</mat-dialog-content>
