<h2 mat-dialog-title class="title">{{multimedia.tipo_link_evento_id === '1'?'Video':'Imagen'}}</h2>
<button mat-icon-button mat-dialog-close class="close-button" aria-label="Cerrar">
  <mat-icon>close</mat-icon>
</button>
<mat-dialog-content>
  @if (multimedia.tipo_link_evento_id === '1') {
    <div class="video-container">
      <iframe [lazyLoad]="getLinkVideo(multimedia.link)"  frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
    </div>
  }
  @if (multimedia.tipo_link_evento_id === '3') {
    <div class="image-container">
      <button class="button-redirect" (click)="onRedirectImage(multimedia.link)">
        <mat-icon>launch</mat-icon>
      </button>
      <div class="img-container">
        <img [src]="getGoogleDriveThumbnail(multimedia.link)" alt="">
      </div>
    </div>
  }
</mat-dialog-content>
