import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ReviewCreated, ReviewUpdated } from '../interfaces/review.interface';
import { map } from 'rxjs';
const URL = environment.url + '/api/v1';

@Injectable({
  providedIn: 'root'
})
export class ReviewsService {
  private httpOptions = {};

  constructor(private http: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({
        "Accept": "application/json",
        "Content-Type": "application/json"
      }),
      observe: 'response' as const
    }
  }

  create(review: ReviewCreated) {
    return this.http
      .post(`${URL}/reviews`, review, this.httpOptions)
      .pipe(map((response: any) => response.body));
  }

  update(reviewId: number, review: ReviewUpdated) {
    return this.http
      .put(`${URL}/reviews/${reviewId}`, review, this.httpOptions)
      .pipe(map((response: any) => response.body));
  }
}
