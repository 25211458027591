@if (notifications.size) {
  <div class="asei_notifications">
    @for (entry of notifications | keyvalue; track entry) {
      <div class="asei_notifications_container save" [ngClass]="{'save': entry.key === 'save', 'not-save': entry.key === 'not-save'}">
        <p>
          {{ entry.value }}
        </p>
        <button
          class="notifications_close"
          (click)="removeNotification(entry.key)"
          >
          x
        </button>
      </div>
    }
  </div>
}
