import { getDateDDMMYYYY, getHourInFormat12HRS, getLinkImage } from "../helpers/converters.helper";
import { AseiInfo, AseiInfoEvent } from "../types/asei-info";

export const parseAseiInfo = (row: any): AseiInfo => {
  return {
    id: row.id,
    title: row.title,
    urlImage: row.linkImage ? getLinkImage(row.linkImage): '',
    dateInit: getDateDDMMYYYY(row.dateInit),
    timeInit: getHourInFormat12HRS(row.timeInit)
  }
}

export const parseAseiInfoEvent = (row: any): AseiInfoEvent => {
  return {
    id: row.id,
    title: row.title,
    eventType: row.eventType,
    urlImage: row.linkImage ? getLinkImage(row.linkImage): '',
    dateInit: getDateDDMMYYYY(row.dateInit),
    timeInit: getHourInFormat12HRS(row.timeInit),
    dateEnd: getDateDDMMYYYY(row.dateEnd),
    timeEnd: getHourInFormat12HRS(row.timeEnd),
    registrationLink : row.linkRegister,
    phone: row.phone,
    textButtonRegister : row.textButtonRegister
  }
}

export const parseAseiInfoList = (data: any): AseiInfoEvent[] =>{
  return data.map((row:any) => parseAseiInfoEvent(row));
}

