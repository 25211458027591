import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ASEI-radio-button',
  templateUrl: './asei-radio-button.component.html',
  styleUrls: ['./asei-radio-button.component.scss']
})
export class AseiRadioButtonComponent {
  @Input() options: { value: string | number, label: string }[] = [];
  @Input() selectedValue?: string | number = '';
  @Input() disabled : boolean = false;
  @Output() $change = new EventEmitter<string | number>();

  onSelectOption(option: string | number): void {
    this.selectedValue = option;
    this.$change.emit(option);
  }
}
