import {Component, DoCheck, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {AbstractControl, ControlValueAccessor, FormControl, FormGroupDirective, ValidatorFn, Validators} from "@angular/forms";
import {getLinkImage} from "../../helpers/converters.helper";
import MESSAGE_ERROR from 'src/app/helpers/message-error.helper';

@Component({
  selector: 'app-asei-upload-file',
  templateUrl: './asei-upload-file.component.html',
  styleUrls: ['./asei-upload-file.component.scss'],
})
export class AseiUploadFileComponent implements OnInit, OnDestroy,  ControlValueAccessor, DoCheck {
  @ViewChild('fileInput') fileInput?: ElementRef;
  @Input()
  accept: string = 'image/*, application/pdf'
  @Input()
  controlName !: string
  @Input()
  label: string  = ''
  formControl!: FormControl
  submitted!: boolean
  image!: string
  @Input() invalid: boolean = false;
  @Input() errorMessage: string | null = '';
  @Input() value: string | null | undefined = '';
  @Input() required: boolean = false;

  private onChange: any = () => {};
  private onTouch: any = () => {};

  constructor(private formGroupDirective: FormGroupDirective) {
  }

  ngOnInit() {
    this.formGroupDirective.ngSubmit.subscribe({
      next: (value: any) => {
        this.submitted = value.isTrusted
      }
    })
    const form = this.formGroupDirective.form
    this.formControl = form.get(this.controlName) as FormControl
    if (!this.formControl.value) {
      this.image = `https://fakeimg.pl/600x400?text=Archivo&font=bebas`
    } else {
      this.image = getLinkImage(this.formControl.value)
    }
  }

  ngDoCheck() {
    this.invalid = this.validateInput(this.value);
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  validateInput(value: any) {
    this.errorMessage = '';
    if (this.required && (!value || value === '')) {
      this.errorMessage = MESSAGE_ERROR.OBLIGATORY_FIELD;
      return true;
    }else {
      return false;
    }
  }

  validate: ValidatorFn = (control: AbstractControl) => {
    const validators: ValidatorFn[] = [];
    if (this.required) {
      validators.push(Validators.required);
    }
    if (validators.length) {
      const validator = Validators.compose(validators);
      const result = validator ? validator(control) : null;
      return result;
    }
    return null;
  };

  // get invalidValue(): boolean {
  //   return this.submitted && this.formControl.invalid
  // }

  // get errorMessage(): string {
  //   if (this.formControl.errors?.['required']) {
  //     return `Archivo requerido`;
  //   } else if (this.formControl.errors?.['minlength']) {
  //     return `Archivo debe tener mas caracteres`;
  //   }
  //   return `Archivo inválido`
  // }

  openInputFile() {
    if (this.fileInput) {
      this.fileInput.nativeElement.click();
    }
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      this.formControl.setValue(file)
      const reader = new FileReader();
      reader.onload = () => {
        this.image = reader.result as string;
      };
      reader.readAsDataURL(file);
    }
  }

  ngOnDestroy(): void {
  }
}
