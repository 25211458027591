import {
  HttpInterceptor,
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from '../auth/services/auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const excludedEndpoints: string[] = ['login'];
    const excludedEndpointsAfterRefresh: string[] = ['refresh-token', 'logout'];
    const data = JSON.parse(localStorage.getItem('UserData') || '{}');
    const emailUser = data.data?.email;
    const token = this.getToken();

    if (excludedEndpoints.some(endpoint => req.url.includes(endpoint))) {
      return next.handle(req);
    }

    const clonedRequest = req.clone(
      {headers: req.headers.append('Authorization', `Bearer ${token}`)}
    );

    return next.handle(clonedRequest).pipe(
      catchError((error: HttpErrorResponse) => {
        if (
          error.status === 401 &&
          !excludedEndpointsAfterRefresh.some(endpoint => req.url.includes(endpoint)) &&
          emailUser
        ) {
          return this.authService.refreshToken(emailUser).pipe(
            switchMap((response: any) => {
              localStorage.setItem('UserData', JSON.stringify(response.body));
              const token = this.getToken();
              const newRequest = req.clone(
                {headers: req.headers.append('Authorization', `Bearer ${token}`)}
              );

              return next.handle(newRequest);
            }),
            catchError(refreshError => {
              console.error('Error al refrescar el token:', refreshError);
              localStorage.clear();
              this.router.navigate(['/login']);
              return throwError(refreshError);
            })
          );
        }
        return throwError(error);
      })
    );
  }
  private getToken() {
    let token = '';
    const userData = localStorage.getItem('UserData');
    if (userData) {
      const data = JSON.parse(userData);
      token = data.token
    }
    return token
  }
}
