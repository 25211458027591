import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {Observable} from "rxjs";
import {map} from 'rxjs/operators';
import Evento from "../../../interfaces/evento.interface";
import { getDateYYYYMMDD } from 'src/app/helpers/converters.helper';

const URL = environment.url + '/api/v1';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  private httpOptions = {};

  constructor(private http: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({
        "Accept": "application/json",
        "Content-Type": "application/json"
      }),
      observe: 'response' as const // Asegura que el tipo de 'observe' sea correcto
    }
  }

  getAll(
    eventType = '',
    dateStart = '',
    dateEnd = '',
    search = '',
    page = '',
    perPage = 6,
    display = 'summary',
    sort = 'desc'
  ) {
    let url = `${URL}/events?&type=${eventType}&search=${search}&display=${display}&sort=${sort}&per_page=${perPage}&start_date=${getDateYYYYMMDD(dateStart)}&end_date=${getDateYYYYMMDD(dateEnd)}`;
    if (page) {
      url += `&page=${page}`
    }
    return this.http.get<any>(url, this.httpOptions).pipe(
      map(response => response)
    );
  }

  getOne(id: string) {
    const url = `${URL}/events/${id}`;
    return this.http.get<any>(url, this.httpOptions).pipe(
      map(response => response)
    );
  }

  update(id: string, data: any) {
    const url = `${URL}/events/${id}`;
    return this.http.put<any>(url, JSON.stringify(data), this.httpOptions).pipe(
      map(response => response)
    );
  }

  getAllByTypeEvent(typeEvent: string, sort = 'desc', perPage = 6, dateStart = '', dateEnd = '', search = '') {
    const url = `${URL}/events?display=summary&per_page=${perPage}&sort=${sort}&type=${typeEvent}
    &start_date=${getDateYYYYMMDD(dateStart)}&end_date=${getDateYYYYMMDD(dateEnd)}&search=${search}`;
    return this.http.get<any>(url, this.httpOptions).pipe(
      map(response => response)
    );
  }

  getAllByTypeEventDetail(typeEvent: string, sort = 'desc', perPage = 10, dateStart = '', dateEnd = '', search = '') {
    const url = `${URL}/events?display=detail&per_page=${perPage}&sort=${sort}&type=${typeEvent}
    &start_date=${getDateYYYYMMDD(dateStart)}&end_date=${getDateYYYYMMDD(dateEnd)}&search=${search}`;
    return this.http.get<any>(url, this.httpOptions).pipe(
      map(response => response)
    );
  }

  getOneByTypeEvent(typeEvent: string, id: string): Observable<Evento> {
    const url = `${URL}/eventos/${typeEvent}/${id}`;
    return this.http.get<any>(url).pipe(
      map(response => response?.data)
    );
  }

  createEvent(body: any): Observable<any> {
    const url = `${URL}/events`;
    return this.http.post<any>(url, JSON.stringify(body), this.httpOptions).pipe(
      map((response: any) => response)
    );
  }

  delete(id: number) {
    const url = `${URL}/events/${id}`;
    return this.http.delete<any>(url, this.httpOptions).pipe(
      map(response => response)
    );
  }

  getByUrlPagination(url: string) {
    return this.http.get<any>(url, this.httpOptions).pipe(
      map(response => response)
    );
  }

}
