<form [formGroup]="form" (ngSubmit)="save()">
  <div class="popup-overlay popup-rating">
    <div class="popup-content popup-rating">
    <h2>{{ title }}</h2>
      <div class="rating-selector">
        <p-rating
          formControlName="rating"
          [stars]="5"
          [cancel]="false"
        ></p-rating>
      </div>
      <div class="input-box">
        <label for="comment">Comentario</label>
        <textarea id="comment" name="comment" formControlName="review" required></textarea>
      </div>
      <div class="popup-actions">
        <button type="submit" class="btn save-btn">Guardar</button>
      </div>
    </div>
  </div>
</form>
<p-confirmDialog />
<p-toast />
