import {Module} from "../interfaces/module.interface";
import {Page} from "../interfaces/page.interface";

export const findModuleOrPageBySlug =
  (modules: Module[], slug: string): Module | Page | null => {
    for (const module of modules) {
      if (module.slug === slug) {
        return module;
      }
      for (const page of module.pages) {
        if (page.slug === slug) {
          return page;
        }
      }
    }
    return null;
  }
