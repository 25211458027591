<h1 mat-dialog-title>¿Eliminar este recurso?</h1>
<div mat-dialog-content>
  Estás a punto de decir adiós a un recurso.
  <br>
  ¿Estás seguro?
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close (click)="onConfirmDeletion(false)">Cancelar</button>
  <button mat-button mat-dialog-close cdkFocusInitial (click)="onConfirmDeletion(true)">Sí, Eliminar</button>
</div>
