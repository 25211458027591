<div class="card-directivo {{ classSeparator }}" [ngClass]="{'card-active': isSelected, 'card-inactive' :showInactive}" (click)="onClickShowMore()">
  @if (urlImage) {
    <img class="image" [src]="getLinkImage(urlImage)" alt="">
  }
  @if (!urlImage) {
    <div class="avatar">
      <span class="first-letter">{{this.firstLetterName}}</span>
    </div>
  }
  <div class="info">
    <h2 class="name">{{ name }}</h2>
    <p class="job">{{ businessName }}</p>
  </div>
  <!--  <span *ngIf="isActive" [ngClass]="{'badge-active': isActive, 'badge': !isActive}">{{-->
  <!--    isActive ? 'Habilitado' : 'Deshabilitado'-->
<!--    }}</span>-->
<!-- <span *ngIf="employee.company"
[ngClass]="{'badge-active': isSelected, 'badge': !isSelected}">
Inmobiliaria
<br>
  {{employee.company.name}}
</span>

<div *ngIf="employee.company" class="icono-link">
  <div class="circle">
    <mat-icon class="icon">chevron_right</mat-icon>
  </div>
</div> -->
@if (canShowMore) {
  <div class="show_more">
    <a (click)="onClickShowMore()">
      <span class="more">{{actionText}} <mat-icon class="icon">chevron_right</mat-icon></span>
    </a>
  </div>
}
@if (showInactive) {
  <div class="text-inactive">
    <span class="more">INACTIVO</span>
  </div>
}
</div>
