<div class="card" [class.short]="!showThumbnail" (click)="navigateTo(report)">
  <div class="preview" [class.hidden]="!showThumbnail">
    <!-- @if (showThumbnail) {
      <img [src]="getLinkImagePreview()" alt="flyer"
        (click)="preview(report.file)">
    } -->
    @if(isFile == true){
      <img [src]="thumbnail ?? 'https://fakeimg.pl/600x400?text=Archivo&font=bebas'" alt="" />
    }@else if(isNotImg == true){
      <img src="https://fakeimg.pl/600x400?text=Sin%20Archivo&font=bebas" alt="" />

    }@else {
      <img [src]="getLinkImagePreview()" alt="flyer" />
    }
    <button
      mat-mini-fab matTooltip="Opciones"
      aria-Slabel="Opciones"
      [matMenuTriggerFor]="menu"
      (click)="onButtonClick($event)"
    >
      <mat-icon>more_horiz</mat-icon>
    </button>
  </div>
  <div class="body">
    <div class="asei_card_info_meeting_content-info--datetime">
      <div class="asei_card_info_meeting_content-info--date">
        <mat-icon>calendar_month</mat-icon>
        <span>
          {{ getDateDDMMYYYY(report.creationDate) }}
        </span>
      </div>
      <div class="asei_card_info_meeting_content-info--time">
        <mat-icon>access_time</mat-icon>
        <span>
          {{ getHourInFormat12HRS(report.creationTime) }}
        </span>
      </div>
    </div>
    <div class="title">
      <h2>{{report.title}}</h2>
    </div>
    <!-- <div class="description">
      <p>
        {{report.description}}
      </p>
    </div> -->
  </div>
</div>

<mat-menu #menu="matMenu">
  @if (canDownload) {
    <button (click)="onClickDownload(report)" mat-menu-item>Descargar</button>
  }
  @if (canEdit) {
    <button (click)="onClickEdit(report)" mat-menu-item>Editar</button>
  }
  @if (canDelete) {
    <button (click)="openDialogDelete('250ms', '250ms')" mat-menu-item>Eliminar</button>
  }
</mat-menu>

<p-toast></p-toast>
