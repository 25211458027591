import { getDateDDMMYYYY, getLinkImage } from "../helpers/converters.helper";
import { AseiInfoNews, PostType } from "../types/asei-info-news";
import {Post} from "../pages/post/interfaces/post.interface";

export const parseAseiInfoNews = (row: Post): AseiInfoNews => {
  return {
    id: row.id,
    title: row.title,
    date: row.date,
    content: row.content,
    link_image_thumbnail: row.link_image_thumbnail ? getLinkImage(row.link_image_thumbnail) : '',
    links: row.links || [],
    linkImage: row.linkImage ? getLinkImage(row.linkImage): '',
    createdAt: getDateDDMMYYYY(row.createdAt),
    postType: row.postType??null
  }
}

export const parseAseiInfoNewsList = (data: any): AseiInfoNews[] =>{
  return data.map((row:any) => parseAseiInfoNews(row));
}
