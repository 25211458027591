import { AbstractControl } from "@angular/forms";

export const validatorUrl = (control: AbstractControl) => {
  if (control.value && !isUrl(String(control.value))) {
    return {
      urlError: {
        given: control.value,
      },
    };
  }
  return null;
};

export const isUrl = (url: string): boolean => {
  try {
    const nurl = new URL(url);
    return Boolean(nurl && regex.url.test(url));
  } catch (ex) {
    return false;
  }
};

export const validatorRuc = (control: AbstractControl) =>{
  if (control.value && !isRuc(String(control.value))) {
    return {
      urlError: {
        given: control.value,
      },
    };
  }
  return null;
}

export const isRuc = (ruc: string): boolean => {
  try {
    return Boolean(ruc && regex.ruc.test(ruc));
  } catch (ex) {
    return false;
  }
};

export const validatorDni = (control: AbstractControl) =>{
  if (control.value && !isDni(String(control.value))) {
    return {
      urlError: {
        given: control.value,
      },
    };
  }
  return null;
}

export const isDni = (dni: string): boolean => {
  try {
    return Boolean(dni && regex.dni.test(dni));
  } catch (ex) {
    return false;
  }
};

export const validatorPhone = (control: AbstractControl) =>{
  if (control.value && !isPhone(String(control.value))) {
    return {
      urlError: {
        given: control.value,
      },
    };
  }
  return null;
}

export const isPhone = (phone: string): boolean => {
  try {
    return Boolean(phone && regex.phone.test(phone));
  } catch (ex) {
    return false;
  }
};

export const regex = {
  url: /^(http|https):\/\/([\w-]+(\.[\w-]+)+)(\/[\w-./?%&=]*)?$/,
  email: /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/,
  password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
  ruc: /^[2]\d{10}$/,
  ce: /^[a-zA-Z0-9]{8,12}$/,
  dni: /^\d{8}$/,
  phone: /^9\d{8}$/,
  letter: /^[a-zA-Z\s]*$/
}
