<div class="input-group">
  <label class="{{ colorLabelInverse ? 'color_inverse_label': '' }}">{{title}}:</label>
  <div class="input" [ngClass]="{'input-invalid': invalid, 'input-disabled': disabled}">
    <select [(ngModel)]="value" (ngModelChange)="onInputChange($event)" [disabled]="disabled">
      @if (lockSelectedOption && value) {
        <option [value]="value" disabled selected>
          {{ getOptionText(value) }}
        </option>
      } @else {
        <option disabled selected value="">Selecciona una opción</option>
      }

      @for (option of options; track option) {
        <option [value]="option.value">
          {{option.text}}
        </option>
      }
    </select>
  </div>
  @if (invalid) {
    <div class="invalid">
      {{errorMessage}}
    </div>
  }
</div>
