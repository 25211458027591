import {Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {AseiInfoEvent} from 'src/app/types/asei-info';
import { getDatetime, getDatetimeWithoutFormatUTC, getDatetimeUTC } from '../../helpers/converters.helper';
import {EventDetail} from "../../pages/events/interfaces/event-detail.interface";
import createGoogleCalendarEvent from 'src/app/helpers/calendar';

@Component({
  selector: 'event-preview-buttons',
  templateUrl: './event-preview-buttons.component.html',
  styleUrls: ['./event-preview-buttons.component.scss']
})
export class EventPreviewButtonsComponent {
  @Input()
  event!: any;

  onAddCalendar() {
    const dateInit = getDatetimeWithoutFormatUTC(this.event.dateInit, this.event.timeInit);
    const dateEnd = getDatetimeWithoutFormatUTC(
      this.event.dateEnd ? this.event.dateEnd : this.event.dateInit,
      this.event.timeEnd ? this.event.timeEnd : this.event.timeInit
    );
    createGoogleCalendarEvent( this.event.title, '', dateInit, dateEnd )
  }

  onConfirmAssistance() {
    const urlWhatsapp = new URL('http://wa.me');
    urlWhatsapp.pathname = '+51' + this.event.phone;

    urlWhatsapp.searchParams.append("text", `Hola, confirmo mi asistencia para el evento ${this.event.title}.`);

    window.open(urlWhatsapp.href,"_target");
  }

  onRegister() {
    window.open(this.event.registrationLink, '_target')
  }
}
