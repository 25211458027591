import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {Location} from "@angular/common";
import { MessageService } from 'primeng/api';
import { getDateDDMMYYYY, getHourInFormat12HRS, getLinkImage, getThumbnailVideo } from 'src/app/helpers/converters.helper';
import { getImageOThumbnail } from 'src/app/helpers/news.helper';
import { Report } from '../../interfaces/report.interface';
import { FilesService } from 'src/app/services/files.service';
import { firstValueFrom } from 'rxjs';
import { ReportService } from '../../services/report.service';

@Component({
  selector: 'app-report-show',
  templateUrl: './report-show.component.html',
  styleUrl: './report-show.component.scss',
  providers: [MessageService]
})
export class ReportShowPageComponent {

  report!: Report;
  path: string = '';
  isFile: boolean = false;
  isNotImg: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private messageService: MessageService,
    private readonly filesService: FilesService,
    private readonly newService: ReportService,
  ){}

  async ngOnInit() {
    this.path = this.route.snapshot.url[0]?.path ?? '';
    const id: string = this.route.snapshot.paramMap.get('id') ?? '';
    this.report = await this.getReports(id);
    if (!this.report) this.backPage()

    const filename = this.getFileNameFromUrl(getLinkImage(this.report!.file ?? this.report!.fileThumbnail));
    const extension = this.getExtensionFromFilename(filename);
    if(extension == 'pdf'){
      this.isFile = true;
    }
    if(extension == ''){
      this.isNotImg = true;
    }
  }

  ngDoCheck() {
    const content = document.getElementById("content_event");
    if (content) {
      const img = content.querySelector("img");
      img?.setAttribute('style', 'width: 100%');
    }
  }

  async getReports(id: string) {
    try {
      const response: any = await firstValueFrom(this.newService.getOne(id))
      if (response.ok) {
        return response.body.data
      }
    } catch (error) {
      return null;
    }
  }

  getFileNameFromUrl(url: string): string {
    const parts = url.split('/');
    return parts[parts.length - 1];
  }

  getExtensionFromFilename(filename: string): string {
    const parts = filename.split('.');
    return parts.length > 1 ? parts[parts.length - 1] : '';
  }

  onClickDownload(report: Report) {
    const filename = this.getFileNameFromUrl(getLinkImage(report.file));
    const extension = this.getExtensionFromFilename(filename);
    if(extension == 'pdf'){
      this.filesService.downloadFile(filename).subscribe({
        next: (response: Blob) => {
          const url = window.URL.createObjectURL(response);
          const link = document.createElement('a');
          link.href = url;
          link.download = filename;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        },
        error: () => {
          this.showError('Pdf no encontrado.')
        }
      });
    }else{
      this.filesService.downloadImage(filename).subscribe({
        next: (response: Blob) => {
          const url = window.URL.createObjectURL(response);
          const link = document.createElement('a');
          link.href = url;
          link.download = filename;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        },
        error: () => {
          this.showError('Imagen no encontrada.')
        }
      });
    }
  }

  backPage() {
    if (window.history.length > 1) {
      this.location.back();
    } else {
      const path = '/' + this.path; // Ruta dinámica de tu aplicación
      this.router.navigateByUrl(path);
    }
  }

  showSuccess(message: string) {
    this.messageService.add({ severity: 'success', summary: 'Operación exitosa', detail: message, life: 3000 });
  }

  showError(message: string) {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: message, life: 3000 });
  }

  protected readonly getLinkImage = getLinkImage;
  protected readonly getDateDDMMYYYY = getDateDDMMYYYY;
  protected readonly getHourInFormat12HRS = getHourInFormat12HRS;
  protected readonly getThumbnailVideo = getThumbnailVideo;
  protected readonly getImageOThumbnail = getImageOThumbnail;
}
