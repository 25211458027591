<div class="section-image">
  <div class="left">
    <div class="text">

      @if (!notPdf) {
        <p class="no-wrap">**Sólo es posible cargar archivos en PDF o en formato de imagen: JPG o PNG.</p>
      } @else {
        <p class="no-wrap">**Sólo es posible cargar archivos en formato de imagen: JPG o PNG.</p>
      }
      <p>*Para el caso de una imagen se recomienda:</p>
      <ul>
        <li>El tamaño {{imageDimensionText}} o dimensión proporcional</li>
        <li>Formato png o jpg</li>
        <li>Peso máximo: 2MB</li>
      </ul>
      @if (!notPdf) {
        <p>*Para el caso de un pdf se recomienda:</p>
      }
      @if (!notPdf) {
        <ul>
          <li>Peso maximo: 5MB</li>
        </ul>
      }
    </div>
    <div class="image">
      <img [src]="imagePlaceholder" [ngClass]="{'image-invalid': invalid}" (error)="handleImageError($event)">
    </div>
  </div>
  <div class="right">
    <input type="file" #fileInput style="display: none" (change)="cargarImagen($event)">
    <a class="btn secondary margin-right"  (click)="OpenInputFile()">Seleccionar</a>
    <button class="btn secondary" type="button" (click)="clear()">Limpiar</button>
  </div>
</div>
@if (invalid) {
  <div class="invalid">
    @if (messageToNonPdf) {
      Solo se aceptan archivos de tipo Imagen
    } @else {
      {{errorMessage}}
    }
  </div>
}
