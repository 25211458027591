import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'app-image-input',
  templateUrl: './image-input.component.html',
  styleUrls: ['./image-input.component.scss'],
})
export class ImageInputComponent implements OnInit {
  imageDefault = "";
  @Input()
  image = ''
  @ViewChild('fileInput') fileInput?: ElementRef;
  imageFileSelected: any = null
  @Output() imageSelected = new EventEmitter<File>();
  @Input() invalid: boolean = false;
  @Input() disabled: boolean = false;

  constructor() {
  }

  ngOnInit() {
    this.imageDefault = this.image || "https://via.placeholder.com/100x100?text=Photo";
    this.image = this.image || "https://via.placeholder.com/100x100?text=Photo"
  }

  abrirInputFile() {
    if (this.fileInput && !this.disabled) {
      this.fileInput.nativeElement.click();
    }
  }

  cargarImagen(event: any) {
    if (event.target.files && event.target.files[0]) {
      if (!this.isImageValid(event)) {
        alert('Imagen no valida');
        return;
      }
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.image = e.target.result;
      };
      this.imageFileSelected = event.target.files[0];
      reader.readAsDataURL(event.target.files[0]);
    } else {
      this.imageFileSelected = null;
      this.image = this.imageDefault;
    }
    this.imageSelected.emit(this.imageFileSelected);
  }

  isImageValid(event: any) {
    const file = event.target.files[0];
    const maxSize = 2 * 1024 * 1024; // 2MB

    if (file && file.size <= maxSize) return true
    return false;
  }
}
