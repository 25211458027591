<div class="page container_section_large">

  <asei-title-section
    link="/guia-proveedores/nuevo"
    title="Guía de Proveedores"
    description="Gestiona la información de los proveedores de ASEI"
    actionText="Nuevo"
    [hasPermission]="true"
    version="two"
  ></asei-title-section>

  <div class="main">
    <div class="container_filter">
      <div class="filter_button">
        <p>Filtrar por:</p>
        @for (supplierType of supplierTypes | async; track $index) {
          <button class="btn" (click)="getType(supplierType)" >{{ supplierType.name }}</button>
        }
      </div>
      <div class="search">
        <input
          type="text"
          placeholder="Buscar proveedor por nombre"
          [(ngModel)]="searchByFullName"
          (input)="onFilter()" />
          @if (searchByFullName) {
            <span class="clear-btn" (click)="clearSearchInput()">
              <mat-icon>close</mat-icon>
            </span>
          }
      </div>
    </div>
    <div class="container_item">
      <div class="item-list list-custom">
        @for (supplier of suppliers | async; track $index) {
          <div class="custom-item">
            <asei-item-list
              [name]="supplier.name"
              [businessName]="supplier.businessName"
              environment="guia-proveedores"
              [urlImage]="supplier.image"
              version="inverse"
              [isSelected]="true"
              ($showMore)="redirect(supplier.id)"
              [canShowMore]="true"
              [showInactive]="!supplier.isActive"
            ></asei-item-list>
          </div>
        }
      </div>
      @if (paginatorData) {
        <app-paginator [paginatorData]="paginatorData"
          (pageSelected)="onPageSelected($event)"
        ></app-paginator>
      }
    </div>
  </div>
</div>
