export interface SupplierList {
  id: number;
  name: string;
  businessName: string;
  image: string;
  isActive: boolean;
  supplierTypeId: number;
}

export interface Links {
  first: string;
  last: string;
  prev: string | null;
  next: string | null;
}

export interface LinksMeta {
  url: string | null;
  label: string;
  active: boolean;
}

export interface Meta {
  current_page: number;
  from: number;
  last_page: number;
  links: LinksMeta[];
  path: string;
  per_page: number;
  to: number;
  total: number;
}

export interface SupplierListResponse {
  body: {
    data: SupplierList[],
    links: Links;
    meta: Meta;
  }
}

export interface SupplierType {
  id: number;
  name: string;
  isActive: boolean;
}

export interface Pagination {
  current_page: number;
  per_page: number;
  total: number;
  last_page: number;
}

export interface SupplierTypeResponse {
  data: SupplierType[];
  pagination: Pagination;
}

export interface SelectOption {
  value: number;
  label: string;
}

export interface User {
  id: number;
  name: string;
  email: string;
  image?: string;
}

export interface Review {
  id: number;
  description: string;
}

export interface Rating {
  id: number;
  supplierRatingId?: number;
  number: number;
  isEdit: boolean;
  review: Review | null;
  user: User;
}

export interface Supplier {
  id: number;
  name: string;
  businessName: string;
  documentNumber: string;
  entryDate: string;
  image?: string;
  address: string;
  isActive: boolean;
  web: string;
  supplierTypeId: number;
  average: string;
  ratings: Rating[];
}

export interface ISupplierSave {
  id?: number;
  name: string;
  businessName: string;
  documentNumber: string;
  entryDate: string;
  image?: string;
  address: string;
  web: string;
  supplierTypeId: number;
  isActive?: boolean;
}


export class SupplierSave {
  id?: number;
  name: string;
  businessName: string;
  documentNumber: string;
  entryDate: string;
  image?: string;
  address: string;
  web: string;
  supplierTypeId: number;
  isActive?: boolean;
  constructor(supplier: ISupplierSave) {
    this.id = supplier.id;
    this.name = supplier.name;
    this.businessName = supplier.businessName;
    this.documentNumber = supplier.documentNumber;
    this.entryDate = supplier.entryDate;
    this.image = supplier.image;
    this.address = supplier.address;
    this.web = supplier.web;
    this.supplierTypeId = supplier.supplierTypeId;
    this.isActive = supplier.isActive;
  }
}

export interface IRatingCreated {
  ratingId: number;
  supplierRatingId?: number;
  reviewId?: number;
}

export class RatingCreated {
  ratingId: number;
  supplierRatingId?: number;
  reviewId?: number;
  constructor(rating: IRatingCreated) {
    this.ratingId = rating.ratingId;
    this.supplierRatingId = rating.supplierRatingId;
    this.reviewId = rating.reviewId;
  }
}
