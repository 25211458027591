import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {map} from 'rxjs/operators';
import { CompanyEmployee } from '../../../interfaces/company-employee.interface';

const URL = environment.url + '/api/v1';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {


  private httpOptions = {};

  constructor(private http: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({
        "Accept": "application/json",
        "Content-Type": "application/json"
      }),
      observe: 'response' as const // Asegura que el tipo de 'observe' sea correcto
    }
  }

  getAll(
    perPage = 10,
    type = '',
    category = '',
    sort='asc',
    search ='',
    page = '',
  ) {
    let url = `${URL}/companies?per_page=${perPage}&type=${type}&sort=${sort}&search=${search}&orderBy=name`;
    if (category) {
      url += `&company_category=${category}`;
    }
    if (page) {
      url += `&page=${page}`
    }
    return this.http.get<any>(url, this.httpOptions).pipe(
      map(response => response)
    );
  }

  getById(id: number | string | null | undefined) {
    const url = `${URL}/companies/${id}`;
    return this.http.get<any>(url, this.httpOptions).pipe(
      map(response => response)
    );
  }

  create(company: CompanyEmployee){
    const url = `${URL}/companies`;
    return this.http.post<any>(url, JSON.stringify(company), this.httpOptions).pipe(
      map(response => response)
    );
  }

  update(company: CompanyEmployee){
    const url = `${URL}/companies/${company.id}`;
    return this.http.put<any>(url, JSON.stringify(company), this.httpOptions).pipe(
      map(response => response)
    );
  }

  sendNotification(employees: any) {
    const url = `${URL}/companies/send-notification`;
    return this.http.post<any>(url, JSON.stringify(employees), this.httpOptions).pipe(
      map(response => response)
    );
  }
}
