import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from "../services/auth.service";
import { firstValueFrom } from "rxjs";

@Component({
  selector: 'app-olvido',
  templateUrl: './request-reset-password.component.html',
  styleUrls: ['./request-reset-password.component.scss']
})
export class RequestResetPasswordComponent {
  email = '';
  showNotification = false;
  notifications: Map<string, string> = new Map<string, string>();
  isShowLoaderButton = false;

  constructor(private router: Router, private authService: AuthService) {
  }

  async send() {
    try {
      this.isShowLoaderButton = true;
      await firstValueFrom(this.authService.requestResetPassword({email: this.email}))

      setTimeout(() => {
        this.notifications.clear();
        this.showNotification = true;
        this.isShowLoaderButton = false;
      }, 5000);


    } catch (error: any) {
      if (error.error.message.email) {
        this.notifications.set('pre-reset', 'El campo debe ser un email válido');
        this.isShowLoaderButton = false;
        this.showNotification = false;
      }
    }
  }
}
