import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MatListModule} from "@angular/material/list";
import {MatIconModule} from "@angular/material/icon";
import {RouterLink, RouterLinkActive} from "@angular/router";
import {MenuItemLinkComponent} from "../menu-item-link/menu-item-link.component";
import {TYPE_PERMISSIONS} from "../../types/constants/permission.enum";
import {can} from "../../helpers/permissions.helper";
import {MatLineModule} from "@angular/material/core";
import {Page} from "../../interfaces/page.interface";
@Component({
  selector: 'menu-item-dropdown',
  standalone: true,
  imports: [
    MatListModule,
    MatIconModule,
    RouterLink,
    RouterLinkActive,
    MenuItemLinkComponent,
    MatLineModule
  ],
  templateUrl: './menu-item-dropdown.component.html',
  styleUrls: ['./menu-item-dropdown.component.scss']
})
export class MenuItemDropdownComponent {
  @Input() title!: string;
  @Input() path!: string | null;
  @Input() icon!: string;
  @Input() hasPermissionView: boolean = true;
  @Input()
  items: Page[] = []
  @Input()
  dropdown: boolean = false
  @Output() clicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  toggleDropdown() {
    this.dropdown = !this.dropdown
  }
  onClick(){
    this.clicked.emit(true);
  }

  protected readonly typePermission = TYPE_PERMISSIONS;
  protected readonly can = can;
}
