import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {PopupService} from "../popups/services/popup.service";
import {firstValueFrom} from "rxjs";
import {getLinkImage} from "../../helpers/converters.helper";
import {TYPE_PERMISSIONS} from "../../types/constants/permission.enum";
import {PermissionsService} from "../../services/permissions.service";
import DataCardHome from "../../interfaces/data-card-home";
import {EVENT_TYPE_ROUTES} from "../../types/constants/event-type.enum";
import Popup from "../popups/interfaces/popup.interface";
import {Page} from "../../interfaces/page.interface";
import {ModuleService} from "../modules/services/module.service";
import {OptionPage} from "../modules/interfaces/option-page.interface";
import { NgForm } from '@angular/forms';
import { DialogService } from 'primeng/dynamicdialog';
import { ChangePasswordComponent } from '../change-password/pages/change-password.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [DialogService],
})
export class HomeComponent implements OnInit {
  notifications: Map<string, string> = new Map<string, string>();
  showNotifications: boolean = false;
  typePermission: typeof TYPE_PERMISSIONS = TYPE_PERMISSIONS;
  isShowBanner: boolean = false;
  currentPopups: Popup[] | null = null
  dataCards: DataCardHome[] = []
  page: string = ''
  currentUser: any;
  images: any[] | undefined;
  responsiveOptions: any[] | undefined;
  displayBasic: boolean = true;

  constructor(
    private router: Router,
    private permissionsService: PermissionsService,
    private popupService: PopupService,
    private moduleService: ModuleService,
    private route: ActivatedRoute,
    private readonly dialogService: DialogService,
  ) {
  }

  async ngOnInit() {
    this.route.url.subscribe(segments => {
      // segments es una matriz que contiene los segmentos del URL
      const path = segments.join('/');
      this.page = path
      this.moduleService.getOne(path).subscribe({
        next: response => {
          const jsonShortcut = response.body.data.jsonShortcut
          if (jsonShortcut) {
            const optionPages: OptionPage[] = JSON.parse(jsonShortcut)
            this.dataCards = this.convertToDataCardHome(optionPages)
          }
        },
        error: () => {
          this.notifications.set('not-save', 'Se han actualizado los permisos, refresca la página con F5!!');
          this.showNotifications = true;
        },
      })
    });

    this.currentPopups = await this.getCurrentPopUp();

    setTimeout(() => this.showPopupIfApplicable(), 1000);
    const userString = localStorage.getItem('UserData');
    this.currentUser = JSON.parse(userString!);
    if (this.currentUser.data.reset_password) {
      this.showPasswordChangeModal();
    }
    this.responsiveOptions = [
      {
          breakpoint: '1024px',
          numVisible: 5
      },
      {
          breakpoint: '768px',
          numVisible: 3
      },
      {
          breakpoint: '560px',
          numVisible: 1
      }
  ];
  }

  showPopupIfApplicable() {
    if (this.currentPopups && this.currentPopups.length > 0) {
      const lastShowPopupDateStr = localStorage.getItem('lastShowPopupDate');
      const lastShowPopupDate = lastShowPopupDateStr ? new Date(lastShowPopupDateStr) : null;

      for (const popup of this.currentPopups) {
        const currentPopupUpdatedAt = new Date(popup.updatedAt);

        if (!lastShowPopupDate || currentPopupUpdatedAt > lastShowPopupDate) {
          this.isShowBanner = true;
          localStorage.setItem('lastShowPopupDate', currentPopupUpdatedAt.toISOString());
          break;
        }
      }
    }
  }

  async getCurrentPopUp(): Promise<Promise<Popup[]> | null> {
    try {
      const response = await firstValueFrom(this.popupService.getCurrent())
      return response.body.data
    } catch (error) {
      return null;
    }
  }

  convertToDataCardHome(options: OptionPage[]): DataCardHome[] {
    const data: DataCardHome[] = [];
    options.forEach((option) => {
      data.push({
        text: option.includeTextInBigCard ? option.name : null,
        imageBackgroundCard: option.imageBackgroundCard ? getLinkImage(option.imageBackgroundCard) : '',
        logoPrimaryCard: option.logoPrimaryCard ? getLinkImage(option.logoPrimaryCard) : null,
        logoSecondaryCard: option.logoSecondaryCard ? getLinkImage(option.logoSecondaryCard) : null,
        link: option.slug,
        includeTextInBigCard: option.includeTextInBigCard
      })
    })
    return data;
  }

  onCloseModal() {
    this.isShowBanner = false;
  }

  redirect(path: string) {
    this.router.navigate([path]);
  }

  redirectToURL(url: string | null) {
    if (url) {
      window.open(url, '_blank');
    }
  }

  can(action: TYPE_PERMISSIONS) {
    return this.permissionsService.can(action);
  };

  protected readonly getLinkImage = getLinkImage;
  protected readonly TYPE_PERMISSIONS = TYPE_PERMISSIONS;

  showPasswordChangeModal() {
    this.dialogService.open(ChangePasswordComponent, {
      data: null,
      header: 'Cambio de contraseña',
      style: {
        width: '40%',
        height: '53%',
      },
    });
  }
}
