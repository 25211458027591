import { Component } from '@angular/core';
import { NavigationEnd, Router } from "@angular/router";
import { environment } from "../environments/environment";
import UserDataLogin from "./interfaces/user-data-login.interface";

declare let gtag: (property: string, value: any, configs: any) => {};

interface optionGA4 {
  page_path: string,
  user_id?: string
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'asei-app';
  constructor(public router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const path = event.urlAfterRedirects;
        const userId = this.getUserData()?.customId
        const options = this.getOptionsGA4(path, userId);
        gtag('config', environment.gaMeasurementId, options);
      }
    });
  }

  getOptionsGA4(page: string, userId: string | undefined): optionGA4 {
    let options: optionGA4 = {
      page_path: page
    }
    if (userId !== undefined) {
      options.user_id = userId;
    }
    return options
  }

  getUserData(): UserDataLogin | undefined {
    const jsonData = localStorage.getItem('UserData');

    if (jsonData) {

      const userData = jsonData ? JSON.parse(jsonData) : undefined;

      return userData.data;

    }
    return undefined
  }
}
