import { Component, DoCheck, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { Editor, toDoc } from "ngx-editor";
import { PrimeNGConfig } from 'primeng/api';
import { Subscription } from 'rxjs';

const VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TextEditorComponent),
  multi: true,
};

@Component({
  selector: 'app-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss'],
  providers: [VALUE_ACCESSOR]
})
export class TextEditorComponent implements OnInit, DoCheck, ControlValueAccessor {
  editor!: Editor;
  html!: Record<string, any>;
  @ViewChild('editorContainer') editorContainer!: ElementRef;
  @Input() value: string = '';
  @Input() title = 'Programa';
  @Input() showTitle = true;
  @Input() name = '';
  @Input() errorMessage: string = 'Este campo es obligatorio.*';
  @Input() required: boolean = false;  // Validar si es requerido
  @Input() invalid: boolean = false;
  @Input() maxlength = 1500;
  @Output() textChange = new EventEmitter<any>();
  // @Output() $error = new EventEmitter<boolean>();

  formControl!: NgControl;
  private onChange: any = () => {};
  private onTouch: any = () => {};
  @ViewChild('quillEditor') quillEditor!: any;
  editorTextChange$$!: Subscription;
  currentLength: number = 0;
  content: any;

  constructor(private primengConfig: PrimeNGConfig) {}

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  ngOnInit(): void {
    this.primengConfig.ripple = true;
    this.html = toDoc(this.value);
  }

  ngAfterViewInit() {
    this.editorTextChange$$ = this.quillEditor.getQuill().on('text-change', () => {
      const limit = this.maxlength;
      const textValue = this.quillEditor.getQuill().getText();

      if (textValue.length > limit) {
        this.quillEditor.getQuill().deleteText(limit, textValue.length);
      }

      this.currentLength = this.quillEditor.getQuill().getLength() - 1;

      // Remover el borde rojo solo si el campo es requerido y el usuario escribe algo
      if (this.currentLength > 0 && this.required) {
        this.invalid = false;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Solo validar si el campo es requerido
    if (this.required) {
      this.invalid = this.validateInput(this.value);
    }
  }

  onEditorContentChange(content: any): void {
    const html = content;
    this.onChange(html);
    this.onTouch();

    // Solo marcar como inválido si el campo es requerido
    if (this.required) {
      this.invalid = this.currentLength === 0;
    }

    // Emitir el texto actualizado
    this.textChange.emit(html);

    // Emitir error si es inválido
    //this.$error.emit(this.invalid);
  }

  ngDoCheck() {
    // Solo validar si el campo es requerido
    if (this.required) {
      this.invalid = this.validateInput(this.value);
    }
  }

  validateInput(valueData: any) {
    // Solo validar si es requerido
    if (this.required && (valueData == null || valueData.trim() === '')) {
      //this.$error.emit(true);
      return true;
    }
    //this.$error.emit(false);
    return false;
  }
}
