import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { convertDatetime, } from "../../../../../helpers/converters.helper";
import { PopupService } from "../../../services/popup.service";
import { FilesService } from "../../../../../services/files.service";
import { MessageService } from "primeng/api";
import Popup from "../../../interfaces/popup.interface";
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-pop-up-edit',
  templateUrl: './popup-edit.component.html',
  styleUrls: ['./popup-edit.component.scss'],
  providers: [MessageService]
})

export class PopupEditComponent {
  @ViewChild('top') topElement!: ElementRef;
  form!: FormGroup;
  URL_PATTERN = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
  notifications: Map<string, string> = new Map<string, string>();

  constructor(
    private fb: FormBuilder,
    private popupService: PopupService,
    private filesService: FilesService,
    private messageService: MessageService,
    private dynamicDialogConfig: DynamicDialogConfig,
    private readonly dialogRef: DynamicDialogRef,
  ) {
  }

  ngOnInit() {
    if (this.dynamicDialogConfig.data != null) {
      const id = this.dynamicDialogConfig.data.id;
      this.popupService.getOne(id).subscribe({
        next: response => {
          const popup: Popup = response.body.data
          const [dateI, timeI] = convertDatetime(popup.datetimeInit);
          const [dateE, timeE] = convertDatetime(popup.datetimeEnd);
          this.form = this.fb.group({
            'dateInit': [dateI, Validators.required],
            'timeInit': [timeI, Validators.required],
            'dateEnd': [dateE, Validators.required],
            'timeEnd': [timeE, Validators.required],
            'url': [popup.url, Validators.pattern(this.URL_PATTERN)],
            'image': [popup.image, Validators.required]
          })
        }
      })
    } else {
      this.form = this.fb.group({
        'dateInit': ['', Validators.required],
        'timeInit': ['', Validators.required],
        'dateEnd': ['', Validators.required],
        'timeEnd': ['', Validators.required],
        'url': ['', Validators.pattern(this.URL_PATTERN)],
        'image': ['', Validators.required]
      })
    }
  }

  scrollToTop() {
    this.topElement.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  onSubmit() {
    if (this.form.valid) {
      let payload: any = {
        ...this.form.value,
        datetimeInit: this.form.value.dateInit + ' ' + this.form.value.timeInit,
        datetimeEnd: this.form.value.dateEnd + ' ' + this.form.value.timeEnd,
      };
      const hasFileSelected = typeof this.form.value['image'] !== 'string';
      if (hasFileSelected) {
        this.uploadAndUpdatePopup(payload);
      } else {
        this.updatePopup(payload);
      }
    }else{
      this.scrollToTop();
      this.notifications.set('not-save', 'Hay campos requeridos por llenar verificar!!');
    }
  }

  private uploadAndUpdatePopup(payload: any): void {
    const file = payload.image
    this.filesService.uploadImage(file).subscribe({
      next: response => {
        this.notifications.clear();
        payload.image = response.body.path;
        this.updatePopup(payload);
      },
      error: error => {
        this.showError('No se pudo cargar imagen de el popup.');
      }
    });
  }

  private updatePopup(payload: any): void {
    if (this.dynamicDialogConfig.data != null) {
      const id = this.dynamicDialogConfig.data.id;
      this.popupService.update(id, payload).subscribe({
        next: response => {
          // this.showSuccess('Popup actualizado.')
          this.notifications.clear();
          this.dialogRef.close({ success: true, error: null });
        },
        error: error => {
          // this.showError('No se pudo actualizar el popup.');
          this.dialogRef.close({ success: false, error: 'No se pudo actualizar el popup.' });
        }
      });
    } else {
      this.popupService.create(payload).subscribe({
        next: response => {
          // this.showSuccess('Popup creado.');
          this.notifications.clear();
          this.dialogRef.close({ success: true, error: null });
        },
        error: error => {
          // this.showError('No se pudo crear el popup.')
          this.dialogRef.close({ success: false, error: 'No se pudo crear el popup.' });
        }
      });
    }
  }

  showSuccess(message: string) {
    this.messageService.add({ severity: 'success', summary: 'Operación exitosa', detail: message, life: 3000 });
  }

  showError(message: string) {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: message, life: 3000 });
  }
}
