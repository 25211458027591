import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-asei-directory-form-create',
  templateUrl: './asei-directory-form-create.component.html',
  styleUrls: ['./asei-directory-form-create.component.scss'],
  providers: [MessageService]
})
export class AseiDirectoryFormCreateComponent {
  form!: FormGroup;
  notifications: Map<string, string> = new Map<string, string>();

  constructor(
    private configurationService: ConfigurationService,
    private router: Router,
    private formBuilder: FormBuilder,
    private messageService: MessageService
  ) {
    this.createForm();
  }

  createForm(): void {
    this.form = this.formBuilder.group({
      categoryName: ['', Validators.required],
      text: ['', Validators.required]
    });
  }

  onTextEditorChange(text: string): void {
    this.form.get('text')?.setValue(text.trim());
    this.form.get('text')?.markAsTouched();
    this.form.get('text')?.updateValueAndValidity();
  }

  async onSubmit(): Promise<void> {
    this.notifications.clear();
    if (this.form.invalid) {
      if (this.form.get('categoryName')?.invalid) {
        this.notifications.set('errorCategory', 'Debe ingresar un nombre de categoría.');
        this.messageService.add({ severity: 'warn', summary: 'Validación', detail: 'Nombre de la categoría requerido', life: 3000 });
      }
      if (!this.form.get('text')?.value.trim()) {
        this.notifications.set('errorText', 'Debe ingresar un texto válido.');
        this.messageService.add({ severity: 'warn', summary: 'Validación', detail: 'Debe ingresar texto válido', life: 3000 });
      }
      return;
    }
    try {
      const payload = {
        categoryName: this.form.value.categoryName.trim(),
        text: this.form.value.text.trim()
      };
      await firstValueFrom(this.configurationService.createConfiguration(payload));
      this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Configuración creada correctamente', life: 3000 });
      this.router.navigateByUrl('/directorio-asei');
    } catch (error) {
      this.notifications.set('serverError', 'Ocurrió un error en el servidor.');
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al crear configuración', life: 3000 });
    }
  }

  backPage(): void {
    this.router.navigateByUrl('/directorio-asei');
  }
}
