<div class="container_section_large" #top>
  <asei-title-section
    link="/guia-proveedores/nuevo"
    [title]="isEditMode ? 'Editar un Proveedor' : 'Crear un Proveedor'"
    version="two"
  ></asei-title-section>
  <p-toast></p-toast>
  <asei-notifications [notifications]="notifications"></asei-notifications>
  @if (supplier) {
  <div class="supplier-information">
      <div class="image">
        <ASEI-upload-avatar
          [name]="supplier.name"
          [avatarUrl]="supplier.image ?? ''"
          ($change)="updateImage($event)"
          ($error)="errorLoadImage($event)"
          [disabled]="false"
          [size] = "sizeAvatar"
        ></ASEI-upload-avatar>
      </div>

      <form  #form="ngForm" class="information">
        <!-- Nombre Comercial y Razón Social -->
        <div class="flex">
          <div class="input-box">
            <app-input-group
              name="supplier.name"
              [(ngModel)]="supplier.name"
              type="text"
              title="Nombre Comercial"
              [required]="true"
              [disabled]="false"
            ></app-input-group>
          </div>
          <div class="input-box">
            <app-input-group
              name="supplier.businessName"
              [(ngModel)]="supplier.businessName"
              type="text"
              title="Razón Social"
              [required]="true"
              [disabled]="false"
            ></app-input-group>
          </div>
        </div>

        <!-- Fecha de Ingreso y Número de Documento -->
        <div class="flex">
          <div class="input-box">
            <app-input-group
              name="supplier.documentNumber"
              [(ngModel)]="supplier.documentNumber"
              type="ruc"
              title="RUC"
              [required]="true"
              [disabled]="false"
            ></app-input-group>
          </div>
          <div class="input-box">
            <app-input-group
              name="supplier.entryDate"
              [(ngModel)]="supplier.entryDate"
              type="date"
              title="Fecha de Ingreso"
              [required]="true"
              [disabled]="false"
            ></app-input-group>
          </div>
        </div>

        <!-- Pagina Web y Dirección -->
        <div class="flex">
          <div class="input-box">
            <app-input-group
              name="supplier.web"
              [(ngModel)]="supplier.web"
              type="url"
              title="Web"
              [disabled]="false"
            ></app-input-group>
          </div>
          <div class="input-box">
            <app-input-group
              name="supplier.address"
              [(ngModel)]="supplier.address"
              type="text"
              title="Dirección"
              [disabled]="false"
            ></app-input-group>
          </div>
        </div>

        <div class="input-box">
          <div class="radio-button_container">
            <div class="label-button">
              <p class="labelType">Tipo de Proveedor:</p>
              <button
                pButton
                pRipple
                type="button"
                class="p-button-rounded ml-1"
                size="small"
                icon="pi pi-plus"
                pTooltip="Nuevo"
                tooltipPosition="bottom"
                (click)="newSupplierType()"></button>
            </div>
            <ASEI-radio-button
              [options]="selectOptionsType"
              [selectedValue]="supplier.supplierTypeId"
              ($change)="onSelectedValueChange($event)"
            ></ASEI-radio-button>
          </div>
        </div>

      <!-- Campo isActive -->
      <div *ngIf="isEditMode" class="container-slider">
        <p class="labelType">Estado:</p>
        <ASEI-input-slider
          [isActive]="!!supplier.isActive"
          ($change)="onChangeSliderState($event)"
          [disabled]="false"
        ></ASEI-input-slider>
      </div>

        <!-- Botones de acción -->
        <div class="actions">
          <button class="btn" (click)="toBack()">
            Regresar
            <mat-icon>arrow_back</mat-icon>
          </button>
          <button class="btn" (click)="save(supplier)">
            Guardar
            <mat-icon>save</mat-icon>
          </button>
        </div>
      </form>

      <div class="container-calificaciones" *ngIf="isEditMode">
        <div class="calificaciones-header">
          <p class="labelType">Calificaciones:</p>
          <button class="btn" (click)="createRating(supplier.id)">Nuevo</button>
        </div>
        <div class="calificaciones-lista">
          <div class="calificacion-item" *ngFor="let rating of supplier.ratings">
            <div class="avatar">
              @if (rating.user.image) {
                {{ rating.user.image }}
              } @else {
                <img src="../../../assets/img/icons/profile_default.jpg" alt="avatar"/>
              }
            </div>
            <div class="calificacion-detalles">
              <p class="usuario">{{ rating.user.name }}</p>
              <p class="comentario">{{ rating.review?.description }}</p>
            </div>
            <div class="calificacion-estrellas">
              <p-rating
                [readonly]="true"
                [stars]="5"
                [cancel]="false"
                [ngModel]="rating.number"
              ></p-rating>
            @if (rating.isEdit) {
              <button class="btn-editar" (click)="updateRating(supplier.id, rating)">
                <mat-icon>edit</mat-icon>
              </button>
            }
            </div>
          </div>
        </div>
      </div>
    </div>
  }
</div>
