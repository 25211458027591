import {Component, EventEmitter, Output, OnInit} from '@angular/core';
import { getLinkImage } from 'src/app/helpers/converters.helper';
import UserDataLogin from 'src/app/interfaces/user-data-login.interface';

@Component({
  selector: 'asei-sidebar-mobile',
  templateUrl: './sidebar-mobile.component.html',
  styleUrls: ['./sidebar-mobile.component.scss']
})
export class ASEISidebarMobileComponent implements OnInit {

  @Output() isShow: EventEmitter<boolean> = new EventEmitter();
  firstLetterName: string = ''
  user: UserDataLogin | undefined;
  avatarUrl: string = '';

  // testChange = false;

  ngOnInit() {
    const data = this.getUserData();
    if (data) {
      const name = data.name
      this.avatarUrl = getLinkImage(data?.image);
      this.firstLetterName = name.charAt(0).toUpperCase();
    }
  }

  getUserData(): UserDataLogin | undefined {
    const jsonData = localStorage.getItem('UserData');
    const userData = jsonData ? JSON.parse(jsonData) : undefined;
    return userData.data;
  }

  changeShowHeaderMobile() {
    // this.testChange = !this.testChange;
    this.isShow.emit(true)
  }
}
