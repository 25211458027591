<mat-nav-list>
  <div class="container_menu_asei">
    <div class="titulo">
      <div class="avatar">
        <img *ngIf="avatarUrl" class="image" [src]="avatarUrl" alt="avatar">
        <img *ngIf="!avatarUrl" src="../../../assets/img/icons/profile_default.jpg" alt="avatar"/>
      </div>
      <span class="last-name">Hola {{ this.fullName }} 👋</span>
    </div>
    <div class="menu-items">
      @for (module of modules; track module.id) {
        @if (module.pages.length > 0) {
          <menu-item-dropdown
            [title]="module.name"
            [items]="module.pages"
            [icon]="module.icon"
            [path]="module.slug"
            [dropdown]="module.dropdown || false"
            (clicked)="emitLinkClicked()"
            [hasPermissionView]="module.hasPermission.view"
          />
        } @else {
          <menu-item-link
            [title]="module.name"
            [hasExternalLink]="!!module.externalLink"
            [icon]="module.icon"
            [path]="module.externalLink || module.slug"
            (clicked)="emitLinkClicked()"
            [hasPermissionView]="module.hasPermission.view"
          />
        }
      }
    </div>
  </div>
  <div class="container_menu_sesion">
    <menu-item-link
      title="Directorio ASEI"
      icon="pi pi-building"
      [hasExternalLink]="false"
      path="directorio-asei"
      (clicked)="emitLinkClicked()"
    />
    <menu-item-link
      title="Mi Perfil"
      icon="pi pi-user"
      path="mi-perfil"
      [hasExternalLink]="false"
      (clicked)="emitLinkClicked()"
    />
    <menu-item-link
      title="Cerrar sesión"
      icon="pi pi-sign-out"
      [hasExternalLink]="false"
      (clicked)="logout()"
    />
    <div class="logo">
      <img src="../../../assets/img/logos/logo_oficial.svg" alt="">
    </div>
  </div>
</mat-nav-list>
