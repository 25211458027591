import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {map, switchMap} from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { getDateYYYYMMDD, getLinkImage } from 'src/app/helpers/converters.helper';

const URL = environment.url + '/api/v1';

@Injectable({
  providedIn: 'root'
})
export class PopupService {

  private httpOptions = {};
  popups: any[] = [];
  popups$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(this.popups);

  constructor(private http: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({
        "Accept": "application/json",
        "Content-Type": "application/json"
      }),
      observe: 'response' as const // Asegura que el tipo de 'observe' sea correcto
    }
  }

  callGetList(
    dateStart = '',
    dateEnd = '',
  ) {
    const url = `${URL}/pop-ups?start_date=${getDateYYYYMMDD(dateStart)}&end_date=${getDateYYYYMMDD(dateEnd)}`;
    return this.http.get<any>(url, this.httpOptions).pipe(
      map((response: any) => this.updatePopup(response.body.data))
    );
  }

  getList() {
    return this.popups$.asObservable();
  }

  // getAll() {
  //   const url = `${URL}/pop-ups`;
  //   return this.http.get<any>(url, this.httpOptions).pipe(
  //     map(response => response)
  //   );
  // }
  getCurrent() {
    const url = `${URL}/pop-ups/current`;
    return this.http.get<any>(url, this.httpOptions).pipe(
      map(response => response)
    );
  }
  getOne(id: number) {
    const url = `${URL}/pop-ups/${id}`;
    return this.http.get<any>(url, this.httpOptions).pipe(
      map(response => response)
    );
  }
  create(body: any): Observable<any> {
    const url = `${URL}/pop-ups`;
    return this.http.post<any>(url, JSON.stringify(body), this.httpOptions).pipe(
      switchMap(() => this.callGetList())
    );
  }

  update(id: number, data: any) {
    const url = `${URL}/pop-ups/${id}`;
    return this.http.put<any>(url, JSON.stringify(data), this.httpOptions).pipe(
      switchMap(() => this.callGetList())
    );
  }

  delete(id: number){
    const url = `${URL}/pop-ups/${id}`;
    return this.http.delete<any>(url, this.httpOptions).pipe(
      switchMap(() => this.callGetList())
    );
  }

  getByUrlPagination(url: string) {
    return this.http
      .get<any>(url, this.httpOptions)
      .pipe(map((response) => response));
  }

  // update(id: number, data: any) {
  //   const url = `${URL}/pop-ups/${id}`;
  //   return this.http.put<any>(url, JSON.stringify(data), this.httpOptions).pipe(
  //     map(response => response)
  //   );
  // }

  private updatePopup(value: any) {
    value.image = getLinkImage(value.image)
    this.popups = value;
    this.popups$.next(this.popups);
}

}
