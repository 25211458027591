import { Component, OnInit } from '@angular/core';
import { SuppliersService } from '../../../services/suppliers.service';
import { SupplierList } from '../../../interfaces/supplier.interface';
import { Observable } from 'rxjs';
import { PaginatorData } from 'src/app/interfaces/paginator-data.interface';
import { SupplierTypesService } from '../../../services/supplier-types.service';
import { SupplierType } from '../../../interfaces/supplier-type.interface';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-supplier-list',
  templateUrl: './supplier.component.html',
  styleUrl: './supplier.component.scss'
})
export class SupplierListPageComponent implements OnInit {
  slug!: string
  paginatorData !: PaginatorData;
  perPage = 10;
  type = '';
  page: string = '';
  searchByFullName: string = '';
  constructor(
    private readonly suppliersService: SuppliersService,
    private readonly supplierTypesService: SupplierTypesService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.route.url.subscribe(segments => {
      this.slug = segments.join('/');
    });
    this.getSuppliers(this.perPage, '', 'asc', '', '1');
    this.getSupplierTypes();
    this.paginator.subscribe((response: any) => {
      this.paginatorData = response
    })
  }

  async getSuppliers(
    perPage = this.perPage,
    type = this.type,
    sort = 'asc',
    search = '',
    page: string = this.page,
  ): Promise<void> {
    this.suppliersService.callGetList(perPage, type, sort, search, page).subscribe();
  }

  async getSupplierTypes(): Promise<void> {
    this.supplierTypesService.callGetList().subscribe();
  }

  get suppliers(): Observable<SupplierList[]> {
    return this.suppliersService.getList();
  }

  get supplierTypes(): Observable<SupplierType[]> {
    return this.supplierTypesService.getList();
  }

  get paginator(): Observable<PaginatorData> {
    return this.suppliersService.getPaginator();
  }

  getType(supplierType: any) {
    this.type = supplierType.id;
    this.getSuppliers(this.perPage, this.type, 'asc', '', '1');
  }

  async onPageSelected(url: string) {
    const urlParams = new URLSearchParams(url);
    const pageParam = urlParams.get('page');
    const page = pageParam !== null ? +pageParam : 1;
    this.getSuppliers(this.perPage, this.type, 'asc', this.searchByFullName, page.toString());
  }
  async clearSearchInput() {
    this.searchByFullName = '';
    this.onFilter();
  }

  onFilter() {
    this.getSuppliers(this.perPage, this.type, 'asc', this.searchByFullName, this.page);
  }

  redirect(id: number) {
    this.router.navigate([`${this.slug}/${id}/editar`]);
  }
}
