import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import LinkEvento from "../../interfaces/link-evento.interface";

@Component({
  selector: 'app-presentacion-modal',
  templateUrl: './presentacion-modal.component.html',
  styleUrls: ['./presentacion-modal.component.scss']
})
export class PresentacionModalComponent {
  // constructor(
  //   public dialogRef: MatDialogRef<VideoModalComponent>,
  //   @Inject(MAT_DIALOG_DATA) public videoSrcdoc: string
  // ) { }
  presentaciones: LinkEvento[];

  constructor(
    public dialogRef: MatDialogRef<PresentacionModalComponent>,
    @Inject(MAT_DIALOG_DATA
    ) public data: {
      presentaciones: LinkEvento[]
    }) {
    this.presentaciones = data.presentaciones; // Obtener la variable de video desde los datos del diálogo
  }

  closeModal() {
    this.dialogRef.close();
  }
}
