import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {map, switchMap, tap} from 'rxjs/operators';
import {CompanyEmployee} from '../../../interfaces/company-employee.interface';
import {Observable, Subject} from "rxjs";
import {Module} from "../../../interfaces/module.interface";
import {UpdateModuleDTO} from "../interfaces/update-module.interface";

const URL = environment.url + '/api/v1';

@Injectable({
  providedIn: 'root'
})
export class ModuleService {

  private modulesSubject = new Subject<Module[]>();
  private httpOptions = {};

  constructor(private http: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({
        "Accept": "application/json",
        "Content-Type": "application/json"
      }),
      observe: 'response' as const // Asegura que el tipo de 'observe' sea correcto
    }
  }

  getAll() {
    const url = `${URL}/modules`;
    return this.http.get<any>(url, this.httpOptions).pipe(
      map((response) => response)
    );
  }
  getOne(id: string) {
    const url = `${URL}/modules/${id}`;
    return this.http.get<any>(url, this.httpOptions).pipe(
      map(response => response)
    );
  }
  create(body: any): Observable<any> {
    const url = `${URL}/modules`;
    return this.http.post<any>(url, JSON.stringify(body), this.httpOptions).pipe(
      map(response => response)
    );
  }
  update(id: number, data: UpdateModuleDTO) {
    const url = `${URL}/modules/${id}`;
    return this.http.put<any>(url, JSON.stringify(data), this.httpOptions).pipe(
      map(response => response)
    );
  }

  reorder(data: any) {
    const url = `${URL}/modules/re-order`;
    return this.http.put<any>(url, JSON.stringify(data), this.httpOptions).pipe(
      map(response => response)
    );
  }

  delete(id: number) {
    const url = `${URL}/modules/${id}`;
    return this.http.delete<any>(url, this.httpOptions).pipe(
      map(response => response)
    );
  }

  getModulesSubject(): Subject<Module[]> {
    return this.modulesSubject;
  }

  setModuleInLocalStorage(modules: Module[]) {
    this.modulesSubject.next(modules);
  }
}
