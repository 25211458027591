import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import {environment} from 'src/environments/environment';
const URL = environment.url + '/api/v1';

@Injectable({
  providedIn: 'root'
})
export class LegalRepresentantiveService {

  private httpOptions = {};

  constructor(private http: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({
        "Accept": "application/json",
        "Content-Type": "application/json"
      }),
      observe: 'response' as const // Asegura que el tipo de 'observe' sea correcto
    }
  }

  create(companyId: number, representativeLegal: any) {
    const url = `${URL}/companies/${companyId}/representative-legal`;
    return this.http.post<any>(url, JSON.stringify(representativeLegal), this.httpOptions).pipe(
      map(response => response)
    );
  }

  update(companyId: number, representativeLegal: any) {
    const url = `${URL}/companies/representative-legal/${representativeLegal.id}`;
    return this.http.put<any>(url, JSON.stringify(representativeLegal), this.httpOptions).pipe(
      map(response => response)
    );
  }

  delete(representativeLegal: any) {
    const url = `${URL}/companies/representative-legal/${representativeLegal.id}`;
    return this.http.delete<any>(url, this.httpOptions).pipe(
      map(response => response)
    );
  }
}
