export const can = (path: string, permission: string): boolean => {
  const jsonData = localStorage.getItem('UserData');
  const userData = jsonData ? JSON.parse(jsonData) : undefined;

  if(userData.data)
  {
    const find = userData.data.permisos.find((p: { path: string; }) => p.path == path);
    if(find)
    {
      const permissions = [];

      if(find.visualizar)
      {
        permissions.push('visualizar');
      }

      if(find.crear)
      {
        permissions.push('crear');
      }

      if(find.actualizar)
      {
        permissions.push('actualizar');
      }

      if(find.eliminar)
      {
        permissions.push('eliminar');
      }

      if(find.descargar)
      {
        permissions.push('descargar');
      }


      return permissions.includes(permission);
    }
  }

  return false;
}
