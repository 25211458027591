<div class="container_section_large page_with_filter">
  <div class="page filter">
    <asei-title-section
      [link]="'directorio-asei/editar'"
      title="Directorio ASEI"
      actionText="Editar"
      [hasPermission]="canEdit"
      version="two"
    ></asei-title-section>
  </div>
  <div>
    <mat-accordion>
      <!-- Itera a través de tu arreglo 'data' y crea un panel para cada elemento. -->
      @for (item of data; track item; let i = $index) {
        <mat-expansion-panel [expanded]="i === 0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{item.title}}
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
          </mat-expansion-panel-header>
          @if (item.content) {
            <div [innerHtml]="getSafeContent(item.content)"></div>
          }
        </mat-expansion-panel>
      }
    </mat-accordion>
  </div>
</div>
