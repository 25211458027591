import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IconService {

  constructor() { }
  getAll():string[]{
    return [
      'pi pi-home',
      'pi pi-map',
      'pi pi-calendar',
      'pi pi-calendar-plus',
      'pi pi-external-link',
      'pi pi-file-pdf',
      'pi pi-users',
      'pi pi-cog',
      'pi pi-exclamation-circle',
      'pi pi-bell',
      'pi pi-book',
      'pi pi-camera',
      'pi pi-clock',
      'pi pi-times',
      'pi pi-cloud',
      'pi pi-comment',
      'pi pi-desktop',
      'pi pi-download',
      'pi pi-envelope',
      'pi pi-eye',
      'pi pi-filter',
      'pi pi-flag',
      'pi pi-image',
      'pi pi-info-circle',
      'pi pi-key',
      'pi pi-map-marker',
      'pi pi-minus',
      'pi pi-paperclip',
      'pi pi-pause',
      'pi pi-plus',
      'pi pi-search',
      'pi pi-star',
      'pi pi-thumbs-down',
      'pi pi-thumbs-up',
      'pi pi-upload'
    ]
  }
}
