import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {map} from 'rxjs/operators';
import { CompanyEmployee } from '../../../interfaces/company-employee.interface';
import {UpdatePageDTO} from "../interfaces/update-page.interface";
import {Observable} from "rxjs";

const URL = environment.url + '/api/v1';

@Injectable({
  providedIn: 'root'
})
export class PageService {


  private httpOptions = {};

  constructor(private http: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({
        "Accept": "application/json",
        "Content-Type": "application/json"
      }),
      observe: 'response' as const // Asegura que el tipo de 'observe' sea correcto
    }
  }
  getOne(id: string) {
    const url = `${URL}/pages/${id}`;
    return this.http.get<any>(url, this.httpOptions).pipe(
      map(response => response)
    );
  }
  create(body: any): Observable<any> {
    const url = `${URL}/pages`;
    return this.http.post<any>(url, JSON.stringify(body), this.httpOptions).pipe(
      map((response: any) => response)
    );
  }
  reorder(data: any) {
    const url = `${URL}/pages/re-order`;
    return this.http.put<any>(url, JSON.stringify(data), this.httpOptions).pipe(
      map(response => response)
    );
  }
  update(id: number, data: UpdatePageDTO) {
    const url = `${URL}/pages/${id}`;
    return this.http.put<any>(url, JSON.stringify(data), this.httpOptions).pipe(
      map(response => response)
    );
  }
  delete(id: number) {
    const url = `${URL}/pages/${id}`;
    return this.http.delete<any>(url, this.httpOptions).pipe(
      map(response => response)
    );
  }
}
