import {Component, Input, Output, OnChanges, OnInit, SimpleChanges, EventEmitter} from '@angular/core';
import {getLinkImage} from 'src/app/helpers/converters.helper';


@Component({
  selector: 'asei-item-list',
  templateUrl: './asei-item-list.component.html',
  styleUrls: ['./asei-item-list.component.scss']
})
export class ASEIItemListComponent implements OnChanges, OnInit {
  @Input() name!: string;
  @Input() environment!: string;
  @Input() businessName!: string;
  @Input() urlImage!: string;
  @Input() isSelected: boolean = false;
  @Input() version: string = '';
  @Input() actionText: string = 'Ver mas';
  @Input() canShowMore: boolean = true;
  @Input() showInactive: boolean = false;
  firstLetterName = '';
  classSeparator = '';

  @Output() $showMore = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
    this.firstLetterName = this.name.charAt(0).toUpperCase();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('isActive' in changes) {
      this.classSeparator = this.version === 'inverse' && !this.isSelected ? 'inverse' : '';
    }
  }

  onClickShowMore() {
    this.$showMore.emit();
  }

  protected readonly event = event;
  protected readonly getLinkImage = getLinkImage;
}
