import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AseiInfoNews, AseiInfoNewsCreation} from '../../../types/asei-info-news';
import { getDateYYYYMMDD } from 'src/app/helpers/converters.helper';

const URL = environment.url + '/api/v1';

@Injectable({
  providedIn: 'root',
})
export class PostService {
  private httpOptions = {};

  constructor(private http: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
      observe: 'response' as const, // Asegura que el tipo de 'observe' sea correcto
    };
  }

  getAll(
    type = '',
    dateStart = '',
    dateEnd = '',
    search = '',
    page = '',
    sort = 'desc',
    perPage = 6
  ) {
    let url = `${URL}/posts?sort=${sort}&per_page=${perPage}&start_date=${getDateYYYYMMDD(dateStart)}&end_date=${getDateYYYYMMDD(dateEnd)}&search=${search}&type=${type}`;
    if (page) {
      url += `&page=${page}`
    }
    return this.http
      .get<any>(url, this.httpOptions)
      .pipe(map((response) => response));
  }

  getAllLast(
    dateStart = '',
    dateEnd = '',
    search = '',
  ) {
    const url = `${URL}/posts/last?start_date=${getDateYYYYMMDD(dateStart)}&end_date=${getDateYYYYMMDD(dateEnd)}&search=${search}`;
    return this.http
      .get<any>(url, this.httpOptions)
      .pipe(map((response) => response));
  }

  getOne(id: string) {
    const url = `${URL}/posts/${id}`;
    return this.http
      .get<any>(url, this.httpOptions)
      .pipe(map((response) => response));
  }

  update(data: AseiInfoNews | AseiInfoNewsCreation) {
    const url = `${URL}/posts/${data.id}`;
    return this.http
      .put<any>(url, JSON.stringify(data), this.httpOptions)
      .pipe(map((response) => response));
  }

  createNews(body: AseiInfoNews | AseiInfoNewsCreation) {
    const url = `${URL}/posts`;
    return this.http
      .post<any>(url, JSON.stringify(body), this.httpOptions)
      .pipe(map((response: any) => response));
  }

  getByUrlPagination(url: string) {
    return this.http
      .get<any>(url, this.httpOptions)
      .pipe(map((response) => response));
  }

  delete(id: number) {
    const url = `${URL}/posts/${id}`;
    return this.http
      .delete<any>(url, this.httpOptions)
      .pipe(map((response) => response));
  }
}
