import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgIf, NgOptimizedImage} from "@angular/common";
import {MatListModule} from "@angular/material/list";
import {Params, RouterLink, RouterLinkActive} from "@angular/router";
import {MatLineModule} from "@angular/material/core";
@Component({
  selector: 'menu-item-link',
  standalone: true,
  imports: [
    NgIf,
    MatListModule,
    RouterLink,
    RouterLinkActive,
    NgOptimizedImage,
    MatLineModule
  ],
  templateUrl: './menu-item-link.component.html',
  styleUrls: ['./menu-item-link.component.scss']
})
export class MenuItemLinkComponent {
  @Input() title!:string;
  @Input() path!:string | null;
  @Input() icon!:string;
  @Input() hasPermissionView:boolean = true;
  @Input() hasExternalLink:boolean = false;
  @Input() queryParams:Params | null = null
  @Output() clicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  onClick(){
    this.clicked.emit(true);
  }
}
