
export enum EVENT_TYPE {
  'ASEI_MEETING' = 1,
  'REUNIÓN_SOCIO' = 2,
  'ANIVERSARIO' = 3,
  'ENASEI' = 4,
  'FERIA_NEXO' = 5,
  'ASEI_BUSINESS_TOUR' = 6,
  'COPA_ASEI' = 7,
  'ASEI_PIURA' = 8,
  'FERIA_LIMA_HOME' = 9,
}

export enum EVENT_TYPE_ROUTES {
  'ASEI_MEETING' = 'asei meeting',
  'REUNION_SOCIO' = 'reunion socio',
  'ANIVERSARIO' = 'aniversario',
  'ENASEI' = 'enasei',
  'ASEI_BUSINESS_TOUR' = 'asei business tour',
  'COPA_ASEI' = 'copa asei',
  'ASEI_PIURA' = 'asei piura',

  'FERIA_LIMA_HOME' = 'feria lima home',
  'FERIA_NEXO' = 'feria nexo',
}
