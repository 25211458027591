import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {map} from 'rxjs/operators';

const URL = environment.url + '/api/v1';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  private httpOptions = {};

  constructor(private http: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({
        "Accept": "application/json",
        "Content-Type": "application/json"
      }),
      observe: 'response' as const // Asegura que el tipo de 'observe' sea correcto
    }
  }

  getOne() {
    const url = `${URL}/account`;
    return this.http.get<any>(url, this.httpOptions).pipe(
      map(response => response)
    );
  }

  update(data: any) {
    const url = `${URL}/account`;
    return this.http.put<any>(url, JSON.stringify(data), this.httpOptions);
  }

}
