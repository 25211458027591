import {Component, DoCheck, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AbstractControl, ControlValueAccessor, FormControl, FormGroup, FormGroupDirective, ValidatorFn, Validators} from "@angular/forms";
import { regex } from 'src/app/helpers/input-group.helper';
import MESSAGE_ERROR from 'src/app/helpers/message-error.helper';

@Component({
  selector: 'app-asei-input',
  templateUrl: './asei-input.component.html',
  styleUrls: ['./asei-input.component.scss'],
})
export class AseiInputComponent implements OnInit, ControlValueAccessor, DoCheck {

  @Input() label!: string;
  @Input() type!: string;
  @Input() value: string | null | undefined = '';
  @Input() controlName!: string;
  @Input() disabled: boolean = false;
  @Input() maxlength: number = 250;
  @Input() required: boolean = false;
  @Input() invalid: boolean = false;
  @Input() errorMessage: string | null = '';
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  private onChange: any = () => {};
  private onTouch: any = () => {};

  formControl!: FormControl
  submitted!: boolean

  constructor(private formGroupDirective: FormGroupDirective) {
  }

  ngOnInit() {
    this.formGroupDirective.ngSubmit.subscribe({
      next: (value: any) => {
        this.submitted = value.isTrusted
      }
    })
    const formGroup = this.formGroupDirective.form;
    this.formControl = formGroup.get(this.controlName) as FormControl;
  }

  getInputType(){
    switch(this.type){
      case "text":
      case "ruc":
      case "dni":
      case "email":
      case "phone":
        return "text";
      case "password":
        return "password";
      case "currentPassword":
        return "password";
      case"url":
        return "url";
      case "number":
        return "number";
      case "date":
        return "date";
      case "time":
        return "time";
      case "month":
        return "month";
      default:
        return "text";
    }
  }

  ngDoCheck() {
    this.invalid = this.validateInput(this.value);
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  onInputChange(event: any) {
    const newValue = event.target.value;
    this.validateInput(newValue);
    this.onChange(newValue);
    this.onTouch();
    this.valueChange.emit(newValue);
  }

  validateInput(value: any) {
    this.errorMessage = '';
    if (this.required && (!value || value.trim() === '')) {
      this.errorMessage = MESSAGE_ERROR.OBLIGATORY_FIELD;
      return true;
    } else if ((this.required || value) && this.type === 'letter' && !regex.letter.test(value)) {
      this.errorMessage = 'El formato es inválido. Solo debe tener letras.';
      return true;
    } else if ((this.required || value) && this.type === 'text' ) {
      if( this.maxlength != 0 && this.maxlength < value.length ) {
        this.errorMessage = MESSAGE_ERROR.MAX_TEXT_CHARACTER(value.length, this.maxlength);
        return true;
      }
      return false;
    } else if ((this.required || value) && this.type === 'url' && !regex.url.test(value)) {
      this.errorMessage = MESSAGE_ERROR.FORMAT_INVALID_URL;
      return true;
    }else if ((this.required || value) && this.type === 'email' && !regex.email.test(value)) {
      this.errorMessage = MESSAGE_ERROR.FORMAT_INVALID;
      return true;
    }else if ((this.required || value) && this.type === 'password' && !regex.password.test(value)) {
      this.errorMessage = MESSAGE_ERROR.FORMAT_PASSWORD;
      return true;
    }else if ((this.required || value) && this.type === 'ruc' && !regex.ruc.test(value)) {
      this.errorMessage = MESSAGE_ERROR.RUC_FIELD_HAS_11_CHARACTER;
      return true;
    }else if ((this.required || value) && this.type === 'dni' && !regex.dni.test(value)) {
      this.errorMessage = MESSAGE_ERROR.DNI_FIELD_HAS_8_CHARACTER;
      return true;
    }else if ((this.required || value) && this.type === 'phone' && !regex.phone.test(value)) {
      this.errorMessage = MESSAGE_ERROR.FORMAT_INVALID_PHONE;
      return true;
    }else {
      return false;
    }
  }

  validate: ValidatorFn = (control: AbstractControl) => {
    const validators: ValidatorFn[] = [];
    if (this.required) {
      validators.push(Validators.required);
    }
    if (validators.length) {
      const validator = Validators.compose(validators);
      const result = validator ? validator(control) : null;
      return result;
    }
    return null;
  };

  // get invalidValue(): boolean {
  //   return this.submitted && this.formControl.invalid
  // }

  // get errorMessage(): string {
  //   if (this.formControl.errors?.['required']) {
  //     return MESSAGE_ERROR.OBLIGATORY_FIELD;
  //   } else if (this.formControl.errors?.['minlength']) {
  //     return `${this.label} debe tener mas caracteres`;
  //   }
  //   return `${this.label} inválido`
  // }
}
