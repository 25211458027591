import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, Validators} from "@angular/forms";

@Component({
  selector: 'app-select-group',
  templateUrl: './select-group.component.html',
  styleUrls: ['./select-group.component.scss'],
})
export class SelectGroupComponent implements OnInit {

  @Input() title: string | null | undefined = '';
  @Input() value: string | number | null | undefined = '';
  @Input() type: string | null | undefined = 'text';
  @Input() disabled: boolean = false;
  @Input() options: any;
  @Input() colorLabelInverse: boolean = false;
  @Input() invalid: boolean = false;
  @Input() errorMessage: string | null = '';
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();


  constructor() {
  }

  ngOnInit() {
  }

  onInputChange(event: any) {
    this.valueChange.emit(event);
  }

}
