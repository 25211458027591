import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import Evento from "../../interfaces/evento.interface";
import {getDateDDMMYYYY, getPathByEventType} from "../../helpers/converters.helper";
import {parseAseiInfoList} from "../../mapper/api-to-dashboard.mapper";
import {AseiInfo} from "../../types/asei-info";
import Event from "../../pages/events/interfaces/event.interface";

@Component({
  selector: 'app-calendar-modal',
  templateUrl: './asei-calendar-modal.component.html',
  styleUrls: ['./asei-calendar-modal.component.scss']
})
export class AseiCalendarModalComponent {

  date: string = ''
  events: Event[] = []

  constructor(
    public dialogRef: MatDialogRef<AseiCalendarModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { date: string, events: Event[] }
  ) {
    this.date = data.date; // Obtener la variable de video desde los datos del diálogo
    this.events = data.events;
  }

  closeModal() {
    this.dialogRef.close();
  }

  protected readonly getDateDDMMYYYY = getDateDDMMYYYY;
  protected readonly event = event;
    protected readonly getEnvironment = getPathByEventType;
}
