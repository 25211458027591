import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs";
import { environment } from 'src/environments/environment';

const URL = `${environment.url}/api/v1/configuration/categories/directory-asei`;

@Injectable({
  providedIn: 'root'
})
export class ConfigurationCategoriesService {

  private httpOptions = {
    headers: new HttpHeaders({
      "Accept": "application/json",
      "Content-Type": "application/json"
    })
  };

  constructor(private http: HttpClient) { }

  getAllCategories(): Observable<any> {
    return this.http.get<any>(`${URL}`, this.httpOptions);
  }


  getCategoryById(id: number): Observable<any> {
    return this.http.get<any>(`${URL}/${id}`, this.httpOptions);
  }

  updateCategories(data: { id: number, name: string }[]): Observable<any> {
    return this.http.put<any>(URL, { categories: data }, this.httpOptions);
  }

  deleteCategory(id: number): Observable<any> {
    return this.http.delete<any>(`${URL}/${id}`, this.httpOptions);
  }
}

