import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {firstValueFrom} from "rxjs";
import {Router} from "@angular/router";
import {ConfigurationService} from "../../../../services/configuration.service";
interface Item {
  title: string;
  content: string | null;
}
@Component({
  selector: 'reports-home',
  templateUrl: './asei-directory-form-page.component.html',
  styleUrls: ['./asei-directory-form-page.component.scss'],
})
export class AseiDirectoryFormPageComponent implements OnInit {
  form!: FormGroup;
  submit = false;

  constructor(
    private configurationService: ConfigurationService,
    private router: Router,
    private formBuilder: FormBuilder,
  ) {
  }

  async ngOnInit() {
    const directory:Item[] = await this.getDirectoryAsei();
    this.form = this.createFormWithValidation(directory);
  }
  async getDirectoryAsei() {
    try {
      const response = await firstValueFrom(this.configurationService.getDirectoryAsei())
      return JSON.parse(response.data.value)
    } catch (error) {
      return null;
    }
  }

  createFormWithValidation(data: Item[]) {
    const [
      comunidad,
      comercial,
      institucional,
      responsabilidad,
      dataInteligenciaComercial] = data
    return this.formBuilder.group({
      contentComunidad: [comunidad.content, []],
      contentComercial: [comercial.content, []],
      contentInstitucional: [institucional.content, []],
      contentResponsabilidadSocial: [responsabilidad.content, []],
      contentDataInteligenciaComercial: [dataInteligenciaComercial.content, []],

    });
  }
  async onSubmit() {
    this.submit = true
    if (this.form.valid) {
      const { contentComunidad, contentComercial, contentInstitucional, contentResponsabilidadSocial, contentDataInteligenciaComercial } = this.form.value;
      const payload = {
        data: [
          {
            title: 'Comunidad',
            content: contentComunidad || '',
          },
          {
            title: 'Comercial',
            content: contentComercial || '',
          },
          {
            title: 'Institucional',
            content: contentInstitucional || '',
          },
          {
            title: 'Responsabilidad Social',
            content: contentResponsabilidadSocial || '',
          },
          {
            title: 'Data e Inteligencia comercial',
            content: contentDataInteligenciaComercial || '',
          },
        ]
      };
      const response = await this.update(payload);
      if (response.ok) {
        this.backPage();
        this.submit = false;
      }
    }
  }
  async update(data:any) {
    try {
      return await firstValueFrom(this.configurationService.updateDirectoryAsei(data))
    } catch (error) {
      return null;
    }
  }
  onTextEditorChange(text: any, fieldName: string): void {
    // Asigna el valor del editor de texto al campo correspondiente en el formulario.
    this.form.get(fieldName)?.setValue(text);
  }
  backPage(): void {
    const url = '/directorio-asei'
    this.router.navigateByUrl(url);
  }
}
