import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[lazyLoad]'
})
export class LazyLoadDirective implements OnInit {
  @Input('lazyLoad') lazySrc!: string;

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    this.loadLazySrc();
  }

  loadLazySrc() {
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const imgElement = entry.target as HTMLImageElement;
          imgElement.src = this.lazySrc;
          observer.unobserve(imgElement);
        }
      });
    });

    observer.observe(this.elementRef.nativeElement);
  }
}