<div class="page container_section_large" #top>
  <app-header-page
    [title]="title"
  ></app-header-page>
  <p-toast></p-toast>
  <asei-notifications [notifications]="notifications"></asei-notifications>
  <div class="main">
    <div class="card personal-information">
      @if (form) {
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
          <div class="information">
            <div class="section">
              <div class="input-box">
                <app-asei-input
                  label="Nombre"
                  type="text"
                  controlName="name"
                  [maxlength]="validatorsInput.MAX_TEXT"
                  [required]="true"
                  [value]="form.get('name')?.value"
                />
                <div>
                  <p class="title-component">Icono:</p>
                  <p-dropdown
                    formControlName="icon"
                    [options]="iconList"
                    optionLabel="name"
                  >
                    <ng-template pTemplate="selectedItem">
                      <div class="flex align-items-center gap-2" *ngIf="icon">
                        <i [ngClass]="icon"></i>
                      </div>
                    </ng-template>
                    <ng-template let-icon pTemplate="item">
                      <div style="display: flex;align-items: center;gap: 2px">
                        <i [ngClass]="icon"></i>
                      </div>
                    </ng-template>
                  </p-dropdown>
                </div>
              </div>
              <div class="input-box">
                <app-textarea-group
                  title="Descripcion"
                  [value]="form.get('description')?.value"
                  (valueChange)="form.get('description')?.setValue($event)"
                  [invalid]="(form.get('description')?.invalid || false)"
                  [errorMessage]="getErrorMessage('description',form)"
                  [maxlength]="validatorsInput.MAX_TEXT_DESCRIPTION"
                  controlName="description"
                  style="width: 100%;"
                ></app-textarea-group>
              </div>
              <p class="title-component">Componente:</p>
              <div class="field-checkbox">
                <div *ngFor="let category of componentList">
                  <p-radioButton
                    [inputId]="category.value"
                    [value]="category.value"
                    formControlName="component"
                  ></p-radioButton>
                  <label
                    style="margin-left: 5px"
                    [for]="category.value"
                    class="ml-2"
                  >
                    {{ category.name }}
                  </label>
                </div>
                <div class="flexflex align-items-center">
                  <p-checkbox
                    formControlName="hasPages"
                    [binary]="true"
                    inputId="hasPages" />
                  <label for="hasPages" style="margin-left: 10px">Páginas</label>
                </div>
              </div>
              @switch (component) {
                @case ('Redirect') {
                  <div class="input-box">
                    <app-asei-input
                      label="Link de Redirección"
                      type="text"
                      controlName="externalLink"
                    />
                  </div>
                }
                @case ('Home'){
                  <div class="card" style="display: flex;justify-content: center;align-items: center">
                    @if (sourceOptionPages.length > 0) {
                      <p-pickList
                        [source]="sourceOptionPages" [target]="targetOptionPages"
                        sourceHeader="Disponible" targetHeader="Seleccionados" [dragdrop]="true"
                        [responsive]="true"
                        [showSourceControls]="false"
                        [showTargetControls]="false"
                        [sourceStyle]="{ height: '30rem' }" [targetStyle]="{ height: '30rem' }"
                        breakpoint="1200px"
                      >
                        <ng-template let-option pTemplate="item">
                          <div class="flex flex-wrap p-2 align-items-center gap-3">
                            <div class="flex-1 flex flex-column gap-2">
                              <span class="font-bold">{{ option.name }}</span>
                            </div>
                          </div>
                        </ng-template>
                      </p-pickList>
                    }
                  </div>
                }
                @default {
                }
              }
              <app-asei-upload-file
                label="Imagen de Fondo del Card"
                accept="image/*"
                controlName="imageBackgroundCard"
                [required]="true"
                [value]="form.get('imageBackgroundCard')?.value"
              />
              <app-asei-upload-file
                label="Logo Primario del Card"
                accept="image/*"
                controlName="logoPrimaryCard"
              />
              <app-asei-upload-file
                label="Logo Secundario del Card"
                accept="image/*"
                controlName="logoSecondaryCard"
              />
            </div>
            <div class="actions">
              <a class="btn secondary" (click)="backPage()">Cancelar</a>
              <button class="btn primary" type="submit">Guardar</button>
            </div>
          </div>
        </form>
      }
    </div>
  </div>
</div>
