<div class="page container_section_large">
  <app-header-page
    title="Calendario"
    subtitle="Revise aquí el cronograma de todas las actividades"
  ></app-header-page>
  <div class="card-calendar-event">
    <div class="box calendar-box">
      <asei-calendar></asei-calendar>
    </div>
    @if (nextEvent) {
      <div class="box next-event">
        <div class="next-event_wrapper">
<!--          <span class="flag">{{nextEvent.eventType.name}}</span>-->
          <div (click)="navigateTo(nextEvent)">
            <h2>{{nextEvent.title}}</h2>
            <div class="dateTime_container">
              <div class="dateTime_wrapper">
                <div class="dateTime_card">
                  <div class="dateinit">
                    <h1 class="day">{{day}}</h1>
                    <p class="month">{{month}}</p>
                  </div>
                  @if (dayEnd && monthEnd && (nextEvent.dateInit != nextEvent.dateEnd)) {
                    <div class="dateinit">
                      <h1 class="day">{{dayEnd}}</h1>
                      <p class="month">{{monthEnd}}</p>
                    </div>
                  }
                  @if (nextEvent.timeInit) {
                    <div class="asei-card-info-master_content-info--time">
                      <mat-icon>access_time</mat-icon>
                      <span>{{getHourInFormat12HRS(nextEvent.timeInit)}} {{ nextEvent.timeEnd ? '- ' + getHourInFormat12HRS(nextEvent.timeEnd) : '' }}</span>
                    </div>
                  }
                </div>
                <div class="nex-event_img-container" (click)="navigateTo(nextEvent)">
                  <img class="next-event_img" src="{{getLinkImage(nextEvent.linkImage)}}" alt="" srcset="">
                </div>
              </div>
            </div>
          </div>
          <div class="buttons_actions">
            @if (nextEvent.linkRegister) {
              <button (click)="onRegister()" class="btn primary">
                {{nextEvent.textButtonRegister || "Registrate" }}
              </button>
            }
            @if (nextEvent.phone) {
              <button (click)="onConfirmAssistance()" class="btn primary">
                Confirmar Asistencia
              </button>
            }
            <button (click)="onAddCalendar()" class="btn secondary">
              Agregar al calendario
            </button>
          </div>
        </div>
      </div>
    }
  </div>

  @if (nextEvents && nextEvents.length > 0) {
    <asei-title-section
      title="Próximos eventos"
      version="two"
    ></asei-title-section>
    <div class="card-list">
      @for (event of nextEvents; track event.id) {
        <asei-card-info-master
          [id]="event.id"
          [title]="event.title"
          [linkImage]="getLinkImage(event.linkImage)"
          [dateInit]="getDateDDMMYYYY(event.dateInit)"
          [dateEnd]="getDateDDMMYYYY(event.dateEnd)"
          [dateTimeInit]="getHourInFormat12HRS(event.timeInit)"
          [dateTimeEnd]="getHourInFormat12HRS(event.timeEnd)"
          [registrationLink]="event.linkRegister"
          [textButtonRegister]="event.textButtonRegister"
          [phone]="event.phone"
          [buttonActions]="true"
          [environment]="getEnvironment(event.eventType)"
          design="default"
          [canEdit]="false"
          [canDelete]="false"
        ></asei-card-info-master>
      }
    </div>
  }
</div>
