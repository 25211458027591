<section class="home container_section_large content_main">
  <div class="home_container">
    <section class="home_cards_banners">
      @for (data of dataCards; track data) {
        <div (click)="redirect(data.link)" class="home_card_banner">
          <div class="card_banner_content">
            @if (data.logoPrimaryCard) {
              <img class="primary" [src]="data.logoPrimaryCard" alt="logo">
            }
            @if (data.logoSecondaryCard) {
              <img class="secondary" [src]="data.logoSecondaryCard" alt="logo">
            }
            @if (data.text) {
              <h2 class="title">{{ data.text }}</h2>
            }
            <a (click)="redirect(data.link)">
              <span class="badge">Ver más</span>
            </a>
          </div>
          @if (data.imageBackgroundCard) {
            <img [src]="data.imageBackgroundCard" alt="Banner"/>
          }
        </div>
      }
    </section>
  </div>
</section>
