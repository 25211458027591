@if (post) {
<div class="page container_section_large">
  <div class="actions">
    <span class="button" (click)="backPage()">
      <mat-icon>keyboard_backspace</mat-icon>
    </span>
  </div>
  <div class="container_section_asei">
    <div class="flyer">
      <img [src]="getImageOThumbnail(post.linkImage,post.link_image_thumbnail)" alt="">
      <!-- @if(isFile == true){
        <img src="https://fakeimg.pl/600x400?text=Archivo&font=bebas" alt="">
      }@else if(isNotImg == true){
        <img src="https://fakeimg.pl/600x400?text=Sin%20Archivo&font=bebas" alt="">
      }@else {
        <img [src]="getImageOThumbnail(post.linkImage,post.link_image_thumbnail)" alt="">
      } -->
    </div>
    <div class="button-container">
      <span class="button" (click)="onClickDownload(post)" *ngIf="post.linkImage ? 'disabled' : ''">
        Descargar
      </span>
      <!-- <span class="button" (click)="onClickShare()">
        Compartir
      </span> -->
    </div>
  </div>
  <div class="main">
    <p class="date-time">
      <mat-icon>calendar_month</mat-icon>
      <span>{{getDateDDMMYYYY(post.date)}}</span>
    </p>
    <h2 class="title">{{post.title}}</h2>
    @if (post.content) {
    <div class="content" [innerHTML]="post.content" id="content_event"></div>
    }
    @if (post.links.length>0) {
    <div class="links-container">
      <p>Documentos a descargar:</p>
      @for (link of post.links; track link) {
      <a [href]="link" target="_blank">{{ link }}</a>
      }
    </div>
    }
  </div>
</div>
<p-toast></p-toast>
}
