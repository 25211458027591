import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs";
import { environment } from 'src/environments/environment';

const URL = environment.url + '/api/v1/configuration/directory-asei';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  private httpOptions = {
    headers: new HttpHeaders({
      "Accept": "application/json",
      "Content-Type": "application/json"
    })
  };

  constructor(private http: HttpClient) { }

  getAllConfigurations(): Observable<any> {
    return this.http.get<any>(`${URL}`, this.httpOptions);
  }

  createConfiguration(data: { text: string; categoryName: string }): Observable<any> {
    return this.http.post<any>(`${URL}`, data, this.httpOptions);
  }

  getConfigurationById(id: number): Observable<any> {
    return this.http.get<any>(`${URL}/${id}`, this.httpOptions);
  }

  updateAllDirectoryAsei(payload: { data: { id: number; text: string }[] }): Observable<any> {
    return this.http.put<any>(`${URL}`, payload, this.httpOptions);
  }

}
