<div class="section-image">
  <div class="left">
    @if(label){
      <br>
      <strong>{{ label }}</strong>
    }
    <div class="text">
      @if (accept.includes('image')) {
        <p>Para el caso de una imagen se recomienda:</p>
        <ul>
          <li>El tamaño 800 x 455 o dimensión proporcional</li>
          <li>Formato png o jpg</li>
          <li>Peso máximo: 2MB</li>
        </ul>
      }
      @if (accept.includes('pdf')) {
        <p>Para el caso de un pdf se recomienda:</p>
        <ul>
          <li>Peso máximo: 5MB</li>
        </ul>
      }
    </div>
    <div class="image">
      <img
        [src]="image"
        [ngClass]="{'image-invalid': invalid}"
        alt="Popup image"
      >
    </div>
    @if (invalid) {
      <div class="invalid">
        {{ errorMessage }}
      </div>
    }
  </div>
  <div class="right">
    <input
      #fileInput
      style="display: none"
      type="file"
      [accept]=accept
      (change)="onFileSelected($event)"
    >
    <a class="btn secondary" (click)="openInputFile()">Seleccionar</a>
  </div>
</div>
