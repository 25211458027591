import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ASEI-input-slider',
  templateUrl: './asei-input-slider.component.html',
  styleUrls: ['./asei-input-slider.component.scss']
})
export class AseiInputSliderComponent {
  @Input() isActive: boolean = false;
  @Input() disabled: boolean = false;
  @Output() $change = new EventEmitter<boolean>();

  constructor() { }

  toggleSwitch(): void {
    this.isActive = !this.isActive;
    this.$change.emit(this.isActive);
  }
}
