<div class="wrapper">
  <header>
    <p class="current-date month" *ngIf="!showMonthPicker" (click)="toggleMonthPicker()">
      {{currentMonth}}
    </p>
    <div *ngIf="showMonthPicker">
      <p-dropdown
        [options]="monthLists"
        [(ngModel)]="selectedMonth"
        [filter]="true"
        optionLabel="name"
        optionValue="value"
        [showClear]="false"
        placeholder="Mes"
        (ngModelChange)="onMonthSelect($event)"
      ></p-dropdown>
    </div>
    <div class="icons">
      <span id="prev" (click)="changeCalendar('prev')">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-left-fill"
          viewBox="0 0 16 16">
          <path
            d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z" />
        </svg>
      </span>

      <span id="next" (click)="changeCalendar('next')">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
          class="bi bi-caret-right-fill" viewBox="0 0 16 16">
          <path
            d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
        </svg>
      </span>
    </div>
    <p class="current-date year" (click)="toggleYearPicker()" *ngIf="!showYearPicker">
      {{currentYear}}
    </p>
    <div *ngIf="showYearPicker" >
      <p-dropdown
        [options]="yearLists"
        [(ngModel)]="selectedYear"
        [filter]="true"
        optionLabel="name"
        optionValue="value"
        [showClear]="false"
        placeholder="Año"
        (ngModelChange)="onYearSelect($event)"
      ></p-dropdown>
    </div>
  </header>

  <div class="calendar">
    <ul class="weeks">
      @for (dayName of daysName; track dayName) {
      <li>{{dayName}}</li>
      }
    </ul>
    <ul class="days">
      @for (day of days; track day) {
      <li (click)="openDayModal(day)" [class]="day.class">{{day.value}}</li>
      }
    </ul>
  </div>
</div>
