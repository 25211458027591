import VALIDATORS_INPUT from "./validators-input.helper"

const MESSAGE_ERROR = {
  OBLIGATORY_FIELD: 'Este campo es obligatorio.*',
  EMAIL_IS_FIELD: 'Debe ser un correo electrónico válido.',
  NUMBER_IS_FIELD: 'Debe ser una numérico.',
  URL_IS_FIELD: 'Debe ser una URL válida.',
  MAX_TEXT_CHARACTER: (actualLength:Number,requiredLength:Number) => `Debe tener un máximo de ${VALIDATORS_INPUT.MAX_TEXT} caracteres.(${actualLength}/${requiredLength})`,
  MAX_TEXT_DESCRIPTION_CHARACTER: (actualLength:Number,requiredLength:Number) => `Debe tener un máximo de ${VALIDATORS_INPUT.MAX_TEXT_DESCRIPTION} caracteres.(${actualLength}/${requiredLength})`,
  MAX_TEXT_PRESENTATION_CHARACTER: (actualLength:Number,requiredLength:Number) => `Debe tener un máximo de ${VALIDATORS_INPUT.MAX_PRESENTATION} caracteres.(${actualLength}/${requiredLength})`,
  MIN_9_CHARACTER: 'Debe tener un mínimo de 9 caracteres.',
  RUC_FIELD_HAS_11_CHARACTER: `El campo debe tener ${VALIDATORS_INPUT.LENGTH_RUC} dígitos ejemplo: 20123456789.`,
  DNI_FIELD_HAS_8_CHARACTER: `El campo debe tener ${VALIDATORS_INPUT.LENGTH_DNI} dígitos ejemplo: 35685658.`,
  FORMAT_INVALID: 'El formato es inválido.',
  FORMAT_INVALID_PHONE: 'El número de teléfono celular no es válido.',
  FORMAT_INVALID_URL: 'El formato es inválido ejemplo: https://ejemplo.com.',
  FORMAT_PASSWORD: 'Contraseña: al menos 8 caracteres, 1 minúscula, 1 mayúscula y 1 número.',
  IMG_EXTENSION: (name:string) => `No se acepta este archivo '${name}'. Solo se permiten archivos PDF, JPG, PNG JPEG.`,
  IMG_SIZE: (name:string, maxSize:number) => `El tamaño del archivo '${name}' debe ser menor o igual a ${maxSize / (1024 * 1024)} MB.`,
}

export default MESSAGE_ERROR
