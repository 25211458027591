<footer class="mat-elevation-z2">
  <div class="container_footer">
    <div class="social-links">
      <div class="social-links-footer">
        <a href="https://www.facebook.com/aseiperu" target="_blank">
          <img src="assets/img/footer/Facebook.svg" alt="Mi icono SVG">
        </a>
        <a href="https://instagram.com/asei_empresas_inmobiliarias?igshid=cpalbx63bz3y" target="_blank">
          <img src="assets/img/footer/Instagram.svg" alt="Mi icono SVG">
        </a>
        <a href="https://www.linkedin.com/company/asociación-de-empresas-inmobiliarias-del-perú" target="_blank">
          <img src="assets/img/footer/Linkedin.svg" alt="Mi icono SVG">
        </a>
        <a href="https://www.youtube.com/channel/UCXvcyiKkSTeHkscjlX4khJw" target="_blank">
          <img src="assets/img/footer/Youtube.svg" alt="Mi icono SVG">
        </a>
      </div>
    </div>
    <span class="footer-text">
        © {{ year }} Asociación de Empresas Inmobiliarias del Perú.<br>
        Todos los derechos reservados.
      </span>
  </div>
</footer>
