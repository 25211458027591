import { Injectable } from '@angular/core';
import { Route, Router } from "@angular/router";
import { Module } from "../interfaces/module.interface";
import { HomeComponent } from "../pages/home/home.component";
import { PagesComponent } from "../pages/pages.component";
import { ModulePageComponent } from "../pages/modules/pages/list/module.component";
import { AseiDirectoryShowPageComponent } from "../pages/asei-directory/pages/show/asei-directory-show-page.component";
import { AccountEditComponent } from "../pages/account/pages/form/account-edit.component";
import { ListShortcutsBigCard } from "../pages/list-shortcuts-big-card/list-shortcuts-big-card";
import { ComponentType } from "@angular/cdk/overlay";
import { CalendarPageComponent } from "../pages/calendar/calendar.component";
import {
  AffiliateDirectoryAseiListPageComponent
} from "../pages/affiliate-directory-asei/pages/list/affiliate-directory-asei-list-page.component";
import {
  TechnicalAssistancePageComponent
} from "../pages/technical-assistance/pages/form/technical-assistance-page.component";
import { PostListPageComponent } from "../pages/post/pages/list/post-list-page.component";
import { PostFormPageComponent } from "../pages/post/pages/form/post-form-page.component";
import { PostShowPageComponent } from "../pages/post/pages/show/post-show-page.component";
import { ReportListPageComponent } from "../pages/reports/pages/list/report-list-page.component";
import { ReportFormPageComponent } from "../pages/reports/pages/form/report-form-page.component";
import { AseiDirectoryFormPageComponent } from "../pages/asei-directory/pages/form/asei-directory-form-page.component";
import { EventSimpleListPageComponent } from "../pages/events/pages/list/simple/event-simple-list-page.component";
import { EventsFormPageComponent } from "../pages/events/pages/form/events-form-page.component";
import { EventShowPageComponent } from "../pages/events/pages/show/event-show-page.component";
import { EventAnnualListPageComponent } from "../pages/events/pages/list/annual/event-annual-list-page.component";
import { EventMonthlyListPageComponent } from "../pages/events/pages/list/monthly/event-monthly-list-page.component";
import {
  AffiliateDirectoryFormPageComponent
} from "../pages/affiliate-directory-asei/pages/form/affiliate-directory-form-page.component";
import { PagesFormPageComponent } from "../pages/modules/pages/form/pages-form-page/pages-form-page.component";
import { ModulesFormPageComponent } from "../pages/modules/pages/form/modules-form-page/modules-form-page.component";
import { BlankPageComponent } from "../pages/blank/blank.component";
import { LastNewsComponent } from "../pages/post/pages/last-news/last-news.component";
import { PopupListComponent } from '../pages/popups/pages/list/popup.component';
import { ReportShowPageComponent } from '../pages/reports/pages/show/report-show.component';

import { LoginComponent } from '../auth/login/login.component';

@Injectable({
  providedIn: 'root'
})
export class RouteService {


  constructor(
    private router: Router,
  ) {
  }

  async setInitialRoutes(modules: Module[]): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const { config } = this.router;
      const routePagesComponentNumber = config.findIndex((item) => item.component === PagesComponent);
      if (routePagesComponentNumber > -1) {
        const children: Route[] = this.getDynamicChildrenRoutes(modules);
        this.router.config[routePagesComponentNumber].children = children;
        resolve(); // Resolvemos la promesa una vez que las rutas se hayan configurado correctamente
      } else {
        reject('No se encontró la ruta de PagesComponent'); // Manejar esta situación como un error
      }
    });
  }

  async setInitialRoutesWithOutLogin(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const {config} = this.router;
      const routePagesComponentNumber = config.findIndex((item) => item.component === PagesComponent);
      if (routePagesComponentNumber > -1) {
        const children: Route[] = this.getDynamicChildrenRoutesWithOutLogin();
        this.router.config[routePagesComponentNumber].children = children;
        resolve(); // Resolvemos la promesa una vez que las rutas se hayan configurado correctamente
      } else {
        reject('No se encontró la ruta de PagesComponent'); // Manejar esta situación como un error
      }
    });
  }

  private getDynamicChildrenRoutes(modules: Module[]): Route[] {
    let children: Route[] = this.getDefaultRoutes();
    modules.forEach((module) => {
      module.pages.forEach((page) => {
        const componentsMap: { [key: string]: ComponentType<any> } = {
          'Post': PostListPageComponent,
          'Report': ReportListPageComponent,
          'Popup': PopupListComponent,
          'EventSimple': EventSimpleListPageComponent,
          'EventAnnual': EventAnnualListPageComponent,
          'EventMonthly': EventMonthlyListPageComponent,
          'Module': ModulePageComponent,
          'default': BlankPageComponent
        };
        const componentsFormMap: { [key: string]: ComponentType<any> } = {
          'Post': PostFormPageComponent,
          'Report': ReportFormPageComponent,
          'EventSimple': EventsFormPageComponent,
          'EventAnnual': EventsFormPageComponent,
          'EventMonthly': EventsFormPageComponent,
          'Module': ModulesFormPageComponent,
          'default': BlankPageComponent
        };
        const componentsShowMap: { [key: string]: ComponentType<any> } = {
          'Post': PostShowPageComponent,
          'EventSimple': EventShowPageComponent,
          'EventAnnual': EventShowPageComponent,
          'EventMonthly': EventShowPageComponent,
          'Report': ReportShowPageComponent,
          'default': BlankPageComponent
        };
        const component = componentsMap[page.component || 'default'];
        const componentForm = componentsFormMap[page.component || 'default'];
        const componentShow = componentsShowMap[page.component || 'default'];

        if (page.hasPermission.create) {
          children.push({ path: page.slug + '/nuevo', component: componentForm })
        }
        if (page.hasPermission.update) {
          children.push({ path: page.slug + '/:id/editar', component: componentForm })
        }
        if (page.hasPermission.view) {
          children.push({ path: page.slug, component: component })
          children.push({ path: page.slug + '/:id', component: componentShow })
        }
        if (page.component === 'Module' && page.hasPermission.create && page.hasPermission.update) {
          children.push({ path: page.slug + '/:id/paginas/nuevo', component: PagesFormPageComponent })
          children.push({ path: page.slug + '/:id/paginas/:idPage/editar', component: PagesFormPageComponent })
        }
      })
      if (!module.externalLink) {
        const componentsMap: { [key: string]: ComponentType<any> } = {
          'Home': HomeComponent,
          'ListShortcutsBigCard': ListShortcutsBigCard,
          'LastNews': LastNewsComponent,
          'Calendar': CalendarPageComponent,
          'AffiliateDirectory': AffiliateDirectoryAseiListPageComponent,
          'TechnicalAssistance': TechnicalAssistancePageComponent,
          'EventSimple': EventSimpleListPageComponent,
          'EventAnnual': EventAnnualListPageComponent,
          'EventMonthly': EventMonthlyListPageComponent,
          'Post': PostListPageComponent,
          'Report': ReportListPageComponent,
          'default': BlankPageComponent
        };
        const componentsFormMap: { [key: string]: ComponentType<any> } = {
          'AffiliateDirectory': AffiliateDirectoryFormPageComponent,
          'Post': PostFormPageComponent,
          'Report': ReportFormPageComponent,
          'EventSimple': EventsFormPageComponent,
          'EventAnnual': EventsFormPageComponent,
          'EventMonthly': EventsFormPageComponent,
          'default': BlankPageComponent
        };
        const componentsShowMap: { [key: string]: ComponentType<any> } = {
          'Post': PostShowPageComponent,
          'EventSimple': EventShowPageComponent,
          'EventAnnual': EventShowPageComponent,
          'EventMonthly': EventShowPageComponent,
          'Report': ReportShowPageComponent,
          'default': BlankPageComponent
        };
        const component = componentsMap[module.component || 'default'];
        const componentForm = componentsFormMap[module.component || 'default'];
        const componentShow = componentsShowMap[module.component || 'default'];

        if (module.hasPermission.create) {
          children.push({ path: module.slug + '/nuevo', component: componentForm })
        }
        if (module.hasPermission.update) {
          children.push({ path: module.slug + '/:id/editar', component: componentForm })
        }
        if (module.hasPermission.view) {
          children.push({ path: module.slug, component: component })
          children.push({ path: module.slug + '/:id', component: componentShow })
        }
      }
    })
    children.push({ path: '**', redirectTo: 'inicio' })
    return children
  }

  private getDynamicChildrenRoutesWithOutLogin(): Route[] {
    let children: Route[] = this.getDefaultRoutesWithOutLogin();
    children.push(
      {
        path: '**',
        component: LoginComponent
      })
    return children
  }

  private getDefaultRoutes(): Route[] {
    return [
      { path: '', pathMatch: 'full', redirectTo: 'inicio' },
      { path: 'directorio-asei', component: AseiDirectoryShowPageComponent },
      { path: 'directorio-asei/editar', component: AseiDirectoryFormPageComponent },
      { path: 'mi-perfil', component: AccountEditComponent }
    ]
  }

  private getDefaultRoutesWithOutLogin(): Route[] {
    return []
  }
}
