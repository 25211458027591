<div class="page">
  <div class="item-list">
    <div *ngFor="let supplierType of paginatedSupplierTypes" class="item">
      <span>{{ supplierType.name }}</span>
      <button class="icon-btn edit" (click)="openFormDialog(supplierType)">
        <mat-icon>edit</mat-icon>
      </button>
      <button class="icon-btn delete" (click)="confirmDeleteDialog(supplierType.id)">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>
  <div class="item-button">
    <button class="btn primary" (click)="openFormDialog()">Nuevo</button>
    <button class="btn secondary" (click)="closeListDialog()">Cerrar</button>
  </div>

  <!-- Popup para crear/editar -->
  <p-dialog
    [(visible)]="displayFormDialog"
    [modal]="true"
    [closable]="false"
    [resizable]="false"
    [draggable]="false"
    [responsive]="true"
  >
    <ng-template pTemplate="header">
      <span class="popup-title">{{ dialogTitle }}</span>
    </ng-template>
    <ng-template pTemplate="content">
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="input-box">
          <label for="name">Nombre del Tipo de Proveedor</label>
          <input
            id="name"
            type="text"
            formControlName="name"
            placeholder="Ingrese el nombre"
          />
        </div>
      </form>
    </ng-template>
    <ng-template pTemplate="footer">
      <button class="btn secondary" (click)="closeFormDialog()">Regresar</button>
      <button class="btn primary" (click)="onSubmit()" [disabled]="form.invalid">Guardar</button>
    </ng-template>
  </p-dialog>

  <!-- Popup de confirmación para eliminar -->
  <p-dialog
    [(visible)]="displayConfirmDialog"
    [modal]="true"
    [closable]="false"
    [resizable]="false"
    [draggable]="false"
    [responsive]="true"
    class="confirm-dialog"
  >
    <ng-template pTemplate="content">
      <div class="confirmation-container">
        <p class="confirmation-message">
          ¿Estás seguro de que deseas eliminar este <b>tipo de proveedor</b>?
        </p>
      </div>
    </ng-template>
    <ng-template pTemplate="footer">
      <button class="btn secondary" (click)="closeConfirmDialog()">Regresar</button>
      <button class="btn danger" (click)="confirmDelete()">Eliminar</button>
    </ng-template>
  </p-dialog>


  <p-toast></p-toast>
</div>
