import {FormArray, FormGroup, Validators} from "@angular/forms";
import MESSAGE_ERROR from "./message-error.helper";

export function getErrorMessage(controlName: string, form: FormGroup, formArray?: FormArray, index?: number): string {
  let control;
  if (formArray && index !== undefined) {
    const formGroup = formArray.at(index) as FormGroup;
    control = formGroup.get(controlName);
  } else {
    control = form.get(controlName);
  }

  if (control?.errors) {
    if (control.errors['required']) {
      return MESSAGE_ERROR.OBLIGATORY_FIELD;
    }
    if (control.errors['email']) {
      return MESSAGE_ERROR.EMAIL_IS_FIELD;
    }
    if (control.errors['pattern']) {
      if (control.errors['pattern']['requiredPattern'] === '^[0-9]+$') {
        return MESSAGE_ERROR.NUMBER_IS_FIELD;
      }
      return MESSAGE_ERROR.URL_IS_FIELD;
    }
    if (control.errors['maxlength']) {
      const actualLength = control.errors['maxlength']['actualLength']
      const requiredLength = control.errors['maxlength']['requiredLength']
      return MESSAGE_ERROR.MAX_TEXT_CHARACTER(actualLength,requiredLength);
    }
    if (control.errors['minlength']) {
      const actualLength = control.errors['minlength']['actualLength']
      const requiredLength = control.errors['minlength']['requiredLength']
      return `${MESSAGE_ERROR.MIN_9_CHARACTER}(${actualLength}/${requiredLength})`;
    }
    // Agrega más validaciones según tus necesidades
  }
  return '';
}

export function isValidURL() {
  const urlPattern = /^(http|https):\/\/[^ "]+$/; // Expresión regular para validar una URL
  return Validators.pattern(urlPattern)
}
