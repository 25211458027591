import {Component, OnInit} from '@angular/core';
import {MessageService} from "primeng/api";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {PageService} from "../../../services/page.service";
import {Page} from "../../../../../interfaces/page.interface";
import {UpdatePageDTO} from "../../../interfaces/update-page.interface";
import {FilesService} from "../../../../../services/files.service";
import {Location} from "@angular/common";
import {ComponentType} from "../../../interfaces/component-type.interface";
import {ComponentTypesService} from "../../../services/component-types.service";
import {catchError, map, Observable, of} from "rxjs";
import {Module} from "../../../../../interfaces/module.interface";
import {ModuleService} from "../../../services/module.service";

@Component({
  selector: 'app-pages-form-page',
  templateUrl: './pages-form-page.component.html',
  styleUrl: './pages-form-page.component.scss',
  providers: [MessageService]
})
export class PagesFormPageComponent implements OnInit {

  form!: FormGroup
  title!: string

  constructor(private fb: FormBuilder,
              private route: ActivatedRoute,
              private moduleService: ModuleService,
              private pageService: PageService,
              private componentTypesService: ComponentTypesService,
              private filesService: FilesService,
              private location: Location,
              private router: Router,
              private messageService: MessageService) {
  }

  componentList: ComponentType[] = []

  ngOnInit(): void {
    this.route.url.subscribe(segments => {
      const path = segments.join('/');
      if (path.includes('nuevo')) {
        this.loadDataNew()
      } else {
        this.loadDataEdit()
      }
    });
  }

  loadDataNew() {
    this.route.paramMap.subscribe(params => {
      const idModule = params.get('id');
      if (idModule) {
        this.title = "Nueva Página"
        this.form = this.fb.group({
          'id': [''],
          'name': ['', Validators.required],
          'component': ['EventSimple', Validators.required],
          'description': [''],
          'imageBackgroundCard': ['', Validators.required],
          'logoPrimaryCard': [''],
          'logoSecondaryCard': [''],
          'moduleId': [idModule],
          'hasPages': [false]
        })
        this.componentList = this.componentTypesService.getAll()
      }
    });

  }

  loadDataEdit() {
    this.route.paramMap.subscribe(params => {
      const idModule = params.get('id');
      const idValue = params.get('idPage');
      if (idValue) {
        this.title = "Editar Página"
        // Cargar Data del Post
        this.pageService.getOne(idValue).subscribe({
          next: response => {
            const page: Page = response.body.data
            this.form = this.fb.group({
              'id': [page.id, Validators.required],
              'name': [page.name, Validators.required],
              'component': [page.component, Validators.required],
              'description': [page.description],
              'imageBackgroundCard': [page.imageBackgroundCard, Validators.required],
              'logoPrimaryCard': [page.logoPrimaryCard],
              'logoSecondaryCard': [page.logoSecondaryCard],
              'moduleId': [idModule],
              'hasPages': [page.hasPages == true]
            })
            this.componentList = this.componentTypesService.getByType(page.component)
          }
        })
      }
    });
  }

  onSubmit() {
    if (this.form.valid) {
      const pageId: number = this.form.value['id'];
      const payload: UpdatePageDTO = this.getPayload();
      const hasFileForUpload = this.hasFilesForUpload();

      if (pageId) {
        if (hasFileForUpload) {
          this.uploadAndUpdatePage(pageId, payload);
        } else {
          this.updatePage(pageId, payload);
        }
      } else {
        if (hasFileForUpload) {
          this.uploadAndCreatePage(payload);
        } else {
          this.createPage(payload);
        }
      }
    }
  }


  private uploadImagesAndProcessPage(payload: any, isUpdate: boolean, pageId?: number): void {
    const imageKeys = ['imageBackgroundCard', 'logoPrimaryCard', 'logoSecondaryCard'];
    const uploadPromises = imageKeys.map(key => this.uploadImageIfFile(this.form.value[key]));

    Promise.all(uploadPromises)
      .then(([imageBackgroundCard, logoPrimaryCard, logoSecondaryCard]) => {
        if (imageBackgroundCard) payload.imageBackgroundCard = imageBackgroundCard;
        if (logoPrimaryCard) payload.logoPrimaryCard = logoPrimaryCard;
        if (logoSecondaryCard) payload.logoSecondaryCard = logoSecondaryCard;

        if (isUpdate) {
          if (pageId) {
            this.updatePage(pageId, payload);
          } else {
            console.error('No se proporcionó un ID de página para la actualización.');
          }
        } else {
          this.createPage(payload);
        }

        this.backPage();
      })
      .catch(error => {
        const errorMessage = 'No se pudo cargar la imagen.';
        this.showError(errorMessage);
        console.error('Error al cargar la imagen:', error);

        if (isUpdate) {
          if (pageId) {
            this.updatePage(pageId, payload);
          } else {
            console.error('No se proporcionó un ID de página para la actualización.');
          }
        } else {
          this.createPage(payload);
        }
      });
  }

  private uploadAndUpdatePage(pageId: number, payload: any): void {
    this.uploadImagesAndProcessPage(payload, true, pageId);
  }

  private uploadAndCreatePage(payload: any): void {
    this.uploadImagesAndProcessPage(payload, false);
  }


  private uploadImageIfFile(file: any): Promise<string | null> {
    return new Promise((resolve, reject) => {
      if (file instanceof File) {
        this.filesService.uploadImage(file).toPromise()
          .then(response => resolve(response.body.path))
          .catch(error => reject(error));
      } else {
        // Si no es un archivo, resolvemos con undefined sin subir nada.
        resolve(null);
      }
    });
  }

  private createPage(payload: UpdatePageDTO): void {
    this.pageService.create(payload).subscribe({
      next: response => {
        this.getModulesAndSetInLocalStorage()
        this.showSuccess('Página Creada.')
        this.backPage()
      },
    });
  }

  private updatePage(id: number, payload: UpdatePageDTO): void {
    this.pageService.update(id, payload).subscribe({
      next: response => {
        this.getModulesAndSetInLocalStorage()
        this.showSuccess('Página actualizado.')
        this.backPage()
      },
    });
  }

  getModulesAndSetInLocalStorage() {
    this.getModules().subscribe(
      {
        next: modules => {
          this.moduleService.setModuleInLocalStorage(modules);
        },
      }
    );
  }

  getModules(): Observable<Module[]> {
    return this.moduleService.getAll().pipe(
      map(response => response?.body?.data),
      catchError(error => {
        return of([]);
      })
    );
  }

  getPayload(): UpdatePageDTO {
    return {
      name: this.form.value['name'],
      description: this.form.value['description'],
      component: this.form.value['component'],
      imageBackgroundCard: this.form.value['imageBackgroundCard'],
      logoPrimaryCard: this.form.value['logoPrimaryCard'],
      logoSecondaryCard: this.form.value['logoSecondaryCard'],
      module: {
        id: this.form.value['moduleId']
      },
      hasPages:  this.form.value['hasPages'],
    };
  }

  hasFilesForUpload(): boolean {
    return this.form.value['imageBackgroundCard'] instanceof File
      || this.form.value['logoPrimaryCard'] instanceof File
      || this.form.value['logoSecondaryCard'] instanceof File
  }

  backPage(): void {
    if (window.history.length > 1) {
      this.location.back();
    } else {
      this.router.navigateByUrl('inicio');
    }  }

  showSuccess(message: string) {
    this.messageService.add({severity: 'success', summary: 'Operación exitosa', detail: message, life: 3000});
  }

  showError(message: string) {
    this.messageService.add({severity: 'error', summary: 'Error', detail: message, life: 3000});
  }

}
