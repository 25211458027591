import {Component, DoCheck, EventEmitter, Input, OnInit, Output, forwardRef} from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidatorFn, Validators } from '@angular/forms';
import { regex } from 'src/app/helpers/input-group.helper';
import MESSAGE_ERROR from 'src/app/helpers/message-error.helper';

const VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TextareaGroupComponent),
  multi: true,
};

const VALIDATOR = {
  provide: NG_VALIDATORS,
  // tslint:disable-next-line: no-use-before-declare
  useExisting: forwardRef(() => TextareaGroupComponent),
  multi: true,
};

@Component({
  selector: 'app-textarea-group',
  templateUrl: './textarea-group.component.html',
  styleUrls: ['./textarea-group.component.scss'],
})
export class TextareaGroupComponent implements OnInit, ControlValueAccessor, DoCheck {

  // @Input() title: string | null | undefined = '';
  // @Input() value: string | null | undefined = '';
  @Input() rows: number | null | undefined = 4;
  // @Input() disabled: boolean = false;

  @Input() title: string | null | undefined = '';
  @Input() value: string | null | undefined = '';
  @Input() type: string | null | undefined = 'text';
  @Input() disabled: boolean = false;
  @Input() invalid: boolean = false;
  @Input() maxlength: number = 0;
  @Input() errorMessage: string | null = '';
  @Input() required: boolean = false;
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() counter: boolean = false;

  currentLength: number = 0;

  private onChange: any = () => {};
  private onTouch: any = () => {};


  constructor() {
  }

  ngOnInit(): void {
    this.currentLength = this.value?.length ?? 0;
  }

  ngAfterViewInit() {
    this.currentLength = this.value?.length ?? 0;
  }

  ngOnChanges(changes: any) {
    this.currentLength = this.value?.length ?? 0;
  }

  ngDoCheck() {
    this.invalid = this.validateInput(this.value);
  }

  writeValue(value: any): void {
    this.value = value;
    this.currentLength = value?.length ?? 0;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  onInputChange(event: any) {
    this.currentLength = event.target.value.length;
    const newValue = event.target.value;
    this.validateInput(newValue);
    this.onChange(newValue);
    this.onTouch();
    this.valueChange.emit(newValue);
  }

  validateInput(value: any) {
    this.errorMessage = '';
    if (this.required && (!value || value.trim() === '')) {
      this.errorMessage = MESSAGE_ERROR.OBLIGATORY_FIELD;
      return true;
    } else if ((this.required || value) && this.type === 'letter' && !regex.letter.test(value)) {
      this.errorMessage = 'El formato es inválido. Solo debe tener letras.';
      return true;
    } else if ((this.required || value) && this.type === 'text' ) {
      if( this.maxlength != 0 && this.maxlength < value.length ) {
        this.errorMessage = MESSAGE_ERROR.MAX_TEXT_DESCRIPTION_CHARACTER(value.length, this.maxlength);
        return true;
      }
      return false;
    } else if ((this.required || value) && this.type === 'url' && !regex.url.test(value)) {
      this.errorMessage = MESSAGE_ERROR.FORMAT_INVALID_URL;
      return true;
    }else if ((this.required || value) && this.type === 'email' && !regex.email.test(value)) {
      this.errorMessage = MESSAGE_ERROR.FORMAT_INVALID;
      return true;
    }else if ((this.required || value) && this.type === 'password' && !regex.password.test(value)) {
      this.errorMessage = MESSAGE_ERROR.FORMAT_PASSWORD;
      return true;
    }else if ((this.required || value) && this.type === 'ruc' && !regex.ruc.test(value)) {
      this.errorMessage = MESSAGE_ERROR.RUC_FIELD_HAS_11_CHARACTER;
      return true;
    }else if ((this.required || value) && this.type === 'dni' && !regex.dni.test(value)) {
      this.errorMessage = MESSAGE_ERROR.DNI_FIELD_HAS_8_CHARACTER;
      return true;
    }else if ((this.required || value) && this.type === 'phone' && !regex.phone.test(value)) {
      this.errorMessage = MESSAGE_ERROR.FORMAT_INVALID_PHONE;
      return true;
    }else {
      return false;
    }
  }

  validate: ValidatorFn = (control: AbstractControl) => {
    const validators: ValidatorFn[] = [];
    if (this.required) {
      validators.push(Validators.required);
    }
    if (validators.length) {
      const validator = Validators.compose(validators);
      const result = validator ? validator(control) : null;
      return result;
    }
    return null;
  };
}
