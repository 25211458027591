<div class="page container_section_large">
  <app-header-page title="Nuevo Directorio ASEI" subtitle="Completa la información correspondiente."></app-header-page>
  <asei-notifications [notifications]="notifications"></asei-notifications>
  <div class="main">
    <div class="card personal-information">
      <form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="information">
          <div class="section">
            <div class="section-field">
              <label>Nombre de la categoría</label>
              <app-input-group
                type="text"
                class="mr-3"
                formControlName="categoryName"
                [isLabel]="false"
                [maxlength]="70">
              </app-input-group>
              <small class="error" *ngIf="form.get('categoryName')?.invalid && form.get('categoryName')?.touched">El nombre de la categoría es obligatorio.</small>
            </div>
            <div class="section-videos">
              <div class="header">
                <h2>Texto</h2>
              </div>
              <ng-container>
                <app-text-editor [value]="form.get('text')?.value" [showTitle]="false" (textChange)="onTextEditorChange($event)"></app-text-editor>
              </ng-container>
              <small class="error" *ngIf="form.get('text')?.invalid && form.get('text')?.touched">Debe ingresar algo de texto para la configuración.</small>
            </div>
          </div>
        </div>
        <div class="actions">
          <a class="btn secondary" (click)="backPage()">Cancelar</a>
          <button class="btn primary" type="submit">Guardar</button>
        </div>
      </form>
    </div>
  </div>
</div>
<p-toast></p-toast>
