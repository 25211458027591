<div class="page container_section_large">
  <app-header-page
    title="Editar Directorio Asei"
    subtitle="Completa la información correspondiente."
  ></app-header-page>
  <div class="main">
    <div class="card personal-information">
      @if (form) {
        <form (ngSubmit)="onSubmit()">
          <div class="information">
            <div class="section">
              <div class="section-videos">
                <div class="header">
                  <h2>Comunidad</h2>
                </div>
                <ng-container>
                  <app-text-editor
                    [value]="form.get('contentComunidad')?.value"
                    [showTitle] = "false"
                    (textChange)="onTextEditorChange($event,'contentComunidad')"
                  ></app-text-editor>
                </ng-container>
              </div>
              <div class="section-videos">
                <div class="header">
                  <h2>Comercial</h2>
                </div>
                <ng-container>
                  <app-text-editor
                    [value]="form.get('contentComercial')?.value"
                    [showTitle] = "false"
                    (textChange)="onTextEditorChange($event,'contentComercial')"
                  ></app-text-editor>
                </ng-container>
              </div>
              <div class="section-videos">
                <div class="header">
                  <h2>Institucional</h2>
                </div>
                <ng-container>
                  <app-text-editor
                    [value]="form.get('contentInstitucional')?.value"
                    [showTitle] = "false"
                    (textChange)="onTextEditorChange($event,'contentInstitucional')"
                  ></app-text-editor>
                </ng-container>
              </div>
              <div class="section-videos">
                <div class="header">
                  <h2>Responsabilidad Social</h2>
                </div>
                <ng-container>
                  <app-text-editor
                    [value]="form.get('contentResponsabilidadSocial')?.value"
                    [showTitle] = "false"
                    (textChange)="onTextEditorChange($event,'contentResponsabilidadSocial')"
                  ></app-text-editor>
                </ng-container>
              </div>
              <div class="section-videos">
                <div class="header">
                  <h2>Data e Inteligencia comercial</h2>
                </div>
                <ng-container>
                  <app-text-editor
                    [value]="form.get('contentDataInteligenciaComercial')?.value"
                    [showTitle] = "false"
                    (textChange)="onTextEditorChange($event,'contentDataInteligenciaComercial')"
                  ></app-text-editor>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="actions">
            <a class="btn secondary" (click)="backPage()">Cancelar</a>
            <button class="btn primary" type="submit">Guardar</button>
          </div>
        </form>
      }
    </div>
  </div>
</div>
