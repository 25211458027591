import {Component, OnInit} from '@angular/core';
import {TYPE_PERMISSIONS} from "../../../../types/constants/permission.enum";
import {getLinkImage} from "../../../../helpers/converters.helper";
import {PermissionsService} from "../../../../services/permissions.service";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {firstValueFrom} from "rxjs";
import {ConfigurationService} from "../../../../services/configuration.service";
import UserDataLogin from "../../../../interfaces/user-data-login.interface";

interface Item {
  id: number;
  text: string;
  category: string;
}

@Component({
  selector: 'reports-home',
  templateUrl: './asei-directory-show-page.component.html',
  styleUrls: ['./asei-directory-show-page-component.scss'],
})
export class AseiDirectoryShowPageComponent implements OnInit {
  data: Item[] = [];
  canEdit: boolean = false;

  constructor(
    private configurationService: ConfigurationService,
    private sanitizer: DomSanitizer,
    private permissionsService: PermissionsService,
  ) {}

  async ngOnInit() {
    this.data = await this.getDirectoryAsei();
    const userData = this.getUserData();
    const isAdminOrWriter = userData?.roles.some(rol => rol.name === 'admin' || rol.name === 'writer');
    this.canEdit = isAdminOrWriter || false;
  }

  getUserData(): UserDataLogin | undefined {
    const jsonData = localStorage.getItem('UserData');
    const userData = jsonData ? JSON.parse(jsonData) : undefined;
    return userData?.data;
  }

  async getDirectoryAsei(): Promise<Item[]> {
    try {
      const response = await firstValueFrom(this.configurationService.getAllConfigurations());
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  getSafeContent(content: string) {
    return content ? this.sanitizer.bypassSecurityTrustHtml(content) : '';
  }

  can(action: string) {
    return this.permissionsService.can(action as TYPE_PERMISSIONS);
  };

  protected readonly TYPE_PERMISSIONS = TYPE_PERMISSIONS;
  protected readonly getLinkImage = getLinkImage;
}
