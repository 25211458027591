import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatListModule} from "@angular/material/list";
import {MatIconModule} from "@angular/material/icon";
import {NavigationEnd, Router, RouterLink, RouterLinkActive} from "@angular/router";
import {MenuItemLinkComponent} from "../menu-item-link/menu-item-link.component";
import {TYPE_PERMISSIONS} from "../../types/constants/permission.enum";
import {can} from "../../helpers/permissions.helper";
import {MatLineModule} from "@angular/material/core";
import {Page} from "../../interfaces/page.interface";
import { SubPage } from 'src/app/interfaces/sub-page.interface';
import { CommonModule } from '@angular/common';
import { MenuSubItemDropdownComponent } from '../menu-sub-item-dropdown/menu-sub-item-dropdown.component';
import { filter } from 'rxjs';
import UserDataLogin from 'src/app/interfaces/user-data-login.interface';
import { Module } from 'src/app/interfaces/module.interface';
@Component({
  selector: 'menu-item-dropdown',
  standalone: true,
  imports: [
    MatListModule,
    MatIconModule,
    RouterLink,
    RouterLinkActive,
    MenuItemLinkComponent,
    MenuSubItemDropdownComponent,
    MatLineModule,
    CommonModule,
  ],
  templateUrl: './menu-item-dropdown.component.html',
  styleUrls: ['./menu-item-dropdown.component.scss']
})
export class MenuItemDropdownComponent implements OnInit {
  @Input() title!: string;
  @Input() path!: string | null;
  @Input() icon!: string;
  @Input() hasPermissionView: boolean = true;
  @Input()
  items: any[] = []
  @Input()
  dropdown: boolean = false
  @Output() clicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() linkClicked = new EventEmitter<boolean>();

  constructor(private router: Router){}

  ngOnInit(): void {
    this.updateDropdown()
  }

  getUserData(): UserDataLogin | undefined {
    const jsonData = localStorage.getItem('UserData');
    const userData = jsonData ? JSON.parse(jsonData) : undefined;
    return userData.data;
  }

  updateDropdown() {
    const rutaActual = this.router.url;
    this.items.forEach(page => {
      page.dropdown = rutaActual.includes(page.slug);
    })
    const data = this.getUserData();
    const isAdmin = data?.roles.some((rol) => rol.name === 'admin' || rol.name === 'writer');
    if (this.items && Array.isArray(this.items)) {
      this.items.forEach(page => {
        if (!isAdmin) {
          if (page.id == 11) {
            page.hasPermission.view = false;
            page.hasPermission.create = false;
            page.hasPermission.update = false;
            page.hasPermission.delete = false
            page.hasPermission.download = false;

            page.subPages.forEach((subPage: any) => {
              subPage.hasPermission.view = false;
              subPage.hasPermission.create = false;
              subPage.hasPermission.update = false;
              subPage.hasPermission.delete = false;
              subPage.hasPermission.download = false;
            });
          }
        }
        page.dropdown = rutaActual.includes(page.slug);
      });
    }
  }

  toggleDropdown() {
    this.dropdown = !this.dropdown
  }
  onClick(){
    this.clicked.emit(true);
  }

  emitLinkClicked() {
    this.linkClicked.emit(true);
  }



  protected readonly typePermission = TYPE_PERMISSIONS;
  protected readonly can = can;
}
