@if (event) {
  <div
    class="page container_section_large"
  >
    <div class="top-actions">
      <span class="back-button" (click)="backPage()">
        <mat-icon>keyboard_backspace</mat-icon>
      </span>
    </div>
    <h1 class="title-general">{{ event.title }}</h1>
    <div class="container_section_asei">
      <div class="flyer">
        <img [src]='eventGroupFormat.linkImage != "" ? eventGroupFormat.linkImage : "https://fakeimg.pl/600x400?text=Archivo&font=bebas"' alt="">
      </div>
      <div class="info">
        <div class="date-time">
          <p>
            <mat-icon>calendar_month</mat-icon>
            <span>
              {{ eventGroupFormat.dateInit }}
              {{ eventGroupFormat.dateEnd && eventGroupFormat.dateEnd !== eventGroupFormat.dateInit ? ' - ' + eventGroupFormat.dateEnd : '' }}
            </span>
          </p>
          @if (eventGroupFormat.timeInit) {
            <p class="time">
              <mat-icon>access_time</mat-icon>
              <span>
                {{ getHourInFormat12HRS(eventGroupFormat.timeInit) }}
                {{ eventGroupFormat.timeEnd ? ' - ' + getHourInFormat12HRS(eventGroupFormat.timeEnd) : '' }}
              </span>
            </p>
          }
        </div>
        @if (event.showButtons) {
          <event-preview-buttons [event]="parseAseiInfoEvent(event)"></event-preview-buttons>
        }
      </div>
    </div>
    <div class="acordion">
      @for (item of eventGroupFormat.detail; track item; let in = $index) {
        <mat-accordion class="container_accordion">
          @if (item.data && item.data.length > 0) {
            <mat-expansion-panel [expanded]="in === 0">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ item.title }}
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
              </mat-expansion-panel-header>
              @switch (item.title) {
                @case ('Contenido') {
                  <div class="section">
                    <div class="content-eventGroupFormat" [innerHTML]="item.data[0]"></div>
                  </div>
                }
                @case ('Videos') {
                  <div class="section">
                    <div class="multimedias">
                      @for (video of item.data; track video) {
                        <div class="multimedia" (click)="openMultimediaModal(
                          {
                          link:video.link,
                          tipo_link_evento_id:'1'
                        }
                        )">
                          <img [src]="getThumbnailVideo(video.link)" alt="thumbnail video" (error)="handleImageError($event)"/>
                          <span class="play-icon"><mat-icon>play_arrow</mat-icon></span>
                        </div>
                      }
                    </div>
                  </div>
                }
                @case ('Fotos') {
                  <div class="section">
                    <div class="multimedias">
                      @for (image of item.data; track image) {
                        <div class="multimedia" (click)="openMultimediaModal({ link:image.link, tipo_link_evento_id:'3'})">
                          <img class="object-cover" [src]="getLinkImage(image.link)" alt="imagen" (error)="handleImageError($event)"/>
                        </div>
                      }
                    </div>
                  </div>
                }
                @case ('Presentaciones') {
                  <div class="section">
                    <div class="card-list">
                      @for (elem of item.data; track elem; let i = $index) {
                        <div class="card-presentation">
                          <div class="card-header">
                            <h2>{{ truncateText(elem.name || ('Presentación ' + (i + 1))) }}</h2>
                            <p>{{ elem.speaker }}</p>
                          </div>
                          <a target="_blank" [href]="elem.link">Descargar</a>
                        </div>
                      }
                    </div>
                  </div>
                }
                @case ('Auspiciadores') {
                  <div class="section">
                    <div class="multimedias">
                      @for (sponsor of item.data; track sponsor) {
                        <div class="sponsor">
                          <div class="img-container">
                            <img [src]="getLinkImage(sponsor.linkImage)" alt="">
                          </div>
                          @if (sponsor.linkCommercial) {
                            <div class="link">
                              <a [href]="sponsor.linkCommercial" target="_blank">Propuesta Comercial</a>
                            </div>
                          }
                        </div>
                      }
                    </div>
                  </div>
                }
              }
            </mat-expansion-panel>
          }
        </mat-accordion>
      }
    </div>
  </div>
}
