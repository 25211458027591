<div class="page">
  <app-header-page
    title="Mi Perfil"
    subtitle="Completa la información correspondiente a su perfil."
  ></app-header-page>
  <div class="main">
    <div class="card personal-information">
      <asei-notifications [notifications]="notifications"></asei-notifications>
      @if (form) {
        <form>
          <div class="information">
            <div class="section">
              <app-image-input
                [image]="image"
                [disabled]="false"
                (imageSelected)="onImageSelected($event)"
              ></app-image-input>
              <div class="input-box">
                <app-input-group
                  title="Nombres"
                  [value]="form.get('name')!.value"
                  (valueChange)="form.get('name')!.setValue($event)"
                  [invalid]="(form.get('name')!.invalid || false)"
                  [errorMessage]="getErrorMessage('name',form)"
                  type="letter"
                  [required]="true"
                ></app-input-group>
              </div>
              <div class="input-box">
                <app-input-group
                  title="Apellido Paterno"
                  [value]="form.get('fatherName')!.value"
                  (valueChange)="form.get('fatherName')!.setValue($event)"
                  [invalid]="(form.get('fatherName')!.invalid || false)"
                  [errorMessage]="getErrorMessage('fatherName',form)"
                  type="letter"
                  [required]="true"
                ></app-input-group>
                <app-input-group
                  title="Apellido Materno"
                  [value]="form.get('motherName')!.value"
                  (valueChange)="form.get('motherName')!.setValue($event)"
                  [invalid]="(form.get('motherName')!.invalid || false)"
                  [errorMessage]="getErrorMessage('motherName',form)"
                  type="letter"
                  [required]="true"
                ></app-input-group>
              </div>
              <div class="input-box">
                <app-input-group
                  title="Celular"
                  [value]="form.get('phone')!.value"
                  (valueChange)="form.get('phone')!.setValue($event)"
                  [invalid]="(form.get('phone')!.invalid || false)"
                  [errorMessage]="getErrorMessage('phone',form)"
                  type="phone"
                ></app-input-group>
              </div>
              <div class="input-box">
                <app-input-group
                  title="Correo"
                  [value]="form.get('email')!.value"
                  (valueChange)="form.get('email')!.setValue($event)"
                  [invalid]="(form.get('email')!.invalid || false)"
                  [errorMessage]="getErrorMessage('email',form)"
                  [disabled]="true"
                  type="email"
                ></app-input-group>
              </div>
            </div>
          </div>
          <div class="actions">
            <!--          <a class="btn secondary" (click)="backPage()">Cancelar</a>-->
            <button class="btn secondary" type="button" (click)="showChangePassword()">Cambiar contraseña</button>
            <button class="btn primary" type="button"  (click)="onSubmit()" [disabled]="!isFormValid">Guardar</button>
          </div>
        </form>
      }
    </div>
  </div>
</div>
<p-toast></p-toast>
