import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MessageService } from "primeng/api";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { PageService } from "../../../services/page.service";
import { FilesService } from "../../../../../services/files.service";
import { Location } from "@angular/common";
import { ComponentType } from "../../../interfaces/component-type.interface";
import { ComponentTypesService } from "../../../services/component-types.service";
import { catchError, map, Observable, of } from "rxjs";
import { Module } from "../../../../../interfaces/module.interface";
import { ModuleService } from "../../../services/module.service";
import { IconService } from "../../../services/icon.service";
import { UpdateModuleDTO } from "../../../interfaces/update-module.interface";
import { OptionPage } from "../../../interfaces/option-page.interface";
import VALIDATORS_INPUT from 'src/app/helpers/validators-input.helper';
import { getErrorMessage } from 'src/app/helpers/validators.helper';

@Component({
  selector: 'app-pages-form-page',
  templateUrl: './modules-form-page.component.html',
  styleUrl: './modules-form-page.component.scss',
  providers: [MessageService]
})
export class ModulesFormPageComponent implements OnInit {
  @ViewChild('top') topElement!: ElementRef;
  form!: FormGroup
  title!: string
  notifications: Map<string, string> = new Map<string, string>();
  URL_PATTERN = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
  sourceOptionPages: OptionPage[] = [];
  targetOptionPages: OptionPage[] = []
  iconList: string[] = [];
  hasPages: boolean = true;


  constructor(private fb: FormBuilder,
    private route: ActivatedRoute,
    private moduleService: ModuleService,
    private pageService: PageService,
    private iconService: IconService,
    private componentTypesService: ComponentTypesService,
    private filesService: FilesService,
    private location: Location,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private messageService: MessageService,
  ) { }

  componentList: ComponentType[] = []

  ngOnInit(): void {
    this.iconList = this.iconService.getAll()
    this.route.url.subscribe(segments => {
      const path = segments.join('/');
      if (path.includes('nuevo')) {
        this.loadDataNew()
      } else {
        this.loadDataEdit()
      }
    });
  }

  scrollToTop() {
    this.topElement.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  getAllOptionPages(): Promise<OptionPage[]> {
    return new Promise((resolve, reject) => {
      const options: OptionPage[] = [];
      this.moduleService.getAll().subscribe({
        next: response => {
          const modules: Module[] = response.body.data;
          modules.forEach((module) => {
            options.push({
              id: module.id,
              name: module.name,
              imageBackgroundCard: module.imageBackgroundCard,
              logoPrimaryCard: module.logoPrimaryCard,
              logoSecondaryCard: module.logoSecondaryCard,
              slug: module.slug,
              includeTextInBigCard: module.includeTextInBigCard
            });
            module.pages.forEach((page) => {
              options.push({
                id: page.id,
                name: page.name,
                imageBackgroundCard: page.imageBackgroundCard,
                logoPrimaryCard: page.logoPrimaryCard,
                logoSecondaryCard: page.logoSecondaryCard,
                slug: page.slug,
                includeTextInBigCard: page.includeTextInBigCard
              });
            });
          });
          this.cdr.markForCheck();
          resolve(options); // Resuelve la promesa con los datos obtenidos
        },
        error: error => {
          reject(error); // Rechaza la promesa si hay algún error
        }
      });
    });
  }

  loadDataNew() {
    this.title = "Nuevo Módulo"
    this.form = this.fb.group({
      'id': [''],
      'name': ['', Validators.required],
      'component': ['EventSimple', Validators.required],
      'description': [''],
      'icon': ['pi pi-home', Validators.required],
      'imageBackgroundCard': ['', Validators.required],
      'logoPrimaryCard': [''],
      'logoSecondaryCard': [''],
      'externalLink': [''],
      'hasPages': [false]
    })
    this.componentList = this.componentTypesService.getAll()

    this.form.get('component')?.valueChanges.subscribe(component => {
      this.updateValidation(component);
      if (component === 'Home' && this.sourceOptionPages.length == 0) {
        this.getListSourceAndTargetShortcuts().then(({ source, target }) => {
          this.sourceOptionPages = source
          this.targetOptionPages = target
        })
      }
    });
  }

  // Función para actualizar las validaciones de los campos según el componente seleccionado
  updateValidation(component: string): void {
    // Restablecer las validaciones
    this.form.get('externalLink')?.clearValidators();
    this.form.get('externalLink')?.updateValueAndValidity();
    // this.form.get('imageBackgroundCard')?.clearValidators();
    // this.form.get('imageBackgroundCard')?.updateValueAndValidity();

    // Aplicar validaciones según el componente seleccionado
    if (component === 'Redirect') {
      this.form.get('externalLink')?.setValidators([Validators.required, Validators.pattern(this.URL_PATTERN)]);
      this.form.get('externalLink')?.updateValueAndValidity();
    }
    // if (component.includes('Event')
    //   || component.includes('Post')
    //   || component.includes('Report')
    //   || component.includes('ListShortcutsBigCard')
    // ) {
    //   this.form.get('imageBackgroundCard')?.setValidators([Validators.required]);
    //   this.form.get('imageBackgroundCard')?.updateValueAndValidity();
    // }
  }

  loadDataEdit() {
    this.route.paramMap.subscribe(params => {
      const idModule = params.get('id');
      if (idModule) {
        this.title = "Editar Módulo"
        this.moduleService.getOne(idModule).subscribe({
          next: response => {
            const module: Module = response.body.data
            this.form = this.fb.group({
              'id': [module.id],
              'name': [module.name, Validators.required],
              'component': [module.component, Validators.required],
              'description': [module.description],
              'icon': [module.icon, Validators.required],
              'imageBackgroundCard': [module.imageBackgroundCard, Validators.required],
              'logoPrimaryCard': [module.logoPrimaryCard],
              'logoSecondaryCard': [module.logoSecondaryCard],
              'externalLink': [module.externalLink],
              'hasPages': [module.hasPages == true]
            })
            const component = this.form.get('component')?.value;
            this.componentList = this.componentTypesService.getByTypeForModules(component)
            this.updateValidation(component);
            if (component === 'Home' && this.sourceOptionPages.length == 0) {
              this.getListSourceAndTargetShortcuts(module).then(({ source, target }) => {
                this.sourceOptionPages = source
                this.targetOptionPages = target
              })
            }
          }
        })
      }
    });
  }

  getListSourceAndTargetShortcuts(module?: Module): Promise<{ source: OptionPage[], target: OptionPage[] }> {
    return new Promise((resolve, reject) => {
      let source: OptionPage[] = [];
      let target: OptionPage[] = [];

      // OBTENER LISTADO DE MÓDULOS Y PAGINAS, PARA DISPONERLAS COMO ACCESOS DIRECTOS
      this.getAllOptionPages().then((options: OptionPage[]) => {
        source = options;
        if (module?.jsonShortcut) {
          // ACCESOS DIRECTOS QUE YA HAN SELECCIONADOS (ALMACENADOS EN BASE DE DATOS)
          target = JSON.parse(module.jsonShortcut) as OptionPage[];
          // FILTRAR TODOS LOS ACCESOS, MENOS LOS QUE YA HAN SIDO SELECCIONADOS
          source = source.filter(
            sourceOption => !target.some(targetPage => sourceOption.slug === targetPage.slug)
          );
        }
        // Resuelve la promesa con los arrays source y target
        resolve({ source, target });
      }).catch(error => {
        reject(error);
      });
    });
  }


  onSubmit() {
    if (this.form.valid) {
      const moduleId: number = this.form.value['id'];
      const payload: UpdateModuleDTO = this.getPayload();
      const hasFileForUpload = this.hasFilesForUpload();

      if (moduleId) {
        if (hasFileForUpload) {
          this.uploadAndUpdatePage(moduleId, payload);
        } else {
          this.updateModule(moduleId, payload);
        }
      } else {
        if (hasFileForUpload) {
          this.uploadAndCreatePage(payload);
        } else {
          this.createModule(payload);
        }
      }
    } else {
      this.scrollToTop();
      this.notifications.set('not-save', 'Hay campos requeridos por llenar verificar!!');
    }
  }


  private uploadImagesAndProcessPage(payload: UpdateModuleDTO, isUpdate: boolean, moduleId?: number): void {
    const imageKeys = ['imageBackgroundCard', 'logoPrimaryCard', 'logoSecondaryCard'];
    const uploadPromises = imageKeys.map(key => this.uploadImageIfFile(this.form.value[key]));

    Promise.all(uploadPromises)
      .then(([imageBackgroundCard, logoPrimaryCard, logoSecondaryCard]) => {
        if (imageBackgroundCard) payload.imageBackgroundCard = imageBackgroundCard;
        if (logoPrimaryCard) payload.logoPrimaryCard = logoPrimaryCard;
        if (logoSecondaryCard) payload.logoSecondaryCard = logoSecondaryCard;

        if (isUpdate) {
          if (moduleId) {
            this.updateModule(moduleId, payload);
          } else {
            console.error('No se proporcionó un ID de página para la actualización.');
          }
        } else {
          this.createModule(payload);
        }

        this.backPage();
      })
      .catch(error => {
        const errorMessage = 'No se pudo cargar la imagen.';
        this.showError(errorMessage);
        console.error('Error al cargar la imagen:', error);

        if (isUpdate) {
          if (moduleId) {
            this.updateModule(moduleId, payload);
          } else {
            console.error('No se proporcionó un ID de página para la actualización.');
          }
        } else {
          this.createModule(payload);
        }
      });
  }

  private uploadAndUpdatePage(pageId: number, payload: UpdateModuleDTO): void {
    this.uploadImagesAndProcessPage(payload, true, pageId);
  }

  private uploadAndCreatePage(payload: any): void {
    this.uploadImagesAndProcessPage(payload, false);
  }


  private uploadImageIfFile(file: any): Promise<string | null> {
    return new Promise((resolve, reject) => {
      if (file instanceof File) {
        this.filesService.uploadImage(file).toPromise()
          .then(response => resolve(response.body.path))
          .catch(error => reject(error));
      } else {
        // Si no es un archivo, resolvemos con undefined sin subir nada.
        resolve(null);
      }
    });
  }

  private createModule(payload: UpdateModuleDTO): void {
    if (payload.imageBackgroundCard && payload.imageBackgroundCard.length > 190) {
      payload.imageBackgroundCard = payload.imageBackgroundCard.substring(0, 190);
    }
    this.moduleService.create(payload).subscribe({
      next: response => {
        this.notifications.clear();
        this.getModulesAndSetInLocalStorage();
        this.showSuccess('Módulo Creado.')
      },
      error: error => {
        this.showError('No se pudo crear el módulo.')
      }
    });
  }

  private updateModule(id: number, payload: UpdateModuleDTO): void {
    this.moduleService.update(id, payload).subscribe({
      next: response => {
        this.notifications.clear();
        this.getModulesAndSetInLocalStorage()
        this.showSuccess('Módulo actualizado.')
      },
      error: error => {
        this.showError('No se pudo actualizar el módulo.')
      }
    });
  }

  getModulesAndSetInLocalStorage() {
    this.getModules().subscribe(
      {
        next: modules => {
          this.moduleService.setModuleInLocalStorage(modules);
        },
        error: error => {}
      }
    );
  }

  getModules(): Observable<Module[]> {
    return this.moduleService.getAll().pipe(
      map(response => response?.body?.data),
      catchError(error => {
        return of([]);
      })
    );
  }

  getPayload(): UpdateModuleDTO {

    let jsonShortcut: string | null = null
    if (this.targetOptionPages.length > 0) {
      jsonShortcut = JSON.stringify(this.targetOptionPages)
    }
    return {
      name: this.form.value['name'],
      description: this.form.value['description'],
      component: this.form.value['component'],
      imageBackgroundCard: this.form.value['imageBackgroundCard'],
      logoPrimaryCard: this.form.value['logoPrimaryCard'],
      logoSecondaryCard: this.form.value['logoSecondaryCard'],
      icon: this.form.value['icon'],
      externalLink: this.form.value['externalLink'],
      jsonShortcut: jsonShortcut,
      hasPages:  this.form.value['hasPages'],
    };
  }

  hasFilesForUpload(): boolean {
    return this.form.value['imageBackgroundCard'] instanceof File
      || this.form.value['logoPrimaryCard'] instanceof File
      || this.form.value['logoSecondaryCard'] instanceof File
  }

  get component(): string {
    return this.form.get('component')?.value
  }

  get icon(): string {
    return this.form.get('icon')?.value
  }

  backPage(): void {
    if (window.history.length > 1) {
      this.location.back();
    } else {
      this.router.navigateByUrl('inicio');
    }
  }

  showSuccess(message: string) {
    this.messageService.add({ severity: 'success', summary: 'Operación exitosa', detail: message, life: 3000 });
  }

  showError(message: string) {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: message, life: 3000 });
  }

  protected readonly getErrorMessage = getErrorMessage;
  protected readonly validatorsInput = VALIDATORS_INPUT;
}
