<div class="page container_section_large">
  <app-header-page
    title="Administrar Modulos"
    subtitle=""
    actionText="Nuevo"
    [redirectTo]="slug + '/nuevo'"
    [hasPermission]="true"
  ></app-header-page>
  <p-toast></p-toast>
  <div class="card">
    <p-button *ngIf="!reorderActive" label="Reordenar módulos" [link]="true"
              [style]="{'margin-bottom': '1rem'}" (click)="reorderModules()"></p-button>
    @if(!reorderActive){
      <p-treeTable [value]="dataTree" [scrollable]="true" [tableStyle]="{'min-width':'50rem'}">
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 30%;">Nombre</th>
            <th style="width: 50%;">Descripción</th>
            <th style="width: 20%;">Acciones</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
          <tr [ttRow]="rowNode">
            <td style="width: 30%;">
              <div style="display: flex;justify-content: start;align-items: center">
                <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                <span style="font-weight: bold;text-align: start">{{ rowData.name }}</span>
              </div>
            </td>
            <td style="width: 40%;">{{ rowData.description }}</td>
            <td style="width: 30%; text-align: end">
              <p-button *ngIf="rowNode.node.children && rowNode.node.children.length > 0" icon="pi pi-sort-alt" [rounded]="true" [text]="true" [style]="{ 'margin-right': '.5em' }"
                        (click)="reorderPages(rowNode)"
                        severity="secondary"
                        pTooltip="Reordenar páginas" [showDelay]="1000" [hideDelay]="300" tooltipPosition="bottom"
              />
              <p-button *ngIf="rowData.component === 'ListShortcutsBigCard' || rowData.component === 'LastNews'" icon="pi pi-plus" [rounded]="true" [text]="true" [style]="{ 'margin-right': '.5em' }"
                        (click)="addPage(rowNode)"
                        severity="info"
                        pTooltip="Agregar página" [showDelay]="1000" [hideDelay]="300" tooltipPosition="bottom"
              />
              <p-button icon="pi pi-pencil" [rounded]="true" [text]="true" [style]="{ 'margin-right': '.5em' }"
                        severity="warning"
                        pTooltip="Editar" [showDelay]="1000" [hideDelay]="300" tooltipPosition="bottom"
                        (click)="edit(rowNode)"
              />
              <p-button icon="pi pi-trash" [rounded]="true" [text]="true" severity="danger"
                        pTooltip="Eliminar" [showDelay]="1000" [hideDelay]="300" tooltipPosition="bottom"
                        (click)="delete(rowNode)"
              />
            </td>
          </tr>
        </ng-template>
      </p-treeTable>
<!--       THIS IS MODAL FOR DELETED ROWS (MODULES AND PAGES)-->
        <p-confirmDialog #cd>
            <ng-template pTemplate="headless" let-message>
                <div class="headless-container">
                    <div class="circle-container">
                        <i class="pi pi-question"></i>
                    </div>
                    <span class="header">{{ message.header }}</span>
                    <div class="button-container">
                        <p-button severity="danger" label="Eliminar" (click)="cd.accept()"></p-button>
                        <p-button severity="secondary" label="Cancelar" (click)="cd.reject()"></p-button>
                    </div>
                    <p class="message">{{ message.message }}</p>
                </div>
            </ng-template>
        </p-confirmDialog>
    } @else {
      <h3>Reordenar {{reorderTitle}}</h3>
      <div class="card">
        <mat-table
          #table
          [dataSource]="reorderTree"
          class="mat-elevation-z8 reorder-table"
          cdkDropList
          (cdkDropListDropped)="drop($event)"
          cdkDropListData="dataSource"
        >
          <!-- Name Column -->
          <ng-container matColumnDef="name" sticky>
            <mat-header-cell *matHeaderCellDef> Nombre </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <mat-icon class="example-drag-cursor">reorder</mat-icon>
              <span>{{element.data.name}}</span>
            </mat-cell>
          </ng-container>

          <!-- Description Column -->
          <ng-container matColumnDef="description">
            <mat-header-cell *matHeaderCellDef> Descripción </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.data?.description}} </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;" cdkDrag [cdkDragData]="row"></mat-row>
        </mat-table>
      </div>
      <p-button label="Guardar" [style]="{'margin-top': '1rem'}" (click)="saveReorder()" [style]="{ 'margin-right': '.5em' }"></p-button>
      <p-button label="Atrás" [style]="{'margin-top': '1rem'}" (click)="finishReorder()" severity="secondary"></p-button>
    }
  </div>
</div>


<!--
PrimeNG Reorder table
<p-table [value]="reorderTree" [reorderableColumns]="true" [tableStyle]="{'min-width': '50rem'}">
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th style="width: 30%;">Nombre</th>
      <th style="width: 50%;">Descripción</th>
      <th style="width: 20%;"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns" let-index="rowIndex">
    <tr [pReorderableRow]="index">
      <td style="width: 30%;">
        <div style="display: flex;justify-content: start;align-items: center">
          <span class="pi pi-bars" pReorderableRowHandle [style]="{ 'margin-right': '.5em' }">
          </span>
          {{rowData.data.name}}
        </div>
      </td>
      <td style="width: 50%;">{{rowData.data?.description || ''}}</td>
      <td style="width: 20%;"></td>
    </tr>
  </ng-template>
</p-table>
-->
