import { Component, Input, ViewChild } from '@angular/core';
import { SwiperComponent } from "swiper/angular";

import SwiperCore, { Swiper, Virtual } from 'swiper';

SwiperCore.use([Virtual]);

@Component({
  selector: 'app-asei-meeting-inicio',
  templateUrl: './asei-meeting-inicio.component.html',
  styleUrls: ['./asei-meeting-inicio.component.scss']
})
export class AseiMeetingInicioComponent {
  @Input() image: string = '';
  @Input() image2: string = '';
  @Input() speaker: string = '';
  @Input() description: string = '';
  @Input() date: string = '';
  @Input() time: string = '';

  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;


  ngAfterViewInit() {
    if (this.swiper) {
      this.swiper.swiperRef.slideTo(0);
    }
  }

  slideNext(){
    if (this.swiper) {
      this.swiper.swiperRef.slideNext(100);
    }
  }
  slidePrev(){
    if (this.swiper) {
      this.swiper?.swiperRef.slidePrev(100);
    }
  }

}
