<mat-card>
    <mat-card-header>
      <div class="card-avatar">
        <mat-icon>calendar_month</mat-icon>
        <span>2023/03/21</span>
      </div>
      <div class="card-time">
        <mat-icon>access_time</mat-icon>
        <span>4:00 p.m.</span>
      </div>
    </mat-card-header>
    <mat-card-content>
      <span class="titulo">Lorem ipsum dolor sit amet, consecte adipiscing elit.</span>
      <p class="descripcion">Lorem ipsum dolor sit amet, consectetur adi...</p>
    </mat-card-content>
</mat-card>
  