@if (showNotifications) {
  <div class="notifications">
    <asei-notifications [notifications]="notifications"></asei-notifications>
  </div>
}
@if (pageInfo) {
  <div class="container_section_large page_with_filter">
    <div class="page filter">
      <asei-title-section
        [link]="pageInfo.slug + '/nuevo'"
        [title]="pageInfo.name"
        [description]="pageInfo.description"
        [hasPermission]="pageInfo.hasPermission.create"
        actionText="Nuevo"
        version="two"
      ></asei-title-section>
      <app-section-filter
        [searchAll]="filterSearch"
        [dateStartFilter]="filterDateStartFormat ?? ''"
        [dateEndFilter]="filterDateEndFormat ?? ''"
        (filterEvent)="onFilter($event)"
      ></app-section-filter>
    </div>
    <div class="page">
      <div class="card-list">
        @for (report of reports; track report.id) {
          <app-card-report
            [report]="report"
            [canEdit]="pageInfo.hasPermission.update"
            [canDelete]="pageInfo.hasPermission.delete"
            [canDownload]="pageInfo.hasPermission.download"
            ($deleteReportId)="onRemoveReport($event)"
            [environment]=pageInfo.slug
          ></app-card-report>
        }
      </div>
      @if (paginator) {
        <app-paginator [paginatorData]="paginator"
                       (pageSelected)="onPageSelected($event)"
                       design="simple"
        ></app-paginator>
      }
    </div>
  </div>
}
