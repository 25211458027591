import {Component, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {PaginatorComponent} from 'src/app/components/paginator/paginator.component';
import {getLinkImage} from 'src/app/helpers/converters.helper';
import {Company} from 'src/app/interfaces/company.interface';
import {PaginatorData} from 'src/app/interfaces/paginator-data.interface';
import Permission from 'src/app/interfaces/permission.interface';
import {CompanyService} from 'src/app/pages/affiliate-directory-asei/services/company.service';
import {PermissionsService} from 'src/app/services/permissions.service';
import {TYPE_PERMISSIONS} from 'src/app/types/constants/permission.enum';
import UserDataLogin from "../../../../interfaces/user-data-login.interface";
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-affiliate-directory-list-page',
  templateUrl: './affiliate-directory-asei-list-page.component.html',
  styleUrls: ['./affiliate-directory-asei-list-page.component.scss'],
})
export class AffiliateDirectoryAseiListPageComponent {
  business: Company[] = [];
  searchByFullName: string = '';
  paginatorData !: PaginatorData;
  perPage = 10;
  type = '';
  category: string = '';
  status = ''
  page: string = '';
  permissions: Permission = {
    create: false,
    read: false,
    update: false,
    delete: false,
  };
  @ViewChild('pagination') pagination!: PaginatorComponent;
  typePermission: typeof TYPE_PERMISSIONS = TYPE_PERMISSIONS;
  slug!: string
  canAddNew:boolean = false;

  filterType: string = 'affiliate-directory';
  slugFilter: string = '';
  filter: any;
  filterDateStartFormat: any;
  filterDateEndFormat: any;
  filterSearch: string = '';
  filterPage: string = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private inmobiliariaService: CompanyService,
    private permissionsService: PermissionsService,
    private readonly userService: UserService,
  ) {
  }

  async ngOnInit() {
    // await this.getPermissions();
    this.route.url.subscribe(segments => {
      this.slug = segments.join('/');
      this.slugFilter =  this.slug;
      this.initializeFilters();
    });
    const userDataJSON = localStorage.getItem('UserData');
    if (userDataJSON) {
      const userData = JSON.parse(userDataJSON);
      const roleName = userData.data.roles[0].name;
      this.status = roleName == 'admin' ? 'all' : 'active';
    }

    const data = this.getUserData();
    const isAdminOrWriter = data?.roles.some((rol) => rol.name === 'admin' || rol.name === 'writer');
    this.canAddNew = isAdminOrWriter || false;
  }
  getUserData(): UserDataLogin | undefined {
    const jsonData = localStorage.getItem('UserData');
    const userData = jsonData ? JSON.parse(jsonData) : undefined;
    return userData.data;
  }

  async getInmobiliarias(
    perPage = this.perPage,
    type = this.type,
    category = '',
    sort = 'asc',
    search = '',
    page: string = '1',
  ) {

    try {
      this.inmobiliariaService.getAll(perPage, type, category, sort, search, page)
        .subscribe(response => {
          if (response.ok) {
            this.business = response.body.data
            this.paginatorData = {links: response.body.links, meta: response.body.meta};
            this.filterPage = this.paginatorData.meta.current_page.toString();
            this.changeLocalStorage(this.filterPage, this.slugFilter, this.filterType, type, category);
          }
        });
    } catch (error) {
      console.error('Error:', error);
    }
  }

  getType(type: string): void {
    if (this.type === type) {
      this.type = '';
      this.searchByFullName = '';
      this.category = '';
      this.getInmobiliarias(this.perPage, '', '', 'asc', '', '1');
      
      this.filter = {
        type: 'affiliate-directory',
        slug: this.slugFilter,
        search: this.searchByFullName,
        typeAssociate: '',
        company_category: ''
      };
      localStorage.setItem('filters', JSON.stringify(this.filter));
      return;
    } else {
      this.type = type;
      if (type !== 'aliado-estrategico') {
        this.category = '';
      }
      this.getInmobiliarias(this.perPage, this.type, this.category, 'asc', '', '1');
    }
  
    this.filter = {
      type: 'affiliate-directory',
      slug: this.slugFilter,
      search: this.searchByFullName,
      typeAssociate: this.type,
      company_category: this.category
    };
    localStorage.setItem('filters', JSON.stringify(this.filter));
  }
  
  filterByCategory(selectedCategory: string): void {
    if (this.category === selectedCategory) {
      this.category = '';
    } else {
      this.category = selectedCategory;
    }
    this.getInmobiliarias(this.perPage, this.type, this.category, 'asc', '', '1');
    this.filter = {
      type: 'affiliate-directory',
      slug: this.slugFilter,
      search: this.searchByFullName,
      typeAssociate: this.type,
      company_category: this.category
    };
    localStorage.setItem('filters', JSON.stringify(this.filter));
  }

  onFilter() {
    const searchQuery = this.searchByFullName ? this.searchByFullName + '%' : '';
    this.getInmobiliarias(this.perPage, this.type, this.category, 'asc', searchQuery, this.filter?.page || '1');
    this.filter = {
      type: 'affiliate-directory',
      slug: this.slugFilter,
      search: this.searchByFullName,
      typeAssociate: this.type,
      company_category: this.category
    }
    localStorage.setItem('filters', JSON.stringify(this.filter));
  }

  async clearSearchInput() {
    localStorage.removeItem('filters');
    this.searchByFullName = '';
    await this.onFilter();
  }

  async onPageSelected(url: string) {
    const urlParams = new URLSearchParams(url);
    const pageParam = urlParams.get('page');
    const page = pageParam !== null ? +pageParam : 1;
    this.getInmobiliarias(this.perPage, this.type, this.category, 'asc', this.searchByFullName, page.toString());
    this.changeLocalStorage(page.toString(), this.slugFilter, this.filterType, this.type, this.category);
  }

  redirect(id: number) {
    this.router.navigate([`${this.slug}/${id}/editar`]);
  }

  can(action: TYPE_PERMISSIONS) {
    return this.permissionsService.can(action);
  };

  changeLocalStorage(page: string, slug: string, type: string, typeAssociate: string, category: string) {
    this.filter = {
      ...this.filter,
      slug: slug,
      page: page, // Ensure this is updated correctly
      type: type,
      typeAssociate: typeAssociate,
      company_category: category,
    };
    localStorage.setItem('filters', JSON.stringify(this.filter));
  }

  onCategorySelected(selectedCategory: string): void {
    if (!selectedCategory) {
      this.category = '';
    } else {
    const categoryMapping: { [key: string]: number } = {
      'Banca': 1,
      'Comercializador': 3,
      'Estudio de abogado': 5,
      'Estudio de arquitectura': 6,
      'Estudio de auditoría y tributación': 4,
      'Firma de gestión y gerencia de proyectos': 7,
      'Fondo de inversión': 2,
    };
    const selectedId = categoryMapping[selectedCategory];
    this.category = selectedCategory;
  }
    this.getInmobiliarias(this.perPage, this.type, this.category, 'asc', '', '1');
  }
  
  private initializeFilters(): void {
    localStorage.removeItem('filters');
    const storedFilters = localStorage.getItem('filters');

    if (storedFilters) {
      this.filter = JSON.parse(storedFilters);

      if (this.isFilterChanged()) {
        this.resetFilters();
        this.filter.page = '1';
      } else {
        this.applyFilters();
      }
      this.getInmobiliarias(
        this.perPage,
        this.filter.typeAssociate,
        this.filter.company_category || '',
        'asc',
        this.searchByFullName,
        this.filter.page
      );
    } else {
      this.getInmobiliarias(this.perPage, '', '', 'asc', '', '1');
    }
  }

  private isFilterChanged(): boolean {
    return this.filterType !== this.filter.type || this.slugFilter !== this.filter.slug;
  }

  private resetFilters(): void {
    localStorage.removeItem('filters');
    this.filterSearch = '';
    this.searchByFullName = '';
    this.getInmobiliarias(this.perPage, '', '', 'asc', '', '1');
  }

  private applyFilters(): void {
    this.searchByFullName = this.filter.search || '';
    this.filterPage = this.filter.page || '1';
  }

  protected readonly getLinkImage = getLinkImage;
  protected readonly TYPE_PERMISSIONS = TYPE_PERMISSIONS;

  // onExportExcel() {
  //   this.userService.
  //     getUserLoginExcel()
  //     .subscribe((data) => {
  //       const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  //       const url = window.URL.createObjectURL(blob);
  //       const a = document.createElement('a');
  //       a.href = url;
  //       a.download = `users-login.xlsx`;
  //       document.body.appendChild(a);
  //       a.click();
  //       window.URL.revokeObjectURL(url);
  //     });
  // }
}
