import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-dropdown-filter',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './affiliate-directory-asei-dropdown.component.html',
  styleUrl: './affiliate-directory-asei-dropdown.component.scss'
})
export class DropdownFilterComponent {
  @Input() currentCategory: string = 'Categoría';
  @Input() alwaysOpen: boolean = false;
  @Input() forceSelected: boolean = false;
  @Output() categorySelected = new EventEmitter<string>();
  isOpen: boolean = false;

  constructor(private cd: ChangeDetectorRef) {}

  toggleDropdown(event: MouseEvent): void {
    if (!this.alwaysOpen) {
      event.stopPropagation();
      this.isOpen = !this.isOpen;
      this.cd.detectChanges();
    }
  }

  selectCategory(category: string, event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.categorySelected.emit(category);

    if (!this.alwaysOpen) {
      this.isOpen = false;
      this.cd.detectChanges();
    }
  }

  @HostListener('document:click', ['$event'])
  closeDropdownIfClickedOutside(event: MouseEvent): void {
    if (!this.alwaysOpen && !this.isOpen) return;
    if (!this.alwaysOpen) {
      this.isOpen = false;
      this.cd.detectChanges();
    }
  }
}