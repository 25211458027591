import { Component } from '@angular/core';

@Component({
  selector: 'app-card-simple2',
  templateUrl: './card-simple2.component.html',
  styleUrls: ['./card-simple2.component.scss']
})
export class CardSimple2Component {

}
