@if (showNotifications) {
  <div class="notifications">
    <asei-notifications [notifications]="notifications"></asei-notifications>
  </div>
} @else {
  <div class="container_section_large">
    <div class="page">
      <asei-title-section
        title=" Últimas Noticias"
        [hasPermission]="false"
        version="one"
      ></asei-title-section>
      <app-section-filter
        [searchAll]="filterSearch"
        [dateStartFilter]="filterDateStartFormat ?? ''"
        [dateEndFilter]="filterDateEndFormat ?? ''"
        (filterEvent)="onFilter($event)"
        version="one"
      ></app-section-filter>
      <div class="card-list">
        @for (news of lastNews; track news.id) {
          <asei-card-info-master
            [id]="news.id"
            [title]="news.title"
            [dateInit]="getDateDDMMYYYY(news.date)"
            [link_image_thumbnail]="news.link_image_thumbnail"
            [linkImage]="news.linkImage"
            [environment]="getEnvironment(news.postType)"
            design="default"
            [canEdit]="false"
            [canDelete]="false"
          ></asei-card-info-master>
        }
      </div>
    </div>
  </div>
}
