<div class="page container_section_large" #top>
  <asei-title-section
    title="Asistencia Institucional"
    description="Servicio dirigido a los afiliados para recibir sus consultas sobre aspectos regulatorios, normativos o acciones de entidades públicas o privadas que impactan en el desarrollo inmobiliario."
    version="two"
  ></asei-title-section>
  <p-toast></p-toast>
  <asei-notifications [notifications]="notifications"></asei-notifications>
  <div class="main">
    @if (sendSuccess) {
      <div class="response-send">
        <p>Su consulta se ha enviado con éxito al área Institucional. En breve se contactarán con usted.</p>
        <div class="actions">
          <button class="btn primary" (click)="newConsult()">Crear nueva Consulta</button>
        </div>
      </div>
    }
    @if (!sendSuccess) {
      <div class="card personal-information">
        @if (technicalAssistance) {
          <form #form="ngForm">
            <div class="information">
              <div class="section">
                <h2>Ingresa tus datos</h2>
                <div class="input-box">
                  <app-input-group
                    title="Nombre"
                    type="text"
                    [(ngModel)]="technicalAssistance.name"
                    name="technicalAssistance.name"
                    [required]="true"
                    [maxlength]="validatorsInput.MAX_TEXT"
                  ></app-input-group>
                  <app-input-group
                    title="Apellidos"
                    type="text"
                    [(ngModel)]="technicalAssistance.lastname"
                    name="technicalAssistance.lastname"
                    [required]="true"
                    [maxlength]="validatorsInput.MAX_TEXT"
                  ></app-input-group>
                </div>
                <div class="input-box">
                  <app-input-group
                    name="technicalAssistance.documentNumber"
                    type="ruc"
                    title="RUC"
                    [required]="true"
                    [(ngModel)]="technicalAssistance.documentNumber"
                  ></app-input-group>
                  <app-input-group
                    name="technicalAssistance.businessName"
                    type="text"
                    title="Razón social"
                    [required]="true"
                    [(ngModel)]="technicalAssistance.businessName"
                    [maxlength]="validatorsInput.MAX_TEXT"
                  ></app-input-group>
                </div>
                <div class="input-box">
                  <app-input-group
                    name="technicalAssistance.job"
                    type="text"
                    title="Cargo dentro de la empresa"
                    [required]="true"
                    [(ngModel)]="technicalAssistance.job"
                    [maxlength]="validatorsInput.MAX_TEXT"
                  ></app-input-group>
                  <app-input-group
                    name="technicalAssistance.email"
                    type="email"
                    title="Correo"
                    [required]="true"
                    [(ngModel)]="technicalAssistance.email"
                  ></app-input-group>
                </div>
                <h2>¿Cuál es tu solicitud de asistencia?</h2>
                <div class="input-box">
                  <app-input-group
                    name="technicalAssistance.theme"
                    type="text"
                    title="Tema"
                    [required]="true"
                    [(ngModel)]="technicalAssistance.theme"
                    [maxlength]="validatorsInput.MAX_TEXT"
                  ></app-input-group>
                </div>
                <app-text-editor
                  title="Descripcion"
                  name="technicalAssistance.consultation"
                  [maxlength]="maxLengthConsultation"
                  [(ngModel)]="technicalAssistance.consultation"
                  [required]="true"
                ></app-text-editor>
                <app-upload-file
                  [imagePlaceholder]="technicalAssistance.urlFile"
                  (fileSelectedEmitter)="onImageSelected($event)"
                  (typeSelected)="onTypeSelected($event)"
                ></app-upload-file>
              </div>
            </div>
            <div class="actions">
              <button class="btn primary" (click)="sendEmail()">Enviar Consulta</button>
            </div>
          </form>
        }
      </div>
    }
  </div>
</div>
<!-- ($error)="changeError($event)" -->
