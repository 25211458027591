import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { getLinkImage } from 'src/app/helpers/converters.helper';
import { FilesService } from 'src/app/services/files.service';

@Component({
  selector: 'ASEI-upload-avatar',
  templateUrl: './asei-upload-avatar.component.html',
  styleUrls: ['./asei-upload-avatar.component.scss'],
})
export class AseiUploadAvatarComponent implements OnInit {
  @ViewChild('avatarInput') avatarInput!: ElementRef;

  @Input() avatarUrl: string = '';
  @Input() size: string = 'md';
  @Input() name = '';
  @Input() disabled:boolean = false;
  @Output() $change = new EventEmitter<string>();
  @Output() $error = new EventEmitter<string>();

  urlOld = '';
  urlCurrent = '';
  urlBaseFile = 'devapi-intranet.asei.com.pe';

  protected readonly getLinkImage = getLinkImage;

  constructor(private filesService: FilesService) {
  }

  ngOnInit(): void {
    const urlLocalStorage = localStorage.getItem('avatarUrl');
    if(urlLocalStorage){
      this.deleteImageCurrent(urlLocalStorage??'');
    }
    this.urlCurrent = this.avatarUrl ?? '';
  }

  selectImage() {
    this.avatarInput.nativeElement.click();
  }

  onFileChange(event: any): void {
    const file = event.target.files[0];
    const urlTemp = this.avatarUrl;
    const maxSize = 2 * 1024 * 1024;
    this.avatarUrl = '';
    if (file) {
      const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png'];
      if (allowedTypes.includes(file.type) && file.size <= maxSize) {
        this.filesService
          .uploadImage(file)
          .pipe(
            catchError((error) => {
              this.$error.emit('Hubo un problema al subir la imagen');
              this.avatarUrl = urlTemp;
              return throwError(() => error);
            })
          )
          .subscribe((res) => {
            if (res.status === 200 || res.status === 201) {
              this.urlOld = this.urlCurrent;
              this.urlCurrent = res.body.path;
              this.avatarUrl = this.urlCurrent;
              this.$change.emit(this.avatarUrl);

              if (this.urlOld) {
                this.filesService
                  .DeleteImage(this.getFileName(this.urlOld))
                  .subscribe();
              }
              localStorage.setItem('avatarUrl', this.urlCurrent);
            }
          });

        const newAvatarUrl = 'nueva_url_de_la_imagen_desde_la_api';
        this.avatarUrl = newAvatarUrl;
        this.$change.emit(newAvatarUrl);
      } else {
        this.$error.emit('formato no valido solo imagen y maximo 2mb');
      }
    }
  }
  getFileName(fileName: string): string {
    if (this.urlBaseFile === fileName.split('/')[2]) {
      return fileName.split('/')[6];
    }
    if (fileName.split('/')[0] === 'uploads') {
      return fileName.split('/')[2];
    }
    return '';
  }
  getLetter() {
    if (this.name) {
      return this.name.charAt(0).toUpperCase();
    }
    return ''; // Retorna una cadena vacía o algún valor predeterminado si `this.name` es `null`
  }
  deleteImageCurrent(urlLocalStorage?: string) {
    this.urlOld = urlLocalStorage ? urlLocalStorage : this.urlCurrent;
    this.filesService
      .DeleteImage(this.getFileName(this.urlOld))
      .subscribe({
        next: (data: any) => {
            if (data.message == "File successfully removed." && !urlLocalStorage) {
              this.avatarUrl = '';
              this.urlCurrent = '';
              this.urlOld = '';
              localStorage.removeItem('avatarUrl');
              this.$change.emit(this.avatarUrl);
            }
            if(data.message == "File successfully removed." && urlLocalStorage){
              localStorage.removeItem('avatarUrl');
              this.urlOld = '';
            }
          },
          error: () => {}
      });
  }
}
