@if (paginatorData && paginatorData.meta.last_page > 1) {
  <div class="pagination">
    @for (link of paginatorData.meta.links; track link; let first = $first; let last = $last) {
      @if ((design === 'complete') || (design === 'simple' && (first || last))) {
        @if (!(link.label.includes('Previous') && !paginatorData.links.prev) &&
             !(link.label.includes('Next') && !paginatorData.links.next)) {
          <a [ngClass]="{
            'btn': true,
            'btn-active': link.active,
            'btn-inactive': !link.active,
            'btn-actions': link.label.includes('Next') || link.label.includes('Previous')
            }"
            (click)="selectPageNumber(link)"
            >
            @if (link.label.includes('Next') || link.label.includes('Previous')) {
              <span>
                <mat-icon [innerHTML]="link.label.includes('Next') ? 'navigate_next' : 'navigate_before'"></mat-icon>
              </span>
            }
            @if (!(link.label.includes('Next') || link.label.includes('Previous'))) {
              <span [innerHTML]="link.label"></span>
            }
          </a>
        }
      }
    }
  </div>
}
