@if (event.registrationLink) {
  <button (click)="onRegister()" class="btn primary">
    {{event.textButtonRegister || "Registrate" }}
  </button>
}
@if (event.phone) {
  <button (click)="onConfirmAssistance()" class="btn primary">
    Confirmar Asistencia
  </button>
}
<button (click)="onAddCalendar()" class="btn secondary">
  Agregar al calendario
</button>
