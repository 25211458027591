<div class="dropdown" (click)="$event.stopPropagation()">
  <button class="dropbtn" (click)="toggleDropdown($event)"
          [ngClass]="{'selected': forceSelected || (currentCategory && currentCategory !== 'Categoría')}">
    {{ currentCategory ? currentCategory : 'Categoría' }}
  </button>
  <div class="dropdown-content" *ngIf="alwaysOpen ? true : isOpen">
    <a (click)="selectCategory('', $event)">Todos</a>
    <a (click)="selectCategory('Banca', $event)">Banca</a>
    <a (click)="selectCategory('Comercializador', $event)">Comercializador</a>
    <a (click)="selectCategory('Estudio de abogado', $event)">Estudio de abogado</a>
    <a (click)="selectCategory('Estudio de arquitectura', $event)">Estudio de arquitectura</a>
    <a (click)="selectCategory('Estudio de auditoría y tributación', $event)">Estudio de auditoría y tributación</a>
    <a (click)="selectCategory('Firma de gestión y gerencia de proyectos', $event)">Firma de gestión y gerencia de proyectos</a>
    <a (click)="selectCategory('Fondo de inversión', $event)">Fondo de inversión</a>
  </div>
</div>
