import { Module } from "../interfaces/module.interface";
import { Page } from "../interfaces/page.interface";
import { SubPage } from "../interfaces/sub-page.interface";

export const findModuleOrPageBySlug = (modules: Module[], slug: string): Module | Page | SubPage | null => {
  for (const module of modules) {
    if (module.slug === slug) {
      return module;
    }
    for (const page of module.pages) {
      if (page.slug === slug) {
        return page;
      }
      if (page.subPages) {
        for (const subPage of page.subPages) {
          if (subPage.slug === slug) {
            return subPage;
          }
        }
      }
    }
  }
  return null;
}
