import {Component, ViewChild} from '@angular/core';
import {ConfirmationService, MessageService, TreeNode} from "primeng/api";
import {ModuleService} from "../../services/module.service";
import {PageService} from "../../services/page.service";
import {Module, ModuleReorderRequest} from "../../../../interfaces/module.interface";
import {Page, PageReorderRequest} from "../../../../interfaces/page.interface";
import {catchError, map, Observable, of} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import { CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { MatTable } from '@angular/material/table';
import { CdkColumnDef } from '@angular/cdk/table';

@Component({
  selector: 'app-asei-module-page',
  templateUrl: './module.component.html',
  styleUrls: ['./module.component.scss'],
  providers: [MessageService, ConfirmationService, CdkColumnDef]
})
export class ModulePageComponent {
  // @ViewChild('table', {static: true}) table!: MatTable<any>;
  @ViewChild(MatTable) table!: MatTable<any>;
  displayedColumns: string[] = ['name', 'description'];
  dataTree!: any[];
  reorderTree!: any[];
  reorderActive: boolean = false;
  reorderTitle: string = ''
  slug!: string

  constructor(
    private moduleService: ModuleService,
    private router: Router,
    private route: ActivatedRoute,
    private pageService: PageService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
  ) {
  }

  async ngOnInit() {
    this.route.url.subscribe(segments => {
      this.slug = segments.join('/');
    });
    this.getCompleteModules()
  }

  getCompleteModules() {
    this.getModules().subscribe(
      {
        next: modules => {
          this.dataTree = this.convertToTree(modules);
        },
      }
    );
  }

  getModules(): Observable<Module[]> {
    return this.moduleService.getAll().pipe(
      map(response => response?.body?.data),
      catchError(error => {
        console.error(error);
        return of([]);
      })
    );
  }

  reorderPages(data: any) {
    this.startReorder(data.node.children, data.node.data.name)
  }

  reorderModules() {
    this.startReorder(this.dataTree, 'Módulos')
  }

  startReorder(tree: TreeNode[], title: string) {
    this.reorderTree = [...tree]
    this.reorderTitle = title
    this.reorderActive = true;
  }

  finishReorder() {
    this.reorderTree = []
    this.reorderTitle = ''
    this.reorderActive = false;
  }

  async saveReorder() {
    const reorderTree = [...this.reorderTree];

    if (this.reorderTitle === 'Módulos') {
      this.handleModuleReorder(reorderTree);
    } else {
      const originalTree = [...this.dataTree];
      const moduleNameReorder = this.reorderTitle;
      this.handlePageReorder(reorderTree, originalTree, moduleNameReorder);
    }
    this.finishReorder();
  }

  getModulesAndSetInLocalStorage() {
    this.getModules().subscribe(
      {
        next: modules => {
          this.moduleService.setModuleInLocalStorage(modules);
        },
      }
    );
  }

  private handleModuleReorder(reorderTree: TreeNode[]) {
    const payload: ModuleReorderRequest = this.convertTreeNodeToModuleReorderRequest(reorderTree);
    this.moduleService.reorder(payload).subscribe(
      {
        next: response => {
          this.dataTree = reorderTree;
          this.getModulesAndSetInLocalStorage()
          this.showSuccess('se ha reordenado los módulos correctamente')
        },
        error: error => {
          this.showError('Oops! Algo no salió bien')
        }
      }
    );
  }

  private handlePageReorder(reorderTree: TreeNode[], originalTree: TreeNode[], moduleNameReorder: string) {
    const payload: PageReorderRequest = this.convertTreeNodeToPageReorderRequest(reorderTree);
    this.pageService.reorder(payload).subscribe(
      {
        next: response => {
          const nodeIndex = originalTree.findIndex(item => item.data.name === moduleNameReorder);
          if (nodeIndex > 0) {
            originalTree[nodeIndex].children = reorderTree;
            this.dataTree = originalTree;
            this.getModulesAndSetInLocalStorage()
            this.showSuccess('se ha reordenado las paginas correctamente')
          }
        },
        error: error => {
          this.showError('Oops! Algo no salió bien')
        }
      }
    );
  }
  addPage(rowNode: any) {
    // LEVEL = 0 ==> MODULO
    // LEVEL = 1 ==> PAGE
    const {level} = rowNode
    if (level === 0) {
      const {id} = rowNode.node.data;
      const url = `${this.slug}/${id}/paginas/nuevo`;
      this.router.navigateByUrl(url);
    }
  }
  edit(rowNode: any) {
    // LEVEL = 0 ==> MODULO
    // LEVEL = 1 ==> PAGE
    const {level} = rowNode
    if (level == 0) {
      const {id} = rowNode.node.data;
      const url = `${this.slug}/${id}/editar`;
      this.router.navigateByUrl(url);
    }
    if (level === 1) {
      const {id: idParent} = rowNode.node.parent.data;
      const {id} = rowNode.node.data;
      const url = `${this.slug}/${idParent}/paginas/${id}/editar`;
      this.router.navigateByUrl(url);
    }
  }

  delete(rowNode: any) {
    // LEVEL = 0 ==> MODULO
    // LEVEL = 1 ==> PAGE
    const {level} = rowNode
    const {id, name} = rowNode.node.data;

    const header: string = level === 0
      ? `¿Está seguro de eliminar el módulo ${name}?`
      : `¿Está seguro de eliminar la pagina ${name}?`
    const message: string = level === 0
      ? '(*) Al eliminar el módulo, se eliminará todas las paginas asociadas.'
      : ``
    const messageConfirm: string = level === 0
      ? 'se ha eliminado el módulo correctamente'
      : `se ha eliminado la pagina correctamente`

    this.confirmationService.confirm({
      header: header,
      message: message,
      accept: () => {
        const service = level === 0
          ? this.moduleService.delete(id)
          : this.pageService.delete(id)
        service.subscribe({
          next: response => {
            this.getCompleteModules()
            this.getModulesAndSetInLocalStorage()
            this.showSuccess(messageConfirm)
          },
          error: error => {
            this.showError('Oops! Algo no salió bien')
          }
        })
      },
      reject: () => {
      }
    });
  }

  showSuccess(message: string) {
    this.messageService.add({severity: 'success', summary: 'Operación exitosa', detail: message, life: 3000});
  }

  showError(message: string) {
    this.messageService.add({severity: 'error', summary: 'Error', detail: message, life: 3000});
  }

  convertToTree(modules: Module[]): TreeNode[] {
    const tree: TreeNode[] = [];

    const findNodeById = (id: number, nodes: TreeNode[]): TreeNode | undefined => {
      for (const node of nodes) {
        if (node.data.id === id) {
          return node;
        }
        if (node.children) {
          const found = findNodeById(id, node.children);
          if (found) {
            return found;
          }
        }
      }
      return undefined;
    };

    const buildTree = (nodeData: Module | Page, parentNode?: TreeNode) => {
      const node: TreeNode = {
        data: {id: nodeData.id, name: nodeData.name, description: nodeData.description, component:nodeData.component},
      };

      if (parentNode) {
        parentNode.children = parentNode.children || [];
        parentNode.children.push(node);
      } else {
        tree.push(node);
      }

      if ('pages' in nodeData && nodeData.pages && nodeData.pages.length > 0) {
        for (const page of nodeData.pages) {
          buildTree(page, node);
        }
      }
    };

    for (const module of modules) {
      buildTree(module);
    }

    return tree;
  }

  convertTreeNodeToModuleReorderRequest(tree: TreeNode[]): ModuleReorderRequest {
    const request: ModuleReorderRequest = {
      modules: []
    }
    // Iterar sobre cada nodo del árbol y agregarlo a modules
    tree.forEach(node => {
      const module = {
        id: node.data.id,
        name: node.data.name,
      };
      request.modules.push(module);
    });
    return request
  }

  convertTreeNodeToPageReorderRequest(tree: TreeNode[]): PageReorderRequest {
    const request: PageReorderRequest = {
      pages: []
    }
    // Iterar sobre cada nodo del árbol y agregarlo a modules
    tree.forEach(node => {
      const module = {
        id: node.data.id,
        name: node.data.name,
      };
      request.pages.push(module);
    });
    return request
  }

  dropTable(event: CdkDragDrop<any[]>) {
    const prevIndex = this.dataTree.findIndex((d) => d === event.item.data);
    moveItemInArray(this.dataTree, prevIndex, event.currentIndex);
    this.table.renderRows();
  }

  drop(event: CdkDragDrop<string>) {
    const previousIndex = this.reorderTree.findIndex(d => d === event.item.data);

    moveItemInArray(this.reorderTree, previousIndex, event.currentIndex);
    this.table.renderRows();
  }
}
