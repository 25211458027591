import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {
  getDateDDMMYYYY,
  getHourInFormat12HRS,
  getDatetimeUTC,
  getLinkImage
} from "../../helpers/converters.helper";
import {combineLatest} from "rxjs";
import {EventService} from "../events/services/event.service";
import * as moment from "moment";
import Event from "../events/interfaces/event.interface";
import {PermissionsService} from 'src/app/services/permissions.service';
import {TYPE_PERMISSIONS} from 'src/app/types/constants/permission.enum';
import {EventType} from "../events/interfaces/event-type.interface";
import createGoogleCalendarEvent from 'src/app/helpers/calendar';

@Component({
  selector: 'app-asei-calendar-page',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarPageComponent {
  nextEvent!: Event;
  nextEvents!: Event[];

  typePermission: typeof TYPE_PERMISSIONS = TYPE_PERMISSIONS;

  day: string = '';
  month: string = '';
  dayEnd: string = '';
  monthEnd: string = '';

  constructor(
    public router: Router,
    private readonly eventService: EventService,
    private permissionsService: PermissionsService,
  ) {

  }

  ngOnInit() {
    this.getEventsCards();
  }

  getEventsCards() {
    let dateStart = moment().format('YYYY-MM-DD');
    combineLatest([
      this.eventService.getAll('', dateStart, '', '', '', 4, 'summary', 'asc')
    ]).subscribe(([resEvents]) => {
      if (resEvents.ok) {
        if (resEvents.body.data.length > 0) {
          let events = resEvents.body.data;
          let firstEvent = events.shift()
          this.nextEvent = firstEvent;
          [this.day, this.month] = this.getDayAndMonth(this.nextEvent.dateInit);
          if (this.nextEvent.dateEnd) {
            [this.dayEnd, this.monthEnd] = this.getDayAndMonth(this.nextEvent.dateEnd);
          }
          this.nextEvents = events;
        }
      }
    });
  }

  getDayAndMonth(date: string) {
    moment.locale('es');
    const getDateFormat = moment(getDateDDMMYYYY(date), 'DD/MM/YYYY');
    const day = getDateFormat.date().toString();
    const month = getDateFormat.format('MMMM').toString();
    return [day, month];
  }

  onAddCalendar() {
    const dateInit = getDatetimeUTC(this.nextEvent.dateInit, this.nextEvent.timeInit);
    const dateEnd = getDatetimeUTC(
      this.nextEvent.dateEnd ? this.nextEvent.dateEnd : this.nextEvent.dateInit,
      this.nextEvent.timeEnd ? this.nextEvent.timeEnd : this.nextEvent.timeInit
    );

    createGoogleCalendarEvent( this.nextEvent.title, '', dateInit, dateEnd )
  }

  onConfirmAssistance() {
    const urlWhatsapp = new URL('http://wa.me');
    urlWhatsapp.pathname = '+51' + this.nextEvent.phone;
    urlWhatsapp.searchParams.append("text", `Hola, confirmo mi asistencia para el evento ${this.nextEvent.title}.`);
    window.open(urlWhatsapp.href, "_target");
  }

  onRegister() {
    window.open(this.nextEvent.linkRegister, '_target')
  }

  getEnvironment(eventType: EventType): string {
    return eventType.name.replace(/\./g, "/")
  }

  navigateTo(meet: Event) {
    const env = meet.eventType.name.replace(/\./g, "/")
    const url = `${env}/${meet.id}`; // Ruta dinámica de tu aplicación
    this.router.navigate([url]);
  }

  getDateDDMMYYYY = getDateDDMMYYYY;
  getHourInFormat12HRS = getHourInFormat12HRS;
  protected readonly Event = Event;
  protected readonly getLinkImage = getLinkImage;
}
