@if(form){
  <div class="page" #top>
    <app-header-page
      [title]=titlePage
      subtitle="Completa la información correspondiente al informe."
    ></app-header-page>
    <p-toast></p-toast>
    <asei-notifications [notifications]="notifications"></asei-notifications>
    <div class="main">
      <div class="card personal-information">
        <form (ngSubmit)="onSubmit()">
          <div class="information">
            <div class="section">
              <div class="input-box">
                <app-input-group
                  title="Titulo"
                  [value]="form.get('title')?.value"
                  (valueChange)="form.get('title')?.setValue($event)"
                  [invalid]="(form.get('title')?.invalid || false)"
                  [errorMessage]="getErrorMessage('title',form)"
                  [maxlength]="validatorsInput.MAX_TEXT"
                  [counter]="true"
                  type="text"
                  [required]="true"
                ></app-input-group>
              </div>
              @if( showDataMonthEvent ) {
              <div class="input-box">
                <app-input-group
                  title="Mes del evento"
                  [value]="form.get('month_event')?.value"
                  (valueChange)="form.get('month_event')?.setValue($event)"
                  [invalid]="(form.get('month_event')?.invalid || false)"
                  [errorMessage]="getErrorMessage('month_event',form)"
                  type="month"
                  [required]="true"
                ></app-input-group>
              </div>
              }
              <div class="input-box">
                <app-textarea-group
                  title="Descripcion"
                  [value]="form.get('description')?.value"
                  (valueChange)="form.get('description')?.setValue($event)"
                  [invalid]="(form.get('description')?.invalid || false)"
                  [errorMessage]="getErrorMessage('description',form)"
                  [maxlength]="validatorsInput.MAX_TEXT_DESCRIPTION"
                  [counter]="true"
                ></app-textarea-group>
              </div>
              <app-upload-file
                [imagePlaceholder]="report ? getImageOThumbnail(report.file, report.fileThumbnail) : ''"
                (fileSelectedEmitter)="onImageSelected($event)"
                accept="image/*, .pdf"
              ></app-upload-file>
            </div>
          </div>
          <div class="actions">
            <a class="btn secondary" (click)="backPage()">Cancelar</a>
            <button class="btn primary" type="submit">Guardar</button>
          </div>
        </form>
      </div>
    </div>
  </div>
}
