<div [class]="isLabel ? 'input-group input-group-padding' : 'input-group input-group-no-padding'">
  @if (isLabel) {
    <label class="{{ colorLabelInverse ? 'color_inverse_label': '' }}">{{title}}:@if (required) {
      <span style="font-size: 18px;">*</span>
    }</label>
  }
  <div class="input" [ngClass]="{'input-invalid': invalid, 'input-disabled':disabled}">
    @if (type != 'dateEvent' && type != 'date'){
      <input
        [name]="name"
        [(ngModel)]="value"
        (ngModelChange)="onInputChange($event)"
        [type]="getInputType()"
        [disabled]="disabled"
        [required]="required"
        [ngClass]="invalid ? 'has-error' : 'has-success'"
        [maxlength]="type != 'url' ? maxlength : null"
        #inputElement
      />
      @if (type=='password' || type=='currentPassword') {
        <div class="icon-container--password" (click)="onInputClick($event)">
          <i class="pi pi-eye"></i>
        </div>
      }
    }@else {
      <div class="datepicker" style="width: 100%;">
        <p-calendar
          [name]="name"
          [(ngModel)]="value"
          (ngModelChange)="onInputChange($event)"
          [disabled]="disabled"
          [required]="required"
          [maxlength]="maxlength"
          dateFormat="dd/mm/yy"
          class="w-full"
          placeholder="dd/mm/yyyy"
          [showButtonBar]="true"
          [locale]="es"
        ></p-calendar>
      </div>
    }
  </div>
  @if (counter) {
    <div class="margin-custom">Caracteres actuales: <span class="color-counter">{{ currentLength }}</span>, max: {{ maxlength }}</div>
  }
  @if (invalid) {
    <div class="invalid">
      {{errorMessage}}
    </div>
  }
</div>
