import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import {environment} from 'src/environments/environment';
const URL = environment.url + '/api/v1';
@Injectable({
  providedIn: 'root'
})
export class AssistantService {
  private httpOptions = {};

  constructor(private http: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({
        "Accept": "application/json",
        "Content-Type": "application/json"
      }),
      observe: 'response' as const // Asegura que el tipo de 'observe' sea correcto
    }
  }

  create(companyId: number, assistant: any) {
    const url = `${URL}/companies/${companyId}/assistant`;
    return this.http.post<any>(url, JSON.stringify(assistant), this.httpOptions).pipe(
      map(response => response)
    );
  }

  update(companyId: number, assistant: any) {
    const url = `${URL}/companies/assistant/${assistant.id}`;
    return this.http.put<any>(url, JSON.stringify(assistant), this.httpOptions).pipe(
      map(response => response)
    );
  }

  delete(assistant: any) {
    const url = `${URL}/companies/assistant/${assistant.id}`;
    return this.http.delete<any>(url, this.httpOptions).pipe(
      map(response => response)
    );
  }
}
