@if (hasPermissionView) {
  <mat-list-item
    [routerLink]="path"
    routerLinkActive="list-item-active"
    (click)="toggleDropdown()"
  >
    <div class="content-box">
      <i [class]="icon"></i>
      <span matLine>{{ title }}</span>
      <mat-icon class="dropdown-icon">{{ dropdown ? 'expand_less' : 'expand_more' }}</mat-icon>
    </div>
  </mat-list-item>
  @if (dropdown) {
    <div class="dropdown-content">
      @for (page of items;track page.id) {
        @if (page.subPages?.length > 0) {
          @if (page.hasPages) {
            <menu-sub-item-dropdown
              [title]="page.name"
              [items]="page.subPages"
              [icon]="page.icon"
              [path]="page.slug"
              [dropdown]="page.dropdown || false"
              (clicked)="emitLinkClicked()"
              [hasPermissionView]="page.hasPermission.view"
            />
          } @else {
            <menu-item-link
              [title]="page.name"
              [path]="page.slug"
              (clicked) = "onClick()"
            />
          }
        } @else {
          <menu-item-link
            [title]="page.name"
            [path]="page.slug"
            (clicked) = "onClick()"
          />
        }
      }
    </div>
  }
}
