import {Component, OnInit} from '@angular/core';
import {TYPE_PERMISSIONS} from "../../../../types/constants/permission.enum";
import {getLinkImage} from "../../../../helpers/converters.helper";
import {PermissionsService} from "../../../../services/permissions.service";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {firstValueFrom} from "rxjs";
import {ConfigurationService} from "../../../../services/configuration.service";
import UserDataLogin from "../../../../interfaces/user-data-login.interface";
interface Item {
  title: string;
  content: string | null;
}
@Component({
  selector: 'reports-home',
  templateUrl: './asei-directory-show-page.component.html',
  styleUrls: ['./asei-directory-show-page-component.scss'],
})
export class AseiDirectoryShowPageComponent implements OnInit {
  data!:Item[]
  canEdit:boolean = false;
  constructor(
    private configurationService: ConfigurationService,
    private sanitizer: DomSanitizer,
    private permissionsService: PermissionsService,
  ) {
  }

  async ngOnInit() {
    const directory:Item[] = await this.getDirectoryAsei();
    this.data = directory;
    const data = this.getUserData();
    const isAdminOrWriter = data?.roles.some((rol) => rol.name === 'admin' || rol.name === 'writer');
    this.canEdit = isAdminOrWriter || false;
  }
  getUserData(): UserDataLogin | undefined {
    const jsonData = localStorage.getItem('UserData');
    const userData = jsonData ? JSON.parse(jsonData) : undefined;
    return userData.data;
  }
  async getDirectoryAsei() {
    try {
      const response = await firstValueFrom(this.configurationService.getDirectoryAsei())
      return JSON.parse(response.data.value)
    } catch (error) {
      return null;
    }
  }
  getSafeContent(content: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(content);
  }

  can(action: TYPE_PERMISSIONS) {
    return this.permissionsService.can(action);
  };

  protected readonly TYPE_PERMISSIONS = TYPE_PERMISSIONS;
  protected readonly getLinkImage = getLinkImage;
}
