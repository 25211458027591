<div class="page container_section_large" #top>
  <asei-title-section
    [title]="titlePage"
    description="Completa la información correspondiente a la noticia."
    version="two"
  ></asei-title-section>
  <p-toast></p-toast>
  <asei-notifications [notifications]="notifications"></asei-notifications>
  <div class="main">
    <div class="card personal-information">
      @if (_news) {
        <form #form="ngForm">
          <div class="information">
            <div class="section">
              <div class="input-box">
                <app-input-group
                  title="Titulo"
                  [(ngModel)]="_news.title"
                  [value]="_news.title"
                  type="text"
                  name="news_title"
                  [required]="true"
                  [maxlength]="validatorsInput.MAX_TEXT"
                  [counter]="true"
                ></app-input-group>
                <app-input-group
                  title="Fecha"
                  name="news_date"
                  type="date"
                  [(ngModel)]="_news.date"
                  [required]="true"
                ></app-input-group>
              </div>
              <app-text-editor
                title="Contenido"
                name="news_content"
                [value]="_news.content"
                [(ngModel)]="_news.content"
              ></app-text-editor>
              <div class="section-links">
                <div class="header">
                  <h2>Enlaces:</h2>
                  <span class="icon icon-add" (click)="addLinks()"
                  ><mat-icon>add</mat-icon></span
                  >
                </div>
                @for (link of _news.links; track link; let i = $index) {
                  <div class="container-inputs">
                    <div class="input-box">
                      <app-input-group
                        title=""
                        name="_news_links.{{i}}"
                        type="url"
                        [(ngModel)]="link.value"
                        [required]="true"
                      ></app-input-group>
                    </div>
                    <span class="icon icon-remove" (click)="removeLinks(i)"
                    ><mat-icon>remove</mat-icon></span
                    >
                  </div>
                }
              </div>
              <app-upload-file
                [imagePlaceholder]="news? getImageOThumbnail(news.linkImage, news.link_image_thumbnail): ''"
                (fileSelectedEmitter)="onImageSelected($event)"
                (typeSelected)="onTypeSelected($event)"
              ></app-upload-file>
            </div>
          </div>
          <div class="actions">
            <a class="btn secondary" (click)="backPage()">Cancelar</a>
            <button class="btn primary" (click)="save()">Guardar</button>
          </div>
        </form>
      }
    </div>
  </div>
</div>
