import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {catchError, forkJoin, map, of, switchMap, tap, throwError} from 'rxjs';
import {defaultCompany, defaultEmployees, defaultContacts, defaultOthers} from 'src/app/helpers/affiliate-directory.helper';
import {getLinkImage} from 'src/app/helpers/converters.helper';
import {CompanyEmployee} from 'src/app/interfaces/company-employee.interface';
import {CompanyService} from 'src/app/pages/affiliate-directory-asei/services/company.service';
import {FilesService} from 'src/app/services/files.service';
import {PermissionsService} from 'src/app/services/permissions.service';
import {TYPE_PERMISSIONS} from 'src/app/types/constants/permission.enum';
import UserDataLogin from "../../../../interfaces/user-data-login.interface";
import { ConfirmationService, MessageService } from 'primeng/api';
import VALIDATORS_INPUT from 'src/app/helpers/validators-input.helper';
import { getErrorMessage } from 'src/app/helpers/validators.helper';
import { AssistantService } from '../../services/assistant.service';
import { ContactService } from '../../services/contact.service';
import { LegalRepresentantiveService } from '../../services/legal-representantive.service';
import { UserAdditionalService } from '../../services/user-additional.service';
import { EmployeeTypesService } from '../../services/employee-types.service';

@Component({
  selector: 'app-affiliate-directory-form-page',
  templateUrl: './affiliate-directory-form-page.component.html',
  styleUrls: ['./affiliate-directory-form-page.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class AffiliateDirectoryFormPageComponent implements OnInit {
  @ViewChild('form', {static: false}) public form!: NgForm;
  @ViewChild('top') topElement!: ElementRef;
  @ViewChild('additionalUser') addUserElement!: ElementRef;
  titlePage: string = "";
  company!: CompanyEmployee;
  companyCreate: any;
  companyUpdate: any;
  createdUsers: any[] = [];
  validateUsers: any[] = [];
  firstLetter: string = "";
  isEditMode: boolean = false;
  notifications: Map<string, string> = new Map<string, string>();
  selectOptionsType = [
    {value: 1, label: 'Asociados'},
    {value: 2, label: 'Aliados estratégicos'},
  ]

  typePermission: typeof TYPE_PERMISSIONS = TYPE_PERMISSIONS;
  disabledField: boolean = false;
  sizeAvatar = 'md'
  protected readonly getLinkImage = getLinkImage;

  groupedEmployees!: any[];
  selectedEmployees!: any[];

  nameValidate: string | null = null;
  businessNameValidate: string | null = null;
  documentNumberValidate: string | null = null;
  errorsValidate: any = {};

  isRepresentative: any;
  isAdmin: any;

  employeeTypes: any[] = [];

  errorMessages: any[] = [];

  legalRepresentativeIsEdit = false;
  assistantIsEdit = false;
  contactIsEdit = false;
  userAdditionalIsEdit = false;

  legalRepresentative: string = '';
  assistant: string = '';
  contact: string = '';
  userAdditional: string = '';



  constructor(
    private route: ActivatedRoute,
    private inmobiliariaService: CompanyService,
    private legalRepresentantiveService: LegalRepresentantiveService,
    private assistantService: AssistantService,
    private contactService: ContactService,
    private userAdditionalService: UserAdditionalService,
    private permissionsService: PermissionsService,
    private router: Router,
    private filesService: FilesService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private readonly employeeTypesService: EmployeeTypesService,
  ) {
    // if(this.can(this.typePermission.CREATE_EMPLOYESS_DIRECTORY_AFFILIATES)){
    //   this.router.navigate([`/`]);
    // }
  }

  ngOnInit(): void {
    this.route.paramMap
      .pipe(
        switchMap((params) => {
          const paramId = params.get('id');
          if (this.router.url.includes('nuevo')) {
            this.titlePage = 'Nuevo Afiliado';
            const defaultAffiliateDirectory: CompanyEmployee = defaultCompany();
            return of(defaultAffiliateDirectory);
          } else if (parseInt(paramId || '') && paramId) {
            this.titlePage = 'Editar Afiliado';
            return this.inmobiliariaService.getById(paramId).pipe(
              catchError((err) => {
                this.notifications.set(
                  'getAffiliate',
                  err.error.message
                );
                return throwError(() => err);
              }),
              map((res) => {
                if (res && res.body && res.body.data) {
                  return res.body.data;
                }
              })
            );
          }
          return throwError(() => new Error('Invalid ID'));
        })
      )
      .subscribe((res) => {
        const parseNews = res;
        this.company = parseNews;
        const data = this.getUserData();
        const isAdminOrWriter = data?.roles.some((rol) => rol.name === 'admin' || rol.name === 'writer');
        const email = data?.email;
        const representatives = this.company.employees.legalRepresentatives;
        this.isRepresentative = representatives.some((r: any) => r.email === email);
        this.isAdmin = data?.roles.some((rol) => rol.name === 'admin');

        const canUpdateData = isAdminOrWriter || this.isRepresentative;

        if (!canUpdateData) {
          this.disabledField = true;
          this.titlePage = '';
          this.sizeAvatar = 'lg'
        }
      });

      this.route.paramMap.subscribe(params => {
        this.isEditMode = !params.has('id');
      });

      this.employeeTypesService.findAll().subscribe({
        next: (employeeTypes: any) => {
          this.employeeTypes = employeeTypes;
        }
      });
  }

  getUserData(): UserDataLogin | undefined {
    const jsonData = localStorage.getItem('UserData');
    const userData = jsonData ? JSON.parse(jsonData) : undefined;
    return userData.data;
  }

  getErrorMessages(field: string, index: number): string | null {
    const key = `${field}.${index}.email`;
    return this.errorsValidate[key]?.[0] || null;
  }

  canSave(): boolean {
    return Boolean(this.form && this.form.valid);
  }

  validateForm(company: any): boolean {
    if (!company.employees?.others?.length) {
      return true;
    }

    if (!company.employees?.legalRepresentatives?.length) {
      this.notifications.set(
        'createAffiliate',
        'Datos incompletos, revise los campos de los usuarios!'
      );
      return false;
    }

    return !company.employees.others.some((additionalUser: any) => {
      if (
        company.employees.legalRepresentatives[0]?.email &&
        additionalUser?.email &&
        company.employees.legalRepresentatives[0].email === additionalUser.email
      ) {
        this.notifications.set(
          'createAffiliate',
          'Hay datos duplicados, revise los campos de los usuarios!'
        );
        return true;
      }
      return false;
    });
  }

  save(event: Event) {
    const isValidate = this.validateForm(this.company);
    if (isValidate) {
      if (!this.company.id) {
        this.inmobiliariaService.create(this.company)
        .subscribe({
          next: (response: any) => {
            this.nameValidate = null;
            this.businessNameValidate = null;
            this.documentNumberValidate = null;
            this.company.id = response.body.company.id;
            this.companyCreate = JSON.parse(JSON.stringify(this.company.employees));
            this.saveEmployees(response.body.company.id, this.companyCreate);
          },
          error: (error) => {
            this.notifications.set(
              'createAffiliate',
              'Algunos campos obligatorios están vacíos o contienen datos incorrectos.'
            );

            const { name, businessName, documentNumber } = error.error.errors
            this.nameValidate = name;
            this.businessNameValidate = businessName;
            this.documentNumberValidate = documentNumber;
            this.errorsValidate = error.error.errors;
          }
        });
      } else {
        this.inmobiliariaService.getById(this.company.id).subscribe({
          next: (response) => {
            const company = response.body.data.employees;
            this.validateUsers.push(...company.legalRepresentatives.map((empl: any) => empl));
            this.validateUsers.push(...company.others.map((empl: any) => empl));
          },
        });
        this.inmobiliariaService.update(this.company)
        .subscribe({
          next: (response) => {
            this.companyUpdate = JSON.parse(JSON.stringify(this.company.employees));
            this.getNewDataEmployees(this.companyUpdate, this.company.employees);
            this.updateEmployees(response.body.company.id, this.companyUpdate);

            if (this.isRepresentative) {
              const currentUserEmail = this.getUserData()?.email;
              const newUserEmail = this.company.employees.legalRepresentatives[0].email;
              if (currentUserEmail != newUserEmail) {
                localStorage.clear();
                localStorage.setItem('updateAssociate', 'true');
                this.router.navigate(['/login']);
              }
            }
          },
          error: (error) => {
            this.notifications.set(
              'createAffiliate',
              error.error.message,
            );

            const { name, businessName, documentNumber } = error.error.errors
            this.nameValidate = name;
            this.businessNameValidate = businessName;
            this.documentNumberValidate = documentNumber;
            this.errorsValidate = error.error.errors;
          }
        });
      }
    }
  }

  saveEmployees(companyId: number, employees: any) {
    const allRequests = [
      ...this.createRequests(companyId, employees.legalRepresentatives || [],
        (employee: any) => this.legalRepresentantiveService.create(companyId, employee),
        'legalRepresentatives'
      ),
      ...this.createRequests(companyId, employees.assistants || [],
        (employee: any) => this.assistantService.create(companyId, employee),
        'assistants'
      ),
      ...this.createRequests(companyId, employees.contacts || [],
        (employee: any) => this.contactService.create(companyId, employee),
        'contacts'
      ),
      ...this.createRequests(companyId, employees.others || [],
        (employee: any) => this.userAdditionalService.create(companyId, employee),
        'others'
      ),
    ];

    if (allRequests.length > 0) {
      forkJoin(allRequests).subscribe({
        next: (results: any[]) => {
          this.handleSuccess(results, 'Afiliado creado.');
        },
        error: (err: any) => {
          this.handleError(err, allRequests);
        }
      });
    } else {
      this.finalizeEmployeeCreation('Afiliado creado.');
    }
  }

  updateEmployees(companyId: number, employees: any) {
    const allRequests = [
      ...this.createRequests(companyId, employees.legalRepresentatives || [],
        (employee: any) => employee.id
          ? this.legalRepresentantiveService.update(companyId, employee)
          : this.legalRepresentantiveService.create(companyId, employee),
        'legalRepresentatives'
      ),
      ...this.createRequests(companyId, employees.assistants || [],
        (employee: any) => employee.id
          ? this.assistantService.update(companyId, employee)
          : this.assistantService.create(companyId, employee),
        'assistants'
      ),
      ...this.createRequests(companyId, employees.contacts || [],
        (employee: any) => employee.id
          ? this.contactService.update(companyId, employee)
          : this.contactService.create(companyId, employee),
        'contacts'
      ),
      ...this.createRequests(companyId, employees.others || [],
        (employee: any) => employee.id
          ? this.userAdditionalService.update(companyId, employee)
          : this.userAdditionalService.create(companyId, employee),
        'others'
      ),
    ];

    if (allRequests.length > 0) {
      forkJoin(allRequests).subscribe({
        next: (results: any[]) => {
          this.handleSuccess(results, 'Afiliado actualizado.');
        },
        error: (err: any) => {
          this.handleError(err, allRequests);
        }
      });
    } else {
      this.finalizeEmployeeCreation('Afiliado actualizado.');
    }
  }

  private createRequests(
    companyId: number,
    employees: any[],
    createOrUpdateFunction: (employee: any) => any,
    propertyKey: keyof any
  ): any[] {
    if (!employees || employees.length === 0) return [];

    return employees.map((employee: any) =>
      createOrUpdateFunction(employee).pipe(
        tap(() => {
          // Filtrar empleados después de crear o actualizar
          if (this.companyCreate) {
            this.companyCreate[propertyKey] = this.companyCreate[propertyKey].filter(
              (existingEmployee: any) => existingEmployee.email !== employee.email
            );
          }
          if (this.companyUpdate) {
            this.companyUpdate[propertyKey] = this.companyUpdate[propertyKey].filter(
              (existingEmployee: any) => existingEmployee.email !== employee.email
            );
          }
        }),
        catchError((err) => {
          if (propertyKey == 'others' || propertyKey == 'legalRepresentatives') {
            this.createdUsers.push({email: employee.email, status: false});
          }
          this.errorMessages.push(err.error.message);
          return of(null);
        })
      ).pipe(
        tap(() => {
          if (propertyKey == 'others' || propertyKey == 'legalRepresentatives') {
            this.createdUsers.push({email: employee.email, status: true});
          }
        })
      )
    );
  }

  private formatErrorMessages(errors: string[]): string {
    // Filtrar mensajes que contienen información sobre correos ya asociados
    const emailMessages = errors.filter(message =>
      message.includes("El o los correos") && message.includes("ya están asociados a otra empresa registrada en nuestro sistema")
    );

    // Extraer los correos únicos
    const extractedEmails = Array.from(new Set(emailMessages.map(message => {
      const match = message.match(/correos\s(.*?)\sya/);
      return match ? match[1] : '';
    }).filter(email => email)));

    // Crear el mensaje combinado
    let combinedErrorMessage = '';
    if (extractedEmails.length > 0) {
      const uniqueEmails = extractedEmails.join(', ');
      combinedErrorMessage = `El o los correos ${uniqueEmails} ya están asociados a otra empresa registrada en nuestro sistema.`;
    }

    // Filtrar otros errores y eliminar duplicados
    const otherErrors = Array.from(new Set(errors.filter(message => !message.includes("El o los correos"))));

    if (otherErrors.length > 0) {
      const generalErrorMessage = otherErrors.join(', ');
      combinedErrorMessage = combinedErrorMessage
        ? `${combinedErrorMessage}, ${generalErrorMessage}`
        : generalErrorMessage;
    }

    return combinedErrorMessage;
}

  private handleSuccess(results: any[], message: string): void {
    const isFailed = results.some(result => result === null);
    if (!isFailed) {
      this.finalizeEmployeeCreation(message);
    } else {
      this.updateEmployeesId();
      if (this.errorMessages.length > 0) {
        const combinedErrorMessage = this.formatErrorMessages(this.errorMessages);
        this.notifications.set('not-save', combinedErrorMessage);
      }
      this.errorMessages = [];
    }
  }

  private handleError(err: any[], allRequests: any): void {
    const messages = err.map(error => error.error.error.message);
    const combinedErrMessage = this.formatErrorMessages(messages);
    this.notifications.set('not-save', combinedErrMessage);
  }

  private finalizeEmployeeCreation(message: string): void {
    this.updateEmployeesId();
    localStorage.removeItem('avatarUrl');
    this.showSuccess(message);
    this.notifications.clear();
    if (this.router.url.includes('nuevo')) {
      this.company.id = null;
      this.company.image = '';
      this.form.reset();

      this.inmobiliariaService.sendNotification(this.createdUsers).subscribe({
        next: (response) => {
          if (response.body) {
            this.createdUsers = [];
            this.showSuccess('Reporte de correos electrónicos envíados');
          }
        },
        error: () => this.createdUsers = [],
      });
    } else {
      const validateEmails = this.validateUsers.map(user => user.email);
      const differentEmails = this.createdUsers.filter(createdUser =>
        !validateEmails.includes(createdUser.email)
      );
      this.inmobiliariaService.sendNotification(differentEmails).subscribe({
        next: (response) => {
          if  (response.body) {
            this.createdUsers = [];
            this.showSuccess('Reporte de correos electrónicos envíados');
          }
        },
        error: () => this.createdUsers = [],
      });
    }
  }

  getNewDataEmployees(employees: any, companyEmployees: any) {
    for (const key in employees) {
      if (companyEmployees.hasOwnProperty(key)) {
        employees[key] = employees[key].map((employee: any, index: number) => {
          const companyEmployee = companyEmployees[key][index];
          if (companyEmployee) {
            for (const field in employee) {
              if (companyEmployee.hasOwnProperty(field)) {
                employee[field] = companyEmployee[field];
              }
            }
          }
          return employee;
        });
      }
    }
  }

  getUpdatedDataEmployees(formCompany: any, dbCompany: any): any {
    let updatedData: any = {};
    for (const key in formCompany) {
      if (formCompany.hasOwnProperty(key)) {
        if (typeof formCompany[key] === 'object' && formCompany[key] !== null && !Array.isArray(formCompany[key])) {
          const subUpdate = this.getUpdatedDataEmployees(formCompany[key], dbCompany[key] || {});
          if (Object.keys(subUpdate).length > 0) {
            updatedData[key] = subUpdate;
          }
        } else if (formCompany[key] !== dbCompany[key]) {
          if (Array.isArray(formCompany[key]) && Array.isArray(dbCompany[key])) {
            const differences = formCompany[key].filter((item: any) =>
              !dbCompany[key].some((dbItem: any) => JSON.stringify(dbItem) === JSON.stringify(item))
            );

            if (differences.length > 0) {
              updatedData[key] = differences;
            }
          } else if (formCompany[key] !== dbCompany[key]) {
            updatedData[key] = formCompany[key];
          }
        }
      }
    }
    return updatedData;
  }

  private updateEmployeesId() {
    this.inmobiliariaService.getById(this.company.id).subscribe(company => {
      const newEmployees = company.body.data.employees;
      const addIdToEmployees = (group: any[], newGroup: any[]) => {
          group.forEach((employee, index) => {
              if (!employee.id && newGroup[index]) {
                  employee.id = newGroup[index].id;
              }
          });
      };
      addIdToEmployees(this.company.employees.legalRepresentatives, newEmployees.legalRepresentatives);
      addIdToEmployees(this.company.employees.assistants, newEmployees.assistants);
      addIdToEmployees(this.company.employees.contacts, newEmployees.contacts);
      addIdToEmployees(this.company.employees.others, newEmployees.others);
    });
  }

  getLetter() {
    return this.company.name.charAt(0).toUpperCase()
  }

  scrollToTop() {
    this.topElement.nativeElement.scrollIntoView({behavior: 'smooth'});
  }

  scrollToAddUser() {
    this.addUserElement.nativeElement.scrollIntoView({behavior: 'smooth'});
  }

  addRepresentative() {
    this.company.employees.legalRepresentatives.push(defaultEmployees());
    // if (this.canSave()) {
    // } else {
    //   this.scrollToTop();
    //   this.notifications.set('not-save', 'Hay campos requeridos por llenar verificar!!');
    // }
  }

  //contact
  addAssistant() {
    this.company.employees.assistants.push(defaultEmployees());
    // if (this.canSave()) {
    // } else {
    //   this.scrollToTop();
    //   this.notifications.set('not-save', 'Hay campos requeridos por llenar verificar!!');
    // }
  }

  addContact() {
    this.company.employees.contacts.push(defaultContacts());
    // if (this.canSave()) {
    // } else {
    //   this.scrollToTop();
    //   this.notifications.set('not-save', 'Hay campos requeridos por llenar verificar!!');
    // }
  }

  addOther() {
    this.company.employees.others.push(defaultOthers());
    // if (this.canSave()) {
    // } else {
    //   this.scrollToTop();
    //   this.notifications.set('not-save', 'Hay campos requeridos por llenar verificar!!');
    // }
  }

  removeRepresentative(index: number, legalRepresentativeId: number | undefined) {
    this.company.employees.legalRepresentatives.splice(index, 1);
  }

  removeAssistant(index: number, assistant: any, event: Event) {
    if (assistant.id) {
      this.confirmationService.confirm({
        target: event.target as EventTarget,
        message: 'Deseas eliminar este asistente?',
        header: 'Eliminar asistente',
        icon: 'pi pi-info-circle',
        acceptButtonStyleClass: 'p-button-danger p-button-text',
        rejectButtonStyleClass: 'p-button-text p-button-text',
        acceptIcon: 'none',
        rejectIcon: 'none',

        accept: () => {
          this.assistantService.delete(assistant).subscribe(() => {
            this.company.employees.assistants.splice(index, 1);
            this.showSuccess(`El asistente ${assistant.name} ${assistant.fatherName} ha sido eliminado`);
          });
        },
        reject: () => {
          // this.showError('No se eleminó al representante legal, intenteló nuevamente');
        },
      });
    } else {
      this.company.employees.assistants.splice(index, 1);
    }
  }

  removeContact(index: number, contact: any, event: Event) {
    if (contact.id) {
      this.confirmationService.confirm({
        target: event.target as EventTarget,
        message: 'Deseas eliminar este contacto?',
        header: 'Eliminar contacto',
        icon: 'pi pi-info-circle',
        acceptButtonStyleClass: 'p-button-danger p-button-text',
        rejectButtonStyleClass: 'p-button-text p-button-text',
        acceptIcon: 'none',
        rejectIcon: 'none',

        accept: () => {
          this.assistantService.delete(contact).subscribe(() => {
            this.company.employees.contacts.splice(index, 1);
            this.showSuccess(`El contacto ${contact.name} ${contact.fatherName} ha sido eliminado`);
          });
        },
        reject: () => {
          // this.showError('No se eleminó al representante legal, intenteló nuevamente');
        },
      });
    } else {
      this.company.employees.contacts.splice(index, 1);
    }
  }

  removeOther(index: number, userAdditional: any, event: Event) {
    if (userAdditional.id) {
      this.confirmationService.confirm({
        target: event.target as EventTarget,
        message: 'Deseas eliminar este usuario adicional?',
        header: 'Eliminar usuario adicional',
        icon: 'pi pi-info-circle',
        acceptButtonStyleClass: 'p-button-danger p-button-text',
        rejectButtonStyleClass: 'p-button-text p-button-text',
        acceptIcon: 'none',
        rejectIcon: 'none',

        accept: () => {
          this.userAdditionalService.delete(userAdditional).subscribe(() => {
            this.company.employees.others.splice(index, 1);
            this.showSuccess(`El usuario adicional ${userAdditional.name} ${userAdditional.fatherName} ha sido eliminado`);
          });
        },
        reject: () => {
          // this.showError('No se eleminó al representante legal, intenteló nuevamente');
        },
      });
    } else {
      this.company.employees.others.splice(index, 1);
    }
  }

  onCancel() {
    this.router.navigate([`/directorio-afiliados`]);
  }

  can(action: TYPE_PERMISSIONS) {
    return this.permissionsService.can(action);
  };

  errorLoadImage(event: string) {
    this.notifications.set('errorLoadImage', event);
  }

  updateImage(event: string) {
    this.company.image = event;
  }

  onChangeSliderState(event: boolean) {
    this.company.isActive = event;
  }

  onSelectedValueChange(newValue: string | number): void {
    this.company.companyType.id = Number(newValue);
  }

  renderDataSelectedItems( employees:any, { label, value } :any ) {
    this.groupedEmployees.push(
      {
        label,
        value,
        items: employees.map( (employee:any) => {
            if( employee.hasUser ) {
              this.selectedEmployees.push( employee.id )
            }
            return ({ label: `${employee.name} ${employee.fatherName } ${employee.motherName} `, value: employee.id })
          })
      }
    )
  }

  renderDataSelect( employees:any ){
    this.groupedEmployees = []
    this.selectedEmployees = []
    if( employees.assistants.length != 0 ) {
      this.renderDataSelectedItems( employees.assistants, { value: 'ast', label: 'ASISTENTE' } )
    }
    if( employees.contacts.length != 0 ) {
      this.renderDataSelectedItems( employees.contacts, { value: 'cont', label: 'CONTACTO' } )
    }
    if( employees.others.length != 0 ) {
      this.renderDataSelectedItems( employees.others, { value: 'otr', label: 'OTROS' } )
    }
    this.company.users_additional = [...this.selectedEmployees]
  }

  onChangeSelect(event:any){
    this.company.users_additional = [ ...event.value ]
  }

  showSuccess(message: string) {
    this.messageService.add({ severity: 'success', summary: 'Operación exitosa', detail: message, life: 3000 });
  }

  showError(message: string) {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: message, life: 3000 });
  }

  legalRepresentativeButton(employeeTypeId: number, isSave: boolean = false) {
    this.legalRepresentativeIsEdit = !this.legalRepresentativeIsEdit;
    if (isSave) {
      this.employeeTypesService.update(employeeTypeId, this.employeeTypes[0].name).subscribe({
        next: () => {
          this.showSuccess('Nombre de perfíl actualizado.');
        }
      })
    }
  }

  assistantButton(employeeTypeId: number, isSave: boolean = false) {
    this.assistantIsEdit = !this.assistantIsEdit;
    if (isSave) {
      this.employeeTypesService.update(employeeTypeId, this.employeeTypes[1].name).subscribe({
        next: () => {
          this.showSuccess('Nombre de perfíl actualizado.');
        }
      })
    }
  }

  contactButton(employeeTypeId: number, isSave: boolean = false) {
    this.contactIsEdit = !this.contactIsEdit;
    if (isSave) {
      this.employeeTypesService.update(employeeTypeId, this.employeeTypes[2].name).subscribe({
        next: () => {
          this.showSuccess('Nombre de perfíl actualizado.');
        }
      })
    }
  }
  userAdditionalButton(employeeTypeId: number, isSave: boolean = false) {
    this.userAdditionalIsEdit = !this.userAdditionalIsEdit;
    if (isSave) {
      this.employeeTypesService.update(employeeTypeId, this.employeeTypes[3].name).subscribe({
        next: () => {
          this.showSuccess('Nombre de perfíl actualizado.');
        }
      })
    }
  }

  protected readonly getErrorMessage = getErrorMessage;
  protected readonly validatorsInput = VALIDATORS_INPUT;
}
