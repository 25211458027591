import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { catchError, throwError } from 'rxjs';
import { AseiInfoNews, AseiInfoNewsCreation, } from 'src/app/types/asei-info-news';
import { _defaultNewsData, _NewsData } from '../../../../helpers/news.helper';
import { PostService } from 'src/app/pages/post/services/post.service';
import { parseAseiInfoNews } from 'src/app/mapper/news.mapper';
import { NgForm } from '@angular/forms';
import { FilesService } from '../../../../services/files.service';
import { getImageOThumbnail } from 'src/app/helpers/news.helper';
import { getErrorMessage } from "../../../../helpers/validators.helper";
import { Location } from "@angular/common";
import { PostTypeService } from "../../services/post-type.service";
import { PostType } from "../../interfaces/post-type.interface";
import VALIDATORS_INPUT from 'src/app/helpers/validators-input.helper';
import { MessageService } from 'primeng/api';
import { AseiNotificationsComponent } from 'src/app/components/asei-notifications/asei-notifications.component';
import { getDateDDMMYYYY } from 'src/app/helpers/converters.helper';

@Component({
  selector: 'app-news-form-page',
  templateUrl: './post-form-page.component.html',
  styleUrls: ['./post-form-page.component.scss'],
  providers: [MessageService]
})
export class PostFormPageComponent implements OnInit {
  @ViewChild('form', { static: false }) public form!: NgForm;
  @ViewChild('top') topElement!: ElementRef;
  titlePage!: string;
  news!: any;
  _news!: _NewsData;
  fileSelected?: File | "";
  links: { value: string }[] = [];
  isChangeUrlFile: boolean = false;
  oldUrlFile: string | null= null;
  typeOld = '';
  urlBaseFile = 'devapi-intranet.asei.com.pe';
  notifications: Map<string, string> = new Map<string, string>();

  _textButtonOptions = [
    {
      "value": 1,
      "text": "Comunidad",
    },
    {
      "value": 2,
      "text": "Insititucional",
    },
    {
      "value": 3,
      "text": "Comercial",
    },
    {
      "value": 4,
      "text": "Responsabilidad social",
    },
    {
      "value": 5,
      "text": "Otros",
    }
  ]

  typeFile = '';


  constructor(
    private route: ActivatedRoute,
    private postService: PostService,
    private postTypeService: PostTypeService,
    private router: Router,
    private filesService: FilesService,
    private location: Location,
    private messageService: MessageService,
  ) {
  }

  ngOnInit(): void {
    this.prepareDataForPost();
  }

  prepareDataForPost(){
    this.route.url.subscribe(segments => {
      const path = segments.join('/');
      if (path.includes('nuevo')) {
        this.loadDataForPostNew(segments)
      } else {
        this.loadDataForPostEdit()
      }
    });
  }

  loadDataForPostNew(segments: UrlSegment[]) {
    const segmentsExceptLast = segments.slice(0, -1);
    const postTypeName = segmentsExceptLast.join('.');

    this.titlePage = 'Nueva Publicación';
    this._news = _defaultNewsData();
    // Cargar el Tipo de Publicación (postType) a Objeto (Post) por default
    this.postTypeService.getOne(postTypeName).subscribe({
      next: response => {
        const postType: PostType = response.body.data;
        this._news.postType = postType
        this.news = this._news;
      }
    })
  }

  loadDataForPostEdit() {
    this.route.paramMap.subscribe(params => {
      const idValue = params.get('id');
      if (idValue) {
        this.titlePage = 'Editar Publicación';
        // Cargar Data del Post
        this.postService.getOne(idValue).subscribe({
          next: (response: any) => {
            const formatNews = { ...response.body.data }
            formatNews.date = getDateDDMMYYYY(formatNews.date);
            const parseNews = parseAseiInfoNews(formatNews);
            this.news = parseNews;
            this._news = {
              ...this._news,
              ...parseNews,
              links: parseNews.links.map((link) => ({ value: link })),
            };
          }
        })
      }
    });
  }

  getTypeNews(nameFile: string) {
    const allowedExtensions = /(\.pdf)$/i;
    if (allowedExtensions.exec(nameFile)) {
      return 'pdf';
    }
    return 'image';
  }

  scrollToTop() {
    this.topElement.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  canSave(): boolean {
    return Boolean(this.form && this.form.valid);
  }

  async save() {
    if (!this.canSave()) {
      this.scrollToTop();
      return this.notifications.set('not-save', 'Hay campos requeridos por llenar verificar!!');
    }

    await this.uploadFile();
    return this.sendData(this.news);
  }

  async sendData(newsData: any) {
    const payload = {
      ...this.news,
      title: this._news.title,
      date: this._news.date,
      content: this._news.content,
      links: this._news?.links.map((link) => link.value),
      postType: this._news.postType,
    };

    if (newsData.id) {
      if (this.fileSelected === "") {
        payload.linkImage = '';
        payload.link_image_thumbnail = '';
      }
      this.postService.update(payload).subscribe({
        next: () => {
          this.notifications.clear();
          this.showSuccess('Publicación Actualizada.');
        },
        error: (error) => {
          this.scrollToTop();
          this.notifications.set('createNews', 'Hubo un error, intentelo más tarde');
          return error;
        },
      });
    } else {
      this.postService.createNews(payload).subscribe({
        next: () => {
          this.notifications.clear();
          this.prepareDataForPost();
          this.fileSelected = '';
          this.news.linkImage = 'https://fakeimg.pl/600x400?text=Sin+Archivo&font=bebas';
          this.showSuccess('Publicación Creada.');
        },
        error: (error) => {
          this.scrollToTop();
          this.notifications.set('createNews', 'Hubo un error, intentelo más tarde');
          return error;
        },
      });
    }
  }

  async uploadFile() {
    if (this.fileSelected) {
      try {
        const response = await this.uploadFileBasedOnType(this.fileSelected).toPromise();
        this.managementNext(response);
      } catch (error) {
        this.managementErrors(error);
      }
    }
  }

  uploadFileBasedOnType(file: File): any {
    if (file.type.startsWith('image/')) {
      return this.filesService.uploadImage(file);
    } else {
      return this.filesService.uploadPDF(file, 'yes');
    }
  }

  managementErrors(error: any) {
    this.scrollToTop();
    this.notifications.set('image', 'Hubo un error al subir la imagen, intentelo más tarde');
    return throwError(() => error);
  }

  managementNext(response: any) {
    this.news.linkImage = response.body.path;
    this.news.link_image_thumbnail = response.body.thumbnail_path;
  }

  backPage(): void {
    if (window.history.length > 1) {
      this.location.back();
    } else {
      this.router.navigateByUrl('/inicio');
    }
  }

  parseNewsData(urlImage: string, urlThumbnail: string) {
    this.validChangeUrlFile(urlImage, urlThumbnail);
    this.news = {
      ...this.news,
      title: this._news.title,
      date: this._news.date,
      content: this._news.content,
      link_image_thumbnail: urlThumbnail,
      links: this._news?.links.map((link) => link.value),
      linkImage: urlImage,
      postType: this._news.postType
    };
    return this.news;
  }

  validChangeUrlFile(urlImage: string, urlThumbnail: string) {
    if (this._news.id && this.getFileName(this._news.linkImage) !== this.getFileName(urlImage)) {
      this.typeOld = this.getTypeNews(this._news.linkImage!);
      this.isChangeUrlFile = true;
      this.oldUrlFile = this._news.linkImage;
    } else {
      this.isChangeUrlFile = false;
    }
  }

  addLinks() {
    if (this._news && this.canSave()) {
      this._news.links.push({ value: '' });
    } else {
      this.scrollToTop();
      this.notifications.set(
        'addLink',
        'Hay campos requeridos por llenar primero!!'
      );
    }
  }

  removeLinks(index: number) {
    if (this._news?.links.length) {
      this._news.links = this._news.links.filter((link, i) => i !== index);
    }
  }

  onImageSelected(image: File) {
    this.fileSelected = image;
  }

  onTypeSelected(type: string) {
    this.typeFile = type;
  }

  rollbackImageUpload(url: string, type: string) {
    if (type === 'pdf') {
      this.deleteFile(url);
    } else {
      this.deleteImage(url);
    }
  }

  deleteImage(urlImage: string) {
    try {
      const image = this.getFileName(urlImage);
      this.filesService.DeleteImage(image).subscribe((data) => {
      });
    } catch (error) {
      console.error('error log: ', error);
    }
  }

  deleteFile(url: string) {
    try {
      const file = this.getFileName(url);
      this.filesService.DeleteFile(file).subscribe((data) => {
      });
    } catch (error) {
      console.error('error log: ', error);
    }
  }

  getFileName(fileName: string): string {
    if (this.urlBaseFile === fileName.split('/')[2]) {
      return fileName.split('/')[6];
    }
    if (fileName.split('/')[0] === 'uploads') {
      return fileName.split('/')[2];
    }
    return '';
  }

  showSuccess(message: string) {
    this.messageService.add({ severity: 'success', summary: 'Operación exitosa', detail: message, life: 3000 });
  }

  showError(message: string) {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: message, life: 3000 });
  }

  protected readonly getImageOThumbnail = getImageOThumbnail;
  protected readonly getErrorMessage = getErrorMessage;
  protected readonly validatorsInput = VALIDATORS_INPUT
}
