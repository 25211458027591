<div class="page container_section_large">
  <app-header-page title="Editar Directorio ASEI" subtitle="Completa la información correspondiente."></app-header-page>
  <p-toast></p-toast>
  <div class="main">
    <div class="card personal-information">
      <form *ngIf="form" (ngSubmit)="onSubmit()">
        <div class="information">
          <div *ngFor="let cat of categories" class="section-videos">
            <div class="header perfil-title-center">
              <ng-container *ngIf="editCategoryId === cat.id; else normalView">
                <input
                  type="text"
                  name="editableCategoryName"
                  maxlength="70"
                  [(ngModel)]="editableCategoryName"
                  [ngModelOptions]="{standalone: true}"
                  class="mr-3"
                />
              </ng-container>
              <ng-template #normalView>
                <h2 class="mr-3">{{ cat.name }}</h2>
              </ng-template>
              <button
                type="button"
                pButton
                pRipple
                [icon]="editCategoryId === cat.id ? 'pi pi-check' : 'pi pi-pencil'"
                class="p-button-rounded ml-1 mr-1 custom-orange-btn"
                size="small"
                (click)="onEditCategory(cat)"
              ></button>
              <ng-container *ngIf="editCategoryId === cat.id; else deleteBtn">
                <button
                  type="button"
                  pButton
                  pRipple
                  icon="pi pi-times"
                  class="p-button-rounded p-button-text p-button-secondary custom-gray-btn"
                  size="small"
                  (click)="onCancelEdit(cat)"
                ></button>
              </ng-container>
              <ng-template #deleteBtn>
                <button
                  type="button"
                  pButton
                  pRipple
                  icon="pi pi-trash"
                  class="p-button-rounded p-button-danger"
                  size="small"
                  (click)="onDeleteCategory(cat)"
                ></button>
              </ng-template>
            </div>
            <app-text-editor
              [value]="form.get(getControlName(cat.name))?.value"
              [showTitle]="false"
              (textChange)="onTextEditorChange($event, getControlName(cat.name))"
            ></app-text-editor>
          </div>
        </div>
        <div class="actions">
          <a class="btn secondary" (click)="backPage()">Cancelar</a>
          <button class="btn primary" type="submit">Guardar</button>
        </div>
      </form>
    </div>
  </div>
  <p-confirmDialog></p-confirmDialog>
</div>
