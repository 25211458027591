const  formatGoogleDate = (date:any) => {
  return date.toISOString().replace(/-|:|\.\d+/g, '');
}

const createGoogleCalendarEventV2 = (title:string, description:string, startDate:Date, endDate:Date) => {

  const urlGoogleCalendar = new URL('http://www.google.com/calendar/event');

  urlGoogleCalendar.searchParams.append("action", "TEMPLATE");
  urlGoogleCalendar.searchParams.append("text", title);
  urlGoogleCalendar.searchParams.append("details", description);
  urlGoogleCalendar.searchParams.append("dates", formatGoogleDate(startDate) + "/" + formatGoogleDate(endDate));
  window.open(urlGoogleCalendar.href, '_blank');
}

const createGoogleCalendarEvent = (title:string, description:string, startDate:string, endDate:string) => {

    const urlGoogleCalendar = new URL('http://www.google.com/calendar/event');

    urlGoogleCalendar.searchParams.append("action", "TEMPLATE");
    urlGoogleCalendar.searchParams.append("text", title);
    urlGoogleCalendar.searchParams.append("dates", `${startDate}/${endDate}`);
    urlGoogleCalendar.searchParams.append("details", description);
    urlGoogleCalendar.searchParams.append("trp", "false");
    urlGoogleCalendar.searchParams.append("sprop", "name");

    window.open(urlGoogleCalendar.href,"_target");
}




export default createGoogleCalendarEvent
