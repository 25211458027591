import { CompanyEmployee } from '../interfaces/company-employee.interface';
import { Employee } from '../interfaces/employee.interface';

export const defaultCompany = (): CompanyEmployee => {
  return {
    name: '',
    businessName: '',
    image: '',
    documentNumber: '',
    web: '',
    isActive: true,
    address: '',
    entryDate: '',
    companyType: {
      id: 1,
      name: '',
    },
    employees: {
      legalRepresentatives: [],
      assistants: [],
      contacts: [],
      others: [],
    },
  };
};

export const defaultEmployees = (): Employee => {
  return {
    name: '',
    fatherName: '',
    motherName: '',
    phone: '',
    address: '',
    gender: '',
    email: '',
    image: '',
    birthday: '',
    documentType: 'dni',
    documentNumber: '',
    position: '',
    hierarchy: 0,
  };
};

export const defaultContacts = (): Employee => {
  return {
    name: '',
    fatherName: '',
    motherName: '',
    phone: '',
    address: '',
    gender: '',
    email: '',
    image: '',
    birthday: '',
    documentType: 'dni',
    documentNumber: '',
    position: '',
    hierarchy: 0,
  };
};

export const defaultOthers = (): Employee => {
  return {
    name: '',
    fatherName: '',
    motherName: '',
    phone: '',
    address: '',
    gender: '',
    email: '',
    image: '',
    birthday: '',
    documentType: 'dni',
    documentNumber: '',
    position: '',
    hierarchy: 0,
  };
};
