<mat-drawer-container>
  <mat-drawer #drawer class="sidenav_container" [mode]="format">
    <app-sidenav
      (linkClicked)="handleLinkClicked($event)"
    ></app-sidenav>
  </mat-drawer>
  <mat-drawer-content class="container_main">
    @if (!isShowSidebar) {
      <asei-sidebar-mobile (isShow)="changeOpenMobileSidebar($event)"></asei-sidebar-mobile>
    }
    <div class="main">
      <div class="content">
        <router-outlet>
          <app-spinner></app-spinner>
        </router-outlet>
      </div>
      <app-footer></app-footer>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
