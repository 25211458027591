import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import DataCardHome from "../../interfaces/data-card-home";
import {ModuleService} from "../modules/services/module.service";
import {Page} from "../../interfaces/page.interface";
import {getLinkImage} from "../../helpers/converters.helper";

@Component({
  selector: 'app-list-shortcuts-big-card',
  templateUrl: './list-shortcuts-big-card.html',
  styleUrls: ['./list-shortcuts-big-card.scss'],
})
export class ListShortcutsBigCard implements OnInit {

  dataCards: DataCardHome[] = []
  notifications: Map<string, string> = new Map<string, string>();
  showNotifications: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private moduleService: ModuleService
  ) {
  }

  async ngOnInit() {
    this.route.url.subscribe(segments => {
      const path = segments.join('/');
      this.moduleService.getOne(path).subscribe({
        next: response => {
          const pages: Page[] = response.body.data.pages
          this.dataCards = this.convertToDataCardHome(pages)
        },
        error: () => {
          this.notifications.set('not-save', 'Se han actualizado los permisos, refresca la página con F5!!');
          this.showNotifications = true;
        },
      })
    });
  }

  convertToDataCardHome(pages: Page[]): DataCardHome[] {
    const data: DataCardHome[] = [];
    pages.forEach((page) => {
      data.push({
        text: page.includeTextInBigCard ? page.name : null,
        imageBackgroundCard: page.imageBackgroundCard ? getLinkImage(page.imageBackgroundCard) : '',
        logoPrimaryCard: page.logoPrimaryCard ? getLinkImage(page.logoPrimaryCard) : null,
        logoSecondaryCard: page.logoSecondaryCard ? getLinkImage(page.logoSecondaryCard) : null,
        link: page.slug,
        includeTextInBigCard:page.includeTextInBigCard
      })
    })
    return data;
  }
}
