import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {Observable} from "rxjs";
import {map} from 'rxjs/operators';
import { getDateYYYYMMDD } from 'src/app/helpers/converters.helper';

const URL = environment.url + '/api/v1';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  private httpOptions = {};

  constructor(private http: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({
        "Accept": "application/json",
        "Content-Type": "application/json"
      }),
      observe: 'response' as const // Asegura que el tipo de 'observe' sea correcto
    }
  }

  // getAll(sort = 'desc', perPage = 10, dateStart = '', dateEnd = '') {
  //   const url = `${URL}/events?sort=${sort}&per_page=${perPage}&date-start=${dateStart}&date-end=${dateEnd}`;
  //   return this.http.get<any>(url, this.httpOptions).pipe(
  //     map(response => response)
  //   );
  // }
  //
  getOne(id: string) {
    const url = `${URL}/reports/${id}`;
    return this.http.get<any>(url, this.httpOptions).pipe(
      map(response => response)
    );
  }
  //
  update(id: number, data: any) {
    const url = `${URL}/reports/${id}`;
    return this.http.put<any>(url, JSON.stringify(data), this.httpOptions).pipe(
      map(response => response)
    );
  }

  getAll(
    type = '',
    dateStart = '',
    dateEnd = '',
    search = '',
    page = '',
    sort = 'desc',
    perPage = 6,
    orderByMonthEvent = false
  ) {
    let paramsOrder = ''
    if(orderByMonthEvent) paramsOrder = `&order_by_month_event=${orderByMonthEvent}`
    let url = `${URL}/reports?per_page=${perPage}&sort=${sort}&type=${type}&start_date=${getDateYYYYMMDD(dateStart)}&end_date=${getDateYYYYMMDD(dateEnd)}&search=${search}${paramsOrder}`;
    if (page) {
      url += `&page=${page}`
    }
    return this.http.get<any>(url, this.httpOptions).pipe(
      map(response => response)
    );
  }

  create(body: any): Observable<any> {
    const url = `${URL}/reports`;
    return this.http.post<any>(url, JSON.stringify(body), this.httpOptions).pipe(
      map((response: any) => response)
    );
  }
  getByUrlPagination(url: string) {
    return this.http.get<any>(url, this.httpOptions).pipe(
      map(response => response)
    );
  }
  delete(id: number) {
    const url = `${URL}/reports/${id}`;
    return this.http.delete<any>(url, this.httpOptions).pipe(
      map(response => response)
    );
  }

}
