import {Component, OnInit} from '@angular/core';
@Component({
  selector: 'app-home',
  templateUrl: './blank.component.html',
  styleUrls: ['./blank.component.scss'],
})
export class BlankPageComponent implements OnInit {
  constructor() {
  }

  async ngOnInit() {
  }
}
