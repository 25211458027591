import {APP_INITIALIZER, NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {RouteService} from "../services/route.service";
import {ModuleService} from "./modules/services/module.service";
import {Module} from "../interfaces/module.interface";
import {PagesComponent} from "./pages.component";
import {AuthGuard} from "../auth/guards/auth.guard";
import {AuthService} from "../auth/services/auth.service";

const routes: Routes = [
  {
    path: '',
    component: PagesComponent,
    canActivate: [AuthGuard],
    children: []
  }
];

export function loadRoutes(moduleService: ModuleService,
                           authService:AuthService,
                           routeService: RouteService) {
  return () => new Promise<void>((resolve, reject) => {
    const isLoggedIn =authService.isLoggedIn(); // Obtenemos el token

    if (isLoggedIn) {
      moduleService.getAll().toPromise().then(response => {
        const modules: Module[] = response.body.data;
        if (modules.length > 0) {
          routeService.setInitialRoutes(modules).then(() => {
            resolve(); // Resolvemos la promesa cuando las rutas están configuradas
          }).catch(error => {
            reject(error); // Manejar cualquier error de configuración de rutas
          });
        } else {
          reject('No se encontraron módulos'); // Podrías manejar esta situación como un error también
        }
      }).catch(error => {
        reject(error); // Manejar cualquier error de carga de módulos
      });
    } else {
      routeService.setInitialRoutesWithOutLogin().then(()=>{
        resolve();
      }).catch(error => {
        reject(error);
      })
      // Si no hay un token disponible, podemos resolver la promesa inmediatamente
      resolve(); // O podríamos rechazarla, dependiendo de la lógica de tu aplicación
    }
  });
}




@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [{
    provide: APP_INITIALIZER,
    useFactory: loadRoutes,
    deps: [ModuleService, AuthService, RouteService],
    multi: true
  }]
})
export class PagesRoutingModule {
}
