import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs';
import { AseiTechnicalAssistance } from '../../../types/asei-technical-assistance';

const URL = environment.url + '/api/v1';

@Injectable({
  providedIn: 'root'
})
export class TechnicalAssistanceService {

  private httpOptions = {};

  constructor(private http: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({
        "Accept": "application/json",
        "Content-Type": "application/json"
      }),
      observe: 'response' as const // Asegura que el tipo de 'observe' sea correcto
    }
   }

   SendEmail(body: AseiTechnicalAssistance){
    const url = `${URL}/technical-assistance`;
    return this.http.post<any>(url, JSON.stringify(body), this.httpOptions).pipe(
      map((response: any) => response)
    );
  }
}
