import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SupplierType } from '../../../interfaces/supplier-type.interface';

const URL = environment.url + '/api/v1';

@Injectable({
  providedIn: 'root',
})
export class SupplierTypeService {
  private httpOptions = {
    headers: new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) {}

  getAll(): Observable<SupplierType[]> {
    const url = `${URL}/supplier-types`;
    return this.http
      .get<{ data: SupplierType[] }>(url, this.httpOptions)
      .pipe(
        map((response) => response.data),
        catchError(this.handleError)
      );
  }

  getOne(id: number): Observable<SupplierType> {
    const url = `${URL}/supplier-types/${id}`;
    return this.http
      .get<{ data: SupplierType }>(url, this.httpOptions)
      .pipe(
        map((response) => response.data),
        catchError(this.handleError)
      );
  }

  create(supplierType: { name: string }): Observable<SupplierType> {
    const url = `${URL}/supplier-types`;
    return this.http
      .post<SupplierType>(url, supplierType, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  update(id: number, supplierType: { name: string }): Observable<SupplierType> {
    const url = `${URL}/supplier-types/${id}`;
    return this.http
      .put<SupplierType>(url, supplierType, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  delete(id: number): Observable<void> {
    const url = `${URL}/supplier-types/${id}`;
    return this.http
      .delete<void>(url, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: any): Observable<never> {
    console.error('Ocurrió un error:', error);
    return throwError(() => new Error('Error en la solicitud HTTP'));
  }
}
