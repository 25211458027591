import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import {environment} from 'src/environments/environment';
const URL = environment.url + '/api/v1';

@Injectable({
  providedIn: 'root',
})
export class EmployeeTypesService {
  private httpOptions = {};

  constructor(private http: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({
        "Accept": "application/json",
        "Content-Type": "application/json"
      }),
      observe: 'response' as const // Asegura que el tipo de 'observe' sea correcto
    }
  }

  findAll() {
    const url = `${URL}/employee-types`;
    return this.http.get<any>(url, this.httpOptions).pipe(
      map(response => response.body)
    );
  }

  update(companyTypeId: number, name: string) {
    const url = `${URL}/employee-types/${companyTypeId}`;
    return this.http.put<any>(url, { name }, this.httpOptions).pipe(
      map(response => response.body)
    );
  }
}
