import {Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Router} from '@angular/router';
import Event from "../../pages/events/interfaces/event.interface";
import {getDatetimeUTC, getDatetimeWithoutFormatUTC, getLinkImage} from '../../helpers/converters.helper';
import * as moment from 'moment';
import {DeleteModalComponent} from "../delete-modal/delete-modal.component";
import {MatDialog} from "@angular/material/dialog";
import createGoogleCalendarEvent from 'src/app/helpers/calendar';

@Component({
  selector: 'asei-card-info-master',
  templateUrl: './asei-card-info-master.component.html',
  styleUrls: ['./asei-card-info-master.component.scss']
})
export class ASEICardInfoMasterComponent implements OnInit, OnChanges {
  @Input() id!: number;
  @Input() title: string = '';
  @Input() linkImage: string | null = '';
  @Input() link_image_thumbnail: string | null = null;
  @Input() dateInit: string = '';
  @Input() dateEnd?: string | null;
  @Input() dateTimeInit?: string;
  @Input() dateTimeEnd?: string | null;
  @Input() registrationLink?: string;
  @Input() phone: string | null = null;
  @Input() textButtonRegister?: string;
  @Input() environment = '';// para la url destino al editar
  @Input() isShowAuthor = false;
  @Input() isShowDescriptions = false;
  @Input() inverseBackground = '';
  @Input() flagText = '';
  @Input() buttonActions = false;
  // default or compact
  @Input() design = 'default';

  @Input() canEdit = false;
  @Input() canDelete = false;

  @Output() $register = new EventEmitter();
  @Output() $addCalendar = new EventEmitter();
  @Output() $deleteEventId: EventEmitter<number> = new EventEmitter<number>();


  classNameContentCard = '';
  background = '';
  backgroundCompact = '';
  day: string = '';
  month: string = '';
  dayEnd: string = '';
  monthEnd: string = '';
  isTouchDevice = false;


  constructor(public router: Router,
              public dialog: MatDialog) {
    this.isTouchDevice = this.checkTouchSupport();
  }

  ngOnInit(): void {
    this.title = this.truncateText(this.title)
    this.classNameContentCard = this.design === 'default' ? 'asei-card-info-master__content' : 'asei-card-info-master__content--compact';
    this.background = this.inverseBackground == 'inverse' ? 'asei-card-info-master background--inverse' : 'asei-card-info-master';
    this.backgroundCompact = this.inverseBackground == 'inverse' ? 'background--inverse' : '';
    [this.day, this.month] = this.getDayAndMonth(this.dateInit);
    if (this.dateEnd) {
      [this.dayEnd, this.monthEnd] = this.getDayAndMonth(this.dateEnd);
    }
  };

  private checkTouchSupport(): boolean {
    return 'ontouchstart' in window || (window.matchMedia && window.matchMedia('(any-pointer: coarse)').matches);
  }

  ngOnChanges(changes: SimpleChanges) {
  }

   getLinkImagePreview() {
    let image = null;
    if(typeof(this.linkImage) == 'string'){
      if(this.linkImage && this.linkImage.includes('pdf')){
        image = getLinkImage(this.link_image_thumbnail!) ?? 'https://fakeimg.pl/302x180?text=Archivo&font=bebas'
      }else{
        image = getLinkImage(this.linkImage);
      }
    }
    if(typeof(this.linkImage) == 'object'){
      image = 'https://fakeimg.pl/302x180?text=Sin%20Archivo&font=bebas';
    }
    return image;
  }

  getDayAndMonth(date: string) {
    moment.locale('es');
    const getDateFormat = moment(date, 'DD/MM/YYYY');
    const day = getDateFormat.date().toString();
    const month = getDateFormat.format('MMMM').toString();
    return [day, month];
  }

  edit(environment: string) {
    const url = `${environment}/${this.id}/editar`;
    this.router.navigateByUrl(url);
  }

  navigateTo(environment: string) {
    const url = `/${environment}/${this.id}`;
    this.router.navigate([url]);
  }

  onAddCalendar() {
    const dateInit = getDatetimeWithoutFormatUTC(this.dateInit, this.dateTimeInit);
    const dateEnd = getDatetimeWithoutFormatUTC(
      this.dateEnd ? this.dateEnd : this.dateInit,
      this.dateTimeEnd ? this.dateTimeEnd : this.dateTimeInit
    );
    createGoogleCalendarEvent( this.title, '', dateInit, dateEnd )
  }

  onConfirmAssistance() {
    const urlWhatsapp = new URL('http://wa.me');
    urlWhatsapp.pathname = '+51' + this.phone;

    urlWhatsapp.searchParams.append("text", `Hola, confirmo mi asistencia para el evento ${this.title}.`);

    window.open(urlWhatsapp.href, "_target");
  }

  openDialogDelete(enterAnimationDuration: string, exitAnimationDuration: string): void {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      width: '250px',
      enterAnimationDuration,
      exitAnimationDuration,
    });

    dialogRef.componentInstance.deleteConfirmed.subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.$deleteEventId.emit(this.id);
      }
    });
  }

  truncateText(text: string) {
    if (text.length < 70) return text
    return `${text?.slice(0, 70)}...`
  }

  onRegister() {
    window.open(this.registrationLink, "_target")
  }
}
