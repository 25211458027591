<div class="input-group">
  <label>{{ label }}</label>
  <div class="input"
       [ngClass]="{
       'input-invalid': invalid,
       'input-disabled':disabled}"
  >
    <input
      [type]="getInputType()"
      [value]="value"
      (input)="onInputChange($event)"
      [formControl]="formControl"
      [required]="required"
      [ngClass]="invalid ? 'has-error' : 'has-success'"
      [maxlength]="(maxlength+1)"
    />
  </div>
  @if (invalid) {
    <div class="invalid">
      {{ errorMessage }}
    </div>
  }
</div>
