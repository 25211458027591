<div class="input-group">
  <label>{{title}}:</label>
  <div class="input" [ngClass]="{'input-invalid': invalid}">
    <textarea
      [rows]="rows"
      [value]="value"
      (input)="onInputChange($event)"
      [disabled]="disabled"
      [maxLength]="maxlength"
      [ngClass]="invalid ? 'has-error' : 'has-success'"
    ></textarea>
  </div>
  @if (counter) {
    <div class="margin-custom">Caracteres actuales: <span class="color-counter">{{ currentLength }}</span>, max: {{ maxlength }}</div>
  }
  @if (invalid) {
    <div class="invalid">
      {{errorMessage}}
    </div>
  }
</div>
