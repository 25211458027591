import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Links, Meta, RatingCreated, Supplier, SupplierList, SupplierListResponse, SupplierTypeResponse } from '../interfaces/supplier.interface';
import { BehaviorSubject, map, Observable, switchMap } from 'rxjs';
import { PaginatorData } from 'src/app/interfaces/paginator-data.interface';
import { SupplierType } from '../interfaces/supplier-type.interface';
const URL = environment.url + '/api/v1';

@Injectable({
  providedIn: 'root'
})
export class SuppliersService {
  private httpOptions = {};

  suppliers: SupplierList[] = [];
  suppliers$: BehaviorSubject<SupplierList[]> = new BehaviorSubject<SupplierList[]>(
    this.suppliers,
  );

  paginator: PaginatorData = {
    links: {} as Links,
    meta: {} as Meta,
  };
  paginator$: BehaviorSubject<PaginatorData> = new BehaviorSubject<PaginatorData>(
    this.paginator,
  );

  constructor(private http: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({
        "Accept": "application/json",
        "Content-Type": "application/json"
      }),
      observe: 'response' as const
    }
  }

  callGetList(
    perPage = 10,
    type = '',
    sort='asc',
    search ='',
    page = '',
  ): Observable<void> {
    let url = `${URL}/suppliers?per_page=${perPage}&type=${type}&sort=${sort}&search=${search}&orderBy=name`;
    if (page) {
      url += `&page=${page}`
    }
    return this.http.get<SupplierListResponse>(url, this.httpOptions)
      .pipe(
        map((response: SupplierListResponse) => {
          this.updateSuppliers(response.body.data);
          this.updatePaginator(response.body.links, response.body.meta);
        }),
      );
  }

  getList(): Observable<SupplierList[]> {
    return this.suppliers$.asObservable();
  }

  getPaginator(): Observable<PaginatorData> {
    return this.paginator$.asObservable();
  }

  getOne(id: number): Observable<Supplier> {
    return this.http
    .get<Supplier>(`${URL}/suppliers/${id}`, this.httpOptions)
    .pipe(
      map((response: any) => response.body.data)
    );
  }

  getSupplierTypes(): Observable<SupplierType[]> {
    return this.http
      .get<SupplierTypeResponse>(`${URL}/supplier-types`, this.httpOptions)
      .pipe(
        map((response: any) => response.body.data)
      );
  }

  create(supplier: any) {
    return this.http.post(`${URL}/suppliers`, supplier, this.httpOptions);
  }

  update(supplier: any) {
    return this.http.put(`${URL}/suppliers/${supplier.id}`, supplier, this.httpOptions);
  }

  addRating(supplierId: number, rating: RatingCreated) {
    return this.http
      .post(`${URL}/suppliers/${supplierId}/add`, rating);
  }

  addReview(supplierId: number, rating: RatingCreated) {
    return this.http
      .post(`${URL}/suppliers/${supplierId}/add/review`, rating);
  }

  modifyRating(supplierId: number, ratingId: number, newRatingId: number) {
    return this.http
      .put(`${URL}/suppliers/${supplierId}/modify/${ratingId}/${newRatingId}`, {});
  }

  private updateSuppliers(value: SupplierList[]): void {
    this.suppliers = value;
    this.suppliers$.next(this.suppliers);
  }

  private updatePaginator(links: Links, meta: Meta): void {
    this.paginator.links = links;
    this.paginator.meta = meta;
    this.paginator$.next(this.paginator);
  }
}
