import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AseiInfoNews, AseiInfoNewsCreation} from '../../../types/asei-info-news';

const URL = environment.url + '/api/v1';

@Injectable({
  providedIn: 'root',
})
export class ReportTypeService {
  private httpOptions = {};

  constructor(private http: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
      observe: 'response' as const, // Asegura que el tipo de 'observe' sea correcto
    };
  }

  getOne(id: string) {
    const url = `${URL}/report-types/${id}`;
    return this.http
      .get<any>(url, this.httpOptions)
      .pipe(map((response) => response));
  }
}
