<div class="page container_section_large" #top>
  <app-header-page
    [title]="titlePage"
    subtitle="Completa la información correspondiente al evento."
  ></app-header-page>
  <p-toast></p-toast>
  <asei-notifications [notifications]="notifications"></asei-notifications>
  <div class="main">
    <div class="card personal-information">
      @if (event) {
        <form #form="ngForm">
          <div class="information">
            <div class="section">
              <div class="input-box">
                <app-input-group
                  title="Titulo"
                  name="event_title"
                  type="text"
                  [(ngModel)]="event.title"
                  [value]="event.title"
                  [required]="true"
                  [maxlength]="validatorsInput.MAX_TEXT"
                  [counter]="true"
                ></app-input-group>
              </div>
              <div class="input-box">
                <app-input-group
                  title="Fecha de inicio"
                  name="event_dateInit"
                  type="date"
                  [(ngModel)]="event.dateInit"
                  [required]="true"
                ></app-input-group>
                <app-input-group
                  title="Hora de inicio"
                  name="event_timeInit"
                  type="time"
                  [(ngModel)]="event.timeInit"
                  [required]="true"
                ></app-input-group>
              </div>
              <div class="input-box">
                <app-input-group
                  title="Fecha de fin"
                  name="event_dateEnd"
                  type="date"
                  [(ngModel)]="event.dateEnd"
                ></app-input-group>
                <app-input-group
                  title="Hora de fin"
                  name="event_timeEnd"
                  type="time"
                  [(ngModel)]="event.timeEnd"
                ></app-input-group>
              </div>
              <div class="input-box">
                <app-input-group
                  title="Teléfono de WhatsApp"
                  name="event_phone"
                  type="phone"
                  [(ngModel)]="event.phone"
                  [required]="false"
                ></app-input-group>
                <app-input-group
                  title="Link de Registro"
                  name="event_linkRegister"
                  type="url"
                  [(ngModel)]="event.linkRegister"
                  [required]="false"
                ></app-input-group>
              </div>
              <div class="input-box">
                <app-select-group
                  [title]="subPageOptions.length === 1 && subPageOptions[0].value === '' ? 'Sub Categoría (Esta sección no tiene subcategorías)' : 'Sub Categoría'"
                  [(value)]="selectedSubPageSlug"
                  [options]="subPageOptions"
                  [disabled]="(subPageOptions.length === 1 && subPageOptions[0].value === '') || (isEditMode && dbSubPage != null)"
                  (valueChange)="onSelectSubPage()"
                ></app-select-group>
              </div>
              <app-text-editor
                title="Contenido"
                name="event_content"
                [value]="event.content"
                [(ngModel)]="event.content"
              ></app-text-editor>
              <div class="section-videos">
                <div class="header">
                  <h2>Videos</h2>
                  <span class="icon icon-add" (click)="addVideo()"><mat-icon>add</mat-icon></span>
                </div>
                @for (linkObject of event.videos; track linkObject; let i = $index) {
                  <div class="container-inputs">
                    <div class="input-box">
                      <app-input-group
                        title="Enlace de video:"
                        name="events_videos_links.{{i}}"
                        type="url"
                        [(ngModel)]="linkObject.link"
                        [required]="true"
                      ></app-input-group>
                    </div>
                    <span class="icon icon-remove" (click)="removeVideo(i)"><mat-icon>remove</mat-icon></span>
                  </div>
                }
              </div>
              <div class="section-fotos">
                <div class="header">
                  <h2>Fotos</h2>
                  <span class="icon icon-add" (click)="addFoto()"><mat-icon>add</mat-icon></span>
                </div>
                @for (imagen of event.images; track imagen; let i = $index) {
                  <div class="container-inputs">
                    <div class="input-box">
                      <app-input-group
                        title="Enlace de foto:"
                        name="events_fotos_links.{{i}}"
                        type="url"
                        [(ngModel)]="imagen.link"
                        [required]="true"
                      ></app-input-group>
                    </div>
                    <span class="icon icon-remove" (click)="removeFoto(i)"><mat-icon>remove</mat-icon></span>
                  </div>
                }
              </div>
              <div class="section-presentations">
                <div class="header">
                  <h2>Presentaciones</h2>
                  <span class="icon icon-add" (click)="addPresentation()"><mat-icon>add</mat-icon></span>
                </div>
                @for (presentation of event.presentations; track presentation; let i = $index) {
                  <div class="container-inputs">
                    <div class="input-box">
                      <app-input-group
                        title="Nombre de la presentación"
                        name="events_presentation_name.{{i}}"
                        [(ngModel)]="presentation.name"
                        [maxlength]="validatorsInput.MAX_TEXT"
                        [required]="true"
                      ></app-input-group>
                      <app-input-group
                        title="Enlace de presentación"
                        name="events_presentation_link.{{i}}"
                        type="url"
                        [(ngModel)]="presentation.link"
                        [required]="true"
                      ></app-input-group>
                    </div>
                    <div class="input-box">
                      <app-input-group
                        title="Nombre de Expositor:"
                        name="events_presentation_speaker.{{i}}"
                        [(ngModel)]="presentation.speaker"
                        [maxlength]="validatorsInput.MAX_TEXT"
                        [required]="true"
                      ></app-input-group>
                    </div>
                    <span class="icon icon-remove" (click)="removePresentation(i)"><mat-icon>remove</mat-icon></span>
                  </div>
                }
              </div>
              <div class="section-sponsors">
                <div class="header">
                  <h2>Auspiciadores</h2>
                  <span class="icon icon-add" (click)="addSponsor()"><mat-icon>add</mat-icon></span>
                </div>
                @for (sponsor of event.sponsors; track sponsor; let i = $index) {
                  <div class="container-inputs">
                    <div class="input-box">
                      <app-input-group
                        title="Enlace de imagen de logo:"
                        name="events_sponsors_linkImage.{{i}}"
                        type="url"
                        [(ngModel)]="sponsor.linkImage"
                        [required] = "true"
                      ></app-input-group>
                      <app-input-group
                        title="Enlace de Propuesta Comercial"
                        name="events_sponsors_linkCommercial.{{i}}"
                        type="url"
                        [(ngModel)]="sponsor.linkCommercial"
                      ></app-input-group>
                    </div>
                    <span class="icon icon-remove" (click)="removeSponsor(i)"><mat-icon>remove</mat-icon></span>
                  </div>
                }
              </div>
              <app-upload-file
                [imagePlaceholder]="getLinkImage(event.linkImage)"
                (fileSelectedEmitter)="onImageSelected($event)"
                [notPdf]="true"
              ></app-upload-file>
            </div>
          </div>
          <div class="actions">
            <a class="btn secondary" (click)="backPage()">Cancelar</a>
            <button class="btn primary" (click)="onSave()">Guardar</button>
          </div>
        </form>
      }
    </div>
  </div>
</div>
