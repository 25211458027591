@if (showTitle) {
  <p class="title">{{ title }}:</p>
}
<div class="editor-container">
  <div [class]="invalid ? 'border-error' : ''">
    <p-editor
      #quillEditor
      [name]="name"
      [(ngModel)]="value"
      [style]="{'height':'320px'}"
      (ngModelChange)="onEditorContentChange($event)"
    ></p-editor>
  </div>
  @if (invalid) {
    <div class="invalid">
      {{ errorMessage }}
    </div>
  }
  <div class="margin-custom">
    Caracteres actuales: <span class="color-counter">{{ currentLength }}</span>, max: {{ maxlength }}
  </div>
</div>
