<div class="container_section_large page_with_filter">
  <div class="page filter">
    <asei-title-section
      title="Directorio ASEI"
      [link]="'/directorio-asei/nuevo'"
      [editLink]="'/directorio-asei/editar'"
      actionText="Nuevo"
      editActionText="Editar"
      [hasPermission]="canEdit"
      version="four"
    ></asei-title-section>
  </div>
  <div>
    <mat-accordion>
      @for (item of data; track item; let i = $index) {
        <mat-expansion-panel [expanded]="i === 0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{item.category}}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div [innerHtml]="getSafeContent(item.text)"></div>
        </mat-expansion-panel>
      }
    </mat-accordion>
  </div>
</div>
