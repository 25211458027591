<div class="card" (click)="preview(event)">
  <div class="box">
    <div class="date">
      <span class="month">{{ getMonth(event.dateInit) }}</span>
      <span class="day">{{getDay(event.dateInit)}}</span>
    </div>
    <div class="content">
      <div class="asei_card_info_meeting_content-info--datetime">
        <div class="asei_card_info_meeting_content-info--date">
          <mat-icon>calendar_month</mat-icon>
          <span>
            {{ getDateDDMMYYYY(event.dateInit) }}
            {{ event.dateEnd ? ' - ' + getDateDDMMYYYY(event.dateEnd) : '' }}
          </span>
        </div>
        @if (event.timeInit) {
          <div class="asei_card_info_meeting_content-info--time">
            <mat-icon>access_time</mat-icon>
            <span class="time_card">
              {{ getHourInFormat12HRS(event.timeInit) }}
              {{ event.timeEnd ? ' - ' + getHourInFormat12HRS(event.timeEnd) : '' }}
            </span>
          </div>
        }
      </div>
      <h2 class="title">{{ event.title }}</h2>
      <div class="description">{{event.description}}</div>
    </div>
    <div class="actions">
      <div class="badge">
        <img src="/assets/img/icons/arrow-rigth.svg" alt="" srcset="">
      </div>
    </div>
  </div>
</div>
