import { PopupEditComponent } from './../form/edit/popup-edit.component';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService, MessageService, TreeNode } from 'primeng/api';
import { Observable, catchError, map, of } from 'rxjs';
import Popup from '../../interfaces/popup.interface';
import { PopupService } from '../../services/popup.service';
import { getDateDDMMYYYY, getDateYYYYMMDD, getLinkImage } from 'src/app/helpers/converters.helper';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrl: './popup.component.scss',
  providers: [MessageService, ConfirmationService, DialogService]
})
export class PopupListComponent implements OnInit {
  hasPermission: boolean = true;
  image: any;
  popupModal: DynamicDialogRef | undefined;
  getPopupsFiltered: Popup | undefined;
  filterType: string = 'popup';
  slugFilter: string = '';
  filter: any;
  filterDateStartFormat: any;
  filterDateEndFormat: any;
  filterPage: string = '';

  constructor(
    private popupService: PopupService,
    private readonly dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
  ) { }

  ngOnInit(): void {
    this.slugFilter = 'popup';
    //this.getPopups();
    this.initializeFilters();
  }

  async getPopups(
    dateStart = '',
    dateEnd = '',
  ) {
    this.popupService.callGetList(dateStart, dateEnd).subscribe();
  }

  get popups(): Observable<any[]> {
    return this.popupService.getList();
  }

  async onFilter(value: any) {
    let {dateStartFilter, dateEndFilter, searchAll} = value;

    if (dateStartFilter){
      dateStartFilter = DateTime.fromFormat(
        dateStartFilter,
        'dd/MM/yyyy'
      ).toFormat('yyyy-MM-dd');
    }

    if (dateEndFilter){
      dateEndFilter = DateTime.fromFormat(
        dateEndFilter,
        'dd/MM/yyyy'
      ).toFormat('yyyy-MM-dd');
    }
    this.getPopups(dateStartFilter, dateEndFilter);

    this.filter = {
      type: 'popup',
      slug: this.slugFilter,
      dateStart: dateStartFilter,
      dateEnd: dateEndFilter,
    }
    localStorage.setItem('filters', JSON.stringify(this.filter));
  }

  private initializeFilters(): void {
    const storedFilters = localStorage.getItem('filters');

    if (!storedFilters) {
      this.getPopups('', '');
      return;
    }

    this.filter = JSON.parse(storedFilters);
    const isFilterChanged = this.isFilterChanged();

    if (isFilterChanged) {
      this.resetFilters();
      this.getPopups('', '');
      this.filter.page = '1';
      localStorage.setItem('filters', JSON.stringify({page: '1'}));
    } else {
      this.applyFilters();
      this.getPopups(this.filter.dateStart, this.filter.dateEnd);
    }
  }

  private isFilterChanged(): boolean {
    return this.filterType !== this.filter.type || this.slugFilter !== this.filter.slug;
  }

  private resetFilters(): void {
    localStorage.removeItem('filters');
    this.filterDateStartFormat = '';
    this.filterDateEndFormat = '';
  }

  private applyFilters(): void {
    if (this.filter.dateStart) {
      this.filterDateStartFormat = DateTime.fromFormat(this.filter.dateStart, 'yyyy-MM-dd')
        .toFormat('dd/MM/yyyy');
    }

    if (this.filter.dateEnd) {
      this.filterDateEndFormat = DateTime.fromFormat(this.filter.dateEnd, 'yyyy-MM-dd')
        .toFormat('dd/MM/yyyy');
    }
  }

  getImage(image: any){
    return getLinkImage(image);
  }

  formatDate(date: string){
    return getDateDDMMYYYY(date);
  }

  addPopup(){
    this.popupModal = this.dialogService.open(
      PopupEditComponent, {
        data: null,
        header: "Crear popup",
        style: {
          width: '80%',
          height: '90%',
        },
      }
    );

    this.popupModal?.onClose.subscribe({
      next: (value) => {
          value && value?.success
              ? this.showSuccess('Popup creado.')
              : value?.error
                  ? this.showError(value?.error)
                  : null;
      },
    });
  }

  editPopup(id: number){
    this.popupModal = this.dialogService.open(
      PopupEditComponent, {
        data: {
          id,
        },
        header: "Editar popup",
        style: {
          width: '80%',
          height: '90%',
        },
      }
    );

    this.popupModal?.onClose.subscribe({
      next: (value) => {
          value && value?.success
              ? this.showSuccess('Popup Actualizado.')
              : value?.error
                  ? this.showError(value?.error)
                  : null;
      },
    });
  }
  deletePopup(event: Event, id: any){
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: 'Deseas eliminar este popup?',
      header: 'Eliminar popup',
      icon: 'pi pi-info-circle',
      acceptButtonStyleClass:"p-button-danger p-button-text",
      rejectButtonStyleClass:"p-button-text p-button-text",
      acceptIcon:"none",
      rejectIcon:"none",

      accept: () => {
          this.popupService.delete(id).subscribe(() => {
            this.showSuccess('Popup eliminado.');
          });
      },
      reject: () => {
        this.showError('No se eleminó el popup.');
      }
    });
  }

  showSuccess(message: string) {
    this.messageService.add({severity: 'success', summary: 'Operación exitosa', detail: message, life: 3000});
  }

  showError(message: string) {
    this.messageService.add({severity: 'error', summary: 'Error', detail: message, life: 3000});
  }
}
