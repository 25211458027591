<div class="page" #top>
  <asei-notifications [notifications]="notifications"></asei-notifications>
  <p-toast></p-toast>
  <div class="main">
    <div class="card personal-information">
      @if(form){
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
          <div class="information">
            <div class="section">
              <div class="input-box">
                <app-asei-input
                  label="Fecha de Inicio"
                  type="date"
                  controlName="dateInit"
                  [required]="true"
                  [value]="form.get('dateInit')?.value"
                />
                <app-asei-input
                  label="Hora de Inicio"
                  type="time"
                  controlName="timeInit"
                  [required]="true"
                  [value]="form.get('timeInit')?.value"
                />
              </div>
              <div class="input-box">
                <app-asei-input
                  label="Fecha de Fin"
                  type="date"
                  controlName="dateEnd"
                  [required]="true"
                  [value]="form.get('dateEnd')?.value"
                />
                <app-asei-input
                  label="Hora de Fin"
                  type="time"
                  controlName="timeEnd"
                  [required]="true"
                  [value]="form.get('timeEnd')?.value"
                />
              </div>
              <div class="input-box">
                <app-asei-input
                  label="Enlace"
                  type="url"
                  controlName="url"
                  [value]="form.get('url')?.value"
                  />
              </div>
              <div class="input-box">
                <label>
                  <input type="checkbox" formControlName="enableUrl" (change)="onToggleUrl()" />
                  Activar redirección de imagen
                </label>
              </div>
              <app-asei-upload-file
                accept="image/*"
                controlName="image"
                [required]="true"
                [value]="form.get('image')?.value"
              />
            </div>
            <div class="actions">
              <button
                class="btn primary"
                type="submit"
              >
                Guardar
              </button>
            </div>
          </div>
        </form>
      }
    </div>
  </div>
</div>
