<div class="navHeaderMobile">
  <div class="navHeaderMobile_container">
    <mat-icon (click)="changeShowHeaderMobile()" class="icon_menu" aria-hidden="false" aria-label="menu" fontIcon="menu"></mat-icon>
    <div  class="avatar">
      @if (avatarUrl) {
        <img class="image" [src]="avatarUrl" alt="avatar">
      }
      @if (!avatarUrl) {
        <img src="../../../assets/img/icons/profile_default.jpg" alt="avatar"/>
      }
    </div>
  </div>
</div>
