@if (report) {
  <div class="page container_section_large">
    <div class="actions">
      <span class="button" (click)="backPage()">
        <mat-icon>keyboard_backspace</mat-icon>
      </span>
    </div>
    <div class="container_section_asei">
      <div class="flyer">
        <img [src]="getImageOThumbnail(report.file, report.fileThumbnail)" alt="">
        <!-- @if(isFile == true){
          <img src="https://fakeimg.pl/600x400?text=Archivo&font=bebas" alt="">
        }@else if(isNotImg == true){
          <img src="https://fakeimg.pl/600x400?text=Sin%20Archivo&font=bebas" alt="">

        }@else {

        } -->
      </div>
      <div>
        <span class="button" (click)="onClickDownload(report)" *ngIf="report.file ? 'disabled' : ''">
          Descargar
        </span>
      </div>
    </div>
    <div class="main">
      <p class="date-time">
        <mat-icon>calendar_month</mat-icon>
        <span>{{getDateDDMMYYYY(report.creationDate)}}</span>
      </p>
      <h2 class="title">{{report.title}}</h2>
      @if (report.description) {
      <div class="content" [innerHTML]="report.description" id="content_event"></div>
      }
      <!-- @if (post.links.length>0) {
      <div class="links-container">
        <p>Documentos a descargar:</p>
        @for (link of post.links; track link) {
        <a [href]="link" target="_blank">{{ link }}</a>
        }
      </div>
      } -->
    </div>
  </div>
  <p-toast></p-toast>
  }
