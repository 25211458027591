import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import Evento from '../interfaces/evento.interface';
import UserData from '../interfaces/user-data.interface';

const URL = environment.url + '/api/v1';

@Injectable({
  providedIn: 'root',
})
export class FilesService {
  private httpOptions = {};

  constructor(private http: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/json',
      }),
      observe: 'response' as const, // Asegura que el tipo de 'observe' sea correcto
    };
  }

  uploadImage(image: File, makeThumbnail = 'yes') {
    const url = `${URL}/uploads/images`;
    let formData = new FormData();
    formData.append('image', image);
    formData.append('makeThumbnail', makeThumbnail);
    return this.http
      .post<any>(url, formData, this.httpOptions)
      .pipe(map((response) => response));
  }

  DeleteImage(image: string) {
    const url = `${URL}/uploads/images/${image}`;
    return this.http.delete<any>(url).pipe(map((response) =>  response));
  }

  downloadImage(image: string){
    const url = `${URL}/downloads/images/${image}`;
    return this.http.get(url, { responseType: 'blob' });
  }

  uploadPDF(file: any, makeThumbnail = 'no') {
    const url = `${URL}/uploads/pdfs`;
    let formData = new FormData();
    formData.append('file', file)
    formData.append('makeThumbnail', makeThumbnail)
    return this.http.post<any>(url, formData, this.httpOptions).pipe(
      map(response => response)
    );
  }

  DeleteFile(file: string) {
    const url = `${URL}/uploads/pdfs/${file}`;
    return this.http.delete<any>(url).pipe(map((response) =>  response));
  }

  downloadFile(file: string){
    const url = `${URL}/downloads/pdfs/${file}`;
    return this.http.get(url, { responseType: 'blob' });
  }
}
