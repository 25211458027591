<div class="page container_section_large">

    <asei-title-section
    link="/directorio-afiliados/nuevo"
    title="Directorio Afiliados"
    description="Gestiona la información de los afiliados de ASEI"
    actionText="Nuevo"
    actionExportText="Exportar"
    [hasPermission]="canAddNew"
    version="three"
  ></asei-title-section>
  <div class="main">
    <div class="container_filter">
      <div class="filter_button">
        <p>Filtrar por:</p>
        <button [ngClass]="{'active': type==='inmobiliaria'}" (click)="getType('inmobiliaria')" class="btn">Asociados</button>
        <button [ngClass]="{'active': type==='aliado-estrategico'}" (click)="getType('aliado-estrategico')" class="btn">Aliados estratégicos</button>
      </div>
      <div class="search">
        <input type="text" placeholder="Buscar Afiliado por su nombre"
          [(ngModel)]="searchByFullName"
          (input)="onFilter()">
        @if (searchByFullName) {
          <span class="clear-btn" (click)="clearSearchInput()">
            <mat-icon>close</mat-icon>
          </span>
          <!-- <span class="clear-btn" (click)="clearSearchInput()">
            <mat-icon>close</mat-icon>
          </span> -->
        }
      </div>
    </div>
    <div class="container_item">
      <div class="item-list list-custom">
        @for (item of business; track item) {
          <asei-item-list
            class="custom-item"
            [name]="item.name"
            [businessName]="item.businessName"
            environment="directorio-afiliados"
            [urlImage]="item.image"
            version="inverse"
            [isSelected]="true"
            ($showMore)="redirect(item.id)"
            [canShowMore]="true"
            [showInactive]="!item.isActive"
          ></asei-item-list>
        }
      </div>
      @if (paginatorData) {
        <app-paginator [paginatorData]="paginatorData"
          (pageSelected)="onPageSelected($event)"
        ></app-paginator>
      }
    </div>
  </div>
</div>
