<div class = "asei_card_info_main inverse">
  <div class="asei_card_info {{ background }}" (click)="navigateTo(environmentss)">
    @if (isShowTitle) {
      <div class="asei_card_info_header">
        <h1>{{aseiCardInfo.title}}</h1>
        <button>
          <img src="assets/img/icons/Flecha.svg" alt="Mi icono SVG">
        </button>
      </div>
    }
    <div class="asei_card_info_content">
      <div class="asei_card_info_content-image">
        <img [src]="getLinkImage(aseiCardInfo.linkImage)" alt=""/>
        @if (flagText) {
          <span class="asei_card_info_content-image--flag">{{flagText}}</span>
        }
      </div>
      <div class="asei_card_info_content-info">
        <div class="asei_card_info_meeting_content-info--datetime">
          <div class="asei_card_info_meeting_content-info--date">
            <mat-icon>calendar_month</mat-icon>
            <span>
              {{ getDateDDMMYYYY(aseiCardInfo.dateInit) }}
              {{ aseiCardInfo.dateEnd ? ' - ' + getDateDDMMYYYY(aseiCardInfo.dateEnd) : '' }}
            </span>
          </div>
          @if (aseiCardInfo.timeInit) {
            <div class="asei_card_info_meeting_content-info--time">
              <mat-icon>access_time</mat-icon>
              <span>
                {{ getHourInFormat12HRS(aseiCardInfo.timeInit) }}
                {{ aseiCardInfo.timeEnd ? ' - ' + getHourInFormat12HRS(aseiCardInfo.timeEnd) : '' }}
              </span>
            </div>
          }
        </div>
        <h2 >{{aseiCardInfo.title}}</h2>
        <p class="asei_card_info_content-info--description">{{aseiCardInfo.description}}</p>
      </div>
    </div>
  </div>
  @if (canEdit || canDelete) {
    <div class="actions">
      @if (canEdit) {
        <mat-icon (click)="edit(environmentss)">edit</mat-icon>
      }
      @if (canDelete) {
        <mat-icon (click)="openDialogDelete('250ms', '250ms')">delete</mat-icon>
      }
    </div>
  }
</div>
