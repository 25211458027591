<div class="header">
  <div class="content">
    <h2 class="title">{{ title }}</h2>
    <p class="subtitle">{{ subtitle }}</p>
  </div>
  @if (hasPermission) {
    <div class="actions">
      <a (click)="redirect(redirectTo)">
        <span class="badge">{{actionText}}</span>
      </a>
    </div>
  }
</div>
