import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatCardModule} from '@angular/material/card';
import {SwiperModule} from 'swiper/angular';
import {MatDialogModule} from '@angular/material/dialog';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";

import {EventPreviewButtonsComponent} from "./event-preview-buttons/event-preview-buttons.component";
import {CdkListbox} from "@angular/cdk/listbox";
import {HeaderPageComponent} from "./header-page/header-page.component";
import {InputGroupComponent} from "./input-group/input-group.component";
import {TextareaGroupComponent} from "./textarea-group/textarea-group.component";
import {SwitchComponent} from "./switch/switch.component";
import {PaginatorComponent} from "./paginator/paginator.component";
import {DirectorioAfiliadosInicioComponent} from './directorio-afiliados-inicio/directorio-afiliados-inicio.component';
import {CardSimpleComponent} from './cards/card-simple/card-simple.component';
import {AseiMeetingInicioComponent} from './asei-meeting-inicio/asei-meeting-inicio.component';
import {CardSimple2Component} from './cards/card-simple2/card-simple2.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatSelectModule} from "@angular/material/select";
import {SelectGroupComponent} from "./select-group/select-group.component";
import {ImageInputComponent} from "./image-input/image-input.component";
import {MultimediaModalComponent} from './video-modal/multimedia-modal.component';
import {LazyLoadDirective} from '../directives/lazy-load.directive';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from "@angular/material/button";
import {ASEICardInfoComponent} from './asei-card-info/asei-card-info.component';
import {ASEITitleSectionComponent} from './asei-title-section/asei-title-section.component';
import {ASEICardInfoMasterComponent} from './asei-card-info-master/asei-card-info-master.component';
import {ASEIItemListComponent} from './asei-item-list/asei-item-list.component';
import {PresentacionModalComponent} from "./presentacion-modal/presentacion-modal.component";
import {RouterModule} from '@angular/router';
import {CalendarPageComponent} from "../pages/calendar/calendar.component";
import {AseiCalendarComponent} from "./asei-calendar/asei-calendar.component";
import {AseiCalendarModalComponent} from "./asei-calendar-modal/asei-calendar-modal.component";
import {ASEISidebarMobileComponent} from './sidebar-mobile/sidebar-mobile.component';
import {UploadFileComponent} from "./upload-image/upload-file.component";
import {TextEditorComponent} from "./text-editor/text-editor.component";
import {NgxEditorModule} from "ngx-editor";
import {AseiNotificationsComponent} from './asei-notifications/asei-notifications.component';
import {SpinnerComponent} from './spinner/spinner.component';
import {AccountEditComponent} from "../pages/account/pages/form/account-edit.component";
import {PopupEditComponent} from "../pages/popups/pages/form/edit/popup-edit.component";
import {SectionFilterComponent} from './section-filter/section-filter.component';
import {DeleteModalComponent} from "./delete-modal/delete-modal.component";
import {AseiUploadAvatarComponent} from './asei-upload-avatar/asei-upload-avatar.component';
import {AseiInputSliderComponent} from './asei-input-slider/asei-input-slider.component';
import {AseiRadioButtonComponent} from './asei-radio-button/asei-radio-button.component';
import {AseiUsersStatusModalComponent} from "./asei-users-status-modal/asei-users-status-modal.component";
import {AseiHomeComponent} from "./asei-home/asei-home.component";
import {MenuItemLinkComponent} from "./menu-item-link/menu-item-link.component";
import {MenuItemDropdownComponent} from "./menu-item-dropdown/menu-item-dropdown.component";
import {ToastModule} from "primeng/toast";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {SidenavComponent} from "./sidenav/sidenav.component";
import {FooterComponent} from "./footer/footer.component";
import {CardAseiAniversarioOldComponent} from "./card-asei-aniversario-old/card-asei-aniversario-old.component";
import {CardDirectiveComponent} from "./card-directive/card-directive.component";
import {CardReportComponent} from "./card-informe-de-mercado/card-report.component";
import {AseiInputComponent} from "./asei-input/asei-input.component";
import {AseiUploadFileComponent} from "./asei-upload-file/asei-upload-file.component";
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { ImageModule } from 'primeng/image';
import { EditorModule } from 'primeng/editor';

@NgModule({
  declarations: [
    EventPreviewButtonsComponent,
    HeaderPageComponent,
    InputGroupComponent,
    TextareaGroupComponent,
    ImageInputComponent,
    CardAseiAniversarioOldComponent,
    CardDirectiveComponent,
    SelectGroupComponent,
    SwitchComponent,
    PaginatorComponent,
    DirectorioAfiliadosInicioComponent,
    CardSimpleComponent,
    AseiMeetingInicioComponent,
    CardSimple2Component,
    MultimediaModalComponent,
    LazyLoadDirective,
    ASEICardInfoComponent,
    ASEITitleSectionComponent,
    ASEICardInfoMasterComponent,
    ASEIItemListComponent,
    PresentacionModalComponent,
    CardReportComponent,
    CalendarPageComponent,
    AseiCalendarComponent,
    AseiCalendarModalComponent,
    AseiCalendarModalComponent,
    ASEISidebarMobileComponent,
    UploadFileComponent,
    TextEditorComponent,
    AseiNotificationsComponent,
    SpinnerComponent,
    AccountEditComponent,
    PopupEditComponent,
    SectionFilterComponent,
    DeleteModalComponent,
    AseiUploadAvatarComponent,
    AseiInputSliderComponent,
    AseiRadioButtonComponent,
    AseiUsersStatusModalComponent,
    AseiHomeComponent,
    SidenavComponent,
    FooterComponent,
    AseiInputComponent,
    AseiUploadFileComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    CdkListbox,
    MatListModule,
    MatCardModule,
    SwiperModule,
    FormsModule,
    MatSelectModule,
    MatDialogModule,
    MatMenuModule,
    MatButtonModule,
    NgOptimizedImage,
    RouterModule,
    ReactiveFormsModule,
    NgxEditorModule,
    MenuItemLinkComponent,
    MenuItemDropdownComponent,
    ToastModule,
    ConfirmDialogModule,
    CalendarModule,
    DropdownModule,
    ImageModule,
    EditorModule,
  ],
  exports: [
    EventPreviewButtonsComponent,
    HeaderPageComponent,
    InputGroupComponent,
    TextareaGroupComponent,
    SelectGroupComponent,
    ImageInputComponent,
    CardAseiAniversarioOldComponent,
    CardDirectiveComponent,
    PaginatorComponent,
    CardReportComponent,
    DirectorioAfiliadosInicioComponent,
    AseiMeetingInicioComponent,
    CardSimple2Component,
    ASEICardInfoComponent,
    ASEITitleSectionComponent,
    ASEICardInfoMasterComponent,
    SwiperModule,
    ASEIItemListComponent,
    PresentacionModalComponent,
    CalendarPageComponent,
    AseiCalendarComponent,
    AseiCalendarModalComponent,
    AseiCalendarModalComponent,
    ASEISidebarMobileComponent,
    UploadFileComponent,
    TextEditorComponent,
    AseiNotificationsComponent,
    MatProgressSpinnerModule,
    SpinnerComponent,
    AccountEditComponent,
    PopupEditComponent,
    SectionFilterComponent,
    DeleteModalComponent,
    AseiUploadAvatarComponent,
    AseiInputSliderComponent,
    AseiRadioButtonComponent,
    MatCardModule,
    AseiUsersStatusModalComponent,
    AseiHomeComponent,
    MenuItemLinkComponent,
    MenuItemDropdownComponent,
    SidenavComponent,
    FooterComponent,
    AseiUploadFileComponent,
    AseiInputComponent
  ]
})
export class ComponentsModule {
}
