import {ChangeDetectorRef, Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {MatDrawer, MatDrawerMode} from '@angular/material/sidenav';
import {displaySize} from '../helpers/size-by-display.helper';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnInit {
  @ViewChild('drawer') drawer!: MatDrawer;
  isShowSidebar = true;
  format: MatDrawerMode = 'side';
  constructor(private changeDetectorRef: ChangeDetectorRef) {} // Inyectar ChangeDetectorRef

  @HostListener('window:resize', ['$event'])
  onWindowResize(event: Event) {
    this.getShowSidebar();
  }

  ngAfterViewInit(): void {
    this.getShowSidebar()
  }

  ngOnInit(): void {
  }

  getShowSidebar() {
    const windowWidth = window.innerWidth;
    if (windowWidth < displaySize['lg']) {
      this.isShowSidebar = false;
      this.closeDrawer();
      this.format = 'over';
    } else {
      this.isShowSidebar = true;
      this.openDrawer();
      this.format = 'side';
    }
    this.changeDetectorRef.detectChanges(); // Forzar verificación de cambios
  }

  changeOpenMobileSidebar(value: boolean): void {
    this.toggleDrawer()
  }
  handleLinkClicked(linkName: boolean) {
    const windowWidth = window.innerWidth;
    if(windowWidth < displaySize['lg']){
      this.closeDrawer();
    }
  }

  openDrawer() {
    this.drawer.open();
  }

  closeDrawer() {
    this.drawer.close();
  }

  toggleDrawer() {
    this.drawer.toggle();
  }
}
