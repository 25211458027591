<div class="container">
  <div class="header-container">
    <div class="title-section-container">
      <h3 class="title-section">Directorio Afiliados</h3>
      <p class="subtitle-section">Gestiona la informacion de los directorios de ASEI</p>
    </div>
    <div class="title-link">
      <a mat-list-item routerLink="/directorio-afiliados" routerLinkActive="list-item-active">
        Ver más
      </a>
    </div>
  </div>
  <span></span>
  @for (user of users; track user) {
    <app-card-directive
      [image]="user.imagenes.length > 0 ? getImage(user.imagenes[0].RUTA) : ''"
      [name]="user.NOMBRES + ' '+ user.APELLIDO_PATERNO"
      [job]="user.CARGO"
      [inmobiliaria]="user.NOMBRE_INMOB"
      [isActive]="false"
    ></app-card-directive>
  }
  <!--  <mat-list>-->
  <!--    <mat-list-item>-->
  <!--      <div class="titulo-header">-->
  <!--        <div class="titulo">-->
  <!--          <div class="avatar">-->
  <!--            <div class="initials">-->
  <!--              <span class="first-letter">C</span>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="descripcion">-->
<!--            <span class="last-name">Carlos Bustamante</span>-->
<!--            <span class="cargo">Gerente general</span>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="titulo-link">-->
<!--          Inmoviliaria Abril-->
<!--        </div>-->
<!--        <div class="icono-link">-->
<!--          <div class="circle">-->
<!--            <mat-icon class="icon">chevron_right</mat-icon>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

<!--    </mat-list-item>-->
<!--    <mat-divider></mat-divider>-->
<!--    <mat-list-item>-->
<!--      <div class="titulo-header">-->
<!--        <div class="titulo">-->
<!--          <div class="avatar">-->
<!--            <div class="initials">-->
<!--              <span class="first-letter">E</span>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="descripcion">-->
<!--            <span class="last-name">Eduardo Alfonso Lopez</span>-->
<!--            <span class="cargo">Gerente general</span>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="titulo-link">-->
<!--          Inmoviliaria Abril-->
<!--        </div>-->
<!--        <div class="icono-link">-->
<!--          <div class="circle">-->
<!--            <mat-icon class="icon">chevron_right</mat-icon>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

<!--    </mat-list-item>-->
<!--    <mat-divider></mat-divider>-->
<!--    <mat-list-item>-->
<!--      <div class="titulo-header">-->
<!--        <div class="titulo">-->
<!--          <div class="avatar">-->
<!--            <div class="initials">-->
<!--              <span class="first-letter">A</span>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="descripcion">-->
<!--            <span class="last-name">Alfredo Luis Quintana</span>-->
<!--            <span class="cargo">Gerente general</span>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="titulo-link">-->
<!--          Inmoviliaria Abril-->
<!--        </div>-->
<!--        <div class="icono-link">-->
<!--          <div class="circle">-->
<!--            <mat-icon class="icon">chevron_right</mat-icon>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

<!--    </mat-list-item>-->
<!--    <mat-divider></mat-divider>-->
<!--    <mat-list-item>-->
<!--      <div class="titulo-header">-->
<!--        <div class="titulo">-->
<!--          <div class="avatar">-->
<!--            <div class="initials">-->
<!--              <span class="first-letter">E</span>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="descripcion">-->
<!--            <span class="last-name">Ernesto Adolfo Rodriguez</span>-->
<!--            <span class="cargo">Gerente general</span>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="titulo-link">-->
<!--          Inmoviliaria Abril-->
<!--        </div>-->
<!--        <div class="icono-link">-->
<!--          <div class="circle">-->
<!--            <mat-icon class="icon">chevron_right</mat-icon>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

<!--    </mat-list-item>-->
<!--    <mat-divider></mat-divider>-->
<!--    <mat-list-item>-->
<!--      <div class="titulo-header">-->
<!--        <div class="titulo">-->
<!--          <div class="avatar">-->
<!--            <div class="initials">-->
<!--              <span class="first-letter">J</span>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="descripcion">-->
<!--            <span class="last-name">Jose Emilio Carrera</span>-->
<!--            <span class="cargo">Gerente general</span>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="titulo-link">-->
<!--          Inmoviliaria Abril-->
<!--        </div>-->
<!--        <div class="icono-link">-->
<!--          <div class="circle">-->
<!--            <mat-icon class="icon">chevron_right</mat-icon>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

<!--    </mat-list-item>-->
<!--    <mat-divider></mat-divider>-->
<!--    <mat-list-item>-->
<!--      <div class="titulo-header">-->
<!--        <div class="titulo">-->
<!--          <div class="avatar">-->
<!--            <div class="initials">-->
<!--              <span class="first-letter">J</span>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="descripcion">-->
<!--            <span class="last-name">Jose Emilio Carrera</span>-->
<!--            <span class="cargo">Gerente general</span>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="titulo-link">-->
<!--          Inmoviliaria Abril-->
<!--        </div>-->
<!--        <div class="icono-link">-->
<!--          <div class="circle">-->
<!--            <mat-icon class="icon">chevron_right</mat-icon>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

<!--    </mat-list-item>-->
<!--    <mat-divider></mat-divider>-->
<!--    <mat-list-item>-->
<!--      <div class="titulo-header">-->
<!--        <div class="titulo">-->
<!--          <div class="avatar">-->
<!--            <div class="initials">-->
<!--              <span class="first-letter">A</span>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="descripcion">-->
<!--            <span class="last-name">Alfredo Luis Quintana</span>-->
<!--            <span class="cargo">Gerente general</span>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="titulo-link">-->
<!--          Inmoviliaria Abril-->
<!--        </div>-->
<!--        <div class="icono-link">-->
<!--          <div class="circle">-->
<!--            <mat-icon class="icon">chevron_right</mat-icon>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

<!--    </mat-list-item>-->
<!--  </mat-list>-->
</div>
