import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { getThumbnailVideo } from '../helpers/converters.helper';
import UserData from '../interfaces/user-data.interface';
import { TYPE_PERMISSIONS } from '../types/constants/permission.enum';

const URL = environment.url;

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  private flagPermisions: string[] = [];

  constructor() {
    this.loadPermissions()
  }

  loadPermissions(){
    if( !localStorage.getItem('UserData') ) return
    const UserData = JSON.parse( localStorage.getItem('UserData') || '' );
    this.flagPermisions = UserData.data.roles.map((rol: { permissions: any; }) => rol.permissions)
      .flat()
      .filter((item: any, index: number, array: any[]) => array.indexOf(item) === index) || [];

  }

  getPermissionSections(action: TYPE_PERMISSIONS) {
    return this.flagPermisions.includes(action);
  }

  can(action: TYPE_PERMISSIONS) {
    return this.flagPermisions.includes(action);
  }

  canRoute( route:string ):boolean {
    return !!this.flagPermisions.filter( role => role.includes( route ) ).length
  }

}
