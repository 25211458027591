<div class="container">
    <div class="header-container">
        <div class="title-section-container">
            <h3 class="title-section">ASEI Meeting</h3>
            <p class="subtitle-section">Todos los cursos de ASEI</p>
        </div>
        <div class="title-link">
            <a mat-list-item routerLink="/directorio-afiliados" routerLinkActive="list-item-active">
                Ver más
            </a>
        </div>
    </div>
    <div class="card">
        
          <div class="image">
            <img [src]="image" alt="">
          </div>
        
        <div class="speaker">
          <img class="image-speaker" height="70" width="70"
               [src]="image2" alt="">
          <div class="info">
            <h2>{{speaker}}</h2>
            <p>Expositor</p>
            <div class="date-time">
              <span style="white-space: nowrap;">{{date}}</span>
              <span class="time" style="white-space: nowrap;">{{time}}</span>
            </div>
          </div>
        </div>
        <div class="meet">          
          <div class="description">
            <p>{{description}}</p>
          </div>
        </div>
      </div>
      
      <div class="slide-container">
        <div class="icono-link">
          <div class="circle"  (click)="slidePrev()">
              <mat-icon class="icon">chevron_left</mat-icon>
          </div>
      </div>
        <swiper #swiper [slidesPerView]="1.2">
          <ng-template swiperSlide><app-card-simple2></app-card-simple2></ng-template>
          <ng-template swiperSlide><app-card-simple2></app-card-simple2></ng-template>
          <ng-template swiperSlide><app-card-simple2></app-card-simple2></ng-template>
          <ng-template swiperSlide><app-card-simple2></app-card-simple2></ng-template>
          <ng-template swiperSlide><app-card-simple2></app-card-simple2></ng-template>
          <ng-template swiperSlide><app-card-simple2></app-card-simple2></ng-template>
          <ng-template swiperSlide><app-card-simple2></app-card-simple2></ng-template>
        </swiper>
        <div class="icono-link" style="padding-left: 2%;">
          <div class="circle" (click)="slideNext()">
              <mat-icon class="icon">chevron_right</mat-icon>
          </div>
        </div>
      </div>
  </div>
  