import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { firstValueFrom } from 'rxjs';
import { AuthService } from 'src/app/auth/services/auth.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrl: './change-password.component.css'
})
export class ChangePasswordComponent {
  errorMessage: string | undefined;
  userForm = {
    currentPassword: '',
    newPassword: '',
    newPasswordConfirmation: ''
  };
  constructor(
    private authService: AuthService,
    private readonly dialogRef: DynamicDialogRef,
  ) { }
  async ngOnInit() {
    localStorage.getItem('UserData');
  }
  async changePasswordForm(fChangePassword: NgForm) {
    try {
      if (fChangePassword.invalid) return;
      const payload = {
        current_password: this.userForm.currentPassword,
        new_password: this.userForm.newPassword,
        new_password_confirmation: this.userForm.newPasswordConfirmation
      }
      const response = await firstValueFrom(this.authService.changePassword(payload))
      if (response.ok) {
        localStorage.setItem('UserData', JSON.stringify(response.body));
        this.dialogRef.close()
      }
    } catch (error: any) {
      this.errorMessage = error.error.message;
    }
  }
}
