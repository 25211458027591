<div class="avatar-container"
     [ngClass]="{ 'avatar-container-lg': size === 'lg' }"
>
  @if (!avatarUrl) {
    <div class="avatarLetter"
         [ngClass]="{ 'avatarLetter-lg': size === 'lg' }"
    >
      <span class="first-letter">{{ getLetter() }}</span>
    </div>
  }
  @if (avatarUrl) {
    <div class="container_image_avatar">
      <img [src]="getLinkImage(avatarUrl)" alt="Avatar" class="avatar">
    </div>
  }
  @if (!disabled){
    <div (click)="selectImage()" class="btn-icon upload-icon {{ !avatarUrl ? 'active': '' }}">
      <mat-icon>camera_alt</mat-icon>
    </div>
    <div (click)="deleteImageCurrent()" class="btn-icon upload-icon-delete {{ avatarUrl ? 'active': '' }}">
      <mat-icon>delete_forever</mat-icon>
    </div>
  }
  <input type="file" #avatarInput (change)="onFileChange($event)">
</div>
